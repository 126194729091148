import React, { useState,useEffect,useContext,useRef } from 'react';
import QRCode from 'qrcode.react';
import {UserContex} from '../App'
import {Link, useNavigate, useParams} from 'react-router-dom'
import SinglePage from './SinglePage';
import AdminHeader from '../components/AdminHeader';

import html2canvas from 'html2canvas';

function SellerReport() {
    const {state, dispatch}= useContext(UserContex)

    const history = useNavigate()
    const componentRef = useRef(null);

  const [inputText, setInputText] = useState('');
  const [qrCodeText, setQRCodeText] = useState('');
  const [Zonedata,setZoneData] = useState([])
  const [data,setData] = useState([])
  const [singleEvent,setSingleEvent] = useState(null)

  const [today,setToday] = useState(new Date())
  const[singleEarning,setSingleEarning]= useState(null)
  const[seller,setSeller]= useState(null)
  const {sellerid} = useParams()

  
  useEffect(  ()=>{


    fetch('/getmyevents',{
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{
      setData(result.events)
      
  })


  fetch('/getSingleSeller',{
    method:"post",
    headers:{
      Authorization: "Bearer " +localStorage.getItem("jwt"),
      "Content-Type":"application/json"

    },
    body:JSON.stringify({
      id:sellerid
    })
}).then(res=>res.json())
.then(result=>{


    setSeller(result.seller)
  // setDataEdit(result.stock)
  

          
})


  

  },[])


 
  const singlEvent = (Itemid)=>{

    fetch(`/getsingleevent/${Itemid}`,{
      method:"get",
      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt"),
        "Content-Type":"application/json"
  
      }
  }).then(res=>res.json())
  .then(result=>{
  

    setSingleEvent(result.event)
    
            
  })


  fetch(`/getsingleearning/${sellerid}/${Itemid}`,{
    method:"get",
    headers:{
      Authorization: "Bearer " +localStorage.getItem("jwt"),
      "Content-Type":"application/json"

    }
}).then(res=>res.json())
.then(result=>{


    setSingleEarning(result.earning)
  
          
})


  
  }
    










  const handleConvertToPng = () => {

    if (!componentRef.current) return;

    // Use html2canvas to capture the content of the component
    html2canvas(componentRef.current, {
      allowTaint: true,
      useCORS: true,
    }).then((canvas) => {
      // Convert the canvas to a data URL
      const imgData = canvas.toDataURL('image/png');

      // Create a link element to trigger the download
      const link = document.createElement('a');
      link.href = imgData;
      link.download = 'Referal-QRCode.png';
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Remove the link element from the DOM
      document.body.removeChild(link);
    });
  };

//   console.log("Sinvle"singleEvent.seats)

  const Logout = ()=>{
    localStorage.clear()
    dispatch({type:"CLEAR"})
        history(`/login`)
}
 
  return (

    <>



        <main class="relative h-screen overflow-hidden bg-gray-100  rounded-2xl">
        <AdminHeader page="events"></AdminHeader>

    <div class="flex items-start justify-between">
        
        <div class="flex flex-col w-full pl-0 md:p-4 md:space-y-4">
           
                <div class="h-screen pt-2 pb-24 pl-2 pr-2 overflow-auto md:pt-0 md:pr-0 md:pl-0">
                    <div class="flex flex-col flex-wrap sm:flex-row ">
                       
                        <div class="w-full sm:w-1/2 xl:w-1/3">

    
<div class="mx-0 mb-4 sm:ml-4 xl:mr-4">


<div class="relative overflow-hidden bg-white shadow-lg rounded-2xl w-full">
    <img src="/logo.png" alt="btc logo" class="absolute w-24 h-24 rounded-full opacity-50 -top-6 -right-6 md:-right-4"/>
    <div class="px-4 py-1 sm:p-6">
        <dl>
            <dt class="text-sm font-medium leading-5 text-gray-500 truncate">
            <img alt="profil" src={seller?seller.pic:""}class="mx-auto object-cover rounded-full h-20 w-20 "/>
            </dt>
            <dd class="mt-1 text-3xl font-semibold leading-9 text-gray-900">
            {seller?seller.name:""}
            </dd>
            <dd class="font-semibold text-gray-500">
                <span>
                {seller?seller.email:""}

                </span>
            </dd>
            <dd class="font-semibold text-gray-500">
                <span>
                {seller?seller.phone:""}

                </span>
            </dd>
        </dl>
    </div>
    <div class="w-full h-2 bg-blue-200 rounded-full mt-0">
            <div class="w-full h-full text-xs text-center text-white bg-purple-500 rounded-full">
            </div>
        </div>
</div>
 
</div>



                            <div class="mx-0 mb-4 sm:ml-4 xl:mr-4">
                                <div class="w-full bg-white shadow-lg rounded-2xl ">
                                    <p class="p-4 font-bold text-black text-md ">
                                         Events
                                        <span class="ml-2 text-sm text-gray-500 ">
                                            ({data.length})
                                        </span>
                                        <span class="ml-2 text-sm text-gray-500 ">
                                          
{/* <button type="button" class="py-2 px-4 flex justify-center items-center  bg-purple-500 hover:bg-purple-600 focus:ring-red-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
<svg class="h-8 w-8 text-white"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z"/>
</svg>

    Add Event
</button> */}

                                        </span>
                                    </p>
                                    <ul>
                                        {data.map((item,index)=>{
                                            var date = new Date(item.date)
                                            //  console.log(date<today)
                                            return(
                                                <li key={index} class="flex items-center cursor-pointer justify-between py-3 text-gray-600 border-b-2 border-gray-100 "
                                                onClick={()=>{singlEvent(item._id)}}
                                                >
                                                <div class="flex items-center justify-start text-sm">
                                                    <span class="mx-4">
                                                        {index+1}
                                                    </span>
                                                    <span class="mx-4">
                                                        {item.name}
                                                    </span>
                                                   
                                                    <span class="flex items-center ml-2 text-gray-400 lg:ml-6 ">
                    
                                                    <svg class="h-6 w-6 text-gray-300"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="4" y="5" width="16" height="16" rx="2" />  <line x1="16" y1="3" x2="16" y2="7" />  <line x1="8" y1="3" x2="8" y2="7" />  <line x1="4" y1="11" x2="20" y2="11" />  <line x1="11" y1="15" x2="12" y2="15" />  <line x1="12" y1="15" x2="12" y2="18" /></svg>
                </span>
                                                    <span class="text-gray-400">
                                                        {item.date.substring(0,10)}
                                                    </span>

                                                   
                                                </div>
                                                <svg width="20" height="20" fill="currentColor" viewBox="0 0 1024 1024" class={date>today?"mx-4 text-green-500":"mx-4 text-red-500"}>
                                                <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448s448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 0 1-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8l157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z" fill="currentColor">
                                                </path>
                                            </svg>
                                            </li>
                                            )
                                        })}
                                       
                                    </ul>
                                </div>
                            </div>





                          
                        </div>
                        {singleEvent?

<>
                        
                        <div class="w-full sm:w-1/2 xl:w-1/3">

                            <div class="mb-4">


                               
                                  
                                   <div class="p-4 bg-white shadow-lg rounded-2xl ">
                                   <div class="flex flex-wrap overflow-hidden">
                                       <div class="w-full rounded shadow-sm">
                                           <div class="flex items-center justify-between mb-4">
                                               <div class="text-xl font-bold text-left text-black ">
                                                   {singleEvent?singleEvent.name:""} 
                                               </div>
                                               <div class="flex space-x-4">
                                                   <button class="p-2 text-white bg-purple-500 rounded-lg">
                                                   {singleEvent?singleEvent.date.substring(0,10):""}

                                                   </button>
                                                   
                                               </div>
                                           </div>
                                           <div class="-mx-2">
                                            

                                           <div class="relative w-full overflow-hidden bg-white shadow-lg ">
   <a href="#" class="block w-full h-full">
       <div class="flex items-center justify-between px-4 py-6 space-x-4">
           <div class="flex items-center">
               <span class="relative p-2 bg-purple-100 rounded-full">
               <svg class="h-6 w-6 text-purple-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
</svg>

               </span>
               <p class="ml-2 text-sm font-semibold text-gray-700 border-b border-gray-200 ">
               {singleEvent?singleEvent.venue:""}
               </p>
           </div>
           <div class="mt-6 text-xl font-bold text-black border-b border-gray-200 md:mt-0 ">
               $ {singleEvent?singleEvent.price:""}

               <span class="text-xs text-gray-400">
                   /Seat
               </span>
           </div>
       </div>
       <div class="w-full h-3 bg-gray-100">
           <div class=" h-full text-xs text-center text-white bg-green-400"
           
           style={{ width: singleEvent?`${(singleEvent.occupied*100)/singleEvent.seats}%` :""}}

           >
           </div>
       </div>
   </a>
</div>




<div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 my-4">


<div class="p-4 bg-white shadow-lg rounded-2xl ">
   <div class="flex items-center">
       <span class="relative p-4 bg-purple-200 rounded-xl">
           <svg width="40" fill="currentColor" height="40" class="absolute h-4 text-purple-500 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
               <path d="M1362 1185q0 153-99.5 263.5t-258.5 136.5v175q0 14-9 23t-23 9h-135q-13 0-22.5-9.5t-9.5-22.5v-175q-66-9-127.5-31t-101.5-44.5-74-48-46.5-37.5-17.5-18q-17-21-2-41l103-135q7-10 23-12 15-2 24 9l2 2q113 99 243 125 37 8 74 8 81 0 142.5-43t61.5-122q0-28-15-53t-33.5-42-58.5-37.5-66-32-80-32.5q-39-16-61.5-25t-61.5-26.5-62.5-31-56.5-35.5-53.5-42.5-43.5-49-35.5-58-21-66.5-8.5-78q0-138 98-242t255-134v-180q0-13 9.5-22.5t22.5-9.5h135q14 0 23 9t9 23v176q57 6 110.5 23t87 33.5 63.5 37.5 39 29 15 14q17 18 5 38l-81 146q-8 15-23 16-14 3-27-7-3-3-14.5-12t-39-26.5-58.5-32-74.5-26-85.5-11.5q-95 0-155 43t-60 111q0 26 8.5 48t29.5 41.5 39.5 33 56 31 60.5 27 70 27.5q53 20 81 31.5t76 35 75.5 42.5 62 50 53 63.5 31.5 76.5 13 94z">
               </path>
           </svg>
       </span>
       <p class="ml-2 text-black text-md ">
           Earnings  
       </p>
   </div>
   <div class="flex flex-col justify-start">
       <p class="my-4 text-4xl font-bold text-left text-gray-700 ">
       {singleEvent?singleEvent.price*singleEvent.occupied:""}
           <span class="text-sm">
               $
           </span>
       </p>
  
   </div>
</div>
<div class="p-4 bg-white shadow-lg rounded-2xl ">
   <div class="flex items-center">
       <span class="relative p-1 bg-purple-200 rounded-xl">
       <svg class="h-6 w-6 text-purple-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"/>
</svg>

       </span>
       <p class="ml-2 text-black text-md ">
           Reference QR
       </p>
   </div>
   <div class="flex flex-col justify-center items-center" ref={componentRef}>
       <p class="my-4 text-4xl font-bold text-left text-gray-700 ">
       <QRCode value={`www.bodhi5entertainment.com/reffered/${sellerid}/${singleEvent._id}`} size={180} ></QRCode>
          
       </p>
      
   </div>

<button type="button" class="py-2 px-4 flex justify-center items-center  bg-green-600 hover:bg-green-700 focus:ring-red-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "

onClick={handleConvertToPng}
>
<svg class="h-8 w-8 text-white"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />  <polyline points="7 11 12 16 17 11" />  <line x1="12" y1="4" x2="12" y2="16" /></svg>
    Download
</button>

</div>


</div>



                                           </div>
                                       </div>
                                   </div>
                               </div>

                                  




         
                               

                                
                            </div>
                            
                       
                       
                       
                       
                        </div>



                        <div class="mx-0 mb-4 sm:ml-4 xl:mr-4">
                                <div class="w-full p-4 bg-white shadow-lg rounded-2xl ">
                                    <p class="font-bold text-black text-md ">
                                        Earnings
                                    </p>
     
<div class="relative overflow-hidden bg-white rounded-lg shadow w-60 md:w-72">
    <img src="https://img.clankapp.com/symbol/btc.svg" alt="btc logo" class="absolute w-24 h-24 rounded-full opacity-50 -top-6 -right-6 md:-right-4"/>
    <div class="px-4 py-5 sm:p-6">
        <dl>
            <dt class="text-sm font-medium leading-5 text-gray-500 truncate">
             Collections for {singleEvent.name}
            </dt>
            <dd class="mt-1 text-3xl font-semibold leading-9 text-gray-900">
                $ {singleEvent.price*singleEarning.length}
            </dd>
            {/* <dd class="font-semibold text-gray-500">
                <span>
                    500
                    <span class="text-xs">
                        .000
                    </span>
                    BTC
                </span>
            </dd> */}
        </dl>
    </div>
</div>

                                </div>
                            </div>

                            </>:<></>}

                            
                    </div>
                </div>


                
            </div>
        </div>
    </main>


    

    </>
    
  );
}
 
export default SellerReport;