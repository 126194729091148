import React, { useState,useEffect,useContext } from 'react';
import QRCode from 'qrcode.react';
import {UserContex} from '../App'
import {Link, useNavigate} from 'react-router-dom'
import SinglePage from './SinglePage';
import AdminHeader from '../components/AdminHeader';
import { Document, Page, Text, View, StyleSheet , PDFViewer,PDFDownloadLink,Image,pdf} from '@react-pdf/renderer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

 
function MembershipEventsReport() {
    const {state, dispatch}= useContext(UserContex)

    const history = useNavigate()

  const [inputText, setInputText] = useState('');
  const [qrCodeText, setQRCodeText] = useState('');
  const [Zonedata,setZoneData] = useState([])
  const [data,setData] = useState([])
  const [ticketData,setTicketData] = useState([])
  const [ranked,setRanked] = useState([])
  const [rankedRevenue,setRankedRevenue] = useState([])
  const [total,setTotal] = useState(0)
  const [totalOccupied,setTotalOccupied] = useState(0)
  const [singleEvent,setSingleEvent] = useState(null)
  const [allEvents,setAllEvents] = useState(true)
  const [showsingleEvent,setShowSingleEvent] = useState(true)
  const [tickets,setTickets] = useState([])

  const [today,setToday] = useState(new Date())
  const[zone,setZone]= useState("")

  const [purchaseHistory, setPurchaseHistory] = useState({});
  const [demographics, setDemographics] = useState([]);
  const [activeCustomers, setActiveCustomers] = useState([]);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState('All');
  const [sellerData, setSellerData] = useState([]);


useEffect(() => {
  fetch('/getmyevents', {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("jwt")
    }
  })
    .then(res => res.json())
    .then(result => {
      // Filter events that don't have the 'membership' field
      const filteredEvents = result.events.filter(event => event.membership);

      // Rank the events by occupied seats in descending order
      // const rankedEvents = filteredEvents.sort((a, b) => b.occupied - a.occupied);

      // Calculate total revenue
      // const totalRevenue = rankedEvents.reduce((sum, event) => {
      //   return sum + (parseFloat(event.price) * event.occupied);
      // }, 0);


         // Calculate the revenue for each event
         const eventsWithRevenue = filteredEvents.map(event => ({
          ...event,
          revenue: parseFloat(event.price) * event.occupied
        }));
  
        // Rank the events by revenue generated in descending order
        const rankedByRevenue = eventsWithRevenue.sort((a, b) => b.revenue - a.revenue);
  
        // Rank the events by occupied seats in descending order
        const rankedByOccupied = [...rankedByRevenue].sort((a, b) => b.occupied - a.occupied);
  
        // Calculate total revenue
        const totalRevenue = eventsWithRevenue.reduce((sum, event) => sum + event.revenue, 0);
        const totalOccupency = rankedByOccupied.reduce((sum, event) => sum + event.occupied, 0);
  
   // Extract unique event names
   const uniqueEvents = [...new Set(filteredEvents.map(event => event.name))];
   setEvents(['All', ...uniqueEvents]);

      setData(filteredEvents);
      setRanked(rankedByOccupied)
      setRankedRevenue(rankedByRevenue)
      setTotal(totalRevenue)
      setTotalOccupied(totalOccupency)


    })
    .catch(err => console.error(err));





    fetch('/findticketsforallevents', {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt")
      }
    })
      .then(res => res.json())
      .then(result => {
        const tickets = result.tickets;
        setTicketData(tickets);

        // Calculate Purchase History
        // const history = tickets.reduce((acc, ticket) => {
        //   if (!acc[ticket.email]) {
        //     acc[ticket.email] = [];
        //   }
        //   acc[ticket.email].push(ticket);
        //   return acc;
        // }, {});



        // setPurchaseHistory(history);

        // Calculate Demographics
        // const demographicsData = tickets.reduce((acc, ticket) => {
        //   if (!acc[ticket.seller]) {
        //     acc[ticket.seller] = {
        //       totalPurchases: 0,
        //       totalAmount: 0,
        //       name: ticket.seller.name,
        //       email: ticket.seller.email,
        //       phone: ticket.seller.phone,
        //     };
        //   }
        //   acc[ticket.email].totalPurchases += 1;
        //   acc[ticket.email].totalAmount += parseFloat(ticket.event.price); // Assuming price is a string that needs to be parsed
        //   return acc;
        // }, {});

        // setDemographics(Object.values(demographicsData));

       
        // Convert the grouped object into an array
        // const sellerDataArray = Object.keys(sellerTicketCount).map(sellerId => ({
        //   sellerId,
        //   name: sellerTicketCount[sellerId].name,
        //   ticketCount: sellerTicketCount[sellerId].count
        // }));

        const counts = tickets.reduce((acc, ticket) => {
          if (ticket.seller) {
            const sellerIndex = acc.findIndex(seller => seller.id === ticket.seller._id);
            if (sellerIndex > -1) {
              acc[sellerIndex].count += 1;
            } else {
              acc.push({
                id: ticket.seller._id,
                name: ticket.seller.name,
                event:ticket.event.name, // Assuming the seller object contains the name
                count: 1,
              });
            }
          }
          return acc;
        }, []);
  
// console.log("SDSDDDDDDDDD")

        setSellerData(counts);

        // Calculate Most Active Customers
        // const sortedCustomers = Object.values(demographicsData).sort((a, b) => b.totalPurchases - a.totalPurchases);
        // setActiveCustomers(sortedCustomers);
      })
      .catch(err => console.error(err));





}, []);

const handleEventChange = (e) => {
  setSelectedEvent(e.target.value);
};

const filteredTickets = selectedEvent === 'All'
  ? ticketData
  : ticketData.filter(ticket => ticket.event.name === selectedEvent);


 
  const singlEvent = (Itemid)=>{

    fetch(`/getsingleevent/${Itemid}`,{
      method:"get",
      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt"),
        "Content-Type":"application/json"
  
      }
  }).then(res=>res.json())
  .then(result=>{
  


    setSingleEvent(result.event)
    // setAllEvents(false)
            
  })


    fetch(`/findeventsticket/${Itemid}`,{
      method:"get",
      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt"),
        "Content-Type":"application/json"
  
      }
  }).then(res=>res.json())
  .then(result=>{
  


    setTickets(result.tickets)

            
  })



  
  }
    


  const PrintSales = (da) => (

    <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
      <Image style={styles.logo} src="/logo.png"
   />
  
        <Text style={styles.address}>Customers for {singleEvent.name}</Text>
        <Text style={{fontSize:10, marginBottom: 3}}>Venue: {singleEvent.venue}</Text>
      
      </View>
      <View style={styles.horizontalLine} />
  
      <View style={styles.invoiceDetails}>
        <View style={styles.itemList}>
          <View style={styles.itemRow}>
            <Text style={styles.label}>Name</Text>
            <Text style={styles.label}>Ticket No</Text>
          </View>
          <View style={styles.horizontalLine} />
  
          {tickets.map((item, index) => (
            <View>
  
            <View key={index} style={styles.itemRow}>
              <Text style={styles.itemDescription}>{item.name}</Text>
              <Text style={styles.itemDescription}>{item._id.slice(-4)}</Text>
              {/* <Text style={styles.itemDescription}>{item.qty}</Text> */}
              {/* <Text style={styles.itemDescription}>{item.amount.toFixed(0)}</Text> */}
            </View>
            <View style={styles.horizontalLine} />
  
            </View>
  
            
          ))}
  
        </View>
  
        <View style={styles.totalRow}>
          <Text style={styles.totalLabel}>Total: {tickets.length}</Text>
          {/* <Text style={styles.totalValue}>{invoice.total}</Text> */}
        </View>
        <View style={styles.horizontalLine} />
  
      </View>
    </Page>
  </Document>
    
  );


  const PrintOccupancy = (da) => (

    <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
      <Image style={styles.logo} src="/logo.png"
   />
  
        <Text style={styles.address}>Occupancy Report</Text>
        {/* <Text style={{fontSize:10, marginBottom: 3}}>Venue: {singleEvent.venue}</Text> */}
      
      </View>
      <View style={styles.horizontalLine} />
  
      <View style={styles.invoiceDetails}>
        <View style={styles.itemList}>
          <View style={styles.itemRow}>
            <Text style={styles.label}>Event</Text>
            <Text style={styles.label}>Occupancy</Text>
          </View>
          <View style={styles.horizontalLine} />
  
          {ranked.map((item, index) => (
            <View>
  
            <View key={index} style={styles.itemRow}>
              <Text style={styles.itemDescription}>{item.name}</Text>
              <Text style={styles.itemDescription}>{item.occupied}</Text>
              {/* <Text style={styles.itemDescription}>{item.qty}</Text> */}
              {/* <Text style={styles.itemDescription}>{item.amount.toFixed(0)}</Text> */}
            </View>
            <View style={styles.horizontalLine} />
  
            </View>
  
            
          ))}
  
        </View>
  
        <View style={styles.totalRow}>
          <Text style={styles.totalLabel}>Total: {totalOccupied}</Text>
          {/* <Text style={styles.totalValue}>{invoice.total}</Text> */}
        </View>
        <View style={styles.horizontalLine} />
  
      </View>
    </Page>
  </Document>
    
  );


  const PrintRevenew = (da) => (

    <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
      <Image style={styles.logo} src="/logo.png"
   />
  
        <Text style={styles.address}>Revenew Report</Text>
        {/* <Text style={{fontSize:10, marginBottom: 3}}>Venue: {singleEvent.venue}</Text> */}
      
      </View>
      <View style={styles.horizontalLine} />
  
      <View style={styles.invoiceDetails}>
        <View style={styles.itemList}>
          <View style={styles.itemRow}>
            <Text style={styles.label}>Event</Text>
            <Text style={styles.label}>Amount</Text>
          </View>
          <View style={styles.horizontalLine} />
  
          {rankedRevenue.map((item, index) => (
            <View>
  
            <View key={index} style={styles.itemRow}>
              <Text style={styles.itemDescription}>{item.name}</Text>
              <Text style={styles.itemDescription}>{item.occupied*item.price}</Text>
              {/* <Text style={styles.itemDescription}>{item.qty}</Text> */}
              {/* <Text style={styles.itemDescription}>{item.amount.toFixed(0)}</Text> */}
            </View>
            <View style={styles.horizontalLine} />
  
            </View>
  
            
          ))}
  
        </View>
  
        <View style={styles.totalRow}>
          <Text style={styles.totalLabel}>Total: {total}</Text>
          {/* <Text style={styles.totalValue}>{invoice.total}</Text> */}
        </View>
        <View style={styles.horizontalLine} />
  
      </View>
    </Page>
  </Document>
    
  );



  const PrintSellerDetail = (da) => (

    <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
      <Image style={styles.logo} src="/logo.png"
   />
  
        <Text style={styles.address}>Sellers Report</Text>
        {/* <Text style={{fontSize:10, marginBottom: 3}}>Venue: {singleEvent.venue}</Text> */}
      
      </View>
      <View style={styles.horizontalLine} />
  
      <View style={styles.invoiceDetails}>
        <View style={styles.itemList}>
          <View style={styles.itemRow}>
            <Text style={styles.label}>Name</Text>
            <Text style={styles.label}>Event</Text>
            <Text style={styles.label}>Nos. Sold</Text>
          </View>
          <View style={styles.horizontalLine} />
  
          {sellerData.map((item, index) => (
            <View>
  
            <View key={index} style={styles.itemRow}>
              <Text style={styles.itemDescription}>{item.name}</Text>
              <Text style={styles.itemDescription}>{item.event}</Text>
              <Text style={styles.itemDescription}>{item.count}</Text>
              {/* <Text style={styles.itemDescription}>{item.qty}</Text> */}
              {/* <Text style={styles.itemDescription}>{item.amount.toFixed(0)}</Text> */}
            </View>
            <View style={styles.horizontalLine} />
  
            </View>
  
            
          ))}
  
        </View>
  
  
      </View>
    </Page>
  </Document>
    
  );
  
  function exportToExcel(data) {
    // Convert data to CSV format
    const formattedData = tickets.map(({ name, _id }) => [name, _id.slice(-4)]);
  
    // Add headers to the CSV string
    const csv = [['Name', 'Ticket No']].concat(formattedData.map(row => row.join(','))).join('\n');
  
    // Create a Blob containing the CSV data
    const blob = new Blob([csv], { type: 'text/csv' });
  
    // Create a temporary URL for the Blob
    const url = window.URL.createObjectURL(blob);
  
    // Create a link element
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${"Ticket "+singleEvent.name+" Report"}.csv`);
  
    // Append the link to the DOM and click it programmatically
    document.body.appendChild(link);
    link.click();
  
    // Clean up: remove the link and revoke the URL
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }
  
  const styles = StyleSheet.create({
    page: {
      fontFamily: 'Helvetica',
      padding: 30,
    },
    header: {
      marginBottom: 2,
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: {
      fontSize: 20,
      fontWeight: 'bold',
      marginBottom: 10,
    },
    address: {
      fontSize: 12,
      marginBottom: 10,
    },
    invoiceDetails: {
      marginBottom: 20,
    },
    label: {
      fontSize: 15,
      fontWeight: 'bold',
    },
    labelfooter: {
      fontSize: 10,
      fontWeight: 'bold',
      marginBottom: 8,
      marginTop: 8,
  
    },
    value: {
      fontSize: 12,
    },
    itemList: {
      marginTop: 2,
    },
    itemRow: {
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      marginBottom: 8,
      marginLeft:8
    },
    itemDescription: {
      width: '20%',
      textAlign:'center',
      fontSize:12
  
    },
    itemQuantity: {
      width: '15%',
      textAlign: 'left',
    },
    itemAmount: {
      width: '25%',
      textAlign: 'right',
    },
    totalRow: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginTop: 10,
    },
    totalLabel: {
      fontSize: 14,
      fontWeight: 'bold',
    },
    totalValue: {
      fontSize: 14,
      marginLeft: 10,
    },
    logo: {
      width: 80, 
      height: 80, 
      marginBottom: 10,
      justifyContent: 'center',
      alignItems: 'center',  },
  
  
    horizontalLine: {
      borderTop: 1,
      borderColor: 'black',
      marginVertical: 4,
      width: '100%',
    },
  });
  

  const renderPDF = (type, info) => {
    switch (type) {
      case 'sales':
        return <PrintSales  />;
      case 'PrintOccupancy':
        return <PrintOccupancy  />;
      case 'PrintRevenew':
        return <PrintRevenew  />;
      case 'PrintSellerDetail':
        return <PrintSellerDetail  />;
      default:
        return null;
    }
  };
  
  
  // Function to send PDF Blob to the backend
  // Function to send PDF Blob to the backend using fetch
  const sendPDFToBackend = async (type,info) => {
    try {
      // Generate the PDF as a Blob
      // const blob = await pdf(<PrintAllVotes />).toBlob();
      const blob = await pdf(renderPDF(type,info)).toBlob();
  
  
      // Create FormData to append the PDF Blob
      const formData = new FormData();
      formData.append('file', blob, `${type}.pdf`);
  
      // Send the Blob to your backend using fetch
      const response = await fetch('/sendvaimail', {
        method: 'POST',
        headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt")
  
        },
        body: formData,
      });
  
      if (response.ok) {
        const result = await response.json();
        toast.success("We have mailed you the Report")
        console.log('PDF successfully uploaded:', result);
      } else {
        console.error('Error uploading the PDF:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


//   console.log("Sinvle"singleEvent.seats)

  const Logout = ()=>{
    localStorage.clear()
    dispatch({type:"CLEAR"})
        history(`/login`)
}
 
  return (

    <>



        <main class="relative h-screen overflow-hidden bg-gray-100  rounded-2xl">
        <AdminHeader page="membership"></AdminHeader>

    
                        {allEvents?<>

<main class="relative h-screen overflow-hidden bg-gray-100 ">
{/* <h1 className='text-center text-3xl'>All Reports</h1> */}

    <div class="flex items-start justify-between">
      
       
        <div class="flex flex-col w-full md:space-y-4">
           
            <div class="h-screen px-4 pb-24 overflow-auto md:px-6">
             
                <div class="flex flex-col items-center w-full my-6 space-y-4 md:space-x-4 md:space-y-0 md:flex-row">
                    {/* <div class="w-full md:w-6/12">
                        <div class="relative w-full overflow-hidden bg-white shadow-lg ">
                            <a href="#" class="block w-full h-full">
                                <div class="flex items-center justify-between px-4 py-6 space-x-4">
                                    <div class="flex items-center">
                                        <span class="relative p-5 bg-yellow-100 rounded-full">
                                            <svg width="40" fill="currentColor" height="40" class="absolute h-5 text-yellow-500 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1362 1185q0 153-99.5 263.5t-258.5 136.5v175q0 14-9 23t-23 9h-135q-13 0-22.5-9.5t-9.5-22.5v-175q-66-9-127.5-31t-101.5-44.5-74-48-46.5-37.5-17.5-18q-17-21-2-41l103-135q7-10 23-12 15-2 24 9l2 2q113 99 243 125 37 8 74 8 81 0 142.5-43t61.5-122q0-28-15-53t-33.5-42-58.5-37.5-66-32-80-32.5q-39-16-61.5-25t-61.5-26.5-62.5-31-56.5-35.5-53.5-42.5-43.5-49-35.5-58-21-66.5-8.5-78q0-138 98-242t255-134v-180q0-13 9.5-22.5t22.5-9.5h135q14 0 23 9t9 23v176q57 6 110.5 23t87 33.5 63.5 37.5 39 29 15 14q17 18 5 38l-81 146q-8 15-23 16-14 3-27-7-3-3-14.5-12t-39-26.5-58.5-32-74.5-26-85.5-11.5q-95 0-155 43t-60 111q0 26 8.5 48t29.5 41.5 39.5 33 56 31 60.5 27 70 27.5q53 20 81 31.5t76 35 75.5 42.5 62 50 53 63.5 31.5 76.5 13 94z">
                                                </path>
                                            </svg>
                                        </span>
                                        <p class="ml-2 text-sm font-semibold text-gray-700 border-b border-gray-200 ">
                                            Level 2 Ambassador
                                        </p>
                                    </div>
                                    <div class="mt-6 text-xl font-bold text-black border-b border-gray-200 md:mt-0 ">
                                        $44,453.39
                                        <span class="text-xs text-gray-400">
                                            /$100K
                                        </span>
                                    </div>
                                </div>
                                <div class="w-full h-3 bg-gray-100">
                                    <div class="w-2/5 h-full text-xs text-center text-white bg-green-400">
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div> */}
                    <ToastContainer/>
                    <div class="flex items-center w-full space-x-4 md:w-1/2">
                        <div class="w-1/2">
                            <div class="relative w-full px-4 py-6 bg-white shadow-lg ">
                                <p class="text-2xl font-bold text-black ">
                                    {data.length}
                                </p>
                                <p class="text-sm text-gray-400">
                                    Total Events
                                </p>
                            </div>
                        </div>
                        <div class="w-1/2">
                            <div class="relative w-full px-4 py-6 bg-white shadow-lg ">
                                <p class="text-2xl font-bold text-black ">
                                    ${total}
                                </p>
                                <p class="text-sm text-gray-400">
                                    Total Earnings
                                </p>
                                <span class="absolute p-4 bg-purple-500 rounded-full top-2 right-4">
                                    <svg width="40" fill="currentColor" height="40" class="absolute h-4 text-white transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1362 1185q0 153-99.5 263.5t-258.5 136.5v175q0 14-9 23t-23 9h-135q-13 0-22.5-9.5t-9.5-22.5v-175q-66-9-127.5-31t-101.5-44.5-74-48-46.5-37.5-17.5-18q-17-21-2-41l103-135q7-10 23-12 15-2 24 9l2 2q113 99 243 125 37 8 74 8 81 0 142.5-43t61.5-122q0-28-15-53t-33.5-42-58.5-37.5-66-32-80-32.5q-39-16-61.5-25t-61.5-26.5-62.5-31-56.5-35.5-53.5-42.5-43.5-49-35.5-58-21-66.5-8.5-78q0-138 98-242t255-134v-180q0-13 9.5-22.5t22.5-9.5h135q14 0 23 9t9 23v176q57 6 110.5 23t87 33.5 63.5 37.5 39 29 15 14q17 18 5 38l-81 146q-8 15-23 16-14 3-27-7-3-3-14.5-12t-39-26.5-58.5-32-74.5-26-85.5-11.5q-95 0-155 43t-60 111q0 26 8.5 48t29.5 41.5 39.5 33 56 31 60.5 27 70 27.5q53 20 81 31.5t76 35 75.5 42.5 62 50 53 63.5 31.5 76.5 13 94z">
                                        </path>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
               
        



                <div class="grid grid-cols-1 gap-4 my-4 md:grid-cols-2 lg:grid-cols-3">
                    <div class="w-full">
                        <div class="relative w-full px-4 py-6 bg-white shadow-lg ">
                            <p class="text-sm font-semibold text-gray-700 border-b border-gray-200 w-max ">
                                Most Occupency
                            </p>
                            <div className='absolute top-0 right-0'>


                             <PDFDownloadLink document={<PrintOccupancy test={"TESt sf"}/>} fileName="Occupancy.pdf">
{({ blob, url, loading, error }) =>
loading ? 'Loading report...' : <>
       <button onClick={()=>{sendPDFToBackend("PrintOccupancy")}}>

<svg class="h-8 w-8 text-purple-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M14 3v4a1 1 0 0 0 1 1h4" />  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />  <line x1="12" y1="11" x2="12" y2="17" />  <polyline points="9 14 12 17 15 14" /></svg>
</button>
</>
}
</PDFDownloadLink>
</div>


                            <div class="flex items-end my-6 space-x-2">
                                <p class="text-5xl font-bold text-black ">
                                    {totalOccupied}
                                </p>
                                <span class="flex items-center text-xl font-bold text-green-500">
                                    <svg width="20" fill="currentColor" height="20" class="h-3" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                        </path>
                                    </svg>
                                    100%
                                </span>
                            </div>
                            <div class="">

                                  {ranked?ranked.map(item=>{

                                    return(
                                      <div class="flex items-center justify-between pb-2 mb-2 text-sm border-b border-gray-200 sm:space-x-12">
                                      <p>
                                          {item.name}
                                      </p>
                                      <div class="flex items-end text-xs">
                                          {item.occupied}
                                          <span class="flex items-center">
                                              <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                                  </path>
                                              </svg>
                                              {item.occupied==0?0: ((100*item.occupied)/(totalOccupied)).toFixed(1)}%
                                          </span>
                                      </div>
                                  </div>
                                 
                                    )
                                  }) :<></>}

                              
                               
                            </div>
                        </div>
                    </div>
                    <div class="w-full">
                        <div class="relative w-full px-4 py-6 bg-white shadow-lg ">
                            <p class="text-sm font-semibold text-gray-700 border-b border-gray-200 w-max ">
                                Revenue
                            </p>
                            <div className='absolute top-0 right-0'>


<PDFDownloadLink document={<PrintRevenew test={"TESt sf"}/>} fileName="Revenew.pdf">
{({ blob, url, loading, error }) =>
loading ? 'Loading report...' : <>
       <button onClick={()=>{sendPDFToBackend("PrintRevenew")}}>

<svg class="h-8 w-8 text-purple-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M14 3v4a1 1 0 0 0 1 1h4" />  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />  <line x1="12" y1="11" x2="12" y2="17" />  <polyline points="9 14 12 17 15 14" /></svg>
</button>
</>
}
</PDFDownloadLink>
</div>
                            <div class="flex items-end my-6 space-x-2">
                                <p class="text-5xl font-bold text-black ">
                                   $ {total}
                                </p>
                                <span class="flex items-center text-xl font-bold text-green-500">
                                    <svg width="20" fill="currentColor" height="20" class="h-3" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                        </path>
                                    </svg>
                                    100%
                                </span>
                            </div>
                            <div class="">

                                  {rankedRevenue?rankedRevenue.map(item=>{

                                    return(
                                      <div class="flex items-center justify-between pb-2 mb-2 text-sm border-b border-gray-200 sm:space-x-12">
                                      <p>
                                          {item.name}
                                      </p>
                                      <div class="flex items-end text-xs">
                                          {item.occupied*item.price}
                                          <span class="flex items-center">
                                              <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                                  </path>
                                              </svg>
                                              {item.occupied*item.price==0?0: (100*total)/(item.occupied*item.price)}%
                                          </span>
                                      </div>
                                  </div>
                                 
                                    )
                                  }) :<></>}

                              
                               
                            </div>
                        </div>
                    </div>
                 
                    {/* <div class="w-full">
                        <div class="relative w-full px-4 py-6 bg-white shadow-lg ">
                            <p class="text-sm font-semibold text-gray-700 border-b border-gray-200 w-max ">
                                Sellers
                            </p>
                            <div className='absolute top-0 right-0'>


<PDFDownloadLink document={<PrintOccupancy test={"TESt sf"}/>} fileName="Occupancy.pdf">
{({ blob, url, loading, error }) =>
loading ? 'Loading report...' : <>
<svg class="h-8 w-8 text-purple-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M14 3v4a1 1 0 0 0 1 1h4" />  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />  <line x1="12" y1="11" x2="12" y2="17" />  <polyline points="9 14 12 17 15 14" /></svg></>
}
</PDFDownloadLink>
</div>
                            <div class=" overflow-auto max-h-48">

                                  {sellerData?sellerData.sort((a, b) => b.count - a.count).map(item=>{

                                    return(
                                      <div class="flex items-center justify-between pb-2 mb-2 text-sm border-b border-gray-200 sm:space-x-12">
                                      <p>
                                          {item.name}
                                      </p>
                                      <p>
                                          {item.event}
                                      </p>
                                      <div class="flex items-end text-xs">
                                          {item.count}
                                          <span class="flex items-center">
                                              <svg width="20" fill="currentColor" height="20" class="h-3 text-green-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                                                  </path>
                                              </svg>
                                              {item.occupied*item.price==0?0: (100*total)/(item.occupied*item.price)}%
                                          </span>
                                      </div>
                                  </div>
                                 
                                    )
                                  }) :<></>}

                              
                               
                            </div>
                        </div>
                    </div>
                  */}


     
      
                  
                </div>
                {/* <h1 className='text-center text-3xl'>Single Events Reports</h1> */}

                <div class="w-full sm:w-1/2 xl:w-1/4">
                
                            <div class="mx-0 mb-4 sm:ml-4 xl:mr-4">
                                <div class="w-full bg-white shadow-lg rounded-2xl ">
                                    <p class="p-4 font-bold text-black text-md ">
                                        My Events
                                        <span class="ml-2 text-sm text-gray-500 ">
                                            ({data.length})
                                        </span>
                                    </p>
                                    <ul>
                                        {data.map((item,index)=>{
                                            var date = new Date(item.date)
                                            //  console.log(date<today)
                                            return(
                                                <li key={index} class="flex items-center cursor-pointer justify-between py-3 text-gray-600 border-b-2 border-gray-100 "
                                                onClick={()=>{singlEvent(item._id);}}
                                                >
                                                <div class="flex items-center justify-start text-sm">
                                                    <span class="mx-4">
                                                        {index+1}
                                                    </span>
                                                    <span class="mx-4">
                                                        {item.name}
                                                    </span>
                                                   
                                                    <span class="flex items-center ml-2 text-gray-400 lg:ml-6 ">
                    
                                                    <svg class="h-6 w-6 text-gray-300"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="4" y="5" width="16" height="16" rx="2" />  <line x1="16" y1="3" x2="16" y2="7" />  <line x1="8" y1="3" x2="8" y2="7" />  <line x1="4" y1="11" x2="20" y2="11" />  <line x1="11" y1="15" x2="12" y2="15" />  <line x1="12" y1="15" x2="12" y2="18" /></svg>
                </span>
                                                    <span class="text-gray-400">
                                                        {item.date.substring(0,10)}
                                                    </span>

                                                   
                                                </div>
                                                <svg width="20" height="20" fill="currentColor" viewBox="0 0 1024 1024" class={date>today?"mx-4 text-green-500":"mx-4 text-red-500"}>
                                                <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448s448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 0 1-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8l157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z" fill="currentColor">
                                                </path>
                                            </svg>
                                            </li>
                                            )
                                        })}
                                       
                                    </ul>
                                </div>
                            </div>
                          
                        </div>


{singleEvent?
  <>

<div className='grid md:grid-cols-2 grid-cols-1 gap-4'>


<div class="flex flex-wrap overflow-hidden">
               <div class="w-full rounded shadow-sm">
                   <div class="flex items-center justify-between mb-4">
                       <div class="text-xl font-bold text-left text-black ">
                           {singleEvent?singleEvent.name:""}
                       </div>
                       <div class="flex space-x-4">
                           <button class="p-2 text-white bg-purple-500 rounded-lg">
                           {singleEvent?singleEvent.date.substring(0,10):""}

                           </button>
                           
                       </div>
                   </div>
                   <div class="-mx-2">
                    

                   <div class="relative w-full overflow-hidden bg-white shadow-lg ">
<a href="#" class="block w-full h-full">
<div class="flex items-center justify-between px-4 py-6 space-x-4">
<div class="flex items-center">
<span class="relative p-2 bg-yellow-100 rounded-full">
<svg class="h-6 w-6 text-yellow-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
</svg>

</span>
<p class="ml-2 text-sm font-semibold text-gray-700 border-b border-gray-200 ">
{singleEvent?singleEvent.venue:""}
</p>
</div>
<div class="mt-6 text-xl font-bold text-black border-b border-gray-200 md:mt-0 ">
$ {singleEvent?singleEvent.price:""}

<span class="text-xs text-gray-400">
/Seat 
</span>
</div>
</div>

<div class="w-full h-3 bg-gray-100">

<div class=" h-full text-xs text-center text-white bg-green-400"

style={{ width: singleEvent?`${(singleEvent.booked.length*100)/singleEvent.seats}%` :""}}

>
</div>

</div>
</a>
</div>




<div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 my-4">


<div class="p-4 bg-white shadow-lg rounded-2xl ">
<div class="flex items-center">
<span class="relative p-4 bg-purple-200 rounded-xl">
<svg width="40" fill="currentColor" height="40" class="absolute h-4 text-purple-500 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
<path d="M1362 1185q0 153-99.5 263.5t-258.5 136.5v175q0 14-9 23t-23 9h-135q-13 0-22.5-9.5t-9.5-22.5v-175q-66-9-127.5-31t-101.5-44.5-74-48-46.5-37.5-17.5-18q-17-21-2-41l103-135q7-10 23-12 15-2 24 9l2 2q113 99 243 125 37 8 74 8 81 0 142.5-43t61.5-122q0-28-15-53t-33.5-42-58.5-37.5-66-32-80-32.5q-39-16-61.5-25t-61.5-26.5-62.5-31-56.5-35.5-53.5-42.5-43.5-49-35.5-58-21-66.5-8.5-78q0-138 98-242t255-134v-180q0-13 9.5-22.5t22.5-9.5h135q14 0 23 9t9 23v176q57 6 110.5 23t87 33.5 63.5 37.5 39 29 15 14q17 18 5 38l-81 146q-8 15-23 16-14 3-27-7-3-3-14.5-12t-39-26.5-58.5-32-74.5-26-85.5-11.5q-95 0-155 43t-60 111q0 26 8.5 48t29.5 41.5 39.5 33 56 31 60.5 27 70 27.5q53 20 81 31.5t76 35 75.5 42.5 62 50 53 63.5 31.5 76.5 13 94z">
</path>
</svg>
</span>
<p class="ml-2 text-black text-md ">
Earnings

</p>

</div>
{singleEvent?singleEvent.occupied:0} booked of {singleEvent?singleEvent.seats:""} sets

<div class="flex flex-col justify-start">
<p class="my-4 text-4xl font-bold text-left text-gray-700 ">
{singleEvent?singleEvent.price*(singleEvent.occupied):""}
<span class="text-sm">
$
</span>
</p>

</div>
</div>
<div class="p-4 bg-white shadow-lg rounded-2xl ">
<div class="flex items-center">
<span class="relative p-1 bg-purple-200 rounded-xl">
<svg class="h-6 w-6 text-purple-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"/>
</svg>

</span>
<p class="ml-2 text-black text-md ">
Sold Seats
</p>
</div>
<div class="flex flex-col justify-start">
<p class="my-4 text-4xl font-bold text-left text-gray-700 ">
{singleEvent?singleEvent.occupied:""}
<span class="text-sm">
Seats
</span>
</p>

</div>
</div>


</div>



                   </div>
               </div>
           </div>

                                         
<div class="w-full bg-white shadow-lg rounded-2xl  h-80 overflow-auto">
<div class="flex items-center justify-between p-4 sticky top-0 bg-white z-10">
<p class="font-bold text-black text-md ">
Customers
</p>


PDF: <PDFDownloadLink document={<PrintSales test={"TESt sf"}/>} fileName="Customer.pdf">
{({ blob, url, loading, error }) =>
loading ? 'Loading report...' : <>

<button onClick={()=>{sendPDFToBackend("PrintSales")}}>

<svg class="h-8 w-8 text-purple-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M14 3v4a1 1 0 0 0 1 1h4" />  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />  <line x1="12" y1="11" x2="12" y2="17" />  <polyline points="9 14 12 17 15 14" /></svg>
</button></>
}
</PDFDownloadLink>


Excel:
                <svg class="h-8 w-8 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"
                onClick={()=>{exportToExcel()}}
                >   <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2" />  <path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4" />  <rect x="7" y="13" width="10" height="8" rx="2" /></svg>



</div>
{tickets?tickets.map(item=>{
return(
<div class="flex items-center justify-between px-4 py-2 text-gray-600 border-b-2 border-gray-100">
<p class="flex items-center text-xs ">
{/* <svg width="20" height="20" fill="currentColor" class="mr-2" viewBox="0 0 24 24">
<g fill="none">
<path d="M12 5a8.5 8.5 0 1 1 0 17a8.5 8.5 0 0 1 0-17zm0 3a.75.75 0 0 0-.743.648l-.007.102v4.5l.007.102a.75.75 0 0 0 1.486 0l.007-.102v-4.5l-.007-.102A.75.75 0 0 0 12 8zm7.17-2.877l.082.061l1.149 1a.75.75 0 0 1-.904 1.193l-.081-.061l-1.149-1a.75.75 0 0 1 .903-1.193zM14.25 2.5a.75.75 0 0 1 .102 1.493L14.25 4h-4.5a.75.75 0 0 1-.102-1.493L9.75 2.5h4.5z" fill="currentColor">
</path>
</g>
</svg> */}
{item.name}
</p>
<div class="flex items-center">
<span class="ml-2 mr-2 text-xs text-gray-400 md:ml-4">
{item.email}
</span>


<span class="ml-2 mr-2 text-xs text-gray-400 md:ml-4">
<svg class="h-4 w-4 text-black"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" /></svg>            </span>
<a href={`tel:${item.phone}`}>
{item.phone}
</a>

</div>
</div>
)
}):<></>}




</div>
</div>

</>
:<></>}

{/* <h1 className='text-center text-3xl'>Tickets</h1> */}

                <div class="grid md:grid-cols-2 grid-cols-1">

                <div class="container max-w-3xl px-4 mx-auto sm:px-8 ">
    <div class="py-8">
    <div class="grid md:grid-cols-2 grid-cols-1  w-full mb-1 sm:mb-0">
    <h2 class="text-2xl leading-tight">
                All Tickets
            </h2>
            <div class="text-end">
            <select
          className="mb-4 px-4 py-2 border border-gray-300 rounded"
          onChange={handleEventChange}
          value={selectedEvent}
        >
          {events.map(event => (
            <option key={event} value={event}>{event}</option>
          ))}
        </select>
                </div>
            </div>
            <div class="px-4 py-4 -mx-6 overflow-x-auto ">
                <div class="inline-block min-w-full  rounded-lg shadow h-80 overflow-auto">
             

                    <table class="min-w-full leading-normal">
                    <thead class="sticky top-0 bg-white z-10">
                    <tr>
                                <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                    User
                                </th>
                                <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                    Event
                                </th>
                                <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                    Created at
                                </th>
                                <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                    status
                                </th>
                               
                            </tr>
                        </thead>
                        <tbody  >

                          {ticketData?filteredTickets.map(item=>{

                             return(
                              <tr>
                              <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                  <div class="flex items-center">
                                      
                                      <div class="ml-3">
                                          <p class="text-gray-900 whitespace-no-wrap">
                                              {item.name}
                                          </p>
                                      </div>
                                  </div>
                              </td>
                              <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                  <p class="text-gray-900 whitespace-no-wrap">
                                      {item.event.name}
                                  </p>
                              </td>
                              <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                  <p class="text-gray-900 whitespace-no-wrap">
                                      {item.event.date.substring(0,10)}
                                  </p>
                              </td>
                              <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                  <span class="relative inline-block px-3 py-1 font-semibold leading-tight text-green-900">
                                      <span aria-hidden="true" class="absolute inset-0 bg-green-200 rounded-full opacity-50">
                                      </span>
                                      <span class="relative">
                                          {item.status}
                                      </span>
                                  </span>
                              </td>
                              {/* <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                  <a href="#" class="text-indigo-600 hover:text-indigo-900">
                                      Edit
                                  </a>
                              </td> */}
                          </tr>
                        
                             ) 

                          }):<></>}
                           
                            
                        </tbody>
                    </table>
                    
                </div>
            </div>
        </div>
    </div>
          
                </div>
        


            </div>

        </div>
    </div>
</main>

                        
                        </>:<></>}

                                                    {singleEvent?


<>
      
                        <div class="w-full sm:w-1/2 xl:w-1/3">

                            <div class="mb-4">


                                   <div class="p-4 bg-white shadow-lg rounded-2xl">
                                   <div class="flex flex-wrap overflow-hidden">
                                       <div class="w-full rounded shadow-sm">
                                           <div class="flex items-center justify-between mb-4">
                                               <div class="text-xl font-bold text-left text-black ">
                                                   {singleEvent?singleEvent.name:""}
                                               </div>
                                               <div class="flex space-x-4">
                                                   <button class="p-2 text-white bg-purple-500 rounded-lg">
                                                   {singleEvent?singleEvent.date.substring(0,10):""}

                                                   </button>
                                                   
                                               </div>
                                           </div>
                                           <div class="-mx-2">
                                            

                                           <div class="relative w-full overflow-hidden bg-white shadow-lg ">
   <a href="#" class="block w-full h-full">
       <div class="flex items-center justify-between px-4 py-6 space-x-4">
           <div class="flex items-center">
               <span class="relative p-2 bg-yellow-100 rounded-full">
               <svg class="h-6 w-6 text-yellow-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
</svg>

               </span>
               <p class="ml-2 text-sm font-semibold text-gray-700 border-b border-gray-200 ">
               {singleEvent?singleEvent.venue:""}
               </p>
           </div>
           <div class="mt-6 text-xl font-bold text-black border-b border-gray-200 md:mt-0 ">
               $ {singleEvent?singleEvent.price:""}

               <span class="text-xs text-gray-400">
                   /Seat 
               </span>
           </div>
       </div>
     
       <div class="w-full h-3 bg-gray-100">
       
           <div class=" h-full text-xs text-center text-white bg-green-400"
           
           style={{ width: singleEvent?`${(singleEvent.booked.length*100)/singleEvent.seats}%` :""}}

           >
           </div>
           
       </div>
   </a>
</div>




<div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 my-4">


<div class="p-4 bg-white shadow-lg rounded-2xl ">
   <div class="flex items-center">
       <span class="relative p-4 bg-purple-200 rounded-xl">
           <svg width="40" fill="currentColor" height="40" class="absolute h-4 text-purple-500 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
               <path d="M1362 1185q0 153-99.5 263.5t-258.5 136.5v175q0 14-9 23t-23 9h-135q-13 0-22.5-9.5t-9.5-22.5v-175q-66-9-127.5-31t-101.5-44.5-74-48-46.5-37.5-17.5-18q-17-21-2-41l103-135q7-10 23-12 15-2 24 9l2 2q113 99 243 125 37 8 74 8 81 0 142.5-43t61.5-122q0-28-15-53t-33.5-42-58.5-37.5-66-32-80-32.5q-39-16-61.5-25t-61.5-26.5-62.5-31-56.5-35.5-53.5-42.5-43.5-49-35.5-58-21-66.5-8.5-78q0-138 98-242t255-134v-180q0-13 9.5-22.5t22.5-9.5h135q14 0 23 9t9 23v176q57 6 110.5 23t87 33.5 63.5 37.5 39 29 15 14q17 18 5 38l-81 146q-8 15-23 16-14 3-27-7-3-3-14.5-12t-39-26.5-58.5-32-74.5-26-85.5-11.5q-95 0-155 43t-60 111q0 26 8.5 48t29.5 41.5 39.5 33 56 31 60.5 27 70 27.5q53 20 81 31.5t76 35 75.5 42.5 62 50 53 63.5 31.5 76.5 13 94z">
               </path>
           </svg>
       </span>
       <p class="ml-2 text-black text-md ">
           Earnings

       </p>
       
   </div>
   {singleEvent?singleEvent.occupied:0} booked of {singleEvent?singleEvent.seats:""} sets

   <div class="flex flex-col justify-start">
       <p class="my-4 text-4xl font-bold text-left text-gray-700 ">
       {singleEvent?singleEvent.price*(singleEvent.occupied):""}
           <span class="text-sm">
               $
           </span>
       </p>
  
   </div>
</div>
<div class="p-4 bg-white shadow-lg rounded-2xl ">
   <div class="flex items-center">
       <span class="relative p-1 bg-purple-200 rounded-xl">
       <svg class="h-6 w-6 text-purple-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"/>
</svg>

       </span>
       <p class="ml-2 text-black text-md ">
           Sold Seats
       </p>
   </div>
   <div class="flex flex-col justify-start">
       <p class="my-4 text-4xl font-bold text-left text-gray-700 ">
       {singleEvent?singleEvent.occupied:""}
           <span class="text-sm">
                Seats
           </span>
       </p>
      
   </div>
</div>


</div>



                                           </div>
                                       </div>
                                   </div>
                               </div>

                                  
                               

                                
                            </div>

                         

                           
                        </div> 
                        
                        <div class="mb-4 md:ml-4">
                              
<div class="w-full bg-white shadow-lg rounded-2xl ">
    <div class="flex items-center justify-between p-4">
        <p class="font-bold text-black text-md ">
            Customers
        </p>
       
       
        <PDFDownloadLink document={<PrintSales test={"TESt sf"}/>} fileName="Customer.pdf">
      {({ blob, url, loading, error }) =>
        loading ? 'Loading report...' : <><svg class="h-8 w-8 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2" />  <path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4" />  <rect x="7" y="13" width="10" height="8" rx="2" /></svg>
         </>
      }
    </PDFDownloadLink>


    Excel:
                                        <svg class="h-8 w-8 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"
                                        onClick={()=>{exportToExcel()}}
                                        >   <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2" />  <path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4" />  <rect x="7" y="13" width="10" height="8" rx="2" /></svg>



    </div>
   {tickets?tickets.map(item=>{
    return(
        <div class="flex items-center justify-between px-4 py-2 text-gray-600 border-b-2 border-gray-100">
        <p class="flex items-center text-xs ">
            {/* <svg width="20" height="20" fill="currentColor" class="mr-2" viewBox="0 0 24 24">
                <g fill="none">
                    <path d="M12 5a8.5 8.5 0 1 1 0 17a8.5 8.5 0 0 1 0-17zm0 3a.75.75 0 0 0-.743.648l-.007.102v4.5l.007.102a.75.75 0 0 0 1.486 0l.007-.102v-4.5l-.007-.102A.75.75 0 0 0 12 8zm7.17-2.877l.082.061l1.149 1a.75.75 0 0 1-.904 1.193l-.081-.061l-1.149-1a.75.75 0 0 1 .903-1.193zM14.25 2.5a.75.75 0 0 1 .102 1.493L14.25 4h-4.5a.75.75 0 0 1-.102-1.493L9.75 2.5h4.5z" fill="currentColor">
                    </path>
                </g>
            </svg> */}
            {item.name}
        </p>
        <div class="flex items-center">
            <span class="ml-2 mr-2 text-xs text-gray-400 md:ml-4">
                {item.email}
            </span>
        

            <span class="ml-2 mr-2 text-xs text-gray-400 md:ml-4">
            <svg class="h-4 w-4 text-black"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" /></svg>            </span>
            <a href={`tel:${item.phone}`}>
{item.phone}
            </a>

        </div>
    </div>
    )
   }):<></>}
   
   
   
    
</div>

                            </div>
                </>        
                        
                        
                        
                              
                               
                   
 :<>
                                  
                                  
                                  
                                  
                                  </>} 

                               
       
    </main>


    

    </>
    
  );
}
 
export default MembershipEventsReport;