import React, {useContext, useRef, useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import DatePicker from 'react-datepicker'
import {UserContex} from '../App'
import {fill} from "@cloudinary/url-gen/actions/resize";
import {CloudinaryImage} from '@cloudinary/url-gen';
import AdminHeader from '../components/AdminHeader';
import SuperAdminHeader from '../components/SuperAdminHeader';
// import NavBar from './nav'
require('react-datepicker/dist/react-datepicker.css')

const SuperUsers = ()=>{


  const history = useNavigate()
  const {state, dispatch}= useContext(UserContex)



  const  [data, setData]= useState([])

  const [search,setSearch] = useState("")

  const [addStock,setAddStock]= useState(false)
  const [editEvent,setEdit]= useState(false)
  const[id,setId] = useState("")  
 
  const[zone,setZone]= useState("")
  const[price,setPrice]= useState("")
  const[venue,setVenue]=useState("")
  const[description,setDescription]=useState("")
  const[seats,setSeats]= useState("")
  const [name,setName]= useState("")
  const [col,setCol]= useState("")
  const [row,setRow]= useState("")
  const [url,setUrl] = useState("")  
  const [category,setCategory] = useState("")  
  const [date,setDate] = useState(new Date())
  const [isChecked, setIsChecked] = useState(false);

  const[progress,setProgress]= useState(false)


  useEffect(  ()=>{

    fetch('/getallusers',{
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{
      setData(result.details)
      
  })

  },[])



  const singlEvent = (Itemid)=>{

  fetch(`/getsingleevent/${Itemid}`,{
    method:"get",
    headers:{
      Authorization: "Bearer " +localStorage.getItem("jwt"),
      "Content-Type":"application/json"

    }
}).then(res=>res.json())
.then(result=>{


  // setDataEdit(result.stock)
  console.log("RESULT",result.event)
  setId(result.event._id)
  setName(result.event.name)
  setVenue(result.event.venue)
  setDescription(result.event.description)
  setSeats(result.event.seats)
  setPrice(result.event.price)

  //setDob(result.employee.dob)
  
  

          
})

}
  



  

  const postEvent = ()=>{

    fetch('/addevent',{

      method:"post",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({
       
        name,venue,seats,description,price,pic:url,date,row,col,category
      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
      }

      else{
        
        window.location.reload();
            // history.go(`/employee`)

      }
    })
    .catch(err=>{
      console.log(err)
    })

  }


  
  const uploadppt=({target:{files}})=>{

    setProgress(true)

    let data = new FormData()

    for(const file of files){

   data.append('uploadimage', file)
   data.append('name',file.name)
}



       fetch("/uploadimage", {

         method:"post",
         body: data
     })
     .then(res=>res.json())
     .then(data=>{

          setUrl(data.url)
          setProgress(false)

      

       
     })
     .catch(err=>{
         console.log(err)
     })

}
  






  const fetchPost=(query)=>{


    setSearch(query)

    fetch('/search-users',{

      method:"post",
      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt"),

        "Content-Type":"application/json"

      },
      body:JSON.stringify({
        query
      })

    }).then(res=> res.json())
      .then(results=>{
        
        setData(results.details)
      })


  }

  const deleteEmployee = (postId)=>{

    fetch(`/deleteevent/${postId}`,{
        method: "delete",
        headers:{
          "Content-Type":"application/json",
          Authorization: "Bearer " +localStorage.getItem("jwt")
        }
    }).then(res=>res.json())
    .then(result=>{
        window.location.reload(false);

    }).catch(err=>{
        console.log(err)
    })
}
  
    //deleteEmployee
    

    const Logout = ()=>{
        localStorage.clear()
        dispatch({type:"CLEAR"})
            history(`/login`)
    }

return(

    <div>


 <script src="https://cdn.jsdelivr.net/gh/alpinejs/alpine@v2.x.x/dist/alpine.min.js" defer></script>

 <div  className="flex h-screen bg-white">

 <div className=" "></div>

        <link rel="stylesheet" href="https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css" />
  






        <div className="flex-1 flex flex-col overflow-hidden ">

            
        {/* <NavBar/> */}
     <SuperAdminHeader page="users"></SuperAdminHeader>

            <main className="flex-1 overflow-x-hidden overflow-y-auto bg-white">
  <div className="container mx-auto px-6 py-8">
    <h3 className="text-gray-400 text-3xl font-medium">All Users</h3>


<div className="flex flex-row ...">


<div class="shadow-lg rounded-xl bg-gray-200 w-full md:w-64 p-6 bg-white  relative overflow-hidden">
    <p class="text-black text-xl">
    Users
    </p>
    <div class="flex items-center my-4 text-blue-500 rounded justify-between">
        
    <svg class="h-8 w-8 text-gray-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"/>
</svg>

        <div class="flex flex-col w-full ml-2 items-start justify-evenly">
            <p class="text-black text-lg">
                {data.length}
            </p>
            <p class="text-gray-700 text-sm">
                Total Accounts
            </p>
        </div>
    </div>
   
    {/* <div class="mt-4">
        <button type="button" class="py-2 px-4  bg-black hover:bg-gray-400 focus:ring-gray-400 focus:ring-offset-gray-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md "
        
        onClick={()=>setAddStock(true)}
        >
            Add Event +
        </button>
    </div> */}
</div>



  
</div>
    
   
    
    <br></br>









  








    <div className="w-full h-10 pl-3 pr-2 bg-gray-100 border-gray-50 rounded-full  outline-none  border-none flex focus:outline-none justify-between items-center relative">
  <input type="text" name="search" id="search" placeholder="Search" 
         className=" w-full bg-gray-100 border-none rounded-full    focus:outline-none focus:border-white active:outline-none"
         value= {search} onChange={(e)=>fetchPost(e.target.value)}
         />
  <button type="submit" className="ml-1 outline-none border-none focus:border-none  active:outline-none">
    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
         viewBox="0 0 24 24" className="w-6 h-6">
      <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
    </svg>
  </button>
</div>

 
<br/>

 

<div className="flex flex-col mt-8">
      <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="px-6 py-3 border-b border-gray-200 bg-black text-left text-xs leading-4 font-medium text-gray-100 uppercase tracking-wider">
                Name
                  </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-black text-left text-xs leading-4 font-medium text-gray-100 uppercase tracking-wider">
                   Email
                   </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-black text-left text-xs leading-4 font-medium text-gray-100 uppercase tracking-wider">
                Phone                 
                   </th>
           
              
                
              </tr>
            </thead>




 <tbody className="bg-white">
            {data?data.map(item=>{
       


       

              return(

                
         
              <tr data-href="#edit" key={item._id} style={{cursor:"pointer"}} class={"bg-white" }>

                  
<td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                <div class="flex items-center">
                                    <div class="flex-shrink-0">
                                        <a href="#" class="relative block">
                                            <img alt="profil" src={item.pic?item
                      .pic:"./pcm.png"} class="mx-auto object-cover rounded-full h-10 w-10 "/>
                                        </a>
                                    </div>
                                    <div class="ml-3">
                                        <p class="text-gray-900 whitespace-no-wrap">
                                        {item.name}
                                        </p>
                                    </div>
                                </div>
                            </td>
            


                
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
                //   onClick={()=>{setEditStock(true);singleStock(item._id)}}

                >
                  <div className="text-sm leading-5 text-gray-900">{item.email}</div>
                </td>
               
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
                //   onClick={()=>{setEditStock(true);singleStock(item._id)}}

                >
                  <div className="text-sm leading-5 text-gray-900">{item.phone}</div>
                </td>
               
                       
                

                         
              </tr> 


              )
            }) :<></>}
           
  
             
            </tbody>

          
          </table>
        </div>
      </div>
    </div>

       





   
  </div>
</main>
</div>

    </div>



  </div>  
);


  
}


export default SuperUsers