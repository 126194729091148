import React, {useContext, useRef, useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import DatePicker from 'react-datepicker'
import {UserContex} from '../App'
import AdminHeader from '../components/AdminHeader'

// import NavBar from './nav'

const Seller = ()=>{


  const history = useNavigate()
  const {state, dispatch}= useContext(UserContex)



  const  [data, setData]= useState([])
  const  [Zonedata, setZoneData]= useState([])

  const [search,setSearch] = useState("")

  const [addStock,setAddStock]= useState(false)
  const [editEmoloyee,setEdit]= useState(false)
  const[id,setId] = useState("")  
  const[name,setName]= useState("")
  const[phone,setPhone]= useState("")
  const[address,setAddress]= useState("")
  const[email,setEmail]= useState("")
  const[password,setPassword]= useState("")
  const[cid,setCID]= useState("")
  const[zone,setZone]= useState("")
  const[status,setStatus]= useState("")
  const[skills,setSkills]=useState([])
  const[docs,setDocs]=useState([])
  const[salary,setSalary]= useState("")
  const [dob,setDob]= useState(new Date())
  const [url,setUrl] = useState("")  

  const[progress,setProgress]= useState(false)


  useEffect(  ()=>{

    fetch('/getmysellers',{
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{
      setData(result.sellers)
      
  })


  },[])



  const singleEmployee = (Itemid)=>{

  fetch('/getSingleSeller',{
    method:"post",
    headers:{
      Authorization: "Bearer " +localStorage.getItem("jwt"),
      "Content-Type":"application/json"

    },
    body:JSON.stringify({
      id:Itemid
    })
}).then(res=>res.json())
.then(result=>{


  // setDataEdit(result.stock)
  console.log(result.seller._id)
  setId(result.seller._id)
  setName(result.seller.name)
  setEmail(result.seller.email)
  setPhone(result.seller.phone)
  setZone(result.seller.zone)
  setCID(result.seller.cid)

  //setDob(result.employee.dob)
  
  

          
})

}
  



  


  const postStock = ()=>{

    fetch('/addseller',{

      method:"post",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({
        name:name,
        email,
        password,
        phone,
        pic:url
      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
      }

      else{
        
        window.location.reload();
            // history.go(`/employee`)

      }
    })
    .catch(err=>{
      console.log(err)
    })

  }


  
  const uploadppt=({target:{files}})=>{

    setProgress(true)

    let data = new FormData()

    for(const file of files){

   data.append('uploadimage', file)
   data.append('name',file.name)
}
       fetch("/uploadimage", {

         method:"post",
         body: data
     })
     .then(res=>res.json())
     .then(data=>{

          setUrl(data.url)
          setProgress(false)

      

       
     })
     .catch(err=>{
         console.log(err)
     })

}
  



  const postEmployeeUpdate = ()=>{

    fetch('/updateseller',{

      method:"post",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({
        id:id,
        name:name,
        email,
        password,
        phone,
   
      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
      }

      else{
        window.location.reload();
        // history.go('/dashboard')
      }
    })
    .catch(err=>{
      console.log(err)
    })

  }





  const fetchPost=(query)=>{


    setSearch(query)

    fetch('/search-seller',{

      method:"post",
      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt"),

        "Content-Type":"application/json"

      },
      body:JSON.stringify({
        query
      })

    }).then(res=> res.json())
      .then(results=>{
        
        setData(results.seller)
      })


  }

  const deleteEmployee = (postId)=>{

    fetch(`/deleteseller/${postId}`,{
        method: "delete",
        headers:{
          "Content-Type":"application/json",
          Authorization: "Bearer " +localStorage.getItem("jwt")
        }
    }).then(res=>res.json())
    .then(result=>{
      history('/sellers')

    }).catch(err=>{
        console.log(err)
    })
}
  
    //deleteEmployee
    

    const Logout = ()=>{
        localStorage.clear()
        dispatch({type:"CLEAR"})
            history.go(`/login`)
    }

return(

    <div>


 <script src="https://cdn.jsdelivr.net/gh/alpinejs/alpine@v2.x.x/dist/alpine.min.js" defer></script>

 <div  className="flex h-screen bg-white">

 <div className=" "></div>

        <link rel="stylesheet" href="https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css" />
  






        <div className="flex-1 flex flex-col overflow-hidden ">

            
        {/* <NavBar/> */}
       <AdminHeader page="events"> </AdminHeader>

            <main className="flex-1 overflow-x-hidden overflow-y-auto bg-white">
  <div className="container mx-auto px-6 py-8">
    <h3 className="text-gray-400 text-3xl font-medium">Associate Seller </h3>


<div className="flex flex-row ...">


<div class="shadow-lg rounded-xl bg-gray-200 w-full md:w-64 p-6 bg-white  relative overflow-hidden">
    <p class="text-black text-xl">
        Sellers
    </p>
    <div class="flex items-center my-4 text-blue-500 rounded justify-between">
        <span class="rounded-lg p-2 bg-white">
        <svg class="h-8 w-8 text-gray-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="9" cy="7" r="4" />  <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />  <path d="M16 3.13a4 4 0 0 1 0 7.75" />  <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" /></svg>        </span>
        <div class="flex flex-col w-full ml-2 items-start justify-evenly">
            <p class="text-black text-lg">
                {data.length}
            </p>
            <p class="text-gray-700 text-sm">
                Total Sellers
            </p>
        </div>
    </div>
   
    <div class="mt-4">
        <button type="button" class="py-2 px-4  bg-black hover:bg-gray-400 focus:ring-gray-400 focus:ring-offset-gray-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md "
        
        onClick={()=>setAddStock(true)}
        >
            Add Seller +
        </button>
    </div>
</div>



  
</div>
    
   
    
    <br></br>

    {
addStock? 
(
  <>
    <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto   relative w-auto  mx-auto ">
        {/*content*/}
        <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex rounded-full items-start  p-2 ">
            
            <button
              className="p-1 ml-auto rounded-full bg-red-500 text-white text-3 "
              onClick={() => setAddStock(false)}
            >
<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>            </button>
          </div>
          {/*body*/}
           



<div class="flex w-full max-w-sm content-center items-center ">
    <div class="w-full max-w-2xl px-5 py-6 m-auto  bg-white rounded-lg shadow ">
       
    <div class="grid max-w-xl grid-cols-2 gap-4 m-auto">

       
        <div className="py-1">
          <span className="px-1 text-sm text-gray-600">Name</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Name"
            value={name}
            onChange={(e)=>setName(e.target.value)}
            >

            </input>
          </div>
        
          <div className="py-1">
          <span className="px-1 text-sm text-gray-600">Phone</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Phone No."
            value={phone}
            onChange={(e)=>setPhone(e.target.value)}
            >

            </input>
          </div>
          </div>

          <div className="py-1">
          <span className="px-1 text-sm text-gray-600">Email</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Email"
            value={email}
            onChange={(e)=>setEmail(e.target.value)}
            >

            </input>
          </div>
          <div className="py-1">
          <span className="px-1 text-sm text-gray-600">Password</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Password"
            value={password}
            onChange={(e)=>setPassword(e.target.value)}
            >

            </input>
          </div>
      
       
        
       
        
           <div className="col-span-4 ">
                       <label className="flex flex-col items-center  bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue-300 hover:text-white">
        <svg className="w-6 h-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
        </svg>
        <span className="mt-2 text-base leading-normal">Upload</span>
        <input type='file' className="hidden" 
        onChange={uploadppt}
        />
        
    </label>



    {progress?         <span className=" text-base leading-normal"> Uploading...</span>:   <span className=" text-base leading-normal"> Upload Photo </span>
         }
                <input type='file' className="hidden"  required
                onChange={uploadppt}
                />
                
        
            {progress?
                <svg class="h-12 w-12 text-blue-500 animate-spin"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" />  <path d="M11 19.95a8 8 0 0 1 -5.3 -12.8" stroke-dasharray=".001 4.13" /></svg>
            :<></>}
   

{url?<img className=" w-28 h-28  rounded-lgflex items-center " src={url} ></img>:null
}
    
                               </div> 

              <br/>
              <div class="col-span-2 text-right">
                        <button  class="py-2 px-4  bg-purple-500 hover:bg-purple-600 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                        

                        onClick={()=>postStock()}
                        >
                            Add Seller
                        </button>


                    </div>
               
                
                </div>
        </div> 
         
 




         
        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </>
)


:null

}







    {
editEmoloyee? 
(
  <>
   <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto   relative w-auto  mx-auto ">
        {/*content*/}
        <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex rounded-full items-start  p-2 ">
            
            <button
              className="p-1 ml-auto rounded-full bg-red-500 text-white text-3 "
              onClick={() => setEdit(false)}
            >
<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>            </button>
          </div>
          {/*body*/}
           



<div class="flex w-full max-w-sm content-center items-center ">
    <div class="w-full max-w-2xl px-5 py-6 m-auto  bg-white rounded-lg shadow ">
       
    <div class="grid max-w-xl grid-cols-2 gap-4 m-auto">

       
        <div className="py-1">
          <span className="px-1 text-sm text-gray-600">Name</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Name"
            value={name}
            onChange={(e)=>setName(e.target.value)}
            >

            </input>
          </div>
        
          <div className="py-1">
          <span className="px-1 text-sm text-gray-600">Phone</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Phone No."
            value={phone}
            onChange={(e)=>setPhone(e.target.value)}
            >

            </input>
          </div>
          </div>

          <div className="py-1">
          <span className="px-1 text-sm text-gray-600">Email</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Email"
            value={email}
            onChange={(e)=>setEmail(e.target.value)}
            >

            </input>
          </div>
         
       
   
  {/* <div className="py-1">
  <span className="px-1 text-sm text-gray-600">Zone</span>
    <select  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
  bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
  
    placeholder="Zone"
    value={zone}
    onChange={(e)=>setZone(e.target.value)}
    >

{Zonedata? Zonedata.map(item=>{

return(
  <option>{item.zone}</option>

)

}):<></>}
    
    </select>
  </div>

        
        */}
        
          {/* <div className="col-span-4 ">
                       <label className="flex flex-col items-center  bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue-300 hover:text-white">
        <svg className="w-6 h-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
        </svg>
        <span className="mt-2 text-base leading-normal">Upload</span>
        <input type='file' className="hidden" 
        onChange={uploadppt}
        />
        
    </label>
    {progress?         <span className=" text-base leading-normal"> Uploading...</span>:   <span className=" text-base leading-normal"> Upload Photo </span>
         }
                <input type='file' className="hidden"  required
                onChange={uploadppt}
                />
                
        
            {progress?
                <svg class="h-12 w-12 text-blue-500 animate-spin"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" />  <path d="M11 19.95a8 8 0 0 1 -5.3 -12.8" stroke-dasharray=".001 4.13" /></svg>
            :<></>}

{url?<img className=" w-28 h-28  rounded-lgflex items-center " src={url} ></img>:null
}
    
                               </div> */}

              <br/>
              <div class="col-span-2 text-right">
                        <button  class="py-2 px-4  bg-yellow-300 hover:bg-orange-400 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                        

                        onClick={()=>{postEmployeeUpdate();setEdit(false)}}
                        >
                            Update Employee
                        </button>


                    </div>
               
                
                </div>
        </div> 
         
 




         
        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </>
)


:null

}
  








    <div className="w-full h-10 pl-3 pr-2 bg-gray-100 border-gray-50 rounded-full  outline-none  border-none flex focus:outline-none justify-between items-center relative">
  <input type="text" name="search" id="search" placeholder="Search" 
         className=" w-full bg-gray-100 border-none rounded-full    focus:outline-none focus:border-white active:outline-none"
         value= {search} onChange={(e)=>fetchPost(e.target.value)}
         />
  <button type="submit" className="ml-1 outline-none border-none focus:border-none  active:outline-none">
    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
         viewBox="0 0 24 24" className="w-6 h-6">
      <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
    </svg>
  </button>
</div>

 
<br/>

 
       
    <div className="grid  grid-cols-1 md:grid-cols-3 sm:space-x-0 gap-4  ">

                    

      {data.map(item=>{

        return(



          <>
          
         
<div class= {`flex-col shadow-2xl rounded-2xl  p-4 bg-gray-50   `}>
    <div class="flex flex-row items-start gap-2">
        <img src={item.pic}  class="w-28 h-28 pr-3 rounded-lg"/>
        <div class="h-28 w-full flex flex-col justify-between">
            <div class="flex items-center">
            <svg class="h-6 w-6 text-black"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />  <circle cx="12" cy="7" r="4" /></svg>    

                <p class="text-gray-800 text-l font-medium ml-2">
                    {item.name}
                </p>
            </div>
            <div class="flex items-center mt-2">
            <svg class="h-6 w-6 text-black"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="3" y="5" width="18" height="14" rx="2" />  <polyline points="3 7 12 13 21 7" /></svg>            
            
               <p class="text-md text-black ml-2">
            
            {item.email}
        </p>
    </div>
           <a href= {`tel:${item.phone}`} class="flex items-center pt-2 pb-2">
            <svg class="h-6 w-6 text-black"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" /></svg>            
              <p class="text-md text-black ml-2">
            
            {item.phone}
        </p> </a>
    
         
      
        </div>
   
   
   
   
    </div>

    <div class=" items-center gap-4 mt-8">
        <button type="button" class="w-1/3 px-4 py-2 text-base border rounded-lg text-white bg-red-500  "
        
        onClick={()=>{if(window.confirm('Are you sure you want to delete?')) deleteEmployee(item._id)}}

        >
            Remove
        </button>
        <button type="button" class="w-1/3 px-4 py-2 text-base border rounded-lg text-white bg-blue-600  "
                          onClick={()=>{setEdit(true);singleEmployee(item._id)}}
                          >
            Update
        </button>
        <button type="button" class="w-1/3 px-4 py-2 text-base border rounded-lg text-white bg-purple-500  "
                          onClick={()=>{history(`/sellerinfo/${item._id}`)}}
                          >
            More
        </button>
    </div>
   
</div> 


</>

              




             
    


        
      )

      })}
      </div>


       {/* { data.map(item=>{
                      
                      
                      console.log(item._id);


            return(


                <Link to={`/singlerecord/${item._id}`}>
                <li class="border-gray-400 flex flex-row mb-2">
                <div class="shadow border select-none cursor-pointer bg-white dark:bg-gray-800 rounded-md flex flex-1 items-center p-4">
                   
                    <div class="flex-1 pl-1 md:mr-16">
                        <div class="font-medium dark:text-white">
                            {item.name}
                        </div>
                        <div class="text-gray-600 dark:text-gray-200 text-sm">
                            {item.address}
                        </div>
                    </div>
                    <div class="flex md:space-x-8 space-x-6 w-14 h-10 justify-center items-center mr-4">
                            {item.phone}                     
                    </div>
                                   
                </div>
            </li>
                </Link>
                
         
            )

       })
        


       } */}

       
       
   




   
  </div>
</main>
</div>

    </div>



  </div>  
);


  
}


export default Seller