import React, { useState,useEffect,useContext } from 'react';
import QRCode from 'qrcode.react';
import {UserContex} from '../App'
import {Link, useNavigate} from 'react-router-dom'
import {Html5QrcodeScanner} from 'html5-qrcode';
import AdminHeader from '../components/AdminHeader';

 
function QRGenerate() {
    const {state, dispatch}= useContext(UserContex)

    const history = useNavigate()

  const [stat, setSat] = useState();
  const [qrCodeData, setQRCodeData] = useState(null);
  const [Zonedata,setZoneData] = useState([])

  const[data,setData]= useState(null)
  const[scanned,setScanned]= useState(false)


 
  useEffect(() => {
 const scanner = new Html5QrcodeScanner('reader',{

  qrbox:{
    width:250,
    height:250
  },
  fps:5

 })

 const success = (result)=>{
  scanner.clear()
  setQRCodeData(JSON.parse(result))

  fetch("/getTicket",{
    method:"post",
    headers:{
      Authorization: "Bearer " +localStorage.getItem("jwt"),

        "Content-Type":"application/json"
    },
    body: JSON.stringify({
      
        id:result
    })
    
}).then(res=>res.json())
.then(data=> {
  // console.log("hjghjgjhg",data.stat)
   setData(data.ticket)
  //  setSat(data.stat)
  setScanned(true)
})
.catch(err=>{
    console.log(err)
})
  


 }

//  console.log(qrCodeData)
 const error = (err)=>{

  // console.log(err)
 }
 scanner.render(success,error)

  
  }, []);



  const updateTicket =()=>{

    fetch("/updateticket",{
      method:"put",
      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt"),
  
          "Content-Type":"application/json"
      },
      body: JSON.stringify({
        
          id:data[0]._id
      })
      
  }).then(res=>res.json())
  .then(data=> {
     
    setData([])
    
  })
  .catch(err=>{
      console.log(err)
  })
  
  }

  const Logout = ()=>{
    localStorage.clear()
    dispatch({type:"CLEAR"})
        history(`/login`)
}
 
  return (

    <>
<AdminHeader page="scan"></AdminHeader>

<div class="bg-white  overflow-hidden relative lg:flex lg:items-center">
    <div class="w-full px-4 sm:px-6  lg:px-8 z-20">
        <h2 class="text-3xl font-extrabold text-black  sm:text-4xl">
            <span class="block">
                Read QR code
            </span>
        </h2>

      
                <div class="" id="reader"></div>

        {/* <h1>{qrCodeData}</h1> */}

        <div class="lg:mt-0 lg:flex-shrink-0">
        
        </div>
    </div>
    {data?
    data.status=="Active" ?
<div class="w-64 p-4 m-auto bg-white shadow-lg rounded-2xl ">
    <div class="w-full h-full text-center">
        <div class="flex flex-col justify-between h-full">
          
        <svg class="w-20 h-20 m-auto mt-4 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7">
                </path>
            </svg>

            <p class="mt-4 text-xl font-bold text-gray-800 ">
                Active
            </p>
            <p class="px-6 py-2 text-xs text-gray-600 ">
                The Ticket is Active 
            </p>
            <div class="flex items-center justify-between w-full gap-4 mt-8">
                <button type="button" class="py-2 px-4  bg-red-600 hover:bg-red-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                    Cancel
                </button>
                <button type="button" class="py-2 px-4  bg-green-500 hover:bg-green-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-indigo-500 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                onClick={updateTicket}
                >
                    Approve
                </button>
            </div>
        </div>
    </div>
</div>:data.status=="Used"?<div class="w-64 p-4 m-auto bg-white shadow-lg rounded-2xl ">
    <div class="w-full h-full text-center">
        <div class="flex flex-col justify-between h-full">
          
        <svg class="w-20 h-20 m-auto mt-4 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7">
                </path>
            </svg>

            <p class="mt-4 text-xl font-bold text-gray-800 ">
                Used
            </p>
            <p class="px-6 py-2 text-xs text-gray-600 ">
                The Ticket is not Valid 
            </p>
                <button 
                onClick={()=>{window.location.reload()}}

                
                type="button" class="py-2 px-4  bg-red-600 hover:bg-red-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                    Done
                </button>
               
      </div>
    </div>
</div>:<>
</>
: <>
</>


}
  
    {/* <div class="flex flex-col items-center gap-8 p-8 lg:p-24">
        <div>
            <QRCode
        id="qrCodeEl"
        size={400}
        value={qrCodeText}
      />
           <button class="flex-shrink-0 px-4 mt-5 py-2 text-base font-semibold text-white bg-green-600 rounded-lg shadow-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-green-200" type="submit"
                    
                    onClick={downloadQRCode}
                    >
                            Download QR Code
                        </button>
        </div>
   
    </div> */}
</div>

    

    </>
    
  );
}
 
export default QRGenerate;