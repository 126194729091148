
import React,{useContext, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {UserContex} from '../App'
import Header from '../components/Header';


const Error = ()=>{
  
const navigate = useNavigate()
    return(


<>
<>
<Header></Header>
  {/* component */}

  <div class="relative h-screen overflow-hidden bg-purple-500">
    <img src="https://img.freepik.com/free-vector/glitch-error-404-page_23-2148105403.jpg?size=626&ext=jpg&ga=GA1.1.16292083.1700716592&semt=ais" class="absolute object-cover w-full h-full"/>
    <div class="absolute inset-0 bg-black opacity-25">
    </div>
    <div class="container relative z-10 flex items-center px-6 py-32 mx-auto md:px-12 xl:py-40">
        <div class="relative z-10 flex flex-col items-center w-full font-mono">
            <h1 class="mt-4 text-5xl font-extrabold leading-tight text-center text-white">
                You&#x27;re alone here
            </h1>
            <p class="font-extrabold text-white text-8xl my-44 animate-bounce">
                404
            </p>
        </div>
    </div>
</div>

</>

{/* <div class="flex flex-wrap w-full">
    <div class="flex flex-col w-full md:w-1/2">
       
        <div class="flex flex-col justify-center px-8 pt-8 my-auto md:justify-start md:pt-0 md:px-24 lg:px-32">
           
        
<form class="flex w-full max-w-sm space-x-3">
    <div class="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-white rounded-lg shadow dark:bg-gray-800">
        <div class="mb-6 text-3xl font-light text-center text-gray-800 dark:text-white">
            Contact us !
        </div>
        <div class="grid max-w-xl grid-cols-2 gap-4 m-auto">
            <div class="col-span-2 lg:col-span-1">
                <div class=" relative ">
                    <input type="text" id="contact-form-name" class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Name"/>
                    </div>
                </div>
                <div class="col-span-2 lg:col-span-1">
                    <div class=" relative ">
                        <input type="text" id="contact-form-email" class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="email"/>
                        </div>
                    </div>
                    <div class="col-span-2">
                        <label class="text-gray-700" for="name">
                            <textarea class="flex-1 w-full px-4 py-2 text-base text-gray-700 placeholder-gray-400 bg-white border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" id="comment" placeholder="Enter your comment" name="comment" rows="5" cols="40">
                            </textarea>
                        </label>
                    </div>
                    <div class="col-span-2 text-right">
                        <button type="submit" class="py-2 px-4  bg-blue-500 hover:bg-blue-600 focus:ring-blue-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                            Send
                        </button>
                    </div>
                </div>
            </div>
        </form>

                  
                </div>
            </div>
            <div class="w-1/2 shadow-2xl">
                <img class=" object-fit w-full h-screen " src="/contact.jpeg"/>
            </div>
        </div> */}


<div class=" lg:invisible visible fixed bottom-0 flex flex-row w-full ">

<div class="flex items-center w-full">
    <button type="button" class="w-full px-4 py-2 text-base font-medium text-black bg-white  rounded-l-md hover:bg-gray-100"
    onClick={()=>navigate("/home")}
    >
    <svg class="h-6 w-6 text-blue-400 content-center justify-center	ml-4"  width="26" height="26" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <polyline points="5 12 3 12 12 3 21 12 19 12" />  <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />  <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" /></svg>
    </button>
    <button type="button" class="w-full px-4 py-2 text-base font-medium text-black bg-white  hover:bg-gray-100"
        onClick={()=>navigate("/products")}

    >
    <svg class="h-6 w-6 text-blue-400 content-center justify-center	ml-4"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M4.5 12.5l8 -8a4.94 4.94 0 0 1 7 7l-8 8a4.94 4.94 0 0 1 -7 -7" />  <path d="M8.5 8.5l7 7" /></svg>
    </button>
    <button type="button" class="w-full px-4 py-2 text-base font-medium text-black bg-white  hover:bg-gray-100"
            onClick={()=>navigate("/profile")}

    >
    <svg class="h-6 w-6 text-blue-400 content-center justify-center	ml-4"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
</svg>
    </button>
    <button type="button" class="w-full px-4 py-2 text-base font-medium text-black bg-white rounded-r-md hover:bg-gray-100"
                onClick={()=>navigate("/cart")}

    >
    <svg class="h-6 w-6 text-blue-400 content-center justify-center	ml-4 "  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="9" cy="19" r="2" />  <circle cx="17" cy="19" r="2" />  <path d="M3 3h2l2 12a3 3 0 0 0 3 2h7a3 3 0 0 0 3 -2l1 -7h-15.2" /></svg>

    </button>
</div>


</div>


</>
  


    );
}

export default Error;