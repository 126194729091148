import React,{ useEffect, useState,useContext } from "react";
import {Link, useNavigate,useParams} from 'react-router-dom'
import {UserContex} from '../App'
import { ToastContainer, toast } from 'react-toastify';
import QRCode from "qrcode.react";
import 'react-toastify/dist/ReactToastify.css';
import Header from "../components/Header";
import Footer from "../components/Footer";


const ParticipantProfile = ()=>{
  // const {state, dispatch}= useContext(UserContex)

  const history = useNavigate()
   const [name, setName] = useState("")
   const [email, setEmail] = useState("")
   const [newAddress, setNewAddress] = useState("")
   const [registration, setRegistration] = useState("")
   const [address,setAddress] = useState([])
   const [department,setDepartment] = useState("")
   const [orderHistory,setOrderHistory] = useState(false) 
   const [prescriptions,setShowPrescriptions] = useState(true) 
   const [edit,setEdit] = useState(false) 
   const [showimage,setShowImage] = useState(false) 
   const [zoomimage,setZoomImage] = useState("") 
   const [orderHistoryData,setOrderHistoryData] = useState([]) 
   const [data,setData] = useState(null) 
   const [event,setEvent] = useState([]) 
   const [search,setSearch] = useState("")
   const {id} = useParams()
 
  //  console.log("STATE",state)

  const {state, dispatch }= useContext(UserContex)


   const monthNames = ["January", "February", "March", "April", "May", "June",
   "July", "August", "September", "October", "November", "December"
 ];
 
 var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
 
 const d = new Date();
 
   


 useEffect(  ()=>{

  


    fetch(`/getmyvotes`,{
      method:"get",
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt"),
          "Content-Type":"application/json"
  
          
      },
     
  }).then(res=>res.json())
  .then(result=>{
    setData(result.participant)
    // setEvent(result.ticket.event)
      
  })




},[])


const addAddress = (newaddress)=>{

        // console.log(newaddress)
      setAddress(old=>[...old, newaddress])

}

const updateProfile =()=>{

  fetch("/updateConsumerAddress",{
    method:"put",
    headers:{
      Authorization: "Bearer " +localStorage.getItem("jwt"),

        "Content-Type":"application/json"
    },
    body: JSON.stringify({
      
        address:address
    })
    
}).then(res=>res.json())
.then(data=> {
   
    if(data.error){
      toast.error(data.error)

    }
    else{
   toast.success("Updated")
  //  history.go('/cart')

  setEdit(false)
    }
})
.catch(err=>{
    console.log(err)
})

}

// console.log("DATA",data[0].address)

const fetchPost=(query)=>{


  setSearch(query)

  fetch('/searchall-events',{

    method:"post",
    headers:{
      Authorization: "Bearer " +localStorage.getItem("jwt"),

      "Content-Type":"application/json"

    },
    body:JSON.stringify({
      query,
    })

  }).then(res=> res.json())
    .then(results=>{
      
      setData(results.events)
    })


}

const handleSearch = event => {
  const term = event.target.value;
  setSearch(term);

  // Perform the search and update searchResults
  const results = data.filter(item =>
    item.toLowerCase().includes(search.toLowerCase())
  );
  setData(results);
};

const Logout = ()=>{
  localStorage.clear()
  dispatch({type:"CLEAR"})
      history(`/`)
}

    return(
<>
<Header></Header>
<main className="profile-page">
        <section className="relative block" style={{ height: "500px" }}>
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage:
                "url('https://images.unsplash.com/photo-1492684223066-81342ee5ff30?auto=format&fit=crop&q=80&w=1470&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')"
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-50 bg-black"
            ></span>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
            style={{ height: "70px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-300 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </section>
        <section className="relative py-16 bg-gray-300">
          <div className="container mx-auto px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
              <div className="px-6">
              <div className="flex flex-wrap justify-center">
                  <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                    <div className="relative">
                      <img
                        alt="..."
                        src={data?data[0].pic:"/logo.png"}

                        className="shadow-xl rounded-full h-32 w-32 align-middle border-none absolute -m-16 -ml-16 lg:-ml-16"
                        style={{ maxWidth: "150px" }}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
                    <div className="py-6 px-3 mt-32 sm:mt-0">
                      <button
                        className="bg-white active:bg-pink-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1"
                        type="button"
                        style={{ transition: "all .15s ease" }}
                        onClick={()=>Logout()}

                      >
                    <svg class="h-8 w-8 text-red-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />  <path d="M7 12h14l-3 -3m0 6l3 -3" /></svg>
                      </button>
                      <button
                        className="bg-white active:bg-pink-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1"
                        type="button"
                        style={{ transition: "all .15s ease" }}
                        onClick={()=>{if(window.confirm('Send account delete request?'))Logout()}}

                      >
<svg class="h-8 w-8 text-red-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <line x1="4" y1="7" x2="20" y2="7" />  <line x1="10" y1="11" x2="10" y2="17" />  <line x1="14" y1="11" x2="14" y2="17" />  <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />  <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>                      </button>

                      {/* <button
                        className="bg-purple-500 active:bg-pink-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1"
                        type="button"
                        style={{ transition: "all .15s ease" }}
                        onClick={()=>history('/findevents')}

                      >
                        Logout 
                      </button> */}
                    </div>
                  </div>
                  <div className="w-full lg:w-4/12 px-4 lg:order-1">
                    <div className="flex justify-center py-4 lg:pt-4 pt-8">
                
                      {/* <div className="mr-4 p-3 text-center">
                        <span className="text-xl font-bold block uppercase tracking-wide text-gray-700">
                          10
                        </span>
                        <span className="text-sm text-gray-500">Consultations</span>
                      </div> */}
                      {/* <div className="lg:mr-4 p-3 text-center">
                        <span className="text-xl font-bold block uppercase tracking-wide text-gray-700">
                          89
                        </span>
                        <span className="text-sm text-gray-500">Comments</span>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="text-center mt-10">
                  <h3 className="text-4xl font-semibold leading-normal mb-2 text-purple-500 mb-2">
                    {data?data[0].name:""}
                  </h3>
                  <div className="text-sm leading-normal mt-0 mb-2 text-gray-800 font-bold uppercase">
                    <i className="fas fa-map-marker-alt mr-2 text-lg text-purple-500"></i>{" "}
                    {data?data[0].email:""}
                  </div>


<div class="flex justify-center items-center">
<div class="md:w-4/12 w-full  bg-white shadow-lg rounded-2xl  ">
    <p class="p-4 font-bold text-black text-md ">
        My Votes
       
    </p>
    <ul>
    <li class="flex items-center justify-between py-3 text-gray-600 border-b-2 border-gray-100 ">
                <div class="flex items-center justify-start text-sm">
                    <span class="mx-4">
                        Current Week
                    </span>
                    <span>
                        {data?data[0].votes:"Loading.."}
                    </span>
                </div>
                <svg width="20" height="20" fill="currentColor" class="mx-4 text-gray-400 " viewBox="0 0 1024 1024">
                    <path d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0 0 51.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" fill="currentColor">
                    </path>
                    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448s448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372s372 166.6 372 372s-166.6 372-372 372z" fill="currentColor">
                    </path>
                </svg>
            </li>
        {data?data[0].weeklyvotes.map(votes=>{

            return(
                <li class="flex items-center justify-between py-3 text-gray-600 border-b-2 border-gray-100 ">
                <div class="flex items-center justify-start text-sm">
                    <span class="mx-4">
                      Week  {votes.week}
                    </span>
                    <span>
                        {votes.votes} Votes
                    </span>
                </div>
                <svg width="20" height="20" fill="currentColor" class="mx-4 text-gray-400 " viewBox="0 0 1024 1024">
                    <path d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0 0 51.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z" fill="currentColor">
                    </path>
                    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448s448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372s372 166.6 372 372s-166.6 372-372 372z" fill="currentColor">
                    </path>
                </svg>
            </li>
            )
        }):<></>}
      
    
    </ul>
</div>
</div>



                 
                 


                                                    {/* SEARCH */}

{/* <div class="flex flex-row items-center  justify-center w-full p-2 bg-white bg-indigo-500 shadow-xs">
    
    
    <div className="w-1/3 bg-white border-2 border-purple-500 rounded-lg h-12 bg-opacity-80  p-4  focus:outline-none focus:border-white active:outline-none flex focus:outline-none justify-between items-center relative">
 <input type="text" name="search" id="search" placeholder={  'Search'} 
        className=" w-full bg-none border-none rounded-lg h-10   p-4  focus:outline-none focus:border-none active:outline-none"
        
        value= {search} onChange={(e)=>fetchPost(e.target.value)}
        />
 <button type="submit" className="ml-1 outline-none border-none focus:border-none  active:outline-none">
   <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
        viewBox="0 0 24 24" className="w-6 h-6 text-purple-500">
     <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
   </svg>
 </button>


</div>
    </div> */}

    
{/* 
                  <div className="mb-2 text-gray-700 ">
                  <div class="relative w-full item-center  m-auto   p-2 overflow-hidden bg-white shadow-lg rounded-xl md:w-80 dark:bg-gray-800">
   hgjhjhs

   
</div>
         
                     
         </div> */}



{/* 
                  <div class="flex flex-col bg-white m-auto p-auto">

<div
  class="flex overflow-x-scroll pb-10  hide-scroll-bar"
>
  <div
    class="flex flex-nowrap h-24 lg:ml-80 md:ml-24 ml-2 "
  >
    <div class="h-16 w-45 px-3 cursor-pointer">
      <div
        class="w-50 h-16 flex flex-row max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
     onClick={()=>{setOrderHistory(!orderHistory);setShowPrescriptions(false)}}
     >

      <img src="/orderHistory.png"/>
        <h3 class="pt-4 px-2">Orders</h3>
    </div>
    </div>
   
    <div class="h-16 w-45 px-3 cursor-pointer"
         onClick={()=>{setShowPrescriptions(!prescriptions);setOrderHistory(false)}}

    >
      <div
        class="w-50 h-16 flex flex-row max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
      >

      <img src="/myPrescriptions.png"/>
        <h3 class="pt-4 px-2 invisible lg:visible">Prescriptions</h3>
    </div>
    </div>
    <div class="h-16 w-45 px-3 cursor-pointer"
    
    onClick={() => {setEdit(true);setName(state.name);setEmail(state.email)}}

    >
      <div
        class="w-50 h-16 flex flex-row max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
      >

      <img src="/profileEdit.png"/>
        <h3 class="pt-4 px-2">Edit Profile</h3>
    </div>
    </div>


  </div>
</div>
</div> */}
 
                </div>


 

  Votes
 
  



  

   











                <div className="mt-10 py-10 border-t border-gray-300 text-center">
                  <div className="flex flex-wrap justify-center">
                    <div className="w-full lg:w-9/12 px-4">
                      <p className="mb-4 text-lg leading-relaxed text-gray-800">
                        Keep track of your Votes, Track your Vote status. View your history and connect with us.
                      </p>
                      {/* <a
                        href="#pablo"
                        className="font-normal text-pink-500"
                        onClick={e => e.preventDefault()}
                      >
                        Show more
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </main>

      <Footer page="profile"></Footer>
</>
    );

}

export default ParticipantProfile; 