import React, {useContext, useRef, useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import DatePicker from 'react-datepicker'
import {UserContex} from '../App'
import {fill} from "@cloudinary/url-gen/actions/resize";
import {CloudinaryImage} from '@cloudinary/url-gen';
// import NavBar from './nav'
import { Document, Page, Text, View, StyleSheet , PDFViewer,PDFDownloadLink,Image} from '@react-pdf/renderer';

const Unpaid = ()=>{


  const history = useNavigate()
  const {state, dispatch}= useContext(UserContex)



  const  [data, setData]= useState([])

  const [search,setSearch] = useState("")

  const [addStock,setAddStock]= useState(false)
  const [editEmoloyee,setEdit]= useState(false)
  const[id,setId] = useState("")  
  const[area,setArea]= useState("")
  const[car,setCar]= useState("")
  const[twoWheeler,setTwoWheeler]= useState("")
  const[email,setEmail]= useState("")
  const[password,setPassword]= useState("")
  const[cid,setCID]= useState("")
  const[zone,setZone]= useState("")
  const[status,setStatus]= useState("")
  const[skills,setSkills]=useState([])
  const[docs,setDocs]=useState([])
  const[salary,setSalary]= useState("")
  const [dob,setDob]= useState(new Date())
  const [url,setUrl] = useState("")  

  const[progress,setProgress]= useState(false)


  useEffect(  ()=>{

    fetch('/getallunpaidparking',{
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{
      setData(result.unpaid)
      
  })

  },[])



  
  



  

  const postStock = ()=>{

    fetch('/addZone',{

      method:"post",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({
        area,car,zone,twoWheeler, pic:url
      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
      }

      else{
        
        window.location.reload();
            // history.go(`/employee`)

      }
    })
    .catch(err=>{
      console.log(err)
    })

  }


  
  const uploadppt=({target:{files}})=>{

    setProgress(true)

    let data = new FormData()

    for(const file of files){

   data.append('uploadimage', file)
   data.append('name',file.name)
}



       fetch("/uploadimage", {

         method:"post",
         body: data
     })
     .then(res=>res.json())
     .then(data=>{

          setUrl(data.url)
          setProgress(false)

      

       
     })
     .catch(err=>{
         console.log(err)
     })

}
  



  const postPaidUpdate = (item)=>{

    fetch('/makepaid',{

      method:"put",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({
        id:item,
       
        
      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
      }

      else{
        window.location.reload();
        // history.go('/dashboard')
      }
    })
    .catch(err=>{
      console.log(err)
    })

  }





  const fetchPost=(query)=>{


    setSearch(query)

    fetch('/search-unpaid',{

      method:"post",
      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt"),

        "Content-Type":"application/json"

      },
      body:JSON.stringify({
        query
      })

    }).then(res=> res.json())
      .then(results=>{
        
        setData(results.unpaid)
      })


  }

  const deleteEmployee = (postId)=>{

    fetch(`/deleteZone/${postId}`,{
        method: "delete",
        headers:{
          "Content-Type":"application/json",
          Authorization: "Bearer " +localStorage.getItem("jwt")
        }
    }).then(res=>res.json())
    .then(result=>{
        window.location.reload(false);

    }).catch(err=>{
        console.log(err)
    })
}
  
    //deleteEmployee
    

    const Logout = ()=>{
        localStorage.clear()
        dispatch({type:"CLEAR"})
            history.go(`/login`)
    }





const PDFComponent = (da) => (


  <Document>
  <Page size="A4" style={styles.page}>
    <View style={styles.header}>
    <Image style={styles.logo} src="/logo.png"
 />

      <Text style={styles.address}>Druk Smart Parking</Text>
      <Text style={{fontSize:10, marginBottom: 3}}>Tsirang</Text>
      <Text style={{fontSize:10,marginBottom: 3}}>TPN: GAB66324</Text>
      <Text style={{fontSize:10,marginBottom: 3}}>Contact:77428435 / 77413093</Text>

    </View>
    <View style={styles.horizontalLine} />

    <View style={styles.invoiceDetails}>
      <View style={styles.itemList}>
        <View style={styles.itemRow}>
          <Text style={styles.label}>Car</Text>
          <Text style={styles.label}>Time</Text>
          <Text style={styles.label}>Date</Text>
          <Text style={styles.label}>Cost</Text>
        </View>
        <View style={styles.horizontalLine} />

          <View  style={styles.itemRow}>
            <Text style={styles.itemDescription}>{da.car}</Text>
            <Text style={styles.itemDescription}>{da.time}</Text>
            <Text style={styles.itemDescription}>{da.date}</Text>
            <Text style={styles.itemDescription}>{da.cost}</Text>
          </View>
          <View style={styles.horizontalLine} />

      </View>
     
      <View style={styles.totalRow}>
        <Text style={styles.totalLabel}>Total:</Text>
        {/* <Text style={styles.totalValue}>{invoice.total}</Text> */}
      </View>
    </View>
  </Page>
</Document>
  
);




const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    padding: 30,
  },
  header: {
    marginBottom: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  address: {
    fontSize: 12,
    marginBottom: 10,
  },
  invoiceDetails: {
    marginBottom: 20,
  },
  label: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  value: {
    fontSize: 12,
  },
  itemList: {
    marginTop: 20,
  },
  itemRow: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginBottom: 8,
    marginLeft:8
  },
  itemDescription: {
    width: '20%',
    textAlign:'center'
  },
  itemQuantity: {
    width: '15%',
    textAlign: 'left',
  },
  itemAmount: {
    width: '25%',
    textAlign: 'right',
  },
  totalRow: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 10,
  },
  totalLabel: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  totalValue: {
    fontSize: 14,
    marginLeft: 10,
  },
  logo: {
    width: 80, 
    height: 80, 
    marginBottom: 10,
    justifyContent: 'center',
    alignItems: 'center',  },


  horizontalLine: {
    borderTop: 1,
    borderColor: 'black',
    marginVertical: 10,
    width: '100%',
  },
});



return(

    <div>


 <script src="https://cdn.jsdelivr.net/gh/alpinejs/alpine@v2.x.x/dist/alpine.min.js" defer></script>

 <div  className="flex h-screen bg-white">

 <div className=" "></div>

        <link rel="stylesheet" href="https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css" />
  






        <div className="flex-1 flex flex-col overflow-hidden ">

            
        {/* <NavBar/> */}
        <nav class=" bg-gray-100 shadow ">
            <div class="max-w-7xl mx-auto px-8">
                <div class="flex items-center justify-between h-16">
                    <div class="w-full justify-between flex items-center">
                        <a class="flex-shrink-0" href="/dashboard">
                        <svg class="h-8 w-8 text-gray-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <polyline points="5 12 3 12 12 3 21 12 19 12" />  <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />  <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" /></svg>
                        </a>
                        
                    </div>
                    <div class="block">
                        
                    </div>
                    <div class="-mr-2 flex ">
                      
                    <button class="flex p-2 items-center rounded-full bg-white  shadow text-gray-400 hover:text-gray-700 "
                            
                            onClick={()=>{if(window.confirm('Are you sure you want to logout?')) Logout()}}                           
                    
                        >
                   <svg class="h-6 w-6 text-gray-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"
                        
                        
                        />  <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />  <path d="M7 12h14l-3 -3m0 6l3 -3" /></svg>       
                     </button>
                  
    
                    </div>
                </div>
            </div>
           
        </nav>


            <main className="flex-1 overflow-x-hidden overflow-y-auto bg-white">
  <div className="container mx-auto px-6 py-8">
    <h3 className="text-gray-400 text-3xl font-medium">Unpaid</h3>


<div className="flex flex-row ...">


<div class="shadow-lg rounded-xl bg-gray-200 w-full md:w-64 p-6 bg-white dark:bg-gray-500 relative overflow-hidden">
    <p class="text-black text-xl">
        Unpaid
    </p>
    <div class="flex items-center my-4 text-blue-500 rounded justify-between">
    <svg class="h-8 w-8 text-gray-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"/>
</svg>


        <div class="flex flex-col w-full ml-2 items-start justify-evenly">
            <p class="text-black text-lg">
                {data.length}
            </p>
            <p class="text-gray-700 text-sm">
                Total Unpaid Dues 
            </p>
        </div>
    </div>
   
    <div class="mt-4">
        {/* <button type="button" class="py-2 px-4  bg-black hover:bg-gray-400 focus:ring-gray-400 focus:ring-offset-gray-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md "
        
        onClick={()=>setAddStock(true)}
        >
            Add Zones +
        </button> */}
    </div>
</div>



  
</div>
    
   
    
    <br></br>








 
  








    <div className="w-full h-10 pl-3 pr-2 bg-gray-100 border-gray-50 rounded-full  outline-none  border-none flex focus:outline-none justify-between items-center relative">
  <input type="text" name="search" id="search" placeholder="Search" 
         className=" w-full bg-gray-100 border-none rounded-full    focus:outline-none focus:border-white active:outline-none"
         value= {search} onChange={(e)=>fetchPost(e.target.value)}
         />
  <button type="submit" className="ml-1 outline-none border-none focus:border-none  active:outline-none">
    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
         viewBox="0 0 24 24" className="w-6 h-6">
      <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
    </svg>
  </button>
</div>

 
<br/>

 
       
    <div className="grid  grid-cols-1 md:grid-cols-4 gap-4 ">

                    

      {data.map(item=>{


                    var date1 = new Date(item.time);
                         var date2 = new Date(item.endtime)
                         
                         
                         var timeDiff = (Math.abs(date2.getTime() - date1.getTime())/1000)/60;

        return(



          <>
          
 

          <div class="w-full max-w-xs p-6 overflow-hidden bg-white shadow-lg rounded-xl dark:bg-gray-800">
    <div class="flex flex-col items-center justify-between md:flex-row">
        <div class="flex items-center justify-start flex-grow w-full">
            <a href="#" class="relative block">
            <svg class="h-12 w-12 text-blue-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>
            </a>
            <div class="flex flex-col items-start ml-4">
                <span class="text-gray-700 dark:text-white">
                    {item.driver?item.driver.name:<></>}
                </span>
             
            </div>
        </div>
        <div class="flex-none hidden md:block ">
            <span class="w-full px-3 py-1 text-sm text-white bg-blue-500 rounded-full">
                {item.zone}
            </span>
        </div>
    </div>
    <p class="mt-4 mb-2 text-lg text-gray-800 dark:text-white">
        {item.driver?item.driver.email:<></>}
    </p>
    <p class="text-sm font-normal text-gray-400">

    </p>
 

    <div class="flex items-center">
    <svg class="h-6 w-6 text-gray-400"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" /></svg>            <p class="text-md text-black dark:text-white ml-2">
            {item.driver?item.driver.phone:<></>}

        </p>
    </div>
    <div class="flex items-center">
    <svg class="h-6 w-6 text-gray-400"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="4" y="5" width="16" height="16" rx="2" />  <line x1="16" y1="3" x2="16" y2="7" />  <line x1="8" y1="3" x2="8" y2="7" />  <line x1="4" y1="11" x2="20" y2="11" />  <line x1="11" y1="15" x2="12" y2="15" />  <line x1="12" y1="15" x2="12" y2="18" /></svg>
            <p class="text-md text-black dark:text-white ml-2">
            {item.time.substring(0,10)}

        </p>
    </div>
    <div class="flex items-center">
    <svg class="h-6 w-6 text-gray-400"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>
            <p class="text-md text-black dark:text-white ml-2">
            {timeDiff.toFixed(0)} Minutes

        </p>
    </div>
    <div class="flex items-center">
    <svg class="h-6 w-6 text-gray-400"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="7" cy="17" r="2" />  <circle cx="17" cy="17" r="2" />  <path d="M5 17h-2v-6l2-5h9l4 5h1a2 2 0 0 1 2 2v4h-2m-4 0h-6m-6 -6h15m-6 0v-5" /></svg>
            <p class="text-md text-black dark:text-white ml-2">
            {item.car?item.car:""} 

        </p>
    </div>


   

    <div class="flex items-center justify-between p-2 my-6 bg-blue-100 rounded">
        <div class="flex items-start justify-between w-full">
            <p class="flex-grow w-full text-2xl text-gray-700">
                <span class="font-light text-gray-400 text-md">
                    Nu.
                </span>
                {Math.ceil(timeDiff / 30) * 20}              
                
                  <span class="text-sm font-light text-gray-400">
                    
                </span>
            </p>
            
        </div>
    </div>
    <button type="button" class="py-2 px-4  bg-red-600 hover:bg-red-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
    
    onClick={()=>{if(window.confirm('Are you sure you want to delete?')) postPaidUpdate(item._id)}}
    >
        Mark as Paid
    </button>

    <PDFDownloadLink document={<PDFComponent car={item.car} time={timeDiff.toFixed(0)} cost={Math.ceil(timeDiff / 30) * 20} date={item.time.substring(0,10)}/>} fileName="bill.pdf">
      {({ blob, url, loading, error }) =>
        loading ? 'Loading document...' : <><svg class="h-8 w-8 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2" />  <path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4" />  <rect x="7" y="13" width="10" height="8" rx="2" /></svg>
        Print</>
      }
    </PDFDownloadLink>
</div>



</>

              




             
    


        
      )

      })}
      </div>


       {/* { data.map(item=>{
                      
                      
                      console.log(item._id);


            return(


                <Link to={`/singlerecord/${item._id}`}>
                <li class="border-gray-400 flex flex-row mb-2">
                <div class="shadow border select-none cursor-pointer bg-white dark:bg-gray-800 rounded-md flex flex-1 items-center p-4">
                   
                    <div class="flex-1 pl-1 md:mr-16">
                        <div class="font-medium dark:text-white">
                            {item.name}
                        </div>
                        <div class="text-gray-600 dark:text-gray-200 text-sm">
                            {item.address}
                        </div>
                    </div>
                    <div class="flex md:space-x-8 space-x-6 w-14 h-10 justify-center items-center mr-4">
                            {item.phone}                     
                    </div>
                                   
                </div>
            </li>
                </Link>
                
         
            )

       })
        


       } */}

       
       
   




   
  </div>
</main>
</div>

    </div>



  </div>  
);


  
}


export default Unpaid