
import React,{useContext, useState,useEffect} from 'react'
import {Link, useNavigate,useParams} from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {UserContex} from '../App'
import Header from '../components/Header';
import Payment from './Payment';
import { name } from '@cloudinary/url-gen/actions/namedTransformation';
import { loadStripe } from'@stripe/stripe-js';
import {socket} from "../App"
import Footer from '../components/Footer';
// import { CardElement, Elements, useElements } from
import {
    EmailShareButton,
    FacebookShareButton,
    FacebookIcon,
    LineShareButton,
    LinkedinShareButton,
    LinkedinIcon,
    FacebookMessengerShareButton,
    FacebookMessengerIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon
    
  } from "react-share";
// '@stripe/react-stripe-js';

// const stripePromise = loadStripe('pk_test_51O7H4NFtdms7xyPvenr9xBB9Hor425E614fYOEv0vCgo9ygKGSVezMIfQWcg8Rhef7I2APPt1PMC4lfdz3TnHtLT00idMMp6pq');

const ParticipantVote = ()=>{
  const {state, dispatch }= useContext(UserContex)
  const history =useNavigate();
  const [email,setEmail] =  useState("");
  const [billing,setBilling] =  useState(false);
  const [freeEvent,setFreeEvent] =  useState(false);
  const [participant,setParticipant] =  useState("");
  const [name,setName] =  useState("");
  const [phone,setPhone] =  useState("");
  const [password,setPassword] =  useState("");
  const [pay,setPay] =  useState(false);
  const [payment,setPayment] =  useState(false);
  const [validation,setValidation] =  useState(false);
  const [falseTran,setFalseTran] =  useState(false);
  const [startVote,setStartVote] =  useState(false);
  const [seats,setSeats] =  useState(0);
  const [size,setSize] =  useState(0);
  const [amount,setAmount] =  useState();
  const [vote,setVote] =  useState(0);
  const [votefor,setVoteFor] =  useState();
  const navigate= useNavigate()

  const [data,setData]= useState([])
  const [ARresponse,setARresponse]= useState()
  const [bfs_bfsTxnId,setbfs_bfsTxnId] = useState("")
  const [bfs_remitterBankId,setbfs_remitterBankId] = useState("")
  const [bfs_remitterAccNo,setbfs_remitterAccNo] = useState("")
  const [bfs_remitterOtp,setbfs_remitterOtp] = useState("")
  const [details,setDetails]= useState()
  const [column, setColumn] = useState("")
  const [booked,setBooked] = useState([])
  const [taken,setTaken] = useState([])
const {postid} = useParams()



  
let now = new Date();
        let year = now.getFullYear();
        let month = (now.getMonth() + 1).toString().padStart(2, '0');
        let day = now.getDate().toString().padStart(2, '0');
        let hours = now.getHours().toString().padStart(2, '0');
        let minutes = now.getMinutes().toString().padStart(2, '0');
        let seconds = now.getSeconds().toString().padStart(2, '0');
 
 useEffect(() => {
    // console.log("Effect is running"); // Add this line for debugging

    fetch(`/getsingleparticipant/${postid}`, {
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(res => res.json())
        .then(result => {
            // console.log("EVENT",result.event.booked.length)
            setVoteFor(result.participant);
            // setSize(result.event.booked.length)
            // console.log("OCCP",result.event.occupied)
            // setSize(new Set(result.event.occupied).size);

        });






}, []);

 

       

   
    //    const addToCart = (item)=>{

    //     console.log("ITEM",item)
    //     console.log("CART",cart)


    //     if(cart.includes(item)){

    //         // console.log("REMO",cart)

    //         let index = cart.indexOf(item)
    //          setCart([
    //              ...cart.slice(0,  index),
    //              ...cart.slice(index + 1, cart.length)
    //            ]);
    //            setSeats(seats-1)
 
 
 
    //      }
    //      else{
 
    //          setCart(olditem=>[...olditem, item])
             
    //          setSeats(seats+1)
    //         //  console.log("ADD",cart)

    //      }


    //    }

    const addVote = ()=>{


        fetch('/arrequest',{

            method:"post",
            headers:{
            //   Authorization: "Bearer " +localStorage.getItem("jwt"),
      
              "Content-Type":"application/json"
      
            },
            body:JSON.stringify({
                bfs_txnAmount:amount,bfs_benfTxnTime:year+month+day+hours+minutes+seconds
            })
      
          }).then(res=> res.json())
            .then(r=>{
            
            //   setData(results.events)
            if(r.data.bfs_responseCode=="00"){         
                   setPayment(true)
                   setStartVote(false)

                setbfs_bfsTxnId(r.data.bfs_bfsTxnId)
                setARresponse(r.data)
            }
           
            }).catch(err=>{
                console.log(err)
            })
      
         
    // fetch('/addVote',{

    //     method:"put",
    //     headers:{
    //       Authorization: "Bearer " +localStorage.getItem("jwt"),
  
    //       "Content-Type":"application/json"
  
    //     },
    //     body:JSON.stringify({
    //         votes,participant,show:postid
    //     })
  
    //   }).then(res=> res.json())
    //     .then(results=>{
          
    //       setData(results.events)
    //     })
  

       }
       const sendAERequest = ()=>{


        fetch('/aerequest',{

            method:"post",
            headers:{
            //   Authorization: "Bearer " +localStorage.getItem("jwt"),
      
              "Content-Type":"application/json"
      
            },
            body:JSON.stringify({
                bfs_bfsTxnId,bfs_remitterAccNo,bfs_remitterBankId,
            })
      
          }).then(res=> res.json())
            .then(r=>{
            
            //   setData(results.events)
            setPayment(false)

            setPay(true)
            // if(r.data.bfs_responseCode=="00"){
            //     setbfs_bfsTxnId(r.data.bfs_bfsTxnId)
            //     setARresponse(r.data)
            // }
           
            }).catch(err=>{
                console.log(err)
            })
      
         
    // fetch('/addVote',{

    //     method:"put",
    //     headers:{
    //       Authorization: "Bearer " +localStorage.getItem("jwt"),
  
    //       "Content-Type":"application/json"
  
    //     },
    //     body:JSON.stringify({
    //         votes,participant,show:postid
    //     })
  
    //   }).then(res=> res.json())
    //     .then(results=>{
          
    //       setData(results.events)
    //     })
  

       }
       const sendDRRequest = ()=>{


        fetch('/drrequest',{

            method:"post",
            headers:{
            //   Authorization: "Bearer " +localStorage.getItem("jwt"),
      
              "Content-Type":"application/json"
      
            },
            body:JSON.stringify({
                bfs_bfsTxnId,bfs_remitterOtp, votes:amount/data.price,participant,show:postid
            })
      
          }).then(res=> res.json())
            .then(r=>{
            
            //   setData(results.events)
            setPay(false)
            if(r.added){
                setValidation(true)
                setDetails(r.added)
            }
            else{

                setFalseTran(true)
            }
            // if(r.data.bfs_responseCode=="00"){
            //     setbfs_bfsTxnId(r.data.bfs_bfsTxnId)
            //     setARresponse(r.data)
            // }
           
            }).catch(err=>{
                console.log(err)
            })
      
         
    // fetch('/addVote',{

    //     method:"put",
    //     headers:{
    //       Authorization: "Bearer " +localStorage.getItem("jwt"),
  
    //       "Content-Type":"application/json"
  
    //     },
    //     body:JSON.stringify({
    //         votes,participant,show:postid
    //     })
  
    //   }).then(res=> res.json())
    //     .then(results=>{
          
    //       setData(results.events)
    //     })
  

       }




   
     


 



   
    return(

<>
<main class="bg-white h-screen  relative overflow-x-hidden">
    <div class="flex items-start justify-between">
       
        <div class="flex flex-col w-full ">

            

            <Header></Header>


            


<div class="relative md:block lg:block hidden">

<img src="bann.jpeg" class="absolute h-full w-full object-cover"/>
{/* <div class="absolute inset-0 bg-purple-500 opacity-50"></div> */}
<div class="absolute inset-0 overflow-hidden">
    <div class="absolute inset-0 bg-gradient-to-r from-black to-transparent"></div>
  </div>


 {/* <div class="container mx-auto px-6 md:px-12 relative z-10 flex items-center py-8 xl:py-12">
        <div class="lg:w-3/5 xl:w-2/5 flex flex-col items-start relative z-10">
            <span class="font-bold uppercase text-white md:animate-bounce repeat-1 duration-1000">
                ____________________
            </span>
            <h1 class=" font-bold text-4xl md:text-6xl text-white leading-tight mt-2 ">
            Elevate Your Events, Simplify Your Tickets
                <br/>
                <br/>
                <br/>
                
          
            </h1>
           
        </div>
    </div>
    */}
   
    </div>


    



        </div>
     

    </div>



  

   
<div class="flex flex-col">

<img src={votefor?votefor.show.pic:"https://img.freepik.com/free-vector/abstract-purple-wavy-modern-light-background_361591-1420.jpg?size=1450&ext=jpg&ga=GA1.1.1697546048.1698228343&semt=ais" }class="absolute h-1/2 w-full object-cover overflow-hidden"/>
{/* 
<video autoPlay loop muted playbackRate={2.0}  class=" absolute h-1/2 w-full object-fill md:h-1/2  md:w-full md:object-cover">
<source src="https://v4.cdnpk.net/videvo_files/video/free/video0467/large_watermarked/_import_614f00b3e16dc1.55454574_FPpreview.mp4" type="video/mp4" />
</video> */}
<div>
<img src={votefor?votefor.pic:"https://img.freepik.com/free-psd/sound-party-cover-template_23-2148131763.jpg?size=626&ext=jpg&ga=GA1.1.1697546048.1698228343&semt=sph" } class=" relative h-60 w-11/12 ml-4 md:h-96 md:w-1/2 object-center md:ml-80  md:mt-24 mt-32 rounded-lg "/> 

</div>





 {/* <div class=" relative flex  mx-auto">

         <img src="https://img.freepik.com/free-photo/excited-audience-watching-confetti-fireworks-having-fun-music-festival-night-copy-space_637285-559.jpg?w=996&t=st=1698228712~exp=1698229312~hmac=e6d992326774f5a0d719062875e0439936f46b09ea558d4d295af285dc06e732" class=" w-full h-92"/>
 </div>
  */}







<div class="w-full md:p-12  bg-white overflow-auto mt-4 mb-4 relative">

 


<div className="flex justify-center items-center  ">

                    

<div class="p-4 mx-auto text-center bg-white border-indigo-500 rounded shadow h-36 w-96 ">
    <div class="flex items-center justify-between h-full">
        <div class="flex flex-col justify-between h-full">
            <div>
                <span class="p-2 mr-2 text-black text-4xl ">
                    {votefor?votefor.name:""}
                </span>
                <span class="">
                    
                </span>
            </div>
           
<div class=" relative ">
    <input type="text" id="rounded-email" class=" rounded-lg border-purple-300 flex-1 appearance-none border  w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base   focus:border-transparent" placeholder="Amount"
   value={amount}
   onChange={(e)=>{setAmount(e.target.value)}}
    />
    </div>

        </div>
        <div class="flex flex-col justify-between h-full">
            <p class="text-4xl font-bold text-gray-900">
                
                {amount>0?amount/votefor.show.price:""}
                <span class="text-sm text-gray-300">
                    Votes
                </span>
            </p>
            <button type="button" class="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
            
            onClick={() => addVote()}

            >
                Vote
            </button>
        </div>
    </div>
</div>

</div>

  {/* {column} */}

<div class="flex items-end justify-between m-4 header">
 <div class="title">
     <p class="mb-4 text-4xl font-bold text-gray-800">
         {votefor?votefor.show.name:""}
     </p>
     <p class="text-2xl font-light text-gray-400">
     {votefor?votefor.show.description:""}

     </p>
 </div>


 </div>
 
</div>





{
payment? 
(
<>

<div className="fixed inset-0 z-50 flex justify-center items-center overflow-auto">
  <div className="w-full max-w-md">
    <div className="border-0 rounded-lg shadow-lg bg-white">
      {/* Header */}
      <div className="flex items-start justify-end p-2">
        <button
          className="p-1 rounded-full bg-white text-white text-3"
          onClick={() => setPayment(false)}
        >
          <svg
            className="h-8 w-8 text-red-500"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="12" cy="12" r="10" />
            <line x1="15" y1="9" x2="9" y2="15" />
            <line x1="9" y1="9" x2="15" y2="15" />
          </svg>
        </button>
      </div>
      {/* Body */}
      <div className="overflow-auto max-h-full">
        <div className="flex md:flex-row flex-col bg-white rounded-lg shadow ">
          <div className="flex-auto p-6">
            
            <div className="py-1">
              <span className="px-1 text-sm text-gray-600">Bank</span>
              <select
                className="block px-3 py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm w-52 focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                name="animals"
                value={bfs_remitterBankId}
                onChange={(e) => {
                  setbfs_remitterBankId(e.target.value);
                }}
              >
                <option>Select Your Bank</option>
                <option value="1010">Bank of Bhutan</option>
                <option value="1020">Bhutan National Bank</option>
                <option value="1030">Druk PNBL</option>
                <option value="1040">T Bank Ltd</option>
                <option value="1050">BDBL</option>
                <option value="1060">DK Bank Ltd</option>
              </select>
            </div>
            <div className="py-1">
              <span className="px-1 text-sm text-gray-600">Account No</span>
              <input
                type="text"
                className="space-y-6 text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-400 focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300 focus:outline-none"
                placeholder="Account No"
                value={bfs_remitterAccNo}
                onChange={(e) => setbfs_remitterAccNo(e.target.value)}
              />
            </div>
            <div className="flex mb-4 text-sm font-medium">
              <button
                type="button"
                className="py-2 px-4 bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
                onClick={sendAERequest}
              >
                Proceed
              </button>
            </div>
            <div className="bg-white  p-4">
              <div className="flex-row gap-4 flex justify-center items-center">
                <div className="flex flex-col">
                  <span className="text-lg font-medium text-gray-600">
                    {votefor.name}
                  </span>
                  <span className="text-xs text-gray-400">
                    code: {votefor.code}
                  </span>
                  <span className="text-xs text-gray-400">
                    Dzongkhag: {votefor.dzongkhag}
                  </span>
                  <span className="text-xs text-gray-400">
                    Votes: {amount / data.price}
                  </span>
                </div>
                <div className="flex-shrink-0">
                  <a href="#" className="relative block">
                    <img
                      alt="profil"
                      src={votefor.pic ? votefor.pic : ""}
                      className="mx-auto object-cover rounded-full h-16 w-16"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



{/* <div
className="justify-center md:p-44 items-center flex   fixed inset-0 z-50 outline-none focus:outline-none overflow-auto"
>

 <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
   <div className="flex rounded-full items-start  ">
     
     <button
       className="p-1 ml-auto rounded-full bg-white text-white text-3 "
       onClick={() => setPayment(false)}
     >
<svg class="h-8 w-8 text-red-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <line x1="15" y1="9" x2="9" y2="15" />  <line x1="9" y1="9" x2="15" y2="15" /></svg>

</button>
   </div>
  
   


   <div className="text-gray-500 rounded-3xl shadow-xl w-full mb-5" >





<div className="lg:flex w-full">
 
 <div className="w-full md:h-96    px-5  relative overflow-auto">




<div class="flex flex-col w-full max-w-md py-1 bg-white rounded-lg shadow  sm:px-6 md:px-8 lg:px-6">
 
  <div class="mt-1">
          <div class="flex flex-col mb-1">





<div className="py-1">


<span className="px-1 text-sm text-gray-600"> Bank</span>

<select class="block px-3 py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm w-52 focus:outline-none focus:ring-primary-500 focus:border-primary-500" name="animals"

value={bfs_remitterBankId}
onChange={(e)=>{setbfs_remitterBankId(e.target.value)}}
>
    <option >
        Select Your Bank
    </option>
    <option value="1010">
        Bank of Bhutan
    </option>
    <option value="1020">
        Bhutan National Bank
    </option>
    <option value="1030">
        Druk PNBL
    </option>
    <option value="1040">
        T Bank Ltd
    </option>
    <option value="1050">
        BDBL 
    </option>
    <option value="1060">
        DK Bank Ltd
    </option>
</select>

</div>

<div className="py-1">
<span className="px-1 text-sm text-gray-600">Account No</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Account No"
value={bfs_remitterAccNo}
onChange={(e)=>setbfs_remitterAccNo(e.target.value)}
>

</input>
</div>



<button type="button" class="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
onClick={sendAERequest}
>
    Valider
</button>






             
              </div>


   

                 
                 
          </div>
         
      </div>
     



</div>

<div className=" md:block md:w-1/2 w-full  bg-white  ">


<div class="m-auto overflow-auto rounded-lg shadow-lg cursor-pointer h-90 w-11/12">
         
         <img alt="blog photo" src={votefor.pic?votefor.pic:"https://images.unsplash.com/photo-1470229722913-7c0e2dbbafd3?auto=format&fit=crop&q=80&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&w=1470"} class="object-cover w-full max-h-40"/>
             <div class="w-full p-4 bg-white ">
                 <p class="font-medium text-indigo-500 text-md">
                     {votefor.name}
                 </p>
                 <div class="flex items-center">
     <svg class="h-6 w-6 text-indigo-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
</svg>
     
      <p class="text-md text-black  ml-2">
     
     {votefor.dzongkhag}
 </p>
</div>
   
                 <div class="flex items-center">
                 <svg class="h-6 w-6 text-indigo-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="5" cy="5" r="1" />  <circle cx="12" cy="5" r="1" />  <circle cx="19" cy="5" r="1" />  <circle cx="5" cy="12" r="1" />  <circle cx="12" cy="12" r="1" />  <circle cx="19" cy="12" r="1" />  <circle cx="5" cy="19" r="1" />  <circle cx="12" cy="19" r="1" />  <circle cx="19" cy="19" r="1" /></svg>
     
      <p class="text-md text-black  ml-2">
     
     {votefor.code} Seats
 </p>
</div>
   
   
                 <div class="flex items-center">
                 <svg class="h-6 w-6 text-indigo-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>
     
      <p class="text-md text-black ml-2">
     
      {amount/data.price} Votes
 </p>
</div>
                


                 
                
             </div>
     </div>





</div>
   </div>


 </div>



 

</div>


</div> */}
{/* <div className="opacity-25 fixed inset-0 z-40 bg-black"></div> */}
</>
)


:null

}
{
pay? 
(
<>

<div className="fixed inset-0 z-50 flex justify-center items-center overflow-auto">
  <div className="w-full max-w-md">
    <div className="border-0 rounded-lg shadow-lg bg-white">
      {/* Header */}
      <div className="flex items-start justify-end p-2">
        <button
          className="p-1 rounded-full bg-white text-white text-3"
          onClick={() => setPay(false)}
          >
          <svg
            className="h-8 w-8 text-red-500"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="12" cy="12" r="10" />
            <line x1="15" y1="9" x2="9" y2="15" />
            <line x1="9" y1="9" x2="15" y2="15" />
          </svg>
        </button>
      </div>
      {/* Body */}
      <div className="overflow-auto max-h-full">
        <div className="flex md:flex-row flex-col bg-white rounded-lg shadow ">
          <div className="flex-auto p-6">
            
            <div className="py-1">
              <span className="px-1 text-sm text-gray-600">OTP</span>
              <input
                type="text"
                className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
                          bg-white border-2 border-gray-300 placeholder-gray-400  
                          focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  
                          focus:outline-none"
                placeholder="Enter OTP"
                value={bfs_remitterOtp}
                onChange={(e)=>setbfs_remitterOtp(e.target.value)}
              />
            </div>
            <div className="flex mb-4 text-sm font-medium">
              <button
                type="button"
                className="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 
                          focus:ring-indigo-500 focus:ring-offset-indigo-200 
                          text-white w-full transition ease-in duration-200 
                          text-center text-base font-semibold shadow-md 
                          focus:outline-none focus:ring-2 focus:ring-offset-2  
                          rounded-lg "
                onClick={()=>{sendDRRequest()}}
              >
                Pay
              </button>
            </div>

            <div className="bg-white  p-4">
              <div className="flex-row gap-4 flex justify-center items-center">
                <div className="flex flex-col">
                  <span className="text-lg font-medium text-gray-600">
                    {votefor.name}
                  </span>
                  <span className="text-xs text-gray-400">
                    code: {votefor.code}
                  </span>
                  <span className="text-xs text-gray-400">
                    Dzongkhag: {votefor.dzongkhag}
                  </span>
                  <span className="text-xs text-gray-400">
                    Votes: {amount/data.price}
                  </span>
                </div>
                <div className="flex-shrink-0">
                  <a href="#" className="relative block">
                    <img
                      alt="profil"
                      src={votefor.pic ? votefor.pic : ""}
                      className="mx-auto object-cover rounded-full h-16 w-16"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


{/* <div
className="justify-center md:p-44 items-center flex   fixed inset-0 z-50 outline-none focus:outline-none overflow-auto"
>

 <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
   <div className="flex rounded-full items-start  ">
    
   </div>
  
   


   <div className="text-gray-500 rounded-3xl shadow-xl w-full mb-5" >





<div className="lg:flex w-full">
 
 <div className="w-full md:h-96    px-5  relative overflow-auto">




<div class="flex flex-col w-full max-w-md py-1 bg-white rounded-lg shadow  sm:px-6 md:px-8 lg:px-6">
 
  <div class="mt-1">
          <div class="flex flex-col mb-1">







<div className="py-1">
<span className="px-1 text-sm text-gray-600">OTP</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Enter OTP"
value={bfs_remitterOtp}
onChange={(e)=>setbfs_remitterOtp(e.target.value)}
>

</input>
</div>




<button type="button" class="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
onClick={()=>{sendDRRequest()}}
>
    Proceed
</button>


             
              </div>

   

                 
                 
          </div>
         
      </div>
     



</div>


   </div>


 </div>



 

</div>


</div> */}
{/* <div className="opacity-25 fixed inset-0 z-40 bg-black"></div> */}
</>
)


:null

}
{
validation? 
(
<>
<div
className="justify-center md:p-44 items-center flex   fixed inset-0 z-50 outline-none focus:outline-none overflow-auto"
>

 {/*content*/}
   {/*header*/}
  
   {/*body*/}
  
   







 




<div class="flex flex-col w-full max-w-md py-1 bg-transparent rounded-lg shadow  sm:px-6 md:px-8 lg:px-6">
 
  <div class="mt-1">
          <div class="flex flex-col mb-1">





          <div class="relative w-64 p-4 m-auto bg-green-100 shadow-lg rounded-2xl ">
    <div class="w-full h-full text-center">
        <div class="flex flex-col justify-between h-full">
        <img src="/logo.png" alt="adidas" class="w-32 p-4 m-auto h-36"/>

            <p class="absolute text-sm italic text-gray-800 top-2 right-2">
                Voting Successful
            </p>
            <p class="mt-4 text-lg text-gray-900">
                Voting Successful
            </p>
            <p class="px-6 py-2 text-xs font-thin text-gray-700 ">
                {amount/data.price} Votes
            </p>
            <p class="px-6 py-2 text-xs font-thin text-gray-700 ">
                To {details.name} 
            </p>
            <p class="px-6 py-2 text-xs font-thin text-gray-700 ">
                Code {details.code} 
            </p>

<button type="button" class="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
onClick={()=>{setValidation(false)}}
>
    Done
</button>

        </div>
    </div>
</div>






{/* <button type="button" class="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
onClick={()=>{sendDRRequest()}}
>
    Proceed
</button> */}


             
              </div>

   

                 
                 
          </div>
         
      </div>
     












 



</div>
{/* <div className="opacity-25 fixed inset-0 z-40 bg-black"></div> */}
</>
)


:null

}
{
falseTran? 
(
<>
<div className="fixed inset-0 z-50 flex justify-center items-center">
  <div className=" w-full md:h-auto md:max-w-md px-5 relative overflow-auto">
    {/* Your content here */}
    <div className="flex flex-col w-full py-1 bg-transparent rounded-lg shadow sm:px-6 md:px-8 lg:px-6">
      <div className="mt-1">
        <div className="flex flex-col mb-1">
          <div className="relative w-64 p-4 m-auto  shadow-lg rounded-2xl bg-red-100">
            <div className="w-full h-full text-center">
              <div className="flex flex-col justify-between h-full">
                <img src="/logo.png" alt="adidas" className="w-32 p-4 m-auto h-36" />
                <p className="absolute text-sm italic text-gray-800 top-2 right-2">
                  Voting Failed
                </p>
                <p className="mt-4 text-lg text-gray-900">
                  Error occurred while voting
                </p>
                <p className="px-6 py-2 text-xs font-thin text-gray-700 ">
                  Please try again
                </p>
              </div>

<button type="button" class="py-2 px-4  bg-purple-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
onClick={()=>{setFalseTran(false)}}
>
    Retry
</button>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

{/* <div className="opacity-25 fixed inset-0 z-40 bg-black"></div> */}
</>
)


:null

}


</div>
        














<Footer page="events"></Footer>
</main>







</>

  


    );
}

export default ParticipantVote;