
import React,{useContext, useState,useEffect,useRef} from 'react'
import {Link, useNavigate,useParams} from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import YouTube from 'react-youtube';

import {UserContex} from '../App'
import Header from '../components/Header';
import Payment from './Payment';
import { name } from '@cloudinary/url-gen/actions/namedTransformation';
import { loadStripe } from'@stripe/stripe-js';
import {socket} from "../App"
import Footer from '../components/Footer';
// import { CardElement, Elements, useElements } from
import {
    EmailShareButton,
    FacebookShareButton,
    FacebookIcon,
    LineShareButton,
    LinkedinShareButton,
    LinkedinIcon,
    FacebookMessengerShareButton,
    FacebookMessengerIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon
    
  } from "react-share";
// '@stripe/react-stripe-js';

// const stripePromise = loadStripe('pk_test_51O7H4NFtdms7xyPvenr9xBB9Hor425E614fYOEv0vCgo9ygKGSVezMIfQWcg8Rhef7I2APPt1PMC4lfdz3TnHtLT00idMMp6pq');

const SingleShow = ()=>{
  const {state, dispatch }= useContext(UserContex)
  const history =useNavigate();
  const [participant,setParticipant] =  useState("");
  const [name,setName] =  useState("");
  const [phone,setPhone] =  useState("");
  const [password,setPassword] =  useState("");
  const [pay,setPay] =  useState(false);
  const [payment,setPayment] =  useState(false);
  const [validation,setValidation] =  useState(false);
  const [falseTran,setFalseTran] =  useState(false);
  const [startVote,setStartVote] =  useState(false);
  const [seats,setSeats] =  useState(0);
  const [size,setSize] =  useState(0);
  const [amount,setAmount] =  useState();
  const [vote,setVote] =  useState(0);
  const [votefor,setVoteFor] =  useState();
  const navigate= useNavigate()

  const [data,setData]= useState([])
  const [ARresponse,setARresponse]= useState()
  const [bfs_bfsTxnId,setbfs_bfsTxnId] = useState("")
  const [bfs_remitterBankId,setbfs_remitterBankId] = useState("")
  const [bfs_remitterAccNo,setbfs_remitterAccNo] = useState("")
  const [bfs_remitterOtp,setbfs_remitterOtp] = useState("")
  const [participants,setParticipants]= useState([])
  const [cart,setCart]= useState([])
  const [details,setDetails]= useState()
  const [column, setColumn] = useState("")
  const [booked,setBooked] = useState([])
  const [taken,setTaken] = useState([])
  const [amterr,setShowamterror] = useState(false)
const {postid} = useParams()

let now = new Date();
        let year = now.getFullYear();
        let month = (now.getMonth() + 1).toString().padStart(2, '0');
        let day = now.getDate().toString().padStart(2, '0');
        let hours = now.getHours().toString().padStart(2, '0');
        let minutes = now.getMinutes().toString().padStart(2, '0');
        let seconds = now.getSeconds().toString().padStart(2, '0');

console.log(bfs_remitterBankId)
 
 useEffect(() => {

    fetch(`/getsingleshow/${postid}`, {
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(res => res.json())
        .then(result => {
            // console.log("EVENT",result.event.booked.length)
            setData(result.event);
            setAmount(result.event.price)
            // setSize(result.event.booked.length)
            // console.log("OCCP",result.event.occupied)
            // setSize(new Set(result.event.occupied).size);

            setColumn(String("grid-cols-" + result.event.col));
        });


    fetch(`/getmyparticipants/${postid}`, {
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(res => res.json())
        .then(result => {
            setParticipants(result.participants)
            // setColumn(String("grid-cols-" + result.event.col));
        });

        


}, []);

 

       

    //    const addToCart = (item)=>{

    //     console.log("ITEM",item)
    //     console.log("CART",cart)


    //     if(cart.includes(item)){

    //         // console.log("REMO",cart)

    //         let index = cart.indexOf(item)
    //          setCart([
    //              ...cart.slice(0,  index),
    //              ...cart.slice(index + 1, cart.length)
    //            ]);
    //            setSeats(seats-1)
 
 
 
    //      }
    //      else{
 
    //          setCart(olditem=>[...olditem, item])
             
    //          setSeats(seats+1)
    //         //  console.log("ADD",cart)

    //      }


    //    }

       const addVote = ()=>{

if(amount >=data.price){


        fetch('/arrequest',{

            method:"post",
            headers:{
            //   Authorization: "Bearer " +localStorage.getItem("jwt"),
      
              "Content-Type":"application/json"
      
            },
            body:JSON.stringify({
                bfs_txnAmount:amount,bfs_benfTxnTime:year+month+day+hours+minutes+seconds
            })
      
          }).then(res=> res.json())
            .then(r=>{
            
            //   setData(results.events)
            if(r.data.bfs_responseCode=="00"){         
                   setPayment(true)
                   setStartVote(false)

                setbfs_bfsTxnId(r.data.bfs_bfsTxnId)
                setARresponse(r.data)
            }
           
            }).catch(err=>{
                console.log(err)
            })
      
         
    // fetch('/addVote',{

    //     method:"put",
    //     headers:{
    //       Authorization: "Bearer " +localStorage.getItem("jwt"),
  
    //       "Content-Type":"application/json"
  
    //     },
    //     body:JSON.stringify({
    //         votes,participant,show:postid
    //     })
  
    //   }).then(res=> res.json())
    //     .then(results=>{
          
    //       setData(results.events)
    //     })
  }else {setShowamterror(true); toast.error(`Minimum amount should be ${data.price}`)}

       }
       const sendAERequest = ()=>{


        fetch('/aerequest',{

            method:"post",
            headers:{
            //   Authorization: "Bearer " +localStorage.getItem("jwt"),
      
              "Content-Type":"application/json"
      
            },
            body:JSON.stringify({
                bfs_bfsTxnId,bfs_remitterAccNo,bfs_remitterBankId,
            })
      
          }).then(res=> res.json())
            .then(r=>{
            
            //   setData(results.events)
            setPayment(false)

            setPay(true)
            // if(r.data.bfs_responseCode=="00"){
            //     setbfs_bfsTxnId(r.data.bfs_bfsTxnId)
            //     setARresponse(r.data)
            // }
           
            }).catch(err=>{
                console.log(err)
            })
      
         
    // fetch('/addVote',{

    //     method:"put",
    //     headers:{
    //       Authorization: "Bearer " +localStorage.getItem("jwt"),
  
    //       "Content-Type":"application/json"
  
    //     },
    //     body:JSON.stringify({
    //         votes,participant,show:postid
    //     })
  
    //   }).then(res=> res.json())
    //     .then(results=>{
          
    //       setData(results.events)
    //     })
  

       }
       const sendDRRequest = ()=>{


        fetch('/drrequest',{

            method:"post",
            headers:{
            //   Authorization: "Bearer " +localStorage.getItem("jwt"),
      
              "Content-Type":"application/json"
      
            },
            body:JSON.stringify({
                bfs_bfsTxnId,bfs_remitterOtp, votes:amount/data.price,participant,show:postid,week:data.week
            })
      
          }).then(res=> res.json())
            .then(r=>{
            
            //   setData(results.events)
            setPay(false)
            if(r.added){
                setValidation(true)
                setDetails(r.added)
            }
            else{

                setFalseTran(true)
            }
            // if(r.data.bfs_responseCode=="00"){
            //     setbfs_bfsTxnId(r.data.bfs_bfsTxnId)
            //     setARresponse(r.data)
            // }
           
            }).catch(err=>{
                console.log(err)
            })
      
         
    // fetch('/addVote',{

    //     method:"put",
    //     headers:{
    //       Authorization: "Bearer " +localStorage.getItem("jwt"),
  
    //       "Content-Type":"application/json"
  
    //     },
    //     body:JSON.stringify({
    //         votes,participant,show:postid
    //     })
  
    //   }).then(res=> res.json())
    //     .then(results=>{
          
    //       setData(results.events)
    //     })
  

       }




     
       const copyRef = useRef(null);

       const handleCopyText = (textToCopy) => {
         const textArea = document.createElement('textarea');
         textArea.value = textToCopy;
     
         document.body.appendChild(textArea);
         textArea.select();
     
         try {
           const success = document.execCommand('copy');
           if (success) {
             // To give visual feedback on the button, focus and blur it programmatically
             copyRef.current.focus();
             copyRef.current.blur();
             toast.success("Copied the Link")
           }
         } catch (err) {
           console.error('Unable to copy', err);
         }
     
         document.body.removeChild(textArea);
       };
     


       const onPlayerReady = (event) => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
      }
    
      const opts = {
        height: '390',
        width: '640',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 1,
        },
      };
   

      // const getEmbedUrl = (url) => {
      //   if (url.includes("youtube.com")) {
      //     // YouTube
      //     return `${url.replace("watch?v=", "embed/")}?autoplay=1`;
      //   } else if (url.includes("facebook.com")) {
      //     // Facebook
      //     return `https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(url)}&show_text=0&autoplay=1`;
      //   } else if (url.includes("instagram.com")) {
      //     // Instagram (Post)
      //     return `${url}/embed`;
      //   } else if (url.includes("tiktok.com")) {
      //     // TikTok (Video)
      //     return `https://www.tiktok.com/embed/v2/${encodeURIComponent(url.split("/")[5])}?autoplay=1`;
      //   } else {
      //     // Default (Direct URL)
      //     return url;
      //   }
      // }


    // const getEmbedUrl = (url) => {
    //   if (url.includes("youtube.com")) {
    //     // YouTube
    //     return url.replace("watch?v=", "embed/") + "?autoplay=1";
    //   } else if (url.includes("facebook.com")||url.includes("fb")) {
    //     if (url.includes("/live/")) {
    //       // Facebook Live
    //       return `https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(url)}&show_text=0&autoplay=1`;
    //     } else {
    //       // Facebook Video
    //       return `https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(url)}&show_text=0&autoplay=1`;
    //     }
    //   } else if (url.includes("instagram.com")) {
    //     if (url.includes("/live/")) {
    //       // Instagram Live
    //       return `${url}/embed/live`;
    //     } else {
    //       // Instagram Post
    //       return `${url}/embed`;
    //     }
    //   } else if (url.includes("tiktok.com")) {
    //     if (url.includes("/live/")) {
    //       // TikTok Live
    //       const liveId = url.split("/")[5];
    //       return `https://www.tiktok.com/embed/live/${liveId}?autoplay=1`;
    //     } else {
    //       // TikTok Video
    //       const videoId = url.split("/")[5];
    //       return `https://www.tiktok.com/embed/v2/${videoId}?autoplay=1`;
    //     }
    //   } else {
    //     // Default (Direct URL)
    //     return url;
    //   }
    // };


    // const getEmbedUrl = (url) => {
    //   if (url.includes("youtube.com") || url.includes("youtu.be")) {
    //     // YouTube
    //     if (url.includes("/live")) {
    //       // YouTube Live
    //       return url.replace("/live/", "/embed/") + "?autoplay=1";
    //     } else {
    //       // Regular YouTube Video
    //       return url.replace("watch?v=", "embed/") + "?autoplay=1";
    //     }
    //   } else if (url.includes("facebook.com") || url.includes("fb")) {
    //     if (url.includes("/live/")) {
    //       // Facebook Live
    //       return `https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(url)}&show_text=0&autoplay=1`;
    //     } else {
    //       // Facebook Video
    //       return `https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(url)}&show_text=0&autoplay=1`;
    //     }
    //   } else if (url.includes("instagram.com")) {
    //     if (url.includes("/live/")) {
    //       // Instagram Live
    //       return `${url}/embed/live`;
    //     } else {
    //       // Instagram Post
    //       return `${url}/embed`;
    //     }
    //   } else if (url.includes("tiktok.com")) {
    //     if (url.includes("/live/")) {
    //       // TikTok Live
    //       const liveId = url.split("/")[5];
    //       return `https://www.tiktok.com/embed/live/${liveId}?autoplay=1`;
    //     } else {
    //       // TikTok Video
    //       const videoId = url.split("/")[5];
    //       return `https://www.tiktok.com/embed/v2/${videoId}?autoplay=1`;
    //     }
    //   } else {
    //     // Default (Direct URL)
    //     return url;
    //   }
    // };
    
    const getEmbedUrl = (url) => {
      if (url.includes("youtube.com") || url.includes("youtu.be")) {
        // YouTube
        let videoId;
        if (url.includes("/live/")) {
          // YouTube Live
          videoId = url.split("/live/")[1];
          return `https://www.youtube.com/embed/${videoId}?autoplay=1`;
        } else if (url.includes("watch?v=")) {
          // Regular YouTube Video
          videoId = url.split("watch?v=")[1].split("&")[0];
          return `https://www.youtube.com/embed/${videoId}?autoplay=1`;
        } else if (url.includes("youtu.be/")) {
          // Shortened YouTube URL
          videoId = url.split("youtu.be/")[1];
          return `https://www.youtube.com/embed/${videoId}?autoplay=1`;
        }
      } else if (url.includes("facebook.com") || url.includes("fb")) {
        if (url.includes("/live/")) {
          // Facebook Live
          return `https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(url)}&show_text=0&autoplay=1`;
        } else {
          // Facebook Video
          return `https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(url)}&show_text=0&autoplay=1`;
        }
      } else if (url.includes("instagram.com")) {
        if (url.includes("/live/")) {
          // Instagram Live
          return `${url}/embed/live`;
        } else {
          // Instagram Post
          return `${url}/embed`;
        }
      } else if (url.includes("tiktok.com")) {
        if (url.includes("/live/")) {
          // TikTok Live
          const liveId = url.split("/")[5];
          return `https://www.tiktok.com/embed/live/${liveId}?autoplay=1`;
        } else {
          // TikTok Video
          const videoId = url.split("/")[5];
          return `https://www.tiktok.com/embed/v2/${videoId}?autoplay=1`;
        }
      } else if (url.includes("zoom.us")) {
        // Zoom Meeting
        const meetingId = url.match(/\/j\/(\d+)/)[1];
        return `https://zoom.us/wc/join/${meetingId}`;
      } else {
        // Default (Direct URL)
        return url;
      }
    };
    
    return(

<>
<main class="bg-white h-screen  relative overflow-x-hidden">
    <div class="flex items-start justify-between">
       
        <div class="flex flex-col w-full ">

            

            <Header></Header>


            


<div class="relative md:block lg:block hidden">

<img src="bann.jpeg" class="absolute h-full w-full object-cover"/>
{/* <div class="absolute inset-0 bg-purple-500 opacity-50"></div> */}
<div class="absolute inset-0 overflow-hidden">
    <div class="absolute inset-0 bg-gradient-to-r from-black to-transparent"></div>
  </div>


 {/* <div class="container mx-auto px-6 md:px-12 relative z-10 flex items-center py-8 xl:py-12">
        <div class="lg:w-3/5 xl:w-2/5 flex flex-col items-start relative z-10">
            <span class="font-bold uppercase text-white md:animate-bounce repeat-1 duration-1000">
                ____________________
            </span>
            <h1 class=" font-bold text-4xl md:text-6xl text-white leading-tight mt-2 ">
            Elevate Your Events, Simplify Your Tickets
                <br/>
                <br/>
                <br/>
                
          
            </h1>
           
        </div>
    </div>
    */}
   
    </div>


    



        </div>
     

    </div>



  

   
<div class="flex flex-col">



<img src="https://img.freepik.com/free-vector/abstract-purple-wavy-modern-light-background_361591-1420.jpg?size=1450&ext=jpg&ga=GA1.1.1697546048.1698228343&semt=ais" class="absolute h-1/2 w-full object-cover overflow-hidden"/>

<div>

{data.live?

//   <div className="flex justify-center items-center lg:h-96 h-60 mt-4">
//   <div className="relative w-full  h-full lg:p-1 p-2 max-w-screen-lg mx-auto">
//   {/* <YouTube  videoId={data.live?data.live:""} opts={opts} onReady={onPlayerReady} /> */}
//   <iframe
//             className="w-full h-full"

//         src={getEmbedUrl(data.live)}
//         frameBorder="0"
//         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//         allowFullScreen
        
//         title="YouTube Video"
//       ></iframe>

// </div>
// </div>
<div className="flex justify-center items-center lg:h-96 h-60 mt-4 lg:mb-20 ">
  <div className="relative w-full h-full lg:p-1 p-2 max-w-screen-lg mx-auto">
    <div className="relative" style={{ paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%' }}>
    <iframe
  className="absolute top-0 left-0 w-full h-full"
  src={getEmbedUrl(data.live)}
  frameBorder="0"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  allowFullScreen
  title="YouTube Video"
></iframe>

    </div>
  </div>
</div>

:<img src={data.pic?data.pic:"https://img.freepik.com/free-psd/sound-party-cover-template_23-2148131763.jpg?size=626&ext=jpg&ga=GA1.1.1697546048.1698228343&semt=sph" } class=" relative h-60 w-11/12 ml-4 md:h-96 md:w-1/2 object-center md:ml-80  md:mt-24 mt-32 rounded-lg "/> 

}





</div>










<div class="w-full md:p-12  bg-white overflow-auto mt-4 mb-4 relative">




<div className="grid  grid-cols-1 md:grid-cols-4 sm:space-x-0 gap-4  place-items-center mt-2 ">

                    

{participants.filter(participant => participant.status !== 'Eleminated').map(item=>{

  return(



    <>
    
   

    <div class="w-11/12 bg-white shadow-lg rounded-2xl dark:bg-gray-800">
    <img alt="profil" src={data.pic?data.pic:"https://img.freepik.com/free-psd/sound-party-cover-template_23-2148131763.jpg?size=626&ext=jpg&ga=GA1.1.1697546048.1698228343&semt=sph" } class="w-full mb-4 rounded-t-lg h-28"/>
    <div class="flex flex-col items-center justify-center p-4 -mt-16">
        <a href="#" class="relative block">
            <img alt="profil" src={item.pic} class="mx-auto object-cover rounded-full h-16 w-16 "/>
        </a>
        <p class="mt-2 text-xl font-medium text-gray-800 dark:text-white">
            {item.name}
        </p>
        <p class="flex items-center text-sm text-gray-400">
           
           Code: {item.code}
        </p>
        <p class="text-sm text-gray-400 ">
            {item.dzongkhag}
        </p>
       
    {/* <div class=" relative ">
        <input type="number" id="&quot;form-subscribe-Subscribe" class=" rounded-lg border-purple-300 flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Amount"
       
       value = {amount}
        onChange={(e)=>setAmount(e.target.value)}
        />
        </div> */}
          {(data.status=="Ended")?
<></>
            :<>
             <button class="flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-purple-600 rounded-lg shadow-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200" type="submit"
        
        onClick={()=>{setVoteFor(item);setStartVote(true);setParticipant(item._id)}}
        >
            Vote
        </button>
   
           
           
           
<button type="button" class="py-2 px-4 flex justify-center items-center  bg-purple-500 hover:bg-purple-300  text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md    rounded-lg "
    ref={copyRef} onClick={()=>{handleCopyText(`https://www.bodhi5entertainment.com/participantvote/${item._id}`)}}

>
<svg class="h-8 w-8 text-white"  fill="none" viewBox="0 0 24 24" stroke="currentColor"

    >
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"/>
</svg>
    Share
</button>
            </>}
       
   



    </div>

    <ToastContainer></ToastContainer>
    <div class="flex flex-row items-center justify-center mt-2 "
        // onClick={() => {navigator.clipboard.writeText(this.state.textToCopy); toast.success("Copied the Link")}}

    >


   


{/* <FacebookShareButton url= {`https://www.bodhi5entertainment.com/participantvote/${item._id}`}><FacebookIcon class="h-10 w-10 mx-2 my-2 rounded-full"/></FacebookShareButton>
<TwitterShareButton url= {`https://www.bodhi5entertainment.com/participantvote/${item._id}`}><TwitterIcon class="h-10 w-10 mx-2 my-2 rounded-full"/></TwitterShareButton>
<LinkedinShareButton url= {`https://www.bodhi5entertainment.com/participantvote/${item._id}`}><LinkedinIcon class="h-10 w-10 mx-2 my-2 rounded-full"/></LinkedinShareButton>
<FacebookMessengerShareButton url= {`https://www.bodhi5entertainment.com/participantvote/${item._id}`}><FacebookMessengerIcon class="h-10 w-10 mx-2 my-2 rounded-full"/></FacebookMessengerShareButton>
<WhatsappShareButton url= {`https://www.bodhi5entertainment.com/participantvote/${item._id}`}><WhatsappIcon class="h-10 w-10 mx-2 my-2 rounded-full"/></WhatsappShareButton>
 */}


</div>
</div>



</>

        




       



  
)

})}
</div>

  {/* {column} */}

<div class="flex items-end justify-between m-4 header">
 <div class="title">
     <p class="mb-4 text-4xl font-bold text-gray-800">
         {data.name}
     </p>
     <p class="text-2xl font-light text-gray-400">
     {data.description}

     </p>
 </div>


 </div>
 
</div>






</div>
        


{
startVote? 
(
<>

<div className="fixed inset-0   z-50 flex justify-center items-center overflow-auto ">
  <div className="w-full max-w-md">
    <div className="border-0 rounded-lg shadow-lg bg-white">
      {/* Header */}
      <div className="flex items-start justify-end p-2">
        <button
          className="p-1 rounded-full bg-white text-white text-3"
          onClick={() => setStartVote(false)}
        >
          <svg
            className="h-8 w-8 text-red-500"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="12" cy="12" r="10" />
            <line x1="15" y1="9" x2="9" y2="15" />
            <line x1="9" y1="9" x2="15" y2="15" />
          </svg>
        </button>
      </div>
      {/* Body */}          
     {/* <div className='h-60 overflow-y-auto'> */}

     <p class="mb-0 text-center font-extrabold text-purple-500 ">
                      Step 1/3: Enter amount you want to vote
                  </p>
      <div className="flex flex-col items-center justify-center p-6">


      
        <div className="w-full">
          <input
            type="number"
            className="space-y-6 text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-400 focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300 focus:outline-none mb-4"
            placeholder="Amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          <h2 class={amterr?"text-red-500":"text-gray-700"}>
                      Minimum amount Should be Nu. {data.price}

          </h2>
          <button
            type="button"
            className="py-2 px-4 bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
            onClick={() => addVote()}
          >
            Vote Now
          </button>
        </div>
        <div className="bg-white dark:bg-gray-800 p-4 mb-4">
          <div className="flex-row gap-4 flex justify-center items-center">
            <div className="flex flex-col">
              <span className="text-lg font-medium text-gray-600 dark:text-white">
                {votefor.name}
              </span>
              <span className="text-xs text-gray-400">
                code: {votefor.code}
              </span>
              <span className="text-xs text-gray-400">
                Dzongkhag: {votefor.dzongkhag}
              </span>
              <span className="text-xs text-gray-400">
                Votes: {amount / data.price}
              </span>
            </div>
            <div className="flex-shrink-0">
              <a href="#" className="relative block">
                <img
                  alt="profil"
                  src={votefor.pic ? votefor.pic : ""}
                  className="mx-auto object-cover rounded-full h-16 w-16"
                />
              </a>
            </div>
          </div>
        </div>
        </div>


      {/* </div> */}
    </div>
  </div>
</div>

{/* <div
className="justify-center md:p-44 items-center flex   fixed inset-0 z-50 outline-none focus:outline-none overflow-auto"
>

 <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
   <div className="flex rounded-full items-start  ">
     
     <button
       className="p-1 ml-auto rounded-full bg-white text-white text-3 "
       onClick={() => setStartVote(false)}
     >
<svg class="h-8 w-8 text-red-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <line x1="15" y1="9" x2="9" y2="15" />  <line x1="9" y1="9" x2="15" y2="15" /></svg>

</button>
   </div>
  
   


   <div className="text-gray-500 rounded-3xl shadow-xl w-full mb-5" >





<div className="lg:flex w-full">
 
 <div className="w-full md:h-96    px-5  relative overflow-auto">




<div class="flex flex-col w-full max-w-md py-1 bg-white rounded-lg shadow  sm:px-6 md:px-8 lg:px-6">
  <div class="self-start mb-1 text-xl font-light text-gray-600 sm:text-2xl ">
      Add User Details 
  </div>
  <div class="mt-1">
          <div class="flex flex-col mb-1">



          <div class="grid max-w-xl grid-cols-2 gap-4 ">


<div className="py-1">


<span className="px-1 text-sm text-gray-600"> Amount</span>
<input  type="number" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Amount"
value={amount}
onChange={(e)=>setAmount(e.target.value)}
>

</input>
</div>



</div>



             
              </div>

   

             
                 
                      <button type="submit" class="py-2 px-4   bg-purple-600 hover:bg-purple-700 focus:ring-purple-500 focus:ring-offset-purple-200 text-white md:w-1/4 w-1/4 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                      onClick={()=>{addVote()}}
                      >
                          Vote
                      </button>
          </div>
         
      </div>
     



</div>

<div className=" md:block md:w-1/2 w-full  bg-white  ">


<div class="m-auto overflow-auto rounded-lg shadow-lg cursor-pointer h-90 w-11/12">
         
         <img alt="blog photo" src={votefor.pic?votefor.pic:"https://images.unsplash.com/photo-1470229722913-7c0e2dbbafd3?auto=format&fit=crop&q=80&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&w=1470"} class="object-cover w-full max-h-40"/>
             <div class="w-full p-4 bg-white ">
                 <p class="font-medium text-indigo-500 text-md">
                     {votefor.name}
                 </p>
                 <div class="flex items-center">
     <svg class="h-6 w-6 text-indigo-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
</svg>
     
      <p class="text-md text-black  ml-2">
     
     {votefor.dzongkhag}
 </p>
</div>
   
                 <div class="flex items-center">
                 <svg class="h-6 w-6 text-indigo-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="5" cy="5" r="1" />  <circle cx="12" cy="5" r="1" />  <circle cx="19" cy="5" r="1" />  <circle cx="5" cy="12" r="1" />  <circle cx="12" cy="12" r="1" />  <circle cx="19" cy="12" r="1" />  <circle cx="5" cy="19" r="1" />  <circle cx="12" cy="19" r="1" />  <circle cx="19" cy="19" r="1" /></svg>
     
      <p class="text-md text-black  ml-2">
     
     {votefor.code} 
 </p>
</div>
   
   
        
{   data.price && amount>0? <div  class="flex space-x-4 mt-2">
                                                   <button class="p-2 text-white bg-purple-300 rounded-lg">
                                                   {amount/data.price} Votes

                                                   </button>
                                                   
                                               </div>:<></>}
                 
                
             </div>
     </div>





</div>
   </div>


 </div>



 

</div>


</div> */}


</>
)


:null

}
{
payment? 
(
<>

<div className="fixed inset-0 z-50 flex justify-center items-center overflow-auto">
  <div className="w-full max-w-md">
    <div className="border-0 rounded-lg shadow-lg bg-white">
      {/* Header */}
      <div className="flex items-start justify-end p-2">
        <button
          className="p-1 rounded-full bg-white text-white text-3"
          onClick={() => setPayment(false)}
        >
          <svg
            className="h-8 w-8 text-red-500"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="12" cy="12" r="10" />
            <line x1="15" y1="9" x2="9" y2="15" />
            <line x1="9" y1="9" x2="15" y2="15" />
          </svg>
        </button>
      </div>
      {/* Body */}
      <p class="mb-0 text-center font-extrabold text-purple-500 ">
                      Step 2/3: Select Bank and Enter Acc no
                  </p>
      <div className="overflow-auto max-h-full">
        <div className="flex md:flex-row flex-col bg-white rounded-lg shadow dark:bg-gray-800">
          <div className="flex-auto p-6">
           
            <div className="py-1">
              <span className="px-1 text-sm text-gray-600">Bank</span>
              <select
                className="block px-3 py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm w-52 focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                name="animals"
                value={bfs_remitterBankId}
                onChange={(e) => {
                  setbfs_remitterBankId(e.target.value);
                }}
              >
                <option>Select Your Bank</option>
                <option value="1010">Bank of Bhutan</option>
                <option value="1020">Bhutan National Bank</option>
                <option value="1030">Druk PNBL</option>
                <option value="1040">T Bank Ltd</option>
                <option value="1050">BDBL</option>
                <option value="1060">DK Bank Ltd</option>
              </select>
            </div>
            <div className="py-1">
              <span className="px-1 text-sm text-gray-600">Account No</span>
              <input
                type="text"
                className="space-y-6 text-md block px-3 py-2 rounded-lg w-full bg-white border-2 border-gray-300 placeholder-gray-400 focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300 focus:outline-none"
                placeholder="Account No"
                value={bfs_remitterAccNo}
                onChange={(e) => setbfs_remitterAccNo(e.target.value)}
              />
            </div>
            <div className="flex mb-4 text-sm font-medium">
              <button
                type="button"
                className="py-2 px-4 bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
                onClick={sendAERequest}
              >
                Proceed
              </button>
            </div>

            <div className="bg-white dark:bg-gray-800 p-4">
              <div className="flex-row gap-4 flex justify-center items-center">
                <div className="flex flex-col">
                  <span className="text-lg font-medium text-gray-600 dark:text-white">
                    {votefor.name}
                  </span>
                  <span className="text-xs text-gray-400">
                    code: {votefor.code}
                  </span>
                  <span className="text-xs text-gray-400">
                    Dzongkhag: {votefor.dzongkhag}
                  </span>
                  <span className="text-xs text-gray-400">
                    Votes: {amount / data.price}
                  </span>
                </div>
                <div className="flex-shrink-0">
                  <a href="#" className="relative block">
                    <img
                      alt="profil"
                      src={votefor.pic ? votefor.pic : ""}
                      className="mx-auto object-cover rounded-full h-16 w-16"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



{/* <div
className="justify-center md:p-44 items-center flex   fixed inset-0 z-50 outline-none focus:outline-none overflow-auto"
>

 <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
   <div className="flex rounded-full items-start  ">
     
     <button
       className="p-1 ml-auto rounded-full bg-white text-white text-3 "
       onClick={() => setPayment(false)}
     >
<svg class="h-8 w-8 text-red-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <line x1="15" y1="9" x2="9" y2="15" />  <line x1="9" y1="9" x2="15" y2="15" /></svg>

</button>
   </div>
  
   


   <div className="text-gray-500 rounded-3xl shadow-xl w-full mb-5" >





<div className="lg:flex w-full">
 
 <div className="w-full md:h-96    px-5  relative overflow-auto">




<div class="flex flex-col w-full max-w-md py-1 bg-white rounded-lg shadow  sm:px-6 md:px-8 lg:px-6">
 
  <div class="mt-1">
          <div class="flex flex-col mb-1">





<div className="py-1">


<span className="px-1 text-sm text-gray-600"> Bank</span>

<select class="block px-3 py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm w-52 focus:outline-none focus:ring-primary-500 focus:border-primary-500" name="animals"

value={bfs_remitterBankId}
onChange={(e)=>{setbfs_remitterBankId(e.target.value)}}
>
    <option >
        Select Your Bank
    </option>
    <option value="1010">
        Bank of Bhutan
    </option>
    <option value="1020">
        Bhutan National Bank
    </option>
    <option value="1030">
        Druk PNBL
    </option>
    <option value="1040">
        T Bank Ltd
    </option>
    <option value="1050">
        BDBL 
    </option>
    <option value="1060">
        DK Bank Ltd
    </option>
</select>

</div>

<div className="py-1">
<span className="px-1 text-sm text-gray-600">Account No</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Account No"
value={bfs_remitterAccNo}
onChange={(e)=>setbfs_remitterAccNo(e.target.value)}
>

</input>
</div>



<button type="button" class="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
onClick={sendAERequest}
>
    Valider
</button>






             
              </div>


   

                 
                 
          </div>
         
      </div>
     



</div>

<div className=" md:block md:w-1/2 w-full  bg-white  ">


<div class="m-auto overflow-auto rounded-lg shadow-lg cursor-pointer h-90 w-11/12">
         
         <img alt="blog photo" src={votefor.pic?votefor.pic:"https://images.unsplash.com/photo-1470229722913-7c0e2dbbafd3?auto=format&fit=crop&q=80&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&w=1470"} class="object-cover w-full max-h-40"/>
             <div class="w-full p-4 bg-white ">
                 <p class="font-medium text-indigo-500 text-md">
                     {votefor.name}
                 </p>
                 <div class="flex items-center">
     <svg class="h-6 w-6 text-indigo-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
</svg>
     
      <p class="text-md text-black  ml-2">
     
     {votefor.dzongkhag}
 </p>
</div>
   
                 <div class="flex items-center">
                 <svg class="h-6 w-6 text-indigo-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="5" cy="5" r="1" />  <circle cx="12" cy="5" r="1" />  <circle cx="19" cy="5" r="1" />  <circle cx="5" cy="12" r="1" />  <circle cx="12" cy="12" r="1" />  <circle cx="19" cy="12" r="1" />  <circle cx="5" cy="19" r="1" />  <circle cx="12" cy="19" r="1" />  <circle cx="19" cy="19" r="1" /></svg>
     
      <p class="text-md text-black  ml-2">
     
     {votefor.code} Seats
 </p>
</div>
   
   
                 <div class="flex items-center">
                 <svg class="h-6 w-6 text-indigo-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>
     
      <p class="text-md text-black ml-2">
     
      {amount/data.price} Votes
 </p>
</div>
                


                 
                
             </div>
     </div>





</div>
   </div>


 </div>



 

</div>


</div> */}
<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
</>
)


:null

}
{
pay? 
(
<>

<div className="fixed inset-0 z-50 flex justify-center items-center overflow-auto">
  <div className="w-full max-w-md">
    <div className="border-0 rounded-lg shadow-lg bg-white">
      {/* Header */}
      <div className="flex items-start justify-end p-2">
        <button
          className="p-1 rounded-full bg-white text-white text-3"
          onClick={() => setPay(false)}
          >
          <svg
            className="h-8 w-8 text-red-500"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="12" cy="12" r="10" />
            <line x1="15" y1="9" x2="9" y2="15" />
            <line x1="9" y1="9" x2="15" y2="15" />
          </svg>
        </button>
      </div>
      {/* Body */}
      <p class="mb-0 text-center font-extrabold text-purple-500 ">
                      Step 3/3: Enter OTP and click Pay
                  </p>
      <div className="overflow-auto max-h-full">
        <div className="flex md:flex-row flex-col bg-white rounded-lg shadow dark:bg-gray-800">
          <div className="flex-auto p-6">
           
            <div className="py-1">
              <span className="px-1 text-sm text-gray-600">OTP</span>
              <input
                type="text"
                className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
                          bg-white border-2 border-gray-300 placeholder-gray-400  
                          focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  
                          focus:outline-none"
                placeholder="Enter OTP"
                value={bfs_remitterOtp}
                onChange={(e)=>setbfs_remitterOtp(e.target.value)}
              />
            </div>
            <div className="flex mb-4 text-sm font-medium">
              <button
                type="button"
                         class="  focus:ring-indigo-500 focus:ring-offset-indigo-200 
                          text-white w-full transition ease-in duration-200 
                          text-center text-base font-semibold shadow-md 
                          focus:outline-none focus:ring-2 focus:ring-offset-2  
                          rounded-lg "
                onClick={()=>{sendDRRequest()}}
              >
                Pay
              </button>
            </div>
            <div className="bg-white dark:bg-gray-800 p-4">
              <div className="flex-row gap-4 flex justify-center items-center">
                <div className="flex flex-col">
                  <span className="text-lg font-medium text-gray-600 dark:text-white">
                    {votefor.name}
                  </span>
                  <span className="text-xs text-gray-400">
                    code: {votefor.code}
                  </span>
                  <span className="text-xs text-gray-400">
                    Dzongkhag: {votefor.dzongkhag}
                  </span>
                  <span className="text-xs text-gray-400">
                    Votes: {amount/data.price}
                  </span>
                </div>
                <div className="flex-shrink-0">
                  <a href="#" className="relative block">
                    <img
                      alt="profil"
                      src={votefor.pic ? votefor.pic : ""}
                      className="mx-auto object-cover rounded-full h-16 w-16"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


{/* <div
className="justify-center md:p-44 items-center flex   fixed inset-0 z-50 outline-none focus:outline-none overflow-auto"
>

 <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
   <div className="flex rounded-full items-start  ">
    
   </div>
  
   


   <div className="text-gray-500 rounded-3xl shadow-xl w-full mb-5" >





<div className="lg:flex w-full">
 
 <div className="w-full md:h-96    px-5  relative overflow-auto">




<div class="flex flex-col w-full max-w-md py-1 bg-white rounded-lg shadow  sm:px-6 md:px-8 lg:px-6">
 
  <div class="mt-1">
          <div class="flex flex-col mb-1">







<div className="py-1">
<span className="px-1 text-sm text-gray-600">OTP</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Enter OTP"
value={bfs_remitterOtp}
onChange={(e)=>setbfs_remitterOtp(e.target.value)}
>

</input>
</div>




<button type="button" class="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
onClick={()=>{sendDRRequest()}}
>
    Proceed
</button>


             
              </div>

   

                 
                 
          </div>
         
      </div>
     



</div>


   </div>


 </div>



 

</div>


</div> */}
{/* <div className="opacity-25 fixed inset-0 z-40 bg-black"></div> */}
</>
)


:null

}
{
validation? 
(
<>
<div
className="justify-center md:p-44 items-center flex   fixed inset-0 z-50 outline-none focus:outline-none overflow-auto"
>

 {/*content*/}
   {/*header*/}
  
   {/*body*/}
  
   







 




<div class="flex flex-col w-full max-w-md py-1 bg-transparent rounded-lg shadow  sm:px-6 md:px-8 lg:px-6">
 
  <div class="mt-1">
          <div class="flex flex-col mb-1">





          <div class="relative w-64 p-4 m-auto bg-green-100 shadow-lg rounded-2xl ">
    <div class="w-full h-full text-center">
        <div class="flex flex-col justify-between h-full">
        <img src="/logo.png" alt="adidas" class="w-32 p-4 m-auto h-36"/>

            <p class="absolute text-sm italic text-gray-800 dark:text-white top-2 right-2">
                Voting Successful
            </p>
            <p class="mt-4 text-lg text-gray-900 dark:text-white">
                Voting Successful
            </p>
            <p class="px-6 py-2 text-xs font-thin text-gray-700 dark:text-gray-50">
                {amount/data.price} Votes
            </p>
            <p class="px-6 py-2 text-xs font-thin text-gray-700 dark:text-gray-50">
                To {details.name} 
            </p>
            <p class="px-6 py-2 text-xs font-thin text-gray-700 dark:text-gray-50">
                Code {details.code} 
            </p>

<button type="button" class="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
onClick={()=>{setValidation(false)}}
>
    Done
</button>

        </div>
    </div>
</div>






{/* <button type="button" class="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
onClick={()=>{sendDRRequest()}}
>
    Proceed
</button> */}


             
              </div>

   

                 
                 
          </div>
         
      </div>
     












 



</div>
{/* <div className="opacity-25 fixed inset-0 z-40 bg-black"></div> */}
</>
)


:null

}
{
falseTran? 
(
<>
<div className="fixed inset-0 z-50 flex justify-center items-center">
  <div className=" w-full md:h-auto md:max-w-md px-5 relative overflow-auto">
    {/* Your content here */}
    <div className="flex flex-col w-full py-1 bg-transparent rounded-lg shadow sm:px-6 md:px-8 lg:px-6">
      <div className="mt-1">
        <div className="flex flex-col mb-1">
          <div className="relative w-64 p-4 m-auto  shadow-lg rounded-2xl bg-red-100">
            <div className="w-full h-full text-center">
              <div className="flex flex-col justify-between h-full">
                <img src="/logo.png" alt="adidas" className="w-32 p-4 m-auto h-36" />
                <p className="absolute text-sm italic text-gray-800 dark:text-white top-2 right-2">
                  Voting Failed
                </p>
                <p className="mt-4 text-lg text-gray-900 dark:text-white">
                  Error occurred while voting
                </p>
                <p className="px-6 py-2 text-xs font-thin text-gray-700 dark:text-gray-50">
                  Please try again
                </p>
              </div>

<button type="button" class="py-2 px-4  bg-purple-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
onClick={()=>{setFalseTran(false)}}
>
    Retry
</button>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

{/* <div className="opacity-25 fixed inset-0 z-40 bg-black"></div> */}
</>
)


:null

}











<Footer page="findshows"></Footer>
</main>







</>

  


    );
}

export default SingleShow;