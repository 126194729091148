// import React, { useEffect, useState,useRef } from 'react';
// import Header from '../components/Header';
// import QRCode from 'qrcode.react';
// import ReactPDF, { Document, Page, Text, View, StyleSheet , PDFViewer,PDFDownloadLink,Image } from '@react-pdf/renderer';
// import html2canvas from 'html2canvas';

// function Completion(props) {

//   const [paymentIntentId,setPaymentIntent] =useState("")
//   const [data, setData] = useState([])
//   const [event, setEvent] = useState(null)
//   const [pic, setPic] = useState("")
//   const [qr,setQR] = useState([])
//   console.log("DATA",data)
//   console.log("PIC",pic)
//   const qrCodeValues = ['Value1', 'Value2', 'Value3'];
//   const [imageUrls, setImageUrls] = useState([]);
// //   const qrCodeRefs = useRef(qrCodeValues.map(() => React.createRef()));
//   const qrCodeRef = useRef(null);

//   const generateImageUrl = () => {
//     if (qrCodeRef.current) {
//       html2canvas(qrCodeRef.current)
//         .then((canvas) => {
//           const dataUrl = canvas.toDataURL('image/png');
//             setImageUrls(olditem=>[...olditem, dataUrl])

//         })
//         .catch((error) => {
//           console.error('Error converting QR code to image:', error);
//         });
//     }
//   };

  

//   console.log(qr)
//   useEffect(() => {
  
//     fetch('/addticket',{

//       method:"post",
//       headers:{

//         "Content-Type":"application/json"

//       },
//       body:JSON.stringify({
//           data:JSON.parse(localStorage.getItem('data'))
//       })

//     }).then(res=> res.json())
//       .then(results=>{
        
//         setData(results.tickets)
//         setEvent(results.event)
//         setPic(results.event.event.pic)
//       })


//   }, []); 

 

 


//   const MyDocument = ({  }) => (
//     <View style={styles.container}>
//     {data.map((item) => {

//       <View key={item.id} style={styles.ticket}>
//         <Image
//           src={event.event.pic || 'https://img.freepik.com/free-vector/hand-drawn-galungan-illustration_23-2148880620.jpg?size=626&ext=jpg&ga=GA1.1.16292083.1700716592&semt=ais'}
//           style={styles.eventImage}
//         />
//         <View style={styles.eventInfo}>
//           <Text style={styles.eventName}>{event.event.name}</Text>
//           <Text style={styles.eventDescription}>
//             You can't buy your future, but you can do it. Money is nothing,
//             you're everything.
//           </Text>
//           <View style={{ display: 'flex', justifyContent: 'space-between' }}>
//             {[1, 2, 3, 4, 5].map((star) => (
//               <Text key={star} style={{ fontSize: 14, color: '#757575' }}>
//                 ★
//               </Text>
//             ))}
            
//             <QRCode value={JSON.stringify(item)} size={30} />
//           </View>
//           <Text style={styles.eventPrice}>{event.event.price}</Text>
//         </View>
//       </View>
// })}
//   </View>
//   );
  
//   const styles = StyleSheet.create({
//     container: {
//       display: 'flex',
//       flexDirection: 'row',
//       flexWrap: 'wrap',
//       justifyContent: 'center',
//       alignItems: 'center',
//       padding: 20,
//       gap: 20,
//     },
//     ticket: {
//       width: '300px',
//       height: '200px',
//       backgroundColor: '#fff',
//       borderRadius: 10,
//       boxShadow: '0 2px 8px rgba(0, 0, 0, 0.25)',
//       overflow: 'hidden',
//     },
//     eventImage: {
//       width: '50%',
//       height: '100%',
//       objectFit: 'cover',
//       borderRadius: 10,
//     },
//     eventInfo: {
//       padding: 20,
//       display: 'flex',
//       flexDirection: 'column',
//       justifyContent: 'space-between',
//     },
//     eventName: {
//       fontSize: 24,
//       fontWeight: 'bold',
//       color: '#212121',
//     },
//     eventDescription: {
//       fontSize: 14,
//       color: '#757575',
//     },
//     eventPrice: {
//       fontSize: 18,
//       fontWeight: 'bold',
//       color: '#212121',
//     },
//   });

//   return (
//     <>
// <>
// <Header></Header>
//   {/* component */}
//   <style dangerouslySetInnerHTML={{__html: "@import url('https://cdnjs.cloudflare.com/ajax/libs/MaterialDesign-Webfont/5.3.45/css/materialdesignicons.min.css')" }} />
//   <div className=" bg-gray-50 flex  items-center justify-center px-5 py-5">
//   <div class="grid relative grid-cols-1 gap-6 md:grid-cols-1 xl:grid-cols-1">

// {data?data.map((item,index)=>{


// return(
//     <>
  









// <div class="flex max-w-md overflow-hidden bg-white bg-cover rounded-lg shadow-lg"

//   style={{
//             backgroundImage: `url(${pic ? pic : "https://img.freepik.com/free-vector/hand-drawn-galungan-illustration_23-2148880620.jpg?size=626&ext=jpg&ga=GA1.1.16292083.1700716592&semt=ais"})`

//   }}
// >
//     <div class="w-1/2 bg-cover bg-landscape">
//     </div>
//     <div class="w-2/3 p-4">
//         <h1 class="text-2xl font-bold text-white">
//             {event.event.name}
//         </h1>
//         <p class="mt-2 text-sm text-gray-600">
//             You can&#x27;t buy your future, but you can do it. Money is nothing, you&#x27;r everything.
//         </p>
//         <div class="flex mt-2 item-center">
//             <svg class="w-5 h-5 text-gray-700 fill-current" viewBox="0 0 24 24">
//                 <path d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z">
//                 </path>
//             </svg>
//             <svg class="w-5 h-5 text-gray-700 fill-current" viewBox="0 0 24 24">
//                 <path d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z">
//                 </path>
//             </svg>
//             <svg class="w-5 h-5 text-gray-700 fill-current" viewBox="0 0 24 24">
//                 <path d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z">
//                 </path>
//             </svg>
//             <svg class="w-5 h-5 text-gray-500 fill-current" viewBox="0 0 24 24">
//                 <path d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z">
//                 </path>
//             </svg>
//             <svg class="w-5 h-5 text-gray-500 fill-current" viewBox="0 0 24 24">
//                 <path d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z">
//                 </path>
//             </svg>
//         </div>
//         <div class="flex justify-between mt-3 item-center">
//             <h1 class="text-xl font-bold text-gray-50">
//                 ${event.event.price}
//             </h1>
//             <div ref={item._id}>

//            <QRCode value={JSON.stringify(item)}     size={90}
// ></QRCode>
// </div>
//         </div>
//     </div>
// </div>


//     </>
// )
// }):<></>}



// </div>
//       </div>


//       <PDFDownloadLink document={<MyDocument event={event}/>} fileName="bill.pdf">
//       {({ blob, url, loading, error }) =>
//         loading ? 'Loading document...' : <>
//         <span
//         class={`px-4 py-2 m-2 flex w-24 items-center text-base rounded-full text-blue-500  hover:bg-blue-200 border border-indigo-300 undefined  cursor-pointer`}
// >
// <svg class="h-8 w-8 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2" />  <path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4" />  <rect x="7" y="13" width="10" height="8" rx="2" /></svg>
//     Print
// </span>
//         </>
//       }
//     </PDFDownloadLink>

// </>

// {/* <div class="flex flex-wrap w-full">
//     <div class="flex flex-col w-full md:w-1/2">
       
//         <div class="flex flex-col justify-center px-8 pt-8 my-auto md:justify-start md:pt-0 md:px-24 lg:px-32">
           
        
// <form class="flex w-full max-w-sm space-x-3">
//     <div class="w-full max-w-2xl px-5 py-10 m-auto mt-10 bg-white rounded-lg shadow dark:bg-gray-800">
//         <div class="mb-6 text-3xl font-light text-center text-gray-800 dark:text-white">
//             Contact us !
//         </div>
//         <div class="grid max-w-xl grid-cols-2 gap-4 m-auto">
//             <div class="col-span-2 lg:col-span-1">
//                 <div class=" relative ">
//                     <input type="text" id="contact-form-name" class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Name"/>
//                     </div>
//                 </div>
//                 <div class="col-span-2 lg:col-span-1">
//                     <div class=" relative ">
//                         <input type="text" id="contact-form-email" class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="email"/>
//                         </div>
//                     </div>
//                     <div class="col-span-2">
//                         <label class="text-gray-700" for="name">
//                             <textarea class="flex-1 w-full px-4 py-2 text-base text-gray-700 placeholder-gray-400 bg-white border border-gray-300 rounded-lg appearance-none focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" id="comment" placeholder="Enter your comment" name="comment" rows="5" cols="40">
//                             </textarea>
//                         </label>
//                     </div>
//                     <div class="col-span-2 text-right">
//                         <button type="submit" class="py-2 px-4  bg-blue-500 hover:bg-blue-600 focus:ring-blue-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
//                             Send
//                         </button>
//                     </div>
//                 </div>
//             </div>
//         </form>

                  
//                 </div>
//             </div>
//             <div class="w-1/2 shadow-2xl">
//                 <img class=" object-fit w-full h-screen " src="/contact.jpeg"/>
//             </div>
//         </div> */}
// {/* {ReactPDF.render(<MyDocument data={data} />, `${__dirname}/example.pdf`)} */}

// {/* <div>
//       {qrCodeValues.map((value, index) => (
//         <div key={index} ref={qrCodeRefs.current[index]}>
//           <QRCode value={value} size={150} />
//         </div>
//       ))}
//       <button onClick={generateImageUrls}>Generate QR Code Image URLs</button>
//       {imageUrls.length > 0 && (
//         <div>
//           {imageUrls.map((imageUrl, index) => (
//             <div key={index}>
//               <img src={imageUrl} alt={`QR Code ${index + 1}`} />
//             </div>
//           ))}
//         </div>
//       )}
//     </div> */}
// </>
  
//   )
  
// }

// export default Completion;

import React, { useEffect, useState, useRef } from 'react';
import Header from '../components/Header';
import QRCode from 'qrcode.react';
import ReactPDF, { Document, Page, Text, View, StyleSheet, Image,PDFDownloadLink } from '@react-pdf/renderer';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Footer from '../components/Footer';

function Completion(props) {
  const [paymentIntentId, setPaymentIntent] = useState("");
  const [data, setData] = useState([]);
  const [seats, setSeats] = useState();
  const [event, setEvent] = useState(null);
  const [pic, setPic] = useState("");
  const [imageUrls, setImageUrls] = useState([]);
  const divRef = useRef(null);
const [load,setLoad] = useState(false)
  console.log("DATA", data);
  console.log("PIC", pic);

  console.log(imageUrls);

  const handleConvertToPdf = () => {
    if (!divRef.current) return;

    // Use html2canvas to capture the content of the div
    html2canvas(divRef.current,{
        allowTaint: true,
        useCORS: true
      }).then((canvas) => {
      // Convert the canvas to a data URL
      const imgData = canvas.toDataURL('image/png');

      // Calculate the page size based on the div size
      const pdf = new jsPDF({
        unit: 'px',
        format: 'a4',
      });

      // Add the image to the PDF
      pdf.addImage(imgData, 'PNG', 0, 0);
      
//       const pdfData = pdf.output('dataurl');
// console.log(pdfData)
      // Save or open the PDF
      pdf.save('Tickets.pdf');

      // const pdfData = pdf.output("datauristring");
      // var finalpdf = btoa(pdf.output());
      //  console.log("PDFFF",finalpdf)
      // Send the PDF data to the backend
      // sendPdfToBackend(pdfData);
      // sendPdfToBackend(finalpdf)
    });
  };

  useEffect(() => {
    fetch('/addticket', {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        data: JSON.parse(localStorage.getItem('data')),
        seats: JSON.parse(localStorage.getItem('seats'))
        
      })
    })
      .then(res => res.json())
      .then(results => {
        setData(results.tickets);
        setEvent(results.event);
        setPic(results.event.pic);
        setSeats(results.seats)
        // setLoad(true)
        localStorage.removeItem('data');
        localStorage.removeItem('seats');
        // handleConvertToPdf()
      });
  }, []);

  // if(load){
  //   handleConvertToPdf()
  //   setLoad(false)
  // }


  const sendPdfToBackend = (pdfData) => {
    // Assuming you have a server endpoint that accepts file uploads using Multer
    // const backendEndpoint = '/upload-pdf';
  console.log("POSTT")
    // Create a FormData object and append the PDF data
    // const formData = new FormData();
    // formData.append('pdf', pdfData);
  
    // Make a POST request to the server
    fetch("/upload-pdf", {
      method: 'POST',
      body:  JSON.stringify({ pdfData }),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response from the server (e.g., display a success message)
        console.log('Server response:', data);
      })
      .catch((error) => {
        // Handle errors
        console.error('Error sending PDF to the server:', error);
      });
  };
  return (
    <>
      <Header></Header>
      {/* <button class="fixed  z-50 bottom-10 md:top-96 right-8 bg-blue-500 w-16 md:w-32 h-12 rounded-full drop-shadow-lg flex justify-center items-center text-white text-4xl hover:bg-blue-600 hover:drop-shadow-2xl hover:animate-bounce duration-300"
onClick={handleConvertToPdf}
> 
<svg class="h-8 w-8 text-white"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"/>
</svg>

    
    <h6 class="text-xs md:text-sm md:p-2  ">Download</h6>

</button> */}
<p class="mb-0 text-center text-3xl font-extrabold text-purple-500 ">
                      Thank You
                  </p>
      <div   className="bg-gray-50 flex items-center justify-center px-5 py-5">
        <div ref={divRef} className="grid relative grid-cols-1 gap-6 md:grid-cols-1 xl:grid-cols-1">

        <div class="flex items-center justify-between w-64 p-4 bg-white shadow-lg rounded-2xl mb-5">
    <div class="w-2/6">
        <img src="/logo.png" alt="person" class="w-12 h-12 rounded-full"/>
    </div>
    <div class="w-3/6">
        <p class="text-sm text-gray-500">
            We have mailed you
            <span class="font-bold text-indigo-500">
                {data?data.length:""}
            </span>
            Tickets
        </p>
    </div>
    <div class="w-1/6 text-right">
        <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" class="w-6 h-6 text-indigo-500" xmlns="http://www.w3.org/2000/svg">
            <path d="M1792 710v794q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-794q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48 92.5-65.5q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38-42.5-30.5q-91-64-262-182.5t-205-142.5q-62-42-117-115.5t-55-136.5q0-78 41.5-130t118.5-52h1472q65 0 112.5 47t47.5 113z">
            </path>
        </svg>
    </div>
</div>




          {data ? data.map((item, index) => (
            <div class="flex ">
              <div className="flex max-w-md h-1/4  overflow-hidden bg-white  rounded-lg shadow-lg"
               
              >
                <div className="w-1/2 bg-fit ">

                    <img class="h-full " src={pic}/>

                </div>
                <div className="w-2/3 p-4">
                  <h1 className="text-2xl font-bold text-black">{event.name}</h1>
                
                  <div class="flex items-center">
                  <svg class="h-8 w-8 text-indigo-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="4" y="5" width="16" height="16" rx="2" />  <line x1="16" y1="3" x2="16" y2="7" />  <line x1="8" y1="3" x2="8" y2="7" />  <line x1="4" y1="11" x2="20" y2="11" />  <rect x="8" y="15" width="2" height="2" /></svg> 
             <p class="text-md text-black  ml-2">
            
             {event.date.substring(0,10)}
        </p>
    </div>
                  <div class="flex items-center">
            <svg class="h-8 w-8 text-indigo-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
</svg>
            
             <p class="text-md text-black ml-2">
            
            {event.venue}
        </p>
    </div>
    <div class="flex items-center">
                        <svg class="h-8 w-8 text-purple-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <polyline points="12 6 12 12 16 14" /></svg>            
             <p class="text-md text-black  ml-2">
            
            {event.time}
        </p>
    </div>
          

    
               {  seats? <div class="flex items-center">
                  <svg class="h-8 w-8 text-indigo-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="5" cy="5" r="1" />  <circle cx="12" cy="5" r="1" />  <circle cx="19" cy="5" r="1" />  <circle cx="5" cy="12" r="1" />  <circle cx="12" cy="12" r="1" />  <circle cx="19" cy="12" r="1" />  <circle cx="5" cy="19" r="1" />  <circle cx="12" cy="19" r="1" />  <circle cx="19" cy="19" r="1" /></svg>
            
             <p class="text-md text-black  ml-2">
            
           SEAT: {item.seat} 
        </p>
    </div>:<>
    </>}
          
                  <div className="flex justify-between mt-3 item-center">
                    
                    <h1 className="text-xl font-bold text-black">${event.price}</h1>
                    <QRCode value={JSON.stringify(item)} size={90}></QRCode>

                  </div>
                </div>
              </div>




            </div>
          )) : <>
          
          
          
          </>}



          
        </div>
      </div>
      {/* <PDFDownloadLink document={<MyDocument event={event} />} fileName="bill.pdf">
        {({ blob, url, loading, error }) =>
          loading ? 'Loading document...' : (
            <span className="px-4 py-2 m-2 flex w-24 items-center text-base rounded-full text-blue-500 hover:bg-blue-200 border border-indigo-300 undefined cursor-pointer">
              <svg className="h-8 w-8 text-blue-500" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z"></path>
                <path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2"></path>
                <path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4"></path>
                <rect x="7" y="13" width="10" height="8" rx="2"></rect>
              </svg>
              Print
            </span>
          )
        }
      </PDFDownloadLink> */}




{/* 
<span className="px-4 py-2 m-2 flex w-24 items-center text-base rounded-full text-blue-500 hover:bg-blue-200 border border-indigo-300 undefined cursor-pointer"

onClick={handleConvertToPdf}
>
              <svg className="h-8 w-8 text-blue-500" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z"></path>
                <path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2"></path>
                <path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4"></path>
                <rect x="7" y="13" width="10" height="8" rx="2"></rect>
              </svg>
              Print
            </span> */}

            {/* {handleConvertToPdf()} */}
            <Footer></Footer>
    </>
  );
}

export default Completion;
