import React, { useState,useEffect,useContext,useRef } from 'react';
import QRCode from 'qrcode.react';
import {UserContex} from '../App'
import {Link, useNavigate, useParams} from 'react-router-dom'
import SinglePage from './SinglePage';
import AdminHeader from '../components/AdminHeader';
import DatePicker from 'react-datepicker'
import { Document, Page, Text, View, StyleSheet , PDFViewer,PDFDownloadLink,Image,pdf} from '@react-pdf/renderer';

import html2canvas from 'html2canvas';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

 
require('react-datepicker/dist/react-datepicker.css')

function ShowDash() {
    const {state, dispatch}= useContext(UserContex)

    const history = useNavigate()
    const componentRef = useRef(null);

  const [inputText, setInputText] = useState('');
  const [qrCodeText, setQRCodeText] = useState('');
  const [Zonedata,setZoneData] = useState([])
  const [data,setData] = useState([])
  const [singleEvent,setSingleEvent] = useState(null)

  const [today,setToday] = useState(new Date())
  const[singleEarning,setSingleEarning]= useState(null)
  const[seller,setSeller]= useState(null)
  const[detail,setDetail]= useState(null)
  const[weeklyvote,setWeeklyVote]= useState(0)
  const[participants,setParticipants] = useState([])
  const {sellerid} = useParams()
  const[id,setId] = useState("")  
  const[name,setName]= useState("")
  const[phone,setPhone]= useState("")
  const[address,setAddress]= useState("")
  const[email,setEmail]= useState("")
  const[password,setPassword]= useState("")
  const[cid,setCID]= useState("")
  const[zone,setZone]= useState("")
  const[code,setCode]= useState("")
  const[show,setShow]= useState("")
  const[status,setStatus]= useState("")
  const [addStock,setAddStock]= useState(false)
  const [addShow,setAddShow]= useState(false)
  const [editShow,setEditShow]= useState(false)
  const [url,setUrl] = useState("")  
  const[progress,setProgress]= useState(false)
  const [date,setDate] = useState(new Date())
  const[description,setDescription]=useState("")
  const [time,setTime]= useState("")
  const [row,setRow]= useState("")
  const [category,setCategory] = useState("")  
  const[price,setPrice]= useState("")
  const[venue,setVenue]=useState("")
  const[youtube,setYoutube]=useState("")
  const[filterstatus,setFilterStatus]=useState("All")
  const[live,setLive]=useState(false)
  const[ShowParticipantReport,setShowParticipantReport]=useState(false)
  const[singleParticipant,setSingleParticipant] = useState({})


  useEffect(  ()=>{
    setDetail( JSON.parse( localStorage.getItem("vendor")))


    fetch('/getmyshows',{
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{
      setData(result.events)
      
  })


//   fetch('/getSingleSeller',{
//     method:"post",
//     headers:{
//       Authorization: "Bearer " +localStorage.getItem("jwt"),
//       "Content-Type":"application/json"

//     },
//     body:JSON.stringify({
//       id:sellerid
//     })
// }).then(res=>res.json())
// .then(result=>{


//     setSeller(result.seller)
//   // setDataEdit(result.stock)
  

          
// })


  

  },[])


 
  const singlEvent = (Itemid)=>{

    setShow(Itemid)
    fetch(`/getsingleshow/${Itemid}`,{
      method:"get",
      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt"),
        "Content-Type":"application/json"
  
      }
  }).then(res=>res.json())
  .then(result=>{
  

    setSingleEvent(result.event)


  //   var amt = 0
  //   result.event.votes.map(item=>{
  //     amt+=item.vote
  //     console.log(amt)
  // })

  
    
            
  })


  fetch(`/getmyparticipants/${Itemid}`,{
    method:"get",
    headers:{
      Authorization: "Bearer " +localStorage.getItem("jwt"),
      "Content-Type":"application/json"

    }
}).then(res=>res.json())
.then(result=>{

  
    setParticipants(result.participants)
    // setSingleEarning(result.earning)
    var sumVote=0
    result.participants.map(participant=>{
        sumVote+=participant.votes
    })


    setWeeklyVote(sumVote)
          
})


  
  }
    



  const addParticipant = ()=>{

    fetch('/addParticipant',{

        method:"post",
        headers:{
          "Content-Type":"application/json",
          Authorization: "Bearer " +localStorage.getItem("jwt")
  
        },
        body: JSON.stringify({
         
          name,phone,code,zone,pic:url,show:show,
        })
        
      }).then(res=>res.json())
      .then(data=>{
        if(data.error){
          console.log("Error")
        }
  
        else{
          
          window.location.reload();
              // history.go(`/employee`)
  
        }
      })
      .catch(err=>{
        console.log(err)
      })
  }


  const resetWeek = (show)=>{

    const now = new Date();
    const bhutanOffset = 6 * 60; // Bhutan is UTC+6, so offset is 6 hours in minutes
    const localOffset = now.getTimezoneOffset(); 

    fetch('/setvotetozero',{

        method:"put",
        headers:{
          "Content-Type":"application/json",
          Authorization: "Bearer " +localStorage.getItem("jwt")
  
        },
        body: JSON.stringify({
         
          totalvotes:weeklyvote,show:show,week:singleEvent.week,date: new Date(now.getTime() + (bhutanOffset - localOffset) * 60000).toLocaleString('en-US', { timeZone: 'Asia/Thimphu' })
        })
        
      }).then(res=>res.json())
      .then(data=>{
        if(data.error){
          console.log("Error")
        }
  
        else{
          console.log("RELOAD")
          window.location.reload();
              // history.go(`/employee`)
  
        }

      })
      .catch(err=>{
        console.log(err)
      })
  }




  const addLive = ()=>{
      // const pattern = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:live\/|watch\?v=)|youtu\.be\/)([^\#\&\?\/]*)/;
      // const match = youtube.match(pattern);


    fetch('/setlive',{

      method:"put",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({
       
        live:youtube,
        id:singleEvent._id
      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
      }

      else{
        
        window.location.reload();
            // history.go(`/employee`)

      }
    })
    .catch(err=>{
      console.log(err)
    })

  }
  const addStatus = ()=>{
      // const pattern = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:live\/|watch\?v=)|youtu\.be\/)([^\#\&\?\/]*)/;
      // const match = youtube.match(pattern);


    fetch('/setStatus',{

      method:"put",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({
       
        id:singleEvent._id
      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
      }

      else{
        
        window.location.reload();
            // history.go(`/employee`)

      }
    })
    .catch(err=>{
      console.log(err)
    })

  }
  
  const postEvent = ()=>{

    fetch('/addshow',{

      method:"post",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({
       
        name,venue,description,price,pic:url,date,category,time
      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
      }

      else{
        
        window.location.reload();
            // history.go(`/employee`)

      }
    })
    .catch(err=>{
      console.log(err)
    })

  }
  
  const updateEvent = ()=>{

    fetch('/updateshow',{

      method:"put",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({
       
        name,venue,description,price,pic:url,date,category,time, id:singleEvent._id
      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
      }

      else{
        
        window.location.reload();
            // history.go(`/employee`)

      }
    })
    .catch(err=>{
      console.log(err)
    })

  }
  
  
  
  const eleminateParticipant = (itemId,status)=>{

    fetch(`/eleminateParticipant/${itemId}`,{

      method:"post",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({
       
        name,venue,description,price,pic:url,date,category,time,status
      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
      }

      else{
        
        window.location.reload();
            // history.go(`/employee`)

      }
    })
    .catch(err=>{
      console.log(err)
    })

  }



  
  const uploadppt=({target:{files}})=>{

    setProgress(true)

    let data = new FormData()

    for(const file of files){

   data.append('uploadimage', file)
   data.append('name',file.name)
}



       fetch("/uploadimage", {

         method:"post",
         body: data
     })
     .then(res=>res.json())
     .then(data=>{

          setUrl(data.url)
          setProgress(false)

      

       
     })
     .catch(err=>{
         console.log(err)
     })

}
  

const PrintSales = (da) => (

  <Document>
  <Page size="A4" style={styles.page}>
    <View style={styles.header}>
    <Image style={styles.logo} src="/logo.png"
 />

      <Text style={styles.address}>Result for {singleEvent.name}</Text>
      <Text style={{fontSize:10, marginBottom: 3}}>week {singleEvent.week}</Text>
    
    </View>
    <View style={styles.horizontalLine} />

    <View style={styles.invoiceDetails}>
      <View style={styles.itemList}>
        <View style={styles.itemRow}>
          <Text style={styles.label}>Name</Text>
          <Text style={styles.label}>Votes</Text>
        </View>
        <View style={styles.horizontalLine} />

        {participants.map((item, index) => (
          <View>

          <View key={index} style={styles.itemRow}>
            <Text style={styles.itemDescription}>{item.name}</Text>
            <Text style={styles.itemDescription}>{item.votes}</Text>
            {/* <Text style={styles.itemDescription}>{item.qty}</Text> */}
            {/* <Text style={styles.itemDescription}>{item.amount.toFixed(0)}</Text> */}
          </View>
          <View style={styles.horizontalLine} />

          </View>

          
        ))}

      </View>

      <View style={styles.totalRow}>
        <Text style={styles.totalLabel}>Total: {weeklyvote.toFixed(0)}</Text>
        {/* <Text style={styles.totalValue}>{invoice.total}</Text> */}
      </View>
      <View style={styles.horizontalLine} />

    </View>
  </Page>
</Document>
  
);

const PrintHistory = ({week}) => {
  // Filter the participants' weekly votes for the specified week
  const filteredParticipants = participants.map(participant => {
    const weeklyVote = participant.weeklyvotes.find(vote => vote.week === week);
    return {
      name: participant.name,
      votes: weeklyVote ? weeklyVote.votes : 0
    };
  });

  // Calculate the total votes for the specified week
  const totalVotes = filteredParticipants.reduce((acc, participant) => acc + participant.votes, 0);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Image style={styles.logo} src="/logo.png" />
          <Text style={styles.address}>Result for {singleEvent.name}</Text>
          <Text style={{ fontSize: 10, marginBottom: 3 }}>week {week}</Text>
        </View>
        <View style={styles.horizontalLine} />

        <View style={styles.invoiceDetails}>
          <View style={styles.itemList}>
            <View style={styles.itemRow}>
              <Text style={styles.label}>Name</Text>
              <Text style={styles.label}>Votes</Text>
            </View>
            <View style={styles.horizontalLine} />

            {filteredParticipants.map((item, index) => (
              <View key={index}>
                <View style={styles.itemRow}>
                  <Text style={styles.itemDescription}>{item.name}</Text>
                  <Text style={styles.itemDescription}>{item.votes}</Text>
                </View>
                <View style={styles.horizontalLine} />
              </View>
            ))}
          </View>

          <View style={styles.totalRow}>
            <Text style={styles.totalLabel}>Total: {totalVotes}</Text>
          </View>
          <View style={styles.horizontalLine} />
        </View>
      </Page>
    </Document>
  );
};
const PrintAllVotes = () => {
  // Filter the participants' weekly votes for the specified week
  
  // Calculate the total votes for the specified week

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Image style={styles.logo} src="/logo.png" />
          <Text style={styles.address}>Summary for {singleEvent.name}</Text>
          {/* <Text style={{ fontSize: 10, marginBottom: 3 }}>week {week}</Text> */}
        </View>
        <View style={styles.horizontalLine} />

        <View style={styles.invoiceDetails}>
          <View style={styles.itemList}>
            <View style={styles.itemRow}>
              <Text style={styles.label}>Week</Text>
              <Text style={styles.label}>Votes</Text>
              <Text style={styles.label}>Revenew</Text>
            </View>
            <View style={styles.horizontalLine} />

            {singleEvent.summary.map((item, index) => (
              <View key={index}>
                <View style={styles.itemRow}>
                  <Text style={styles.itemDescription}>{item.week}</Text>
                  <Text style={styles.itemDescription}>{item.vote}</Text>
                  <Text style={styles.itemDescription}>{item.vote*singleEvent.price}</Text>
                </View>
                <View style={styles.horizontalLine} />
              </View>
            ))}
          </View>

          <View style={styles.totalRow}>
            <Text style={styles.totalLabel}>Total:{singleEvent.summary.reduce((accumulator, item) => accumulator + item.vote, 0)}
            </Text>
          </View>
          <View style={styles.horizontalLine} />
        </View>
      </Page>
    </Document>
  );
};
const PrintSingleParticipant = () => {
  // Filter the participants' weekly votes for the specified week
  
  // Calculate the total votes for the specified week
const totalvotes = singleParticipant.weeklyvotes.reduce((accumulator, item) => accumulator + item.votes, 0)
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Image style={styles.logo} src={singleParticipant.pic?singleParticipant.pic:'/logo.png'} />
          <Text style={styles.address}>Summary for {singleParticipant.name}</Text>
          <Text style={{ fontSize: 10, marginBottom: 3 }}>Code {singleParticipant.code}</Text>
          {/* <Text style={{ fontSize: 10, marginBottom: 3 }}> {singleParticipant.dzongkhag}</Text> */}
        </View>
        <View style={styles.horizontalLine} />

        <View style={styles.invoiceDetails}>
          <View style={styles.itemList}>
            <View style={styles.itemRow}>
              <Text style={styles.label}>Week</Text>
              <Text style={styles.label}>Votes</Text>
              <Text style={styles.label}>Revenew</Text>
            </View>
            <View style={styles.horizontalLine} />

            {singleParticipant.weeklyvotes.map((item, index) => (
              <View key={index}>
                <View style={styles.itemRow}>
                  <Text style={styles.itemDescription}>{item.week}</Text>
                  <Text style={styles.itemDescription}>{item.votes}</Text>
                  <Text style={styles.itemDescription}>{item.votes*singleEvent.price}</Text>
                </View>
                <View style={styles.horizontalLine} />
              </View>
            ))}
          </View>

          <View style={styles.totalRow}>
            <Text style={styles.totalLabel}>Total:Nu. {totalvotes*singleEvent.price}
            </Text>
          </View>
          <View style={styles.horizontalLine} />
        </View>
      </Page>
    </Document>
  );
};

function exportToExcel(data) {
  // Convert data to CSV format
  const formattedData = participants.map(({ name, code, votes }) => [name, code, votes]);

  // Add headers to the CSV string
  const csv = [['Name', 'Code', 'Votes']].concat(formattedData.map(row => row.join(','))).join('\n');

  // Create a Blob containing the CSV data
  const blob = new Blob([csv], { type: 'text/csv' });

  // Create a temporary URL for the Blob
  const url = window.URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${"Week "+singleEvent.week+" Report"}.csv`);

  // Append the link to the DOM and click it programmatically
  document.body.appendChild(link);
  link.click();

  // Clean up: remove the link and revoke the URL
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
}

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    padding: 30,
  },
  header: {
    marginBottom: 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  address: {
    fontSize: 12,
    marginBottom: 10,
  },
  invoiceDetails: {
    marginBottom: 20,
  },
  label: {
    fontSize: 15,
    fontWeight: 'bold',
  },
  labelfooter: {
    fontSize: 10,
    fontWeight: 'bold',
    marginBottom: 8,
    marginTop: 8,

  },
  value: {
    fontSize: 12,
  },
  itemList: {
    marginTop: 2,
  },
  itemRow: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginBottom: 8,
    marginLeft:8
  },
  itemDescription: {
    width: '20%',
    textAlign:'center',
    fontSize:12

  },
  itemQuantity: {
    width: '15%',
    textAlign: 'left',
  },
  itemAmount: {
    width: '25%',
    textAlign: 'right',
  },
  totalRow: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 10,
  },
  totalLabel: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  totalValue: {
    fontSize: 14,
    marginLeft: 10,
  },
  logo: {
    width: 80, 
    height: 80, 
    marginBottom: 10,
    justifyContent: 'center',
    alignItems: 'center',  },


  horizontalLine: {
    borderTop: 1,
    borderColor: 'black',
    marginVertical: 4,
    width: '100%',
  },
});







const [filter, setFilter] = useState('All');

const filteredParticipants = participants.filter(participant => {
  if (filter === 'All') return true;
  return filter === 'Active' ? participant.status === 'Active' : participant.status === 'Eleminated';
}).sort((a, b) => b.votes - a.votes);
;

// const filteredAndSortedParticipants = participants
//     .filter(participant => {
//       if (filter === 'All') return true;
//       return filter === 'Active' ? participant.status === 'Active' : participant.status === 'Eliminated';
//     })
//     .sort((a, b) => b.votes - a.votes);
//   console.log("Sinvle"singleEvent.seats)

  const Logout = ()=>{
    localStorage.clear()
    dispatch({type:"CLEAR"})
        history(`/login`)
}



const PrintAllVotesmob = () => {
  // Assuming singleEvent and styles are defined outside or passed as props
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Image style={styles.logo} src="/logo.png" />
          <Text style={styles.address}>Summary for {singleEvent.name}</Text>
        </View>
        <View style={styles.horizontalLine} />
        <View style={styles.invoiceDetails}>
          <View style={styles.itemList}>
            <View style={styles.itemRow}>
              <Text style={styles.label}>Week</Text>
              <Text style={styles.label}>Votes</Text>
              <Text style={styles.label}>Revenue</Text>
            </View>
            <View style={styles.horizontalLine} />
            {singleEvent.summary.map((item, index) => (
              <View key={index}>
                <View style={styles.itemRow}>
                  <Text style={styles.itemDescription}>{item.week}</Text>
                  <Text style={styles.itemDescription}>{item.vote}</Text>
                  <Text style={styles.itemDescription}>{item.vote * singleEvent.price}</Text>
                </View>
                <View style={styles.horizontalLine} />
              </View>
            ))}
          </View>
          <View style={styles.totalRow}>
            <Text style={styles.totalLabel}>
              Total: {singleEvent.summary.reduce((accumulator, item) => accumulator + item.vote, 0)}
            </Text>
          </View>
          <View style={styles.horizontalLine} />
        </View>
      </Page>
    </Document>
  );
};


const renderPDF = (type, info) => {
  switch (type) {
    case 'sales':
      return <PrintSales  />;
    case 'history':
      return <PrintHistory week={info} />;
    case 'votes':
      return <PrintAllVotes  />;
    case 'singleParticipant':
      return <PrintSingleParticipant  />;
    default:
      return null;
  }
};


// Function to send PDF Blob to the backend
// Function to send PDF Blob to the backend using fetch
const sendPDFToBackend = async (type,info) => {
  try {
    // Generate the PDF as a Blob
    // const blob = await pdf(<PrintAllVotes />).toBlob();
    const blob = await pdf(renderPDF(type,info)).toBlob();


    // Create FormData to append the PDF Blob
    const formData = new FormData();
    formData.append('file', blob, `${type}.pdf`);

    // Send the Blob to your backend using fetch
    const response = await fetch('/sendvaimail', {
      method: 'POST',
      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: formData,
    });

    if (response.ok) {
      toast.success("We have mailed you the Report")

      const result = await response.json();

      console.log('PDF successfully uploaded:', result);
    } else {
      console.error('Error uploading the PDF:', response.statusText);
    }
  } catch (error) {
    console.error('Error:', error);
  }
};

 
  return (

    <>



        <main class="relative h-screen overflow-hidden bg-gray-100  rounded-2xl">
        <AdminHeader page="show"></AdminHeader>

    <div class="flex items-start justify-between">
        
        <div class="flex flex-col w-full pl-0 md:p-4 md:space-y-4">
           
                <div class="h-screen pt-2 pb-24 pl-2 pr-2 overflow-auto md:pt-0 md:pr-0 md:pl-0">
                    <div class="flex flex-col flex-wrap sm:flex-row ">
                       
                        <div class="w-full sm:w-1/2 xl:w-1/3">

    
<div class="mx-0 mb-4 sm:ml-4 xl:mr-4">


<div class="relative overflow-hidden bg-white shadow-lg rounded-2xl w-full">
    <img src="/logo.png" alt="btc logo" class="absolute w-24 h-24 rounded-full opacity-50 -top-6 -right-6 md:-right-4"/>
    <div class="px-4 py-1 sm:p-6">
        <dl>
            <dt class="text-sm font-medium leading-5 text-gray-500 truncate">
            <img alt="profil" src={detail?detail.pic:""}class="mx-auto object-cover rounded-full h-20 w-20 "/>
            </dt>
            <dd class="mt-1 text-3xl font-semibold leading-9 text-gray-900">
            {detail?detail.name:""}
            </dd>
            <dd class="font-semibold text-gray-500">
                <span>
                {detail?detail.email:""}

                </span>
            </dd>
            <dd class="font-semibold text-gray-500">
                <span>
                {detail?detail.phone:""}

                </span>
            </dd>
        </dl>
    </div>
    <button type="button" class="py-2 px-4 flex justify-center items-center  bg-purple-500 hover:bg-purple-700   text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
    onClick={()=>{setAddShow(true)}}
    >
    <svg width="20" height="20" fill="currentColor" class="mr-2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
        <path d="M1344 1472q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm256 0q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm128-224v320q0 40-28 68t-68 28h-1472q-40 0-68-28t-28-68v-320q0-40 28-68t68-28h427q21 56 70.5 92t110.5 36h256q61 0 110.5-36t70.5-92h427q40 0 68 28t28 68zm-325-648q-17 40-59 40h-256v448q0 26-19 45t-45 19h-256q-26 0-45-19t-19-45v-448h-256q-42 0-59-40-17-39 14-69l448-448q18-19 45-19t45 19l448 448q31 30 14 69z">
        </path>
    </svg>
    Add Shows
</button>
    <div class="w-full h-2 bg-blue-200 rounded-full mt-0">
            <div class="w-full h-full text-xs text-center text-white bg-purple-500 rounded-full">
            </div>
        </div>






</div>
 
</div>

<ToastContainer/>


                            <div class="mx-0 mb-4 sm:ml-4 xl:mr-4">
                                <div class="w-full bg-white shadow-lg rounded-2xl ">
                                    <p class="p-4 font-bold text-black text-md ">
                                         Events
                                        <span class="ml-2 text-sm text-gray-500 ">
                                            ({data.length})
                                        </span>
                                        <span class="ml-2 text-sm text-gray-500 ">
                                          
{/* <button type="button" class="py-2 px-4 flex justify-center items-center  bg-purple-500 hover:bg-purple-600 focus:ring-red-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
<svg class="h-8 w-8 text-white"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z"/>
</svg>

    Add Event
</button> */}

                                        </span>
                                    </p>
                                    <ul>
                                        {data.map((item,index)=>{
                                            var date = new Date(item.date)
                                            //  console.log(date<today)
                                            return(
                                                <li key={index} class="flex items-center cursor-pointer justify-between py-3 text-gray-600 border-b-2 border-gray-100 "
                                                onClick={()=>{singlEvent(item._id)}}
                                                >
                                                <div class="flex items-center justify-start text-sm">
                                                    <span class="mx-4">
                                                        {index+1}
                                                    </span>
                                                    <span class="mx-4">
                                                        {item.name}
                                                    </span>
                                                    
                                                   
                                                    <span class="flex items-center ml-2 text-gray-400 lg:ml-6 ">
                    
                                                    <svg class="h-6 w-6 text-gray-300"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="4" y="5" width="16" height="16" rx="2" />  <line x1="16" y1="3" x2="16" y2="7" />  <line x1="8" y1="3" x2="8" y2="7" />  <line x1="4" y1="11" x2="20" y2="11" />  <line x1="11" y1="15" x2="12" y2="15" />  <line x1="12" y1="15" x2="12" y2="18" /></svg>
                </span>
                                                    <span class="text-gray-400">
                                                        {item.date.substring(0,10)}
                                                    </span>

                                                   
                                                </div>
                                                <svg width="20" height="20" fill="currentColor" viewBox="0 0 1024 1024" class={date>today?"mx-4 text-green-500":"mx-4 text-red-500"}>
                                                <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448s448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 0 1-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8l157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z" fill="currentColor">
                                                </path>
                                            </svg>
                                            </li>
                                            )
                                        })}
                                       
                                    </ul>
                                </div>
                            </div>





                          
                        </div>
                        {singleEvent?

<>
                        
                        <div class="w-full sm:w-1/2 xl:w-1/3">

                            <div class="mb-4">


                               
                                  
                                   <div class="p-4 bg-white shadow-lg rounded-2xl ">
                                   <div class="flex flex-wrap overflow-hidden">
                                       <div class="w-full rounded shadow-sm">
                                      
                                               <span className="flex items-center mb-4">
                                               <div class="text-xl font-bold text-left text-black ">
                                                   {singleEvent?singleEvent.name:""} 
                                               </div>  <svg  className="h-8 w-8 text-purple-500" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"
                                              onClick={()=>{setEditShow(true);setName(singleEvent.name);setPrice(singleEvent.price);setDescription(singleEvent.description);setCategory(singleEvent.category);setVenue(singleEvent.venue);setTime(singleEvent.time);setUrl(singleEvent.pic)}}
                                              >
                                                <path stroke="none" d="M0 0h24v24H0z" />
                                                <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />
                                                <line x1="13.5" y1="6.5" x2="17.5" y2="10.5" />
                                              </svg>
                                            </span>

                                           <div class="flex items-center justify-between mb-4">
                                               
                                               <div class="flex space-x-4">
                                                   <button class="p-2 text-white bg-purple-500 rounded-lg">
                                                   Week {singleEvent?singleEvent.week:""} 

                                                   </button>
                                                   
                                               </div>
                                               PDF:
                                               <PDFDownloadLink document={<PrintSales test={"TESt sf"}/>} fileName="Result.pdf">
      {({ blob, url, loading, error }) =>
        loading ? 'Loading report...' : <>
                  <button onClick={()=>{sendPDFToBackend("sales")}}>

        <svg class="h-8 w-8 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2" />  <path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4" />  <rect x="7" y="13" width="10" height="8" rx="2" /></svg>
         </button>
         </>
      }
    </PDFDownloadLink>



    Excel:
                                        <svg class="h-8 w-8 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"
                                        onClick={()=>{exportToExcel()}}
                                        >   <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2" />  <path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4" />  <rect x="7" y="13" width="10" height="8" rx="2" /></svg>

                                           </div>

                                          {singleEvent.weekdate?
                                            <p class="text-center">
                                            Week {singleEvent.week} Started at: {singleEvent.weekdate}
  
                                            </p>
                                          :<></>}
                                        
                                       
                                           <div class="-mx-2">
                                            

                                           <div class="relative w-full overflow-hidden bg-white shadow-lg ">
   <a href="#" class="block w-full h-full">
       <div class="flex items-center justify-between px-4 py-6 space-x-4">
           <div class="flex items-center">
               <span class="relative p-2 bg-purple-100 rounded-full">
               <svg class="h-6 w-6 text-purple-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
</svg>

               </span>
               <p class="ml-2 text-sm font-semibold text-gray-700 border-b border-gray-200 ">
               {singleEvent?singleEvent.venue:""}
               </p>
           </div>
           <div class="mt-6 text-xl font-bold text-black border-b border-gray-200 md:mt-0 ">
               Nu. {singleEvent?singleEvent.price:""}

               <span class="text-xs text-gray-400">
                   /Vote
               </span>
           </div>
       </div>
       <div class="w-full h-3 bg-gray-100">
           <div class=" h-full text-xs text-center text-white bg-green-400"
           
        //    style={{ width: singleEvent?`${(singleEvent.occupied*100)/singleEvent.seats}%` :""}}

           >
           </div>
       </div>
   </a>
</div>




<div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 my-4">


<div class="p-2 bg-white shadow-lg rounded-2xl ">
   <div class="flex items-center">
       <span class="relative p-2 bg-purple-200 rounded-xl">
       <svg class="h-6 w-6 text-purple-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="7" y="9" width="14" height="10" rx="2" />  <circle cx="14" cy="14" r="2" />  <path d="M17 9v-2a2 2 0 0 0 -2 -2h-10a2 2 0 0 0 -2 2v6a2 2 0 0 0 2 2h2" /></svg>
       </span>
       <p class="ml-2 text-black text-md ">
           Earning This Week  
       </p>
   </div>
  
   <div class="flex flex-col justify-start">
       <p class=" text-4xl font-bold text-left text-gray-700 ">
       {/* {singleEvent?singleEvent.price*singleEvent.occupied:""} */}
           <span class="text-sm">
         Nu.  {weeklyvote*singleEvent.price}
           </span>
       </p>
  
   </div>
  
 
</div>

<div class="p-2 bg-white shadow-lg rounded-2xl ">
<div class="flex items-center">
       <span class="relative p-2 bg-purple-200 rounded-xl">
       <svg class="h-6 w-6 text-purple-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"/>
</svg>

       </span>
       <p class="ml-2 text-black text-md ">
           Votes This Week  
       </p>
   </div>
  
   <div class="flex flex-col justify-start">
       <p class=" text-4xl font-bold text-left text-gray-700 ">
       {/* {singleEvent?singleEvent.price*singleEvent.occupied:""} */}
           <span class="text-sm">
           {weeklyvote}
           </span>
       </p>
  
   </div>
  
 
</div>



<div class="p-4 bg-white shadow-lg rounded-2xl ">
   <div class="flex items-center">
       <span class="relative p-1 bg-purple-200 rounded-xl">
       <svg class="h-6 w-6 text-purple-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"/>
</svg>

       </span>
       <p class="ml-2 text-black text-md ">
           Participants
       </p>
   
   </div>
   


   <button type="button" class="mt-2 py-2 px-4 flex justify-center items-center  bg-purple-600 hover:bg-purple-700   text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
   onClick={()=>{setAddStock(true)}}
   >
   <svg class="h-6 w-6 text-gray-100 mr-2"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="9" cy="7" r="4" />  <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />  <path d="M16 11h6m-3 -3v6" /></svg>
    Add Participant
</button>


</div>
<div class="p-4 bg-white shadow-lg rounded-2xl ">
   <div class="flex items-center">
       <span class="relative p-1 bg-purple-200 rounded-xl">
       <svg class="h-6 w-6 text-purple-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"/>
</svg>

       </span>
       <p class="ml-2 text-black text-md ">
           Stop Voting
       </p>
   
   </div>
   


   <button type="button" class="py-2 px-4 mb-2 flex justify-center items-center  bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
onClick={()=>{if(window.confirm('Are you sure you want to start new Week?')) resetWeek(singleEvent._id)}}                           

>
<svg class="h-8 w-8 text-white mr-2"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M15 10l-4 4l6 6l4 -16l-18 7l4 2l2 6l3 -4" /></svg>
    Start Voting for Next Week
</button>

</div>

<div class="p-4 bg-white shadow-lg rounded-2xl ">
   <div class="flex items-center">
       <span class="relative p-1 bg-purple-200 rounded-xl">
       <svg class="h-6 w-6 text-purple-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"/>
</svg>

       </span>
       <p class="ml-2 text-black text-md ">
           Live Stream
       </p>
   
   </div>
   


   <button type="button" class="mt-2 py-2 px-4 flex justify-center items-center  bg-purple-600 hover:bg-purple-700   text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
   onClick={()=>{setLive(true)}}
   >
   <svg class="h-6 w-6 text-gray-100 mr-2"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z"/>
</svg>

    Add Live Streaming
</button>


</div>
<div class="p-4 bg-white shadow-lg rounded-2xl ">
   <div class="flex items-center">
       <span class="relative p-1 bg-purple-200 rounded-xl">
       <svg class="h-6 w-6 text-purple-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"/>
</svg>

       </span>
       <p class="ml-2 text-black text-md ">
           End The Show
       </p>
   
   </div>
   


   <button type="button" class="mt-2 py-2 px-4 flex justify-center items-center  bg-red-600 hover:bg-red-700   text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
   onClick={()=>{addStatus()}}
   >
   <svg class="h-6 w-6 text-gray-100 mr-2"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z"/>
</svg>

    End Show
</button>


</div>


</div>



                                           </div>
                                       </div>
                                   </div>
                               </div>

                                  




         
                               

                                
                            </div>
                            
                       
                       
                       
                       
                        </div>



                        <div class="mx-0 mb-4 sm:ml-4 xl:mr-4">
                                <div class="w-full p-4 bg-white shadow-lg rounded-2xl mb-2 ">
                                    <p class="font-bold text-black text-md ">
                                        Vote History 


                                    </p>
        

<div class="relative w-full px-4 py-6 bg-white shadow-lg dark:bg-gray-800 ">
    <p class="text-sm font-semibold text-gray-700 border-b border-gray-200 w-max dark:text-white">
        Total Votes
    </p>
    <div className=' absolute top-0 right-0'>


{/* <PDFDownloadLink document={<PrintAllVotes test={"TESt sf"}/>} fileName="AllVotes.pdf">
{({ blob, url, loading, error }) =>
loading ? 'Loading report...' : <>
<svg class="h-8 w-8 text-purple-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M14 3v4a1 1 0 0 0 1 1h4" />  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />  <line x1="12" y1="11" x2="12" y2="17" />  <polyline points="9 14 12 17 15 14" /></svg></>
}
</PDFDownloadLink> */}


<PDFDownloadLink document={<PrintAllVotesmob />} fileName="AllVotgges.pdf">
      {({ blob, url, loading, error }) =>
        loading ? (
          'Loading ddo...'
        ) : (
          <button onClick={()=>{sendPDFToBackend("votes")}}>
            <svg className="h-8 w-8 text-purple-500" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" />
              <path d="M14 3v4a1 1 0 0 0 1 1h4" />
              <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
              <line x1="12" y1="11" x2="12" y2="17" />
              <polyline points="9 14 12 17 15 14" />
            </svg>
          </button>
        )
      }
    </PDFDownloadLink>
</div>
    <div class="flex items-end my-6 space-x-2">
        <p class="text-5xl font-bold text-black dark:text-white">
            {singleEvent.summary.reduce((accumulator, item) => accumulator + item.vote, 0)}
        </p>
        <span class="flex items-center text-xl font-bold text-green-500">
            <svg width="20" fill="currentColor" height="20" class="h-3" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                </path>
            </svg>
            22%
        </span>
    </div>
    <div class="dark:text-white md:h-96 h-64 overflow-auto">
      {singleEvent.summary.map(item=>{

        return(
          <div class="flex items-center justify-between pb-2 mb-2 space-x-12 text-sm border-b border-gray-200 md:space-x-24">
            <p>
                Week : {item.week}
            </p>
            <div class="flex items-end text-xs">
                {item.vote} votes 
                <span class="flex items-center">
                                               <PDFDownloadLink 
                                               
                                               document={<PrintHistory  week={item.week} />}
                                               
                                               fileName="Result.pdf">
      {({ blob, url, loading, error }) =>
        loading ? 'Loading report...' : <>
                  <button onClick={()=>{sendPDFToBackend("history",item.week)}}>

        
        <svg class="h-8 w-8 text-blue-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2" />  <path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4" />  <rect x="7" y="13" width="10" height="8" rx="2" /></svg>
       </button>
         </>
      }
    </PDFDownloadLink>

                </span>
            </div>
        </div>
        )
      })}
        
     
    </div>
</div>

     



                            {/* {participants?participants.filter(participant => participant.status !== 'Eleminated').map(participant=>{

                                return(
                                    <div class="p-4 bg-white shadow-lg rounded-2xl w-80 dark:bg-gray-800">
                                    <div class="flex flex-row items-start gap-4">
                                        <img src={participant.pic} class="rounded-lg w-28 h-28"/>
                                        <div class="flex flex-col justify-between w-full h-28">
                                            <div>
                                                <p class="text-xl font-medium text-gray-800 dark:text-white">
                                                    {participant.name}
                                                </p>
                                                <p class="text-xs text-gray-400">
                                                    {participant.phone}
                                                </p>
                                            </div>
                                            <div class="w-full p-2 bg-blue-100 rounded-lg dark:bg-white">
                                                <div class="flex items-center justify-between text-xs text-gray-400 dark:text-black">
                                                    <p class="flex flex-col">
                                                        Weekly Vote
                                                        <span class="font-bold text-black dark:text-indigo-500">
                                                            {participant.votes}
                                                        </span>
                                                    </p>
                                                    <p class="flex flex-col">
                                                        Followers
                                                        <span class="font-bold text-black dark:text-indigo-500">
                                                            455
                                                        </span>
                                                    </p>
                                                    <p class="flex flex-col">
                                                        Rating
                                                        <span class="font-bold text-black dark:text-indigo-500">
                                                            9.3
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex items-center justify-between gap-4 mt-6">
                                        <button type="button" class="w-1/2 px-4 py-2 text-base bg-red-500 border rounded-lg text-white hover:bg-red-700 "
                                        
                                        onClick={()=>{if(window.confirm('Are you sure you want to Eliminate?'))   eleminateParticipant(participant._id)}}                           

                                        >
                                            Eliminate
                                        </button>
                                       
                                    </div>
                                </div>
                                
                                
                                )
                            }):<></>} */}

          
                                </div>
                            </div>

{/* 
<div class="container max-w-3xl px-4 mx-auto sm:px-8">
  <div class="grid grid-cols-8 gap-2">
    <span class="px-4 py-2  text-center rounded-full text-indigo-500 border border-indigo-500 undefined ">
    All
</span>
    <span class="px-4 py-2  text-center rounded-full text-indigo-500 border border-indigo-500 undefined ">
    Active
</span>
    <span class="px-4 py-2  text-center rounded-full text-indigo-500 border border-indigo-500 undefined ">
    Eleminated
</span>
  </div>

    <div class="py-2">
        <div class="px-4 py-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">



            <div class="inline-block min-w-full overflow-hidden rounded-lg shadow">
                <table class="min-w-full leading-normal">
                    <thead>
                        <tr>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Participant
                            </th>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Votes
                            </th>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Code
                            </th>
                            <th scope="col" class="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                      
                    {participants?participants.filter(participant => participant.status !== 'Eleminated').map(participant=>{

return(
  <tr>
                            <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                <div class="flex items-center">
                                    <div class="flex-shrink-0">
                                        <a href="#" class="relative block">
                                            <img alt="profil" src={participant.pic} class="mx-auto object-cover rounded-full h-10 w-10 "/>
                                        </a>
                                    </div>
                                    <div class="ml-3">
                                        <p class="text-gray-900 whitespace-no-wrap">
                                            {participant.name}
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                <p class="text-gray-900 whitespace-no-wrap">
                                    {participant.votes}
                                </p>
                            </td>
                            <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                <p class="text-gray-900 whitespace-no-wrap">
                                   {participant.code}
                                </p>
                            </td>
                            <td class="px-5 py-5 text-sm bg-white border-b border-gray-200">
                            <button type="button" class="w-1/2 px-4 py-2 text-base bg-red-500 border rounded-lg text-white hover:bg-red-700 "
                                        
                                        onClick={()=>{if(window.confirm('Are you sure you want to Eliminate?'))   eleminateParticipant(participant._id)}}                           

                                        >
                                            Eliminate
                                        </button>
                            </td>
                        </tr>
                      


)
}):<></>}

                      
                     
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div> */}

<div className='grid grid-cols-1 md:grid-cols-2'>
<div className="container max-w-3xl px-4 mx-auto sm:px-8">
      <div className="grid md:grid-cols-6 grid-cols-3 gap-2">
        <span
          className={`px-4 py-2 text-center rounded-full text-indigo-500 border border-indigo-500 ${filter === 'All' ? 'bg-indigo-500 text-white' : ''}`}
          onClick={() => setFilter('All')}
        >
          All
        </span>
        <span
          className={`px-4 py-2 text-center rounded-full text-indigo-500 border border-indigo-500 ${filter === 'Active' ? 'bg-indigo-500 text-white' : ''}`}
          onClick={() => setFilter('Active')}
        >
          Active
        </span>
        <span
          className={`px-4 py-2 text-center rounded-full text-indigo-500 border border-indigo-500 ${filter === 'Eleminated' ? 'bg-indigo-500 text-white' : ''}`}
          onClick={() => setFilter('Eleminated')}
        >
          Eliminated
        </span>
      </div>

      <div className="py-2">
        <div className="px-4 py-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">
          <div className="inline-block min-w-full overflow-hidden rounded-lg shadow">
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th scope="col" className="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                    Participant
                  </th>
                  <th scope="col" className="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                    Votes
                  </th>
                  <th scope="col" className="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                    Code
                  </th>
                  <th scope="col" className="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                    Status
                  </th>
                  <th scope="col" className="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                    Report
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredParticipants.length > 0 ? (
                  filteredParticipants.map(participant => (
                    <tr key={participant._id}>
                      <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            <a href="#" className="relative block">
                              <img
                                alt="profil"
                                src={participant.pic}
                                className="mx-auto object-cover rounded-full h-10 w-10"
                              />
                            </a>
                          </div>
                          <div className="ml-3">
                            <p className="text-gray-900 whitespace-no-wrap">{participant.name}</p>
                          </div>
                        </div>
                      </td>
                      <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                        <p className="text-gray-900 whitespace-no-wrap">{participant.votes}</p>
                      </td>
                      <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                        <p className="text-gray-900 whitespace-no-wrap">{participant.code}</p>
                      </td>
                      <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                        {participant.status === 'Active' && (
                          <button
                            type="button"
                            className="md:w-full w-full px-4 py-2 text-base bg-red-500 border rounded-lg text-white hover:bg-red-700"
                            onClick={() => {
                              if (window.confirm('Are you sure you want to Eliminate?')) {
                                // Call the eliminateParticipant function here
                                eleminateParticipant(participant._id,"Eleminated");
                              }
                            }}
                          >
                            Eliminate
                          </button>
                        )}
                        {participant.status === 'Eleminated' && <span className="text-red-500"    onClick={() => {
                              if (window.confirm('Are you sure you want to Activate?')) {
                                // Call the eliminateParticipant function here
                                eleminateParticipant(participant._id,"Active");
                              }
                            }}>Eliminated</span>}
                      </td>
                      <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                        <p className="text-gray-900 whitespace-no-wrap">

                        <svg class="h-8 w-8 text-purple-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor"
                        onClick={()=>{setShowParticipantReport(true);setSingleParticipant(participant)}}
                        >
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
</svg>

                        </p>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" className="px-5 py-5 text-sm bg-white border-b border-gray-200 text-center">
                      No participants found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    {ShowParticipantReport?<>
    
    
<div class="bg-white shadow-lg rounded-2xl md:w-9/12 dark:bg-gray-800">
    <img alt="profil" src={singleEvent.pic?singleEvent.pic:""} class="w-full mb-4 rounded-t-lg h-28"/>
    <div class="flex flex-col items-center justify-center p-4 -mt-16">

  
        <a href="#" class="relative block">
            <img alt="profil" src={singleParticipant.pic?singleParticipant.pic:""} class="mx-auto object-cover rounded-full h-16 w-16  border-2 border-white dark:border-gray-800"/>
        </a>
        <p class="mt-2 text-xl font-medium text-gray-800 dark:text-white">
            {singleParticipant.name}
        </p>
        
        <p class={`p-2 px-4 text-xs text-white ${singleParticipant.status=="Active"?"bg-green-500" :"bg-red-500 "} rounded-full`}>
            {singleParticipant.status}
        </p>
        <div class="w-80 p-2 mt-4 rounded-lg">
       
            <div class="flex items-center justify-between text-sm text-gray-600 dark:text-gray-200">
                <p class="flex flex-col">
                    Code
                    <span class="font-bold text-black dark:text-white">
                        {singleParticipant.code}
                    </span>
                </p>
                <p class="flex flex-col">
                    Address
                    <span class="font-bold text-black dark:text-white">
                        {singleParticipant.dzongkhag}
                    </span>
                </p>
                <p class="flex flex-col">
                    Votes
                    <span class="font-bold text-black dark:text-white">
                        {singleParticipant.votes}
                    </span>
                </p>
            </div>
        </div>
    </div>

<div class="relative w-full px-4 py-6 bg-white shadow-lg dark:bg-gray-800">
  
    <p class="text-sm text-center font-semibold text-gray-700 border-b border-gray-200 w-max dark:text-white">
        Vote History
    </p>
    <div className='  top-0 right-0'>


<PDFDownloadLink document={<PrintSingleParticipant test={"TESt sf"}/>} fileName="PArticipant.pdf">
{({ blob, url, loading, error }) =>
loading ? 'Loading report...' : <>


<button onClick={()=>{sendPDFToBackend("singleParticipant")}}>

<svg class="h-8 w-8 text-purple-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M14 3v4a1 1 0 0 0 1 1h4" />  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />  <line x1="12" y1="11" x2="12" y2="17" />  <polyline points="9 14 12 17 15 14" /></svg>
</button>

</>
}
</PDFDownloadLink>
</div>
    <div class="flex items-end my-6 space-x-2">
        <p class="text-5xl font-bold text-black dark:text-white">
        {singleParticipant.weeklyvotes.reduce((accumulator, item) => accumulator + item.votes, 0)}

        </p>
        <span class="flex items-center text-xl font-bold text-green-500">
            <svg width="20" fill="currentColor" height="20" class="h-3" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z">
                </path>
            </svg>
            Votes
        </span>
    </div>
    <div class=" overflow-auto max-h-32">

          {singleParticipant.weeklyvotes.map(item=>{
            return(
              <div class="flex items-center justify-between pb-2 mb-2 space-x-12 text-sm border-b border-gray-200 md:space-x-24">
              <p>
                  Week: {item.week}
              </p>
              <div class="flex items-end text-xs">
                  {item.votes} Votes
                
              </div>
          </div>
            )
          })}
       
      
    </div>
</div>

    
</div>

    </>:<></>}


</div>




                            </>:<></>}

                            
                    </div>
                </div>

                {
addStock? 
(
  <>
    <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto   relative w-auto  mx-auto ">
        {/*content*/}
        <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex rounded-full items-start  p-2 ">
            
            <button
              className="p-1 ml-auto rounded-full bg-red-500 text-white text-3 "
              onClick={() => setAddStock(false)}
            >
<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>            </button>
          </div>
          {/*body*/}
           



<div class="flex w-full max-w-sm content-center items-center ">
    <div class="w-full max-w-2xl px-5 py-6 m-auto  bg-white rounded-lg shadow dark:bg-gray-800">
       
    <div class="grid max-w-xl grid-cols-2 gap-4 m-auto">

       
        <div className="py-1">
          <span className="px-1 text-sm text-gray-600">Name</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Name"
            value={name}
            onChange={(e)=>setName(e.target.value)}
            >

            </input>
          </div>
        
          <div className="py-1">
          <span className="px-1 text-sm text-gray-600">Phone</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Phone No."
            value={phone}
            onChange={(e)=>setPhone(e.target.value)}
            >

            </input>
          </div>
          </div>
          <div className="py-1">
          <span className="px-1 text-sm text-gray-600">Code</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Code"
            value={code}
            onChange={(e)=>setCode(e.target.value)}
            >

            </input>
          </div>
      
                        
         
          <div className="py-1">
  <span className="px-1 text-sm text-gray-600">Dzongkhag</span>
    <select  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
  bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
  
    placeholder="Zone"
    value={zone}
    onChange={(e)=>setZone(e.target.value)}
    >

    <option value="Bumthang">Bumthang</option>  
    <option value="Chhukha">Chhukha</option>  
    <option value="Dagana">Dagana</option>  
    <option value="Gasa">Gasa</option>  
    <option value="Haa">Haa</option>  
    <option value="Lhuentse">Lhuentse</option>  
    <option value="Mongar">Mongar</option>  
    <option value="Paro">Paro</option>  
    <option value="Pemagatshel">Pemagatshel</option>  
    <option value="Punakha">Punakha</option>  
    <option value="Samdrup Jongkhar">Samdrup Jongkhar</option>  
    <option value="Samtse">Samtse</option>  
    <option value="Sarpang">Sarpang</option>  
    <option value="Thimphu">Thimphu</option>  
    <option value="Trashigang">Trashigang</option>  
    <option value="Trashiyangtse">Trashiyangtse</option>  
    <option value="Trongsa">Trongsa</option>  
    <option value="Tsirang">Tsirang</option>  
    <option value="Wangdue Phodrang">Wangdue Phodrang</option>  
    <option value="Zhemgang">Zhemgang</option>  



    
    {/* <option>Hongkong-Market</option>
<option>Vegitable-Market</option> */}
    
    </select>
       

            {/* <div className="py-1">
            <span className="px-1 text-sm text-gray-600">Zone</span>
              <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
            bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
            
              placeholder="CID"
              value={zone}
              onChange={(e)=>setZone(e.target.value)}
              >

              </input>
            </div> */}
            </div>

       
       
        
       
        
          <div className="col-span-4 ">
                       <label className="flex flex-col items-center  bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue-300 hover:text-white">
        <svg className="w-6 h-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
        </svg>
        <span className="mt-2 text-base leading-normal">Upload</span>
        <input type='file' className="hidden" 
        onChange={uploadppt}
        />
        
    </label>



    {progress?         <span className=" text-base leading-normal"> Uploading...</span>:   <span className=" text-base leading-normal"> Upload Photo </span>
         }
                <input type='file' className="hidden"  required
                onChange={uploadppt}
                />
                
        
            {progress?
                <svg class="h-12 w-12 text-blue-500 animate-spin"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" />  <path d="M11 19.95a8 8 0 0 1 -5.3 -12.8" stroke-dasharray=".001 4.13" /></svg>
            :<></>}
   

{url?<img className=" w-28 h-28  rounded-lgflex items-center " src={url} ></img>:null
}
    
                               </div> 

              <br/>
              <div class="col-span-2 text-right">
                        <button  class="py-2 px-4  bg-yellow-300 hover:bg-orange-400 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                        

                        onClick={()=>addParticipant()}
                        >
                            Add Participants
                        </button>


                    </div>
               
                
                </div>
        </div> 
         
 




         
        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </>
)


:null

}
                {
live? 
(
  <>
    <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto   relative w-auto  mx-auto ">
        {/*content*/}
        <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex rounded-full items-start  p-2 ">
            
            <button
              className="p-1 ml-auto rounded-full bg-red-500 text-white text-3 "
              onClick={() => setLive(false)}
            >
<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>            </button>
          </div>
          {/*body*/}
           



<div class="flex w-full max-w-sm content-center items-center ">
    <div class="w-full max-w-2xl px-5 py-6 m-auto  bg-white rounded-lg shadow dark:bg-gray-800">
       
  
          <div className="py-1">
          <span className="px-1 text-sm text-gray-600">Paste Link (Youtube, Facebook, Tiktok)</span>
            <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
          
            placeholder="Link"
            value={youtube}
            onChange={(e)=>setYoutube(e.target.value)}
            >

            </input>
          </div>
      
                        
         
          

       
       
        
       
        
              <br/>
              <div class="col-span-2 text-right">
                        <button  class="py-2 px-4  bg-yellow-300 hover:bg-orange-400 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                        

                        onClick={()=>addLive()}
                        >
                            Add Live Streaming
                        </button>


                    </div>
            
                
                </div>
        </div> 
         
 




         
        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </>
)


:null

}
                
                {
addShow? 
(
  <>
    <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto   relative w-auto  mx-auto ">
        {/*content*/}
        <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex rounded-full items-start  p-2 ">
            
            <button
              className="p-1 ml-auto rounded-full bg-red-500 text-white text-3 "
              onClick={() => setAddShow(false)}
            >
<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>            </button>
          </div>
          {/*body*/}
           



<div class="flex w-full max-w-sm content-center items-center ">
    <div class="w-full max-w-2xl px-5  m-auto  bg-white rounded-lg shadow dark:bg-gray-800">
    
    <div>


<div className="">
<span className="px-1 text-sm text-gray-600">Name</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Name"
value={name}
onChange={(e)=>setName(e.target.value)}
>

</input>
</div>

<div className="">
<span className="px-1 text-sm text-gray-600">Price Per Vote</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Price for single vote "
value={price}
onChange={(e)=>setPrice(e.target.value)}
>

</input>
</div>





<div class="grid max-w-xl grid-cols-2 gap-4 ">




<div className="">
<span className="px-1 text-sm text-gray-600">Date</span>
<DatePicker className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

          selected={date} onChange={date => setDate(date)} />

</div>


<div className="">
<span className="px-1 text-sm text-gray-600">Show Time</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Eg. 7:30 PM"
value={time}
onChange={(e)=>setTime(e.target.value)}
>

</input>
</div>

</div>





<div class="grid max-w-xl grid-cols-2 gap-4 ">


<div className="">

<label class="text-gray-700" for="animals">
    Category 
    <select id="animals" class="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" name="animals"
    
    value={category}
    onChange={(e)=>setCategory(e.target.value)}
    
    >
        <option value="">
            Select an option
        </option>
        <option value="Dance">
            Dance
        </option>
        <option value="Music">
            Music
        </option>
        <option value="Talent Show">
            Talent Show
        </option>
        <option value="Networking event">
            Networking event
        </option>
     
        
       
    </select>
</label>

</div>


<div className="">
<span className="px-1 text-sm text-gray-600">Channel</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Venue"
value={venue}
onChange={(e)=>setVenue(e.target.value)}
>

</input>
</div>



</div>



<div className="py-1">
<span className="px-1 text-sm text-gray-600">Description</span>
<textarea  type="text" className="space-y-10 text-md block px-3 py-2 rounded-lg w-full h-32
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Event Description"
value={description}
onChange={(e)=>setDescription(e.target.value)}
>

</textarea>
</div>


<div className="col-span-4 w-full mb-5 ">
                        <label className="flex flex-col items-center  bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue-300 hover:text-white">
         <svg className="w-6 h-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
             <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
         </svg>
        <span className="mt-2 text-base leading-normal">Upload</span>
        <input type='file' className="hidden" 
        onChange={uploadppt}
        />
        
    </label>
                                   </div>
 
     
     
                                   {progress?
                <svg class="h-12 w-12 text-blue-500 animate-spin"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" />  <path d="M11 19.95a8 8 0 0 1 -5.3 -12.8" stroke-dasharray=".001 4.13" /></svg>
            :<></>}
   

{url?<img className=" w-28 h-28  rounded-lgflex items-center " src={url} ></img>:null
}


  <div className="w-full px-3 mb-5">

    
  
      <button className="block w-full max-w-xs mx-auto bg-blue-300 hover:bg-blue-400 focus:bg-blue-400 text-white rounded-lg px-3 py-3 font-semibold"
                  onClick={() => {setAddShow(false);postEvent()}}

      > Add Show</button>
      <button className="block w-full max-w-xs mx-auto bg-red-300 hover:bg-red-400 focus:bg-blue-400 text-white rounded-lg px-3 py-3 font-semibold mt-4"
                  onClick={() => {setAddShow(false)}}

      > Cancel</button>
                  


    </div>



    
</div>
       
    
    </div>
        </div> 
         
 




         
        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </>
)


:null

}
                {
editShow? 
(
  <>
    <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto   relative w-auto  mx-auto ">
        {/*content*/}
        <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex rounded-full items-start  p-2 ">
            
            <button
              className="p-1 ml-auto rounded-full bg-red-500 text-white text-3 "
              onClick={() => setEditShow(false)}
            >
<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>            </button>
          </div>
          {/*body*/}
           



<div class="flex w-full max-w-sm content-center items-center ">
    <div class="w-full max-w-2xl px-5  m-auto  bg-white rounded-lg shadow dark:bg-gray-800">
    
    <div>


<div className="">
<span className="px-1 text-sm text-gray-600">Name</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Name"
value={name}
onChange={(e)=>setName(e.target.value)}
>

</input>
</div>

<div className="">
<span className="px-1 text-sm text-gray-600">Price Per Vote</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Price for single vote "
value={price}
onChange={(e)=>setPrice(e.target.value)}
>

</input>
</div>





<div class="grid max-w-xl grid-cols-2 gap-4 ">




<div className="">
<span className="px-1 text-sm text-gray-600">Date</span>
<DatePicker className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

          selected={date} onChange={date => setDate(date)} />

</div>


<div className="">
<span className="px-1 text-sm text-gray-600">Show Time</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Eg. 7:30 PM"
value={time}
onChange={(e)=>setTime(e.target.value)}
>

</input>
</div>

</div>





<div class="grid max-w-xl grid-cols-2 gap-4 ">


<div className="">

<label class="text-gray-700" for="animals">
    Category 
    <select id="animals" class="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" name="animals"
    
    value={category}
    onChange={(e)=>setCategory(e.target.value)}
    
    >
        <option value="">
            Select an option
        </option>
        <option value="Dance">
            Dance
        </option>
        <option value="Music">
            Music
        </option>
        <option value="Talent Show">
            Talent Show
        </option>
        <option value="Networking event">
            Networking event
        </option>
     
        
       
    </select>
</label>

</div>


<div className="">
<span className="px-1 text-sm text-gray-600">Channel</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Venue"
value={venue}
onChange={(e)=>setVenue(e.target.value)}
>

</input>
</div>



</div>



<div className="py-1">
<span className="px-1 text-sm text-gray-600">Description</span>
<textarea  type="text" className="space-y-10 text-md block px-3 py-2 rounded-lg w-full h-32
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Event Description"
value={description}
onChange={(e)=>setDescription(e.target.value)}
>

</textarea>
</div>


<div className="col-span-4 w-full mb-5 ">
                        <label className="flex flex-col items-center  bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue-300 hover:text-white">
         <svg className="w-6 h-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
             <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
         </svg>
        <span className="mt-2 text-base leading-normal">Upload</span>
        <input type='file' className="hidden" 
        onChange={uploadppt}
        />
        
    </label>
                                   </div>
 
     
     
                                   {progress?
                <svg class="h-12 w-12 text-blue-500 animate-spin"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" />  <path d="M11 19.95a8 8 0 0 1 -5.3 -12.8" stroke-dasharray=".001 4.13" /></svg>
            :<></>}
   

{url?<img className=" w-28 h-28  rounded-lgflex items-center " src={url} ></img>:null
}


  <div className="w-full px-3 mb-5">

    
  
      <button className="block w-full max-w-xs mx-auto bg-blue-300 hover:bg-blue-400 focus:bg-blue-400 text-white rounded-lg px-3 py-3 font-semibold"
                  onClick={() => {setEditShow(false);updateEvent()}}

      > Update Show</button>
      <button className="block w-full max-w-xs mx-auto bg-red-300 hover:bg-red-400 focus:bg-blue-400 text-white rounded-lg px-3 py-3 font-semibold mt-4"
                  onClick={() => {setEditShow(false)}}

      > Cancel</button>
                  


    </div>



    
</div>
       
    
    </div>
        </div> 
         
 




         
        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </>
)


:null

}
                

            </div>
            
        </div>
        
    </main>


    

    </>
    
  );
}
 
export default ShowDash;