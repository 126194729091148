import React, {useContext, useRef, useEffect, useState} from 'react'
import {Link,useNavigate,useParams} from 'react-router-dom'
import DatePicker from 'react-datepicker'
import {UserContex} from '../App'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import NavBar from './nav'
import Header from '../components/Header'
import Footer from '../components/Footer';
// import Footer from './Footer'


const AllEvents = ()=>{


  const history = useNavigate()
  const {state, dispatch}= useContext(UserContex)

  const  [data, setData]= useState([])

  const [search,setSearch] = useState("")
  const  [cart, setCart]= useState([])

  const [addStock,setAddStock]= useState(false)
  const [editStock,setEditStock]= useState(false)

 

  const [checkOut,setCheckOut]= useState(false)
  const[address,setAddress]= useState("")
  const[allAddress,setAllAddress]= useState([])
  const[date,setDate]= useState(new Date().setHours(0,0,0,0))

  const [location,setLocation] = useState([])  

  const[progress,setProgress]= useState(false)



  useEffect(  ()=>{

    

  //   fetch(`/getallevents`,{
  //     headers:{
  //         Authorization: "Bearer " +localStorage.getItem("jwt")
          
  //     }
  // }).then(res=>res.json())
  // .then(result=>{
  //     setData(result.events)
  //     setLocation(getAllVenues(result.events))
      
  // })


  fetch('/getallevents', {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("jwt")
    }
  })
    .then(res => res.json())
    .then(result => {
      const filteredEvents = result.events.reverse().filter(event => !event.membership);
      setData(filteredEvents);
      setLocation(getAllVenues(filteredEvents))

    })
    .catch(err => console.error(err));



  fetch(`/getconsumerprofile`,{
    method:"get",
    headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt"),
        "Content-Type":"application/json"

        
    },
 
}).then(res=>res.json())
.then(result=>{

  // console.log("DSF",result.consumer[0].address)
    // setOrderHistoryData(result.orders)
    // setData(result.consumer)
    setAllAddress(result.consumer[0].address)
})


  },[])

  
  useEffect(() => {
    // This effect uses the `value` variable,
    // so it "depends on" `value`.

    const l = JSON.parse(localStorage.getItem('cart'))

    if(l){
      setCart(l)
    }


    // setSearch(transcript)
    // fetchPost(transcript)
  }, [])



  const fetchPost=(query)=>{


    setSearch(query)

    fetch('/searchall-events',{

      method:"post",
      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt"),

        "Content-Type":"application/json"

      },
      body:JSON.stringify({
        query,
      })

    }).then(res=> res.json())
      .then(results=>{
        
        setData(results.events)
      })


  }





  const getAllVenues = (events) => {
    // Use a Set to store unique venues
    const uniqueVenues = new Set();
  
    // Iterate through the events and add each venue to the Set
    events.forEach((event) => {
      uniqueVenues.add(event.venue);
    });
  
    // Convert the Set back to an array
    return Array.from(uniqueVenues);
  };
  


return(
<main class="bg-white h-screen  relative overflow-x-hidden">
    <div class="flex items-start justify-between">
       
        <div class="flex flex-col w-full ">

            

            <Header></Header>


            
{/* 
            <div>
      <p>Microphone: {listening ? 'on' : 'off'}</p>
      <button onClick={SpeechRecognition.startListening}>Start</button>
      <button onClick={SpeechRecognition.stopListening}>Stop</button>
      <button onClick={resetTranscript}>Reset</button>
      <p>{transcript}</p>
    </div> 
    
    
    */}


         <div class=" md:h-96 h-46  w-full   bg-cover bg-center bg-no-repeat" style={{ 
      backgroundImage: `url("https://img.freepik.com/free-photo/3d-glasses-with-popcorns-clapperboard-concrete-backdrop_23-2148188183.jpg?t=st=1721912970~exp=1721916570~hmac=91dfbb5939bb65c7c43eeebb3d607795fc7a88c44901070a0cc09ea783dedb12&w=1800")` 
    }}>

                <div class=" mx-8 sm:mx-20 my-24 sm:my-32 ">

            <div className="w-full bg-white border-none rounded-lg h-16 bg-opacity-80 hover:bg-opacity-100 p-4  focus:outline-none focus:border-white active:outline-none flex focus:outline-none justify-between items-center relative">
 <input type="text" name="search" id="search" placeholder={  'Search'} 
        className=" w-full bg-white border-none rounded-lg h-16 bg-opacity-10 hover:bg-opacity-100 p-4  focus:outline-none focus:border-none active:outline-none"
        
        value= {search} onChange={(e)=>fetchPost(e.target.value)}
        />
 <button type="submit" className="ml-1 outline-none border-none focus:border-none  active:outline-none">
   <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
        viewBox="0 0 24 24" className="w-6 h-6 text-purple-500">
     <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
   </svg>
 </button>


</div>


      
            </div>
            </div>







        </div>
     

    </div>


    <div class="flex flex-col bg-white  ">

<div
  class="flex overflow-x-scroll   hide-scroll-bar"
>
  <div
    class="flex flex-nowrap md:h-20 h-12    ml-4 "
  >
     <div class="px-1 md:py-6 py-2">
                
    
<select id="animals" class="px-4 py-2 flex items-center text-base rounded-full text-purple-500 border border-purple-500 undefined" name="animals"
    
    value= {search} onChange={(e)=>fetchPost(e.target.value)}

    
    >
        <option class="shadow border select-none cursor-pointer bg-white  rounded-md flex flex-1 items-center p-4" value="">
            Search by Venue
        </option>
        {location.length>0? location.map(item=>{
return(  <option value={item}>
  {item}
</option>)
        }):<></>}
     
     
        
       
    </select>

                    </div>
                   
  </div>
</div>
</div> 









<div class="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-4 place-items-center">

        
        {/* <div className="grid  grid-cols-1  md:grid-cols-4  gap-4   bg-white place-items-center "> */}
          



        {data.map(item=>{


const itemDateObj = new Date(item.date);

itemDateObj.setHours(0, 0, 0, 0);


return(
   


       
  <div class="relative overflow-hidden rounded-xl  shadow-lg cursor-pointer h-90  w-80  ">
      {!(itemDateObj>=date)?
                         <img src="/ended.png" alt="btc logo" class="absolute w-24 h-24 rounded-full opacity-50 -bottom-6 -right-6 md:-right-4"/>

            :<></>}
  <a href={`/single/${item._id}`} class="block w-full h-full">
  <img alt="blog photo" src={item.pic?item.pic:"https://images.unsplash.com/photo-1470229722913-7c0e2dbbafd3?auto=format&fit=crop&q=80&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&w=1470"} class="object-cover object-top w-full max-h-40"/>
      <div class="w-full p-4 bg-white ">
          <p class="font-medium text-purple-500 text-md">
              {item.name}
          </p>
          <div class="flex items-center">
<svg class="h-6 w-6 text-purple-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
</svg>

<p class="text-md text-black  ml-2">

{item.venue}
</p>
</div>

      <div class="grid max-w-xl grid-cols-2 gap-4 ">


      <div class="flex items-center">
      <svg class="h-6 w-6 text-purple-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="5" cy="5" r="1" />  <circle cx="12" cy="5" r="1" />  <circle cx="19" cy="5" r="1" />  <circle cx="5" cy="12" r="1" />  <circle cx="12" cy="12" r="1" />  <circle cx="19" cy="12" r="1" />  <circle cx="5" cy="19" r="1" />  <circle cx="12" cy="19" r="1" />  <circle cx="19" cy="19" r="1" /></svg>

      <p class="text-md text-black ml-2">

      {item.seats} Seats
      </p>
      </div>
      <div class="flex items-center">
      <svg class="h-6 w-6 text-purple-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <polyline points="12 6 12 12 16 14" /></svg>            
      <p class="text-md text-black ml-2">

      {item.time}
      </p>
      </div>

      </div>



          <div class="flex items-center">
          <svg class="h-6 w-6 text-purple-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>

<p class="text-md text-black ml-2">

{item.price} Price
</p>
</div>


          <p class="font-light text-gray-400 text-md">
              {item.description.substring(0,28)}... Read More
          </p>
         
      </div>
  </a>
</div>
        

)

})}









</div>








    {/* <Footer/> */}



{/* 




<div class=" lg:invisible visible fixed bottom-0 flex flex-row w-full ">

<div class="flex items-center w-full">
    <button type="button" class="w-full px-4 py-2 text-base font-medium text-black bg-white  rounded-l-md hover:bg-gray-100"
    onClick={()=>history.push("/home")}
    >
    <svg class="h-6 w-6 text-blue-400 content-center justify-center	ml-4"  width="26" height="26" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <polyline points="5 12 3 12 12 3 21 12 19 12" />  <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />  <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" /></svg>
    </button>
    <button type="button" class="w-full px-4 py-2 text-base font-medium text-black bg-white  hover:bg-gray-100"
        onClick={()=>history.push("/products")}

    >
    <svg class="h-6 w-6 text-blue-400 content-center justify-center	ml-4"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M4.5 12.5l8 -8a4.94 4.94 0 0 1 7 7l-8 8a4.94 4.94 0 0 1 -7 -7" />  <path d="M8.5 8.5l7 7" /></svg>
    </button>
    <button type="button" class="w-full px-4 py-2 text-base font-medium text-black bg-white  hover:bg-gray-100"
            onClick={()=>history.push("/profile")}

    >
    <svg class="h-6 w-6 text-blue-400 content-center justify-center	ml-4"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
</svg>
    </button>
    <button type="button" class="w-full px-4 py-2 text-base font-medium text-black bg-white rounded-r-md hover:bg-gray-100"
                onClick={()=>history.push("/cart")}

    >
    <svg class="h-6 w-6 text-blue-400 content-center justify-center	ml-4 "  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="9" cy="19" r="2" />  <circle cx="17" cy="19" r="2" />  <path d="M3 3h2l2 12a3 3 0 0 0 3 2h7a3 3 0 0 0 3 -2l1 -7h-15.2" /></svg>

    </button>
</div>


</div> */}


<Footer page="events"></Footer>
</main>
);


  
}


export default AllEvents