
 


 

  
import React,{useContext, useState,useEffect} from 'react'
import {Link, useNavigate,useParams} from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {UserContex} from '../App'
import Header from '../components/Header';
import Payment from './MembershipPayment';
import { name } from '@cloudinary/url-gen/actions/namedTransformation';
import { loadStripe } from'@stripe/stripe-js';
import {socket} from "../App"
import Footer from '../components/Footer';
// import { CardElement, Elements, useElements } from
 
// '@stripe/react-stripe-js';

// const stripePromise = loadStripe('pk_test_51O7H4NFtdms7xyPvenr9xBB9Hor425E614fYOEv0vCgo9ygKGSVezMIfQWcg8Rhef7I2APPt1PMC4lfdz3TnHtLT00idMMp6pq');

const SingleMembership = ()=>{
  const {state, dispatch }= useContext(UserContex)
  const history =useNavigate();
  const [email,setEmail] =  useState("");
  const [name,setName] =  useState("");
  const [phone,setPhone] =  useState("");
  const [amount,setAmount] =  useState();
  const [password,setPassword] =  useState("");
  const [billing,setBilling] =  useState(false);
  const [payment,setPayment] =  useState(false);
  const [freeEvent,setFreeEvent] =  useState(false);
  const [seats,setSeats] =  useState(0);
  const [size,setSize] =  useState(0);
  const navigate= useNavigate()

  const [data,setData]= useState([])
  const [cart,setCart]= useState([])
  const [details,setDetails]= useState([])
  const [column, setColumn] = useState("")
  const [booked,setBooked] = useState([])
  const [taken,setTaken] = useState([])
const {postid} = useParams()
// const elements = useElements()

// setColumn("grid-cols-"+data.col) 

// console.log("Booked",cart)

// if(booked.length>0){
//     localStorage.setItem("data", JSON.stringify(booked));

// }


 useEffect(() => {

    fetch(`/getsinglemembership/${postid}`, {
        headers: {
          Authorization: "Bearer " +localStorage.getItem("jwt"),
          "Content-Type":"application/json"
          }
    })
        .then(res => res.json())
        .then(result => {
            setData(result.event);
           
        });


   
        


}, []);
 

       

    



    
    
 
 
 

    return(

<>
<main class="bg-white h-screen  relative overflow-x-hidden">
    <div class="flex items-start justify-between">
       
        <div class="flex flex-col w-full ">

            

            <Header></Header>


            


<div class="relative md:block lg:block hidden">

<img src="bann.jpeg" class="absolute h-full w-full object-cover"/>
{/* <div class="absolute inset-0 bg-purple-500 opacity-50"></div> */}
<div class="absolute inset-0 overflow-hidden">
    <div class="absolute inset-0 bg-gradient-to-r from-black to-transparent"></div>
  </div>



   
    </div>


    



        </div>
     

    </div>



  

   
<div class="flex flex-col">

<img src="https://img.freepik.com/free-vector/abstract-purple-wavy-modern-light-background_361591-1420.jpg?size=1450&ext=jpg&ga=GA1.1.1697546048.1698228343&semt=ais" class="absolute h-1/2 w-full object-cover overflow-hidden"/>
{/* 
<video autoPlay loop muted playbackRate={2.0}  class=" absolute h-1/2 w-full object-fill md:h-1/2  md:w-full md:object-cover">
<source src="https://v4.cdnpk.net/videvo_files/video/free/video0467/large_watermarked/_import_614f00b3e16dc1.55454574_FPpreview.mp4" type="video/mp4" />
</video> */}
<div>
<img src={data.pic?data.pic:"https://img.freepik.com/free-psd/sound-party-cover-template_23-2148131763.jpg?size=626&ext=jpg&ga=GA1.1.1697546048.1698228343&semt=sph" } class=" relative h-60 w-11/12 ml-4 md:h-96 md:w-1/2 object-center md:ml-80  md:mt-24 mt-32 rounded-lg "/> 

</div>





 {/* <div class=" relative flex  mx-auto">

         <img src="https://img.freepik.com/free-photo/excited-audience-watching-confetti-fireworks-having-fun-music-festival-night-copy-space_637285-559.jpg?w=996&t=st=1698228712~exp=1698229312~hmac=e6d992326774f5a0d719062875e0439936f46b09ea558d4d295af285dc06e732" class=" w-full h-92"/>
 </div>
  */}







<div class="w-full md:p-12  bg-white overflow-auto mt-4 mb-4 relative">

<p class="mb-0 text-center font-extrabold text-purple-500 ">
                      Step 1/2: Add Donation Amount
                  </p>
  <div class="p-4 mx-auto text-center  bg-white border-indigo-500 rounded shadow h-36 md:w-96 w-11/12 ">
      <div class="flex items-center justify-center h-full">
         
          
          <div class="flex flex-col justify-center h-full">
          
<div class="flex flex-col justify-center w-full  space-y-3 md:flex-row md:w-full md:space-x-3 md:space-y-0">
    <div class=" relative ">
        <input type="number"  class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none ring-2 ring-purple-600 focus:border-transparent" placeholder="Donation Amount"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        />
        </div>
        <button class="flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-purple-600 rounded-lg shadow-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200" 
                      onClick={()=>{setBilling(true)}}

        >
            Donate
        </button>
    </div>

         
          </div>
      </div>
  </div>



<div class="flex items-end justify-between m-4 header">
 <div class="title">
     <p class="mb-4 text-4xl font-bold text-gray-800">
         {data.name}
     </p>
     <p class="text-2xl font-light text-gray-400">
     {data.description}

     </p>
 </div>


 </div>
 
</div>



{
billing? 
(
<>
<div
className="justify-center md:p-44 items-center flex   fixed inset-0 z-50 outline-none focus:outline-none overflow-auto"
>
 {/*content*/}
 <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
   {/*header*/}
   <div className="flex rounded-full items-start  ">
     
     <button
       className="p-1 ml-auto rounded-full bg-white text-white text-3 "
       onClick={() => setBilling(false)}
     >
<svg class="h-8 w-8 text-red-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <line x1="15" y1="9" x2="9" y2="15" />  <line x1="9" y1="9" x2="15" y2="15" /></svg>

</button>
   </div>
   {/*body*/}
  
   


   <div className="text-gray-500 rounded-3xl shadow-xl w-full mb-5" >
<div className="md:flex w-full">
 
 <div className="w-full md:h-96    px-5 md:px-10 relative overflow-auto">

<Payment   amount={amount} vendor={data.vendor.stripe.id} id={postid}/>


</div>


   </div>

 
 </div>



 

</div>


</div>
</>
)


:null

}

</div>
        














<Footer page="events"></Footer>
</main>







</>

  


    );
}

export default SingleMembership;