import React, {useContext, useRef, useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import DatePicker from 'react-datepicker'
import {UserContex} from '../App'
import AdminHeader from '../components/AdminHeader'
import SuperAdminHeader from '../components/SuperAdminHeader'

// import NavBar from './nav'

const SuperDash = ()=>{


  const history = useNavigate()
  const {state, dispatch}= useContext(UserContex)



  const  [data, setData]= useState([])

  const [search,setSearch] = useState("")

  const [addStock,setAddStock]= useState(false)
  const [editEmoloyee,setEdit]= useState(false)
  const[id,setId] = useState("")  
  const[name,setName]= useState("")
  const[phone,setPhone]= useState("")
  const[address,setAddress]= useState("")
  const[email,setEmail]= useState("")
  const[password,setPassword]= useState("")
  const[cid,setCID]= useState("")
  const[zone,setZone]= useState("")
  const[status,setStatus]= useState("")
  const[skills,setSkills]=useState([])
  const[docs,setDocs]=useState([])
  const[salary,setSalary]= useState("")
  const [dob,setDob]= useState(new Date())
  const [url,setUrl] = useState("")  

  const[progress,setProgress]= useState(false)


  useEffect(  ()=>{

    fetch('/getEmployee',{
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{
      setData(result.employees)
      
  })

  },[])



  const singleEmployee = (Itemid)=>{

    console.log(Itemid)
  fetch('/getSingleEmployee',{
    method:"post",
    headers:{
      Authorization: "Bearer " +localStorage.getItem("jwt"),
      "Content-Type":"application/json"

    },
    body:JSON.stringify({
      id:Itemid
    })
}).then(res=>res.json())
.then(result=>{


  // setDataEdit(result.stock)
  console.log(result.employee._id)
  setId(result.employee._id)
  setName(result.employee.name)
  setEmail(result.employee.email)
  setPhone(result.employee.phone)
  setZone(result.employee.zone)
  setCID(result.employee.cid)
  setSalary(result.employee.salary)
  setStatus(result.employee.status)

  //setDob(result.employee.dob)
  
  

          
})

}
  



  


  const postStock = ()=>{

    fetch('/signupCollector',{

      method:"post",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({
        name:name,
        email,
        password,
        phone,
        zone,
        cid,
        dob,
        salary,
      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
      }

      else{
        
        window.location.reload();
            // history.go(`/employee`)

      }
    })
    .catch(err=>{
      console.log(err)
    })

  }


  
  const uploadppt=({target:{files}})=>{

    setProgress(true)

    let data = new FormData()

    for(const file of files){

   data.append('uploadimage', file)
   data.append('name',file.name)
}
       fetch("/uploadimage", {

         method:"post",
         body: data
     })
     .then(res=>res.json())
     .then(data=>{

          setUrl(data.url)
          setProgress(false)

      

       
     })
     .catch(err=>{
         console.log(err)
     })

}
  



  const postEmployeeUpdate = ()=>{

    fetch('/updateEmployee',{

      method:"post",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({
        id:id,
        name:name,
        email,
        password,
        phone,
        status,
        zone,
        cid,
        dob,
        salary,
        
      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
      }

      else{
        window.location.reload();
        // history.go('/dashboard')
      }
    })
    .catch(err=>{
      console.log(err)
    })

  }





  const fetchPost=(query)=>{


    setSearch(query)

    fetch('/search-employee',{

      method:"post",
      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt"),

        "Content-Type":"application/json"

      },
      body:JSON.stringify({
        query
      })

    }).then(res=> res.json())
      .then(results=>{
        
        setData(results.employee)
      })


  }

  const deleteEmployee = (postId)=>{

    fetch(`/deleteEmployee/${postId}`,{
        method: "delete",
        headers:{
          "Content-Type":"application/json",
          Authorization: "Bearer " +localStorage.getItem("jwt")
        }
    }).then(res=>res.json())
    .then(result=>{
      history('/dashboard')

    }).catch(err=>{
        console.log(err)
    })
}
  
    //deleteEmployee
    

    const Logout = ()=>{
        localStorage.clear()
        dispatch({type:"CLEAR"})
            history(`/login`)
    }

return(

    
<main class="relative h-screen overflow-hidden bg-white  rounded-2xl">
    <div class="flex items-start justify-between">
       
        <div class="flex flex-col w-full pl-0 md:p-4 md:space-y-4">
      

          <SuperAdminHeader page="home"></SuperAdminHeader>




                <div class="overflow-auto h-screen pb-24 px-4 md:px-6">
                <h1 class="text-4xl font-semibold text-black">
                    Super Admin
                </h1>
                <h2 class="text-md text-gray-400">
                    Welcome, Happy Managing!
                </h2>
                {/* <div class="flex my-6  w-full space-y-4 md:space-x-4 md:space-y-0 flex-col md:flex-row"> */}
                    


                  

                    <br></br>



                {/* </div> */}



                
               
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 my-4">
                   
                   
                   
                   
                   
                  
                <Link to="/eventlist"> 
                    
                    <div class="shadow-lg rounded-2xl p-4 bg-white ">
    <div class="flex items-center">
    <svg class="h-8 w-8 text-purple-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z" />  <polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02" /></svg>
            <p class="text-md text-black  ml-2">
            Events
        </p>
    </div>
    <div class="flex flex-col justify-start">
        <p class="text-gray-700 text-2xl text-left font-bold my-4">
            Check all Events
            
        </p>
        <div class="flex items-center text-green-500 text-sm">
        <svg class="h-8 w-8 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>

            <span>
            Check all events list 

            </span>
            
        </div>
    </div>
</div>

                    </Link>


                 

<Link to="/vendorlist"> 
                    
                    <div class="shadow-lg rounded-2xl p-4 bg-white ">
    <div class="flex items-center">
    <svg class="h-8 w-8 text-purple-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M3 21v-13l9-4l9 4v13" />  <path d="M13 13h4v8h-10v-6h6" />  <path d="M13 21v-9a1 1 0 0 0 -1 -1h-2a1 1 0 0 0 -1 1v3" /></svg>

        <p class="text-md text-black  ml-2">
        Organizers
        </p>
    </div>
    <div class="flex flex-col justify-start">
        <p class="text-gray-700  text-2xl text-left font-bold my-4">
            Check all organizers
            
        </p>
        <div class="flex items-center text-green-500 text-sm">
        <svg class="h-8 w-8 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>

            <span>
            Check all organizers list   

            </span>
            
        </div>
    </div>
</div>

                    </Link>



                    <Link to="/userlist"> 
                    
                    <div class="shadow-lg rounded-2xl p-4 bg-white ">
    <div class="flex items-center">
    <svg class="h-8 w-8 text-purple-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"/>
</svg>

        <p class="text-md text-black ml-2">
            User List
        </p>
    </div>
    <div class="flex flex-col justify-start">
        <p class="text-gray-700  text-2xl text-left font-bold my-4">
        Check all users            
        </p>
        <div class="flex items-center text-green-500 text-sm">
        <svg class="h-8 w-8 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>

            <span>
            Check all users list   
            </span>
            
        </div>
    </div>
</div>

                    </Link>
                    <Link to="/realitysuperdash"> 
                    
                    <div class="shadow-lg rounded-2xl p-4 bg-white ">
    <div class="flex items-center">
    <svg class="h-8 w-8 text-purple-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"/>
</svg>

        <p class="text-md text-black ml-2">
            Reality Show
        </p>
    </div>
    <div class="flex flex-col justify-start">
        <p class="text-gray-700  text-2xl text-left font-bold my-4">
        Check all reality Shows            
        </p>
        <div class="flex items-center text-green-500 text-sm">
        <svg class="h-8 w-8 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>

            <span>
            Check all Bhutanese reality shows    
            </span>
            
        </div>
    </div>
</div>

                    </Link>

                    {/* <Link to="/stripeconfig"> 
                    
                    <div class="shadow-lg rounded-2xl p-4 bg-white dark:bg-gray-800">
    <div class="flex items-center">
    <svg class="h-8 w-8 text-purple-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>

        <p class="text-md text-black dark:text-white ml-2">
            Payment 
        </p>
    </div>
    <div class="flex flex-col justify-start">
        <p class="text-gray-700 dark:text-gray-100 text-2xl text-left font-bold my-4">
            Configure Payment 
            
        </p>
        <div class="flex items-center text-green-500 text-sm">
        <svg class="h-8 w-8 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>

            <span>
            Configure Payment for your events

            </span>
            
        </div>
    </div>
</div>

                    </Link> */}



                   

            
                </div>









            </div>







            </div>
        </div>
    </main>

);


  
}


export default SuperDash