import React, {useContext, useRef, useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import DatePicker from 'react-datepicker'
import {UserContex} from '../App'
import AdminHeader from '../components/AdminHeader'

// import NavBar from './nav'

const DashHome = ()=>{


  const history = useNavigate()
  const {state, dispatch}= useContext(UserContex)



  const  [data, setData]= useState([])

  const [search,setSearch] = useState("")

  const [addStock,setAddStock]= useState(false)
  const [editEmoloyee,setEdit]= useState(false)
  const[id,setId] = useState("")  
  const[name,setName]= useState("")
  const[phone,setPhone]= useState("")
  const[address,setAddress]= useState("")
  const[email,setEmail]= useState("")
  const[password,setPassword]= useState("")
  const[cid,setCID]= useState("")
  const[zone,setZone]= useState("")
  const[status,setStatus]= useState("")
  const[skills,setSkills]=useState([])
  const[detail,setDetail]=useState(null)
  const[salary,setSalary]= useState("")
  const [dob,setDob]= useState(new Date())
  const [url,setUrl] = useState("")  
  const [accountLink,setAccountLink] = useState("")  
  const [stripeVerification,setVerification]  = useState(false)
  const[progress,setProgress]= useState(false)
  const[editName,setEditName]= useState(false)


  useEffect(  ()=>{

    fetch('/getvendordetail',{
      method:"get",
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt"),
          "Content-Type":"application/json"
  
          
      },
     
  }).then(res=>res.json())
  .then(result=>{
    setDetail(result.details)
    setName(result.details.name)
    setEmail(result.details.email)
    setPhone(result.details.phone)
    if(result.verified){setVerification(false)}
    else{ setVerification(true)

      setAccountLink(result.accountLink)
      console.log(result.accountLink)

    }
    // setEvent(result.ticket.event)

      // setOrderHistoryData(result.orders)
      
  })

    fetch('/getEmployee',{
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{
      setData(result.employees)
      
  })

  },[])



  const singleEmployee = (Itemid)=>{

    console.log(Itemid)
  fetch('/getSingleEmployee',{
    method:"post",
    headers:{
      Authorization: "Bearer " +localStorage.getItem("jwt"),
      "Content-Type":"application/json"

    },
    body:JSON.stringify({
      id:Itemid
    })
}).then(res=>res.json())
.then(result=>{


  // setDataEdit(result.stock)
  console.log(result.employee._id)
  setId(result.employee._id)
  setName(result.employee.name)
  setEmail(result.employee.email)
  setPhone(result.employee.phone)
  setZone(result.employee.zone)
  setCID(result.employee.cid)
  setSalary(result.employee.salary)
  setStatus(result.employee.status)

  //setDob(result.employee.dob)
  
  

          
})

}
  



  


  const postStock = ()=>{

    fetch('/signupCollector',{

      method:"post",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({
        name:name,
        email,
        password,
        phone,
        zone,
        cid,
        dob,
        salary,
      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
      }

      else{
        
        window.location.reload();
            // history.go(`/employee`)

      }
    })
    .catch(err=>{
      console.log(err)
    })

  }


  
  const uploadppt=({target:{files}})=>{

    setProgress(true)

    let data = new FormData()

    for(const file of files){

   data.append('uploadimage', file)
   data.append('name',file.name)
}
       fetch("/uploadimage", {

         method:"post",
         body: data
     })
     .then(res=>res.json())
     .then(data=>{

          setUrl(data.url)
          setProgress(false)

      

       
     })
     .catch(err=>{
         console.log(err)
     })

}
  



  const postVendorUpdate = ()=>{

    fetch('/updateVendorDetails',{

      method:"put",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({
       
        name:name,
        email,
        phone,
      
        
      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
      }

      else{
        window.location.reload();
        // history.go('/dashboard')
      }
    })
    .catch(err=>{
      console.log(err)
    })

  }





  const fetchPost=(query)=>{


    setSearch(query)

    fetch('/search-employee',{

      method:"post",
      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt"),

        "Content-Type":"application/json"

      },
      body:JSON.stringify({
        query
      })

    }).then(res=> res.json())
      .then(results=>{
        
        setData(results.employee)
      })


  }

  const deleteEmployee = (postId)=>{

    fetch(`/deleteEmployee/${postId}`,{
        method: "delete",
        headers:{
          "Content-Type":"application/json",
          Authorization: "Bearer " +localStorage.getItem("jwt")
        }
    }).then(res=>res.json())
    .then(result=>{
      history('/dashboard')

    }).catch(err=>{
        console.log(err)
    })
}
  
    //deleteEmployee
    

    const Logout = ()=>{
        localStorage.clear()
        dispatch({type:"CLEAR"})
            history(`/login`)
    }

return(

    
<main class="relative h-screen overflow-hidden bg-white  rounded-2xl">
    <div class="flex items-start justify-between">
       
        <div class="flex flex-col w-full pl-0 md:p-4 md:space-y-4">
      

          <AdminHeader page="home"></AdminHeader>




                <div class="overflow-auto h-screen pb-24 px-4 md:px-6">
                <h1 class="text-4xl font-semibold text-black">
                    {detail?
                    
                    <> 
            <span className="flex items-center">
  <h1 className="mr-2">{detail.name}</h1>
  <svg  className="h-8 w-8 text-purple-500" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"
  onClick={()=>{setEditName(true)}}
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />
    <line x1="13.5" y1="6.5" x2="17.5" y2="10.5" />
  </svg>
</span>

                    </>
                    
                    
                    :"Loading..."}
                </h1>
                <h2 class="text-md text-gray-400">
                    Welcome, Happy Managing!
                </h2>

                {editName?<>
                
<section class="h-auto bg-gray-100/50">
    <div class="container max-w-2xl mx-auto shadow-md md:w-3/4">
    <div className="relative p-4 border-t-2 border-purple-400 rounded-lg bg-gray-100/5">
  <div className="max-w-sm mx-auto md:w-full md:mx-0">
    <div className="inline-flex items-center space-x-4">
      <a href="#" className="relative block">
        <img
          alt="profile"
          src={
            detail.pic
              ? detail.pic
              : "https://img.freepik.com/free-vector/user-blue-gradient_78370-4692.jpg?t=st=1723444743~exp=1723448343~hmac=110b7f7d9864e9f0169a5c30232fb8d67c047fcfc77e875059af419f8ee275fe&w=1380"
          }
          className="mx-auto object-cover rounded-full h-16 w-16"
        />
      </a>
      <h1 className="text-gray-600">{detail.name}</h1>
    </div>
  </div>
  <svg
    className="absolute top-2 right-2 h-8 w-8 text-red-500"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    onClick={()=>setEditName(false)}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M6 18L18 6M6 6l12 12"
    />
  </svg>
</div>

        <div class="space-y-6 bg-white">
            <div class="items-center w-full p-4 space-y-4 text-gray-500 md:inline-flex md:space-y-0">
                <h2 class="max-w-sm mx-auto md:w-1/3">
                    Account
                </h2>
                <div class="max-w-sm mx-auto md:w-2/3">
                    <div class=" relative ">
                        <input type="text" id="user-info-email" class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Email"
                        value={email}
                        onChange={(e)=>{setEmail(e.target.value)}}
                        
                        />
                        </div>
                    </div>
                </div>
                <hr/>
                <div class="items-center w-full p-4 space-y-4 text-gray-500 md:inline-flex md:space-y-0">
                    <h2 class="max-w-sm mx-auto md:w-1/3">
                        Personal info
                    </h2>
                    <div class="max-w-sm mx-auto space-y-5 md:w-2/3">
                        <div>
                            <div class=" relative ">
                                <input type="text" id="user-info-name" class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Name"
                                  value={name}
                                  onChange={(e)=>{setName(e.target.value)}}
                                  
                                />
                                </div>
                            </div>
                            <div>
                                <div class=" relative ">
                                    <input type="text" id="user-info-phone" class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Phone number"
                                    
                                    value={phone}
                                    onChange={(e)=>{setPhone(e.target.value)}}

                                    />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr/>
                       
                            <div class="w-full px-4 pb-4 ml-auto text-gray-500 md:w-1/3">
                                <button type="submit" class="py-2 px-4  bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                                
                                onClick={()=>{postVendorUpdate();setEditName(false)}}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                </>:<></>}
               {/* {stripeVerification? 
  <a href={accountLink}>
    <button type="button" class="py-2 px-4 flex justify-center items-center  bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
    <svg width="20" height="20" fill="currentColor" class="mr-2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
        <path d="M1344 1472q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm256 0q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm128-224v320q0 40-28 68t-68 28h-1472q-40 0-68-28t-28-68v-320q0-40 28-68t68-28h427q21 56 70.5 92t110.5 36h256q61 0 110.5-36t70.5-92h427q40 0 68 28t28 68zm-325-648q-17 40-59 40h-256v448q0 26-19 45t-45 19h-256q-26 0-45-19t-19-45v-448h-256q-42 0-59-40-17-39 14-69l448-448q18-19 45-19t45 19l448 448q31 30 14 69z">
        </path>
    </svg>
    Stripe Verification Pending
</button>

  </a>             
              

                
                :<></>} */}
                {/* <div class="flex my-6  w-full space-y-4 md:space-x-4 md:space-y-0 flex-col md:flex-row"> */}
                    


                    {/* <div class="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 my-4  justify-around ">
                        <div class="w-full shadow-lg">
                            <div class="shadow-lg px-4 py-6 w-full bg-white dark:bg-gray-700 relative">
                                <p class="text-2xl text-black dark:text-white font-bold">
                               Nu. {salesDataToday?  salesDataToday : <></> }   
                                </p>
                                <p class="text-gray-400 text-sm">
                                   Todays Sale
                                </p>
                            </div>
                            <div class="w-full h-2 bg-gray-100  drop-shadow-2xl">
                                    <div class="w-full h-full text-center text-xs text-white bg-green-400">
                                    </div>
                                </div>
                        </div>
                       
                       
                        <div class="w-full rounded-lg shadow-lg ">
                            <div class=" shadow-lg px-4 py-6 w-full bg-white dark:bg-gray-700 relative">
                                <p class="text-2xl text-black dark:text-white font-bold">
                               Nu. {saleDataMonth?  saleDataMonth : <></> }   
                                </p>
                                <p class="text-gray-400 text-sm">
                                    Sales This Month
                                </p>
                            </div>
                            <div class="w-full h-2 bg-gray-100">
                                    <div class="w-full h-full text-center text-xs text-white bg-blue-300">
                                    </div>
                                </div>
                        </div>



                        <div class="w-full shadow-lg">
                            <div class="shadow-lg px-4 py-6 w-full bg-white dark:bg-gray-700 relative">
                                <p class="text-2xl text-black dark:text-white font-bold">
                               Nu. {creditDataToday?  creditDataToday : <></> }   
                                </p>
                                <p class="text-gray-400 text-sm">
                                    Credit Today
                                </p>
                            </div>
                            <div class="w-full h-2 bg-gray-100">
                                    <div class="w-full h-full text-center text-xs text-white bg-green-400">
                                    </div>
                                </div>
                        </div>


                        <div class="w-full shadow-lg">
                            <div class="shadow-lg px-4 py-6 w-full bg-white dark:bg-gray-700 relative">
                                <p class="text-2xl text-black dark:text-white font-bold">
                                  Nu  {ExpenseDataMonth}
                                </p>
                                <p class="text-gray-400 text-sm">
                                     Expense This Month
                                </p>
                                <span class="rounded-full absolute p-4 bg-purple-500 top-2 right-4">
                                    <svg width="40" fill="currentColor" height="40" class="text-white h-4 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1362 1185q0 153-99.5 263.5t-258.5 136.5v175q0 14-9 23t-23 9h-135q-13 0-22.5-9.5t-9.5-22.5v-175q-66-9-127.5-31t-101.5-44.5-74-48-46.5-37.5-17.5-18q-17-21-2-41l103-135q7-10 23-12 15-2 24 9l2 2q113 99 243 125 37 8 74 8 81 0 142.5-43t61.5-122q0-28-15-53t-33.5-42-58.5-37.5-66-32-80-32.5q-39-16-61.5-25t-61.5-26.5-62.5-31-56.5-35.5-53.5-42.5-43.5-49-35.5-58-21-66.5-8.5-78q0-138 98-242t255-134v-180q0-13 9.5-22.5t22.5-9.5h135q14 0 23 9t9 23v176q57 6 110.5 23t87 33.5 63.5 37.5 39 29 15 14q17 18 5 38l-81 146q-8 15-23 16-14 3-27-7-3-3-14.5-12t-39-26.5-58.5-32-74.5-26-85.5-11.5q-95 0-155 43t-60 111q0 26 8.5 48t29.5 41.5 39.5 33 56 31 60.5 27 70 27.5q53 20 81 31.5t76 35 75.5 42.5 62 50 53 63.5 31.5 76.5 13 94z">
                                        </path>
                                    </svg>
                                </span>
                            </div>
                            <div class="w-full h-2 bg-gray-100">
                                    <div class="w-full h-full text-center text-xs text-white bg-blue-300">
                                    </div>
                                </div>
                        </div>
                    </div> */}

                    <br></br>



                {/* </div> */}



                
               
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 my-4">
                   
                   
                <Link to="/realityshowdash"> 
                    
                    <div class="shadow-lg rounded-2xl p-4 bg-white ">
    <div class="flex items-center">
    <svg class="h-8 w-8 text-purple-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <rect x="2" y="7" width="20" height="15" rx="2" ry="2" />  <polyline points="17 2 12 7 7 2" /></svg>        <p class="text-md text-black ml-2">
            Bhutanese Reality Show
        </p>
    </div>
    <div class="flex flex-col justify-start">
        <p class="text-gray-700  text-2xl text-left font-bold my-4">
            Manage  Reality Show
            
        </p>
        <div class="flex items-center text-green-500 text-sm">
        <svg class="h-8 w-8 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>

            <span>
            Host and Manage Reality Show 

            </span>
            
        </div>
    </div>
</div>

                    </Link>


                   
                   
                  
                <Link to="/eventsdashhome"> 
                    
                    <div class="shadow-lg rounded-2xl p-4 bg-white ">
    <div class="flex items-center">
    <svg class="h-8 w-8 text-purple-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z"/>
</svg>
            <p class="text-md text-black ml-2">
            Events
        </p>
    </div>
    <div class="flex flex-col justify-start">
        <p class="text-gray-700 d text-2xl text-left font-bold my-4">
            Manage Events
            
        </p>
        <div class="flex items-center text-green-500 text-sm">
        <svg class="h-8 w-8 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>

            <span>
            Manage your events and tickets 

            </span>
            
        </div>
    </div>
</div>

                    </Link>


               









                   
<Link to="/membershipdash"> 
                    
                    <div class="shadow-lg rounded-2xl p-4 bg-white ">
    <div class="flex items-center">
    <svg class="h-8 w-8 text-purple-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="9" cy="7" r="4" />  <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />  <path d="M16 11h6m-3 -3v6" /></svg>
        <p class="text-md text-black ml-2">
            Membership
        </p>
    </div>
    <div class="flex flex-col justify-start">
        <p class="text-gray-700  text-2xl text-left font-bold my-4">
            Manage Mambership
            
        </p>
        <div class="flex items-center text-green-500 text-sm">
        <svg class="h-8 w-8 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>

            <span>
            Creat memberships and manage mambers 

            </span>
            
        </div>
    </div>
</div>

                    </Link>



            
                </div>









            </div>







            </div>
        </div>
    </main>

);


  
}


export default DashHome