

import React, { useState,useContext } from "react";
import { Transition } from "@headlessui/react";
import { Link,useNavigate } from "react-router-dom";
import {UserContex} from '../App'

function AdminHeader(props) {
  const [isOpen, setIsOpen] = useState(false);
  const user = JSON.parse( localStorage.getItem("user"))

  const {state, dispatch}= useContext(UserContex)

  const navigate = useNavigate()

  const Logout = ()=>{
    localStorage.clear()
    dispatch({type:"CLEAR"})
        navigate(`/login`)
}
  return (
    <div>
       <nav class=" bg-white rounded-lg ">
            <div class="max-w-7xl mx-auto px-8">
                <div class="flex items-center justify-between h-16">
                    <div class="w-full justify-between flex items-center">
                        <a class="flex-shrink-0" href="/dashboard">
                        <img
                  className="h-12 w-12"
                  src="/logo.png"
                  alt="Workflow"
                />                        </a>
                        
                    </div>
                    <div class="block">
                        
                    </div>
                    <div class="-mr-2 flex ">
                      
                    <button class="flex p-2 items-center rounded-full bg-white  shadow text-gray-400 hover:text-gray-700 "
                            
                            onClick={()=>{if(window.confirm('Are you sure you want to logout?')) Logout()}}                           
                    
                        >
                   <svg class="h-6 w-6 text-gray-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"
                        
                        
                        />  <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />  <path d="M7 12h14l-3 -3m0 6l3 -3" /></svg>       
                     </button>
                  
    
                    </div>
                </div>
            </div>
           
        </nav>


            
<div class=" lg:invisible visible fixed bottom-0 flex z-40 flex-row w-full ">



<div class="relative w-full p-2  bg-white shadow-lg rounded-t-xl md:w-60 ">
   
    <div class="grid grid-cols-5 gap-4">
        <div class="flex flex-col items-center"
                onClick={()=>{navigate("/dashboard")}}

        >
            <div class="relative">
                <a href="#" class="relative block">
                <svg class={`h-6 w-6 ${props.page=="home"?"text-purple-500":"text-gray-500"} `}  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />  <polyline points="9 22 9 12 15 12 15 22" /></svg>   
                
                
                
                                      </a>
               
            </div>
            <span class=" text-xs text-gray-600 ">
                Home
            </span>
        </div>
        
        <div class="flex flex-col items-center"
                onClick={()=>{navigate("/eventsdashhome")}}

        >
            <div class="relative">
                <a href="#" class="relative block">
                
                <svg class={`h-6 w-6 ${props.page=="events"?"text-purple-500":"text-gray-500"} `}   viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M14.5 10c-.83 0-1.5-.67-1.5-1.5v-5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5v5c0 .83-.67 1.5-1.5 1.5z" />  <path d="M20.5 10H19V8.5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5-.67 1.5-1.5 1.5z" />  <path d="M9.5 14c.83 0 1.5.67 1.5 1.5v5c0 .83-.67 1.5-1.5 1.5S8 21.33 8 20.5v-5c0-.83.67-1.5 1.5-1.5z" />  <path d="M3.5 14H5v1.5c0 .83-.67 1.5-1.5 1.5S2 16.33 2 15.5 2.67 14 3.5 14z" />  <path d="M14 14.5c0-.83.67-1.5 1.5-1.5h5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-5c-.83 0-1.5-.67-1.5-1.5z" />  <path d="M15.5 19H14v1.5c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5-.67-1.5-1.5-1.5z" />  <path d="M10 9.5C10 8.67 9.33 8 8.5 8h-5C2.67 8 2 8.67 2 9.5S2.67 11 3.5 11h5c.83 0 1.5-.67 1.5-1.5z" />  <path d="M8.5 5H10V3.5C10 2.67 9.33 2 8.5 2S7 2.67 7 3.5 7.67 5 8.5 5z" /></svg>
                
                                      </a>
               
            </div>
            <span class=" text-xs text-gray-600 ">
                Events
            </span>
        </div>
        
        <div class="flex flex-col items-center"
        
        onClick={()=>{navigate("/qrcode")}}
        >
            <div class="relative">
            <svg class={`h-16 w-16 ${props.page=="scan"?"text-purple-500":"text-gray-500"} -mt-9`}  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M4 7v-1a2 2 0 0 1 2 -2h2" />  <path d="M4 17v1a2 2 0 0 0 2 2h2" />  <path d="M16 4h2a2 2 0 0 1 2 2v1" />  <path d="M16 20h2a2 2 0 0 0 2 -2v-1" />  <line x1="5" y1="12" x2="19" y2="12" /></svg>
            </div>
            <span class=" text-xs text-gray-600 ">
                Scan
            </span>
        </div>
     
       
        <div class="flex flex-col items-center"
                onClick={()=>{navigate("/realityshowdash")}}

        >
            <div class="relative">
                <a href="#" class="relative block">
         
<svg class={`h-6 w-6 ${props.page=="show"?"text-purple-500":"text-gray-500"} `} viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z" />  <polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02" /></svg>
                
                  </a>
              
            </div>
            <span class=" text-xs text-gray-600 ">
                Show
            </span>
        </div>
        <div class="flex flex-col items-center"
                onClick={()=>{navigate("/membershipdash")}}

        >
            <div class="relative">
                <a href="#" class="relative block">
                {/* <svg class={`h-6 w-6 ${props.page=="report"?"text-purple-500":"text-gray-500"} `} fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"/>
</svg> */}

<svg class={`h-6 w-6 ${props.page=="membership"?"text-purple-500":"text-gray-500"} `} fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"/>
</svg>

                
                
                  </a>
              
            </div>
            <span class=" text-xs text-gray-600 ">
                Member
            </span>
        </div>

       
    </div>
</div>

</div>
     
    </div>
  );
}

export default AdminHeader;