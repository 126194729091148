import React, {useContext, useRef, useEffect, useState} from 'react'
import {Link, useNavigate,useParams} from 'react-router-dom'
import DatePicker from 'react-datepicker'
import {UserContex} from '../App'
import {fill} from "@cloudinary/url-gen/actions/resize";
import {CloudinaryImage} from '@cloudinary/url-gen';
import AdminHeader from '../components/AdminHeader';
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookIcon,
  LineShareButton,
  LinkedinShareButton,
  LinkedinIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon
  
} from "react-share";
// import NavBar from './nav'
require('react-datepicker/dist/react-datepicker.css')

const Event = ()=>{


  const history = useNavigate()
  const {state, dispatch}= useContext(UserContex)
  const {membershipid}= useParams(UserContex)



  const  [data, setData]= useState([])

  const [search,setSearch] = useState("")

  const [addStock,setAddStock]= useState(false)
  const [editEvent,setEdit]= useState(false)
  const[id,setId] = useState("")  
 
  const[payaccount,setPayaccount]= useState("Self")
  const[price,setPrice]= useState("")
  const[venue,setVenue]=useState("")
  const[description,setDescription]=useState("")
  const[seats,setSeats]= useState("")
  const [name,setName]= useState("")
  const [col,setCol]= useState("")
  const [time,setTime]= useState("")
  const [row,setRow]= useState("")
  const [url,setUrl] = useState("")  
  const [category,setCategory] = useState("")  
  const [date,setDate] = useState(new Date())
  const [isChecked, setIsChecked] = useState(false);

  const[progress,setProgress]= useState(false)


  console.log("URL",url)
  useEffect(() => {
    fetch('/getmyevents', {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt")
      }
    })
      .then(res => res.json())
      .then(result => {
        const filteredEvents = result.events.filter(event => event.membership);
        setData(filteredEvents);
      })
      .catch(err => console.error(err));
  }, []);
  



  const singlEvent = (Itemid)=>{

  fetch(`/getsingleevent/${Itemid}`,{
    method:"get",
    headers:{
      Authorization: "Bearer " +localStorage.getItem("jwt"),
      "Content-Type":"application/json"

    }
}).then(res=>res.json())
.then(result=>{


  // setDataEdit(result.stock)
  console.log("RESULT",result.event)
  setId(result.event._id)
  setName(result.event.name)
  setVenue(result.event.venue)
  setDescription(result.event.description)
  setSeats(result.event.seats)
  setPrice(result.event.price)
  setTime(result.event.time)
  setUrl(result.event.pic)

  //setDob(result.employee.dob)
  
  

          
})

}
  



  

  const postEvent = ()=>{

    fetch('/addevent',{

      method:"post",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({
       
        name,venue,seats,description,price,pic:url,date,row,col,category,time,payaccount,membership:membershipid
      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
      }

      else{
        
        window.location.reload();
            // history.go(`/employee`)

      }
    })
    .catch(err=>{
      console.log(err)
    })

  }


  
  const uploadppt=({target:{files}})=>{

    setProgress(true)

    let data = new FormData()

    for(const file of files){

   data.append('uploadimage', file)
   data.append('name',file.name)
}



       fetch("/uploadimage", {

         method:"post",
         body: data
     })
     .then(res=>res.json())
     .then(data=>{

          setUrl(data.url)
          setProgress(false)

      

       
     })
     .catch(err=>{
         console.log(err)
     })

}
  



  const postEventUpdate = ()=>{

    fetch('/updateevent',{

      method:"put",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({
        id:id,
        name,venue,seats,description,price,pic:url,col,row,category,time
        
      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
      }

      else{
        window.location.reload();
        // history.go('/dashboard')
      }
    })
    .catch(err=>{
      console.log(err)
    })

  }





  const fetchPost=(query)=>{


    setSearch(query)

    fetch('/search-employee',{

      method:"post",
      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt"),

        "Content-Type":"application/json"

      },
      body:JSON.stringify({
        query
      })

    }).then(res=> res.json())
      .then(results=>{
        
        setData(results.employee)
      })


  }

  const deleteEmployee = (postId)=>{

    fetch(`/deleteevent/${postId}`,{
        method: "delete",
        headers:{
          "Content-Type":"application/json",
          Authorization: "Bearer " +localStorage.getItem("jwt")
        }
    }).then(res=>res.json())
    .then(result=>{
        window.location.reload(false);

    }).catch(err=>{
        console.log(err)
    })
}
  
    //deleteEmployee
    

    const Logout = ()=>{
        localStorage.clear()
        dispatch({type:"CLEAR"})
            history(`/login`)
    }



    const [copySuccess, setCopySuccess] = useState(null);

    const copyToClipboard = async (linkToCopy) => {
      try {
        await navigator.clipboard.writeText(`https://www.bodhi5entertainment.com/single/${linkToCopy}`);
        setCopySuccess(linkToCopy);
      } catch (err) {
        console.error('Unable to copy to clipboard', err);
        setCopySuccess('Copy failed. Please try again.');
      }
    };
  

return(

    <div>


 <script src="https://cdn.jsdelivr.net/gh/alpinejs/alpine@v2.x.x/dist/alpine.min.js" defer></script>

 <div  className="flex h-screen bg-white">

 <div className=" "></div>

        <link rel="stylesheet" href="https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css" />
  






        <div className="flex-1 flex flex-col overflow-hidden ">

            
        {/* <NavBar/> */}
     <AdminHeader page="membership"></AdminHeader>

            <main className="flex-1 overflow-x-hidden overflow-y-auto bg-white">
  <div className="container mx-auto px-6 py-8">
    <h3 className="text-gray-400 text-3xl font-medium">Events</h3>


<div className="flex flex-row ...">


<div class="shadow-lg rounded-xl bg-gray-200 w-full md:w-64 p-6 bg-white relative overflow-hidden">
    <p class="text-black text-xl">
        Events
    </p>
    <div class="flex items-center my-4 text-blue-500 rounded justify-between"><svg class="h-8 w-8 text-gray-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
</svg>

        <div class="flex flex-col w-full ml-2 items-start justify-evenly">
            <p class="text-black text-lg">
                {data.length}
            </p>
            <p class="text-gray-700 text-sm">
                Total Events
            </p>
        </div>
    </div>
   
    <div class="mt-4">
        <button type="button" class="py-2 px-4  bg-black hover:bg-gray-400 focus:ring-gray-400 focus:ring-offset-gray-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md "
        
        onClick={()=>setAddStock(true)}
        >
            Add Event +
        </button>
    </div>
</div>



  
</div>
    
   
    
    <br></br>

    {
addStock? 
(
  <>
  
    <div className="bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full mb-5" >
      <div className="md:flex w-full">
        
        <div className="w-full md:w-1/2 py-10 px-5 md:px-10">
          <div className="text-center mb-10">
            <h1 className="font-bold text-3xl text-gray-900">Add Event </h1>
          </div>
          <div>
<div class="grid max-w-xl grid-cols-2 gap-4 ">


<div className="py-1">
<span className="px-1 text-sm text-gray-600">Name</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Name"
value={name}
onChange={(e)=>setName(e.target.value)}
>

</input>
</div>

<div className="py-1">
<span className="px-1 text-sm text-gray-600">Price</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Price"
value={price}
onChange={(e)=>setPrice(e.target.value)}
>

</input>
</div>


</div>


<div class="grid max-w-xl grid-cols-2 gap-4 ">


<div className="py-1">
<span className="px-1 text-sm text-gray-600">Seats</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Seats"
value={seats}
onChange={(e)=>setSeats(e.target.value)}
>

</input>
</div>

<div className="py-1">
<span className="px-1 text-sm text-gray-600">Date</span>
<DatePicker className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

          selected={date} onChange={date => setDate(date)} />

</div>




</div>




<div>
      <label className="flex items-center space-x-2">
        <input
          type="checkbox"
          // checked={isChecked}
          onChange={()=>setPayaccount("Bodhi5")}
          className="form-checkbox text-indigo-500 h-5 w-5"
        />
        <span className="text-gray-700">Receive Payment in Bodhi5s' Account</span>
      </label>
    </div>
<div>
      <label className="flex items-center space-x-2">
        <input
          type="checkbox"
          // checked={isChecked}
          onChange={()=>setIsChecked(!isChecked)}
          className="form-checkbox text-indigo-500 h-5 w-5"
        />
        <span className="text-gray-700">User needs to select Seats (Like Movies)</span>
      </label>
    </div>


{isChecked?<>
  <div class="grid max-w-xl grid-cols-2 gap-4 ">


<div className="py-1">
<span className="px-1 text-sm text-gray-600">Row</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Row"
value={row}
onChange={(e)=>setRow(e.target.value)}
>

</input>
</div>

<div className="py-1">
<span className="px-1 text-sm text-gray-600">Column</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Column"
value={col}
onChange={(e)=>setCol(e.target.value)}
>

</input>
</div>


</div>

</>:<></>}

<div class="grid max-w-xl grid-cols-2 gap-4 ">


<div className="py-1">

<label class="text-gray-700" for="animals">
    Category 
    <select id="animals" class="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" name="animals"
    
    value={category}
    onChange={(e)=>setCategory(e.target.value)}
    
    >
        <option value="">
            Select an option
        </option>
        <option value="Movies">
            Movies
        </option>
        <option value="Music">
            Music
        </option>
        <option value="Networking event">
            Networking event
        </option>
     
        
       
    </select>
</label>

</div>



<div className="py-1">
<span className="px-1 text-sm text-gray-600">Time</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Eg. 7:30 PM"
value={time}
onChange={(e)=>setTime(e.target.value)}
>

</input>
</div>

</div>

<div className="py-1">
<span className="px-1 text-sm text-gray-600">Venue</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Venue"
value={venue}
onChange={(e)=>setVenue(e.target.value)}
>

</input>
</div>


<div className="py-1">
<span className="px-1 text-sm text-gray-600">Description</span>
<textarea  type="text" className="space-y-10 text-md block px-3 py-2 rounded-lg w-full h-32
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Event Description"
value={description}
onChange={(e)=>setDescription(e.target.value)}
>

</textarea>
</div>


<div className="col-span-4 w-full mb-5 ">
                        <label className="flex flex-col items-center  bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue-300 hover:text-white">
         <svg className="w-6 h-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
             <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
         </svg>
        <span className="mt-2 text-base leading-normal">Upload</span>
        <input type='file' className="hidden" 
        onChange={uploadppt}
        />
        
    </label>
                                   </div>
 
     



  <div className="w-full px-3 mb-5">

    
  
      <button className="block w-full max-w-xs mx-auto bg-blue-300 hover:bg-blue-400 focus:bg-blue-400 text-white rounded-lg px-3 py-3 font-semibold"
                  onClick={() => {setAddStock(false);postEvent()}}

      > Add Event</button>
      <button className="block w-full max-w-xs mx-auto bg-red-300 hover:bg-red-400 focus:bg-blue-400 text-white rounded-lg px-3 py-3 font-semibold mt-4"
                  onClick={() => {setAddStock(false)}}

      > Cancel</button>
                  


    </div>



    
</div>
       
</div>

<div className=" md:block md:w-1/2 w-full  bg-white py-10 px-10">

<img src={url}></img>

{/* <Carousel showArrows={true} >
         
         
               {url ? url.map(pic=>{
                return(
                  <div>
                  <img src={pic} alt="medicine"  />
          
                   </div>
                )
               }):<></> }         
        
       
         
        
       

   

     </Carousel> */}


     {progress?         <span className=" text-base leading-normal"> Uploading...</span>:   <span className=" text-base leading-normal"> Upload Photo </span>
         }
                <input type='file' className="hidden"  required
                onChange={uploadppt}
                />
                
        
            {progress?
                <svg class="h-12 w-12 text-blue-500 animate-spin"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" />  <path d="M11 19.95a8 8 0 0 1 -5.3 -12.8" stroke-dasharray=".001 4.13" /></svg>
            :<></>}

</div>
          </div>
        </div>
  </>
)


:null

}
    {
editEvent? 
(
  <>
  
    <div className="bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full mb-5" >
      <div className="md:flex w-full">
        
        <div className="w-full md:w-1/2 py-10 px-5 md:px-10">
          <div className="text-center mb-10">
            <h1 className="font-bold text-3xl text-gray-900">Update Event </h1>
          </div>
          <div>
<div class="grid max-w-xl grid-cols-2 gap-4 ">


<div className="py-1">
<span className="px-1 text-sm text-gray-600">Name</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Name"
value={name}
onChange={(e)=>setName(e.target.value)}
>

</input>
</div>

<div className="py-1">
<span className="px-1 text-sm text-gray-600">Price</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Price"
value={price}
onChange={(e)=>setPrice(e.target.value)}
>

</input>
</div>


</div>


<div class="grid max-w-xl grid-cols-2 gap-4 ">


<div className="py-1">
<span className="px-1 text-sm text-gray-600">Seats</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Seats"
value={seats}
onChange={(e)=>setSeats(e.target.value)}
>

</input>
</div>

<div className="py-1">
<span className="px-1 text-sm text-gray-600">Date</span>
<DatePicker className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

          selected={date} onChange={date => setDate(date)} />

</div>


</div>

<div className="py-1">
<span className="px-1 text-sm text-gray-600">Venue</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Venue"
value={venue}
onChange={(e)=>setVenue(e.target.value)}
>

</input>
</div>



<div className="py-1">
<span className="px-1 text-sm text-gray-600">Description</span>
<textarea  type="text" className="space-y-10 text-md block px-3 py-2 rounded-lg w-full h-32
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Product Description"
value={description}
onChange={(e)=>setDescription(e.target.value)}
>

</textarea>
</div>


<div className="col-span-4 w-full mb-5 ">
                        <label className="flex flex-col items-center  bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue-300 hover:text-white">
         <svg className="w-6 h-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
             <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
         </svg>
        <span className="mt-2 text-base leading-normal">Upload</span>
        <input type='file' className="hidden" 
        onChange={uploadppt}
        />
        
    </label>
                                   </div>
 
     



  <div className="w-full px-3 mb-5">

    
  
      <button className="block w-full max-w-xs mx-auto bg-blue-300 hover:bg-blue-400 focus:bg-blue-400 text-white rounded-lg px-3 py-3 font-semibold"
                  onClick={() => {setAddStock(false);postEventUpdate()}}

      > Update Event</button>
      <button className="block w-full max-w-xs mx-auto bg-red-300 hover:bg-red-400 focus:bg-blue-400 text-white rounded-lg px-3 py-3 font-semibold mt-4"
                  onClick={() => {setEdit(false)}}

      > Cancel</button>
                  


    </div>



    
</div>
       
</div>

<div className=" md:block md:w-1/2 w-full  bg-white py-10 px-10">

<img src={url}></img>

{/* <Carousel showArrows={true} >
         
         
               {url ? url.map(pic=>{
                return(
                  <div>
                  <img src={pic} alt="medicine"  />
          
                   </div>
                )
               }):<></> }         
        
       
         
        
       

   

     </Carousel> */}


     {progress?         <span className=" text-base leading-normal"> Uploading...</span>:   <span className=" text-base leading-normal"> Upload Photo </span>
         }
                <input type='file' className="hidden"  required
                onChange={uploadppt}
                />
                
        
            {progress?
                <svg class="h-12 w-12 text-blue-500 animate-spin"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" />  <path d="M11 19.95a8 8 0 0 1 -5.3 -12.8" stroke-dasharray=".001 4.13" /></svg>
            :<></>}

</div>
          </div>
        </div>
  </>
)


:null

}







  








    <div className="w-full h-10 pl-3 pr-2 bg-gray-100 border-gray-50 rounded-full  outline-none  border-none flex focus:outline-none justify-between items-center relative">
  <input type="text" name="search" id="search" placeholder="Search" 
         className=" w-full bg-gray-100 border-none rounded-full    focus:outline-none focus:border-white active:outline-none"
         value= {search} onChange={(e)=>fetchPost(e.target.value)}
         />
  <button type="submit" className="ml-1 outline-none border-none focus:border-none  active:outline-none">
    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
         viewBox="0 0 24 24" className="w-6 h-6">
      <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
    </svg>
  </button>
</div>

 
<br/>

 
       
<div class="grid grid-cols-1 gap-4 md:grid-cols-3 ">

                    

      {data.map(item=>{

        return(



          <>
          
        
<div class="m-auto overflow-hidden rounded-lg shadow-lg cursor-pointer h-full w-11/12 md:w-11/12  mb-2">
                <a href="#" class="block w-full h-full">
                    <img alt="blog photo" src={item.pic?item.pic:"https://images.unsplash.com/photo-1470229722913-7c0e2dbbafd3?auto=format&fit=crop&q=80&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&w=1470"} class="object-cover object-top w-full max-h-40"/>
                    <div class="w-full p-4 bg-white ">
                        <p class="font-medium text-indigo-500 text-md">
                            {item.name}
                        </p>
                        <div class="flex items-center">
            <svg class="h-6 w-6 text-indigo-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
</svg>
            
             <p class="text-md text-black  ml-2">
            
            {item.venue}
        </p>
    </div>
          
                        <div class="flex items-center">
                        <svg class="h-6 w-6 text-indigo-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="5" cy="5" r="1" />  <circle cx="12" cy="5" r="1" />  <circle cx="19" cy="5" r="1" />  <circle cx="5" cy="12" r="1" />  <circle cx="12" cy="12" r="1" />  <circle cx="19" cy="12" r="1" />  <circle cx="5" cy="19" r="1" />  <circle cx="12" cy="19" r="1" />  <circle cx="19" cy="19" r="1" /></svg>
            
             <p class="text-md text-black  ml-2">
            
            {item.seats} Seats
        </p>
    </div>
          
    
                        <p class="font-light text-gray-400  text-md">
                            {item.description.substring(0,30)} ......
                        </p>
                        <div class=" items-center gap-4 mt-8">
        <button type="button" class="w-1/2 px-4 py-2 text-base border rounded-lg text-white bg-red-500 hover:bg-red-700 "
        
        onClick={()=>{if(window.confirm('Are you sure you want to delete?')) deleteEmployee(item._id)}}

        >
            Remove
        </button>
        <button type="button" class="w-1/2 px-4 py-2 text-base border rounded-lg text-white bg-blue-600 hover:bg-blue-700 "
                          onClick={()=>{setEdit(true);singlEvent(item._id)}}
                          >
            Edit
        </button>


<div class="flex flex-row items-center justify-center mt-2">

<FacebookShareButton url= {`https://www.bodhi5entertainment.com/single/${item._id}`}><FacebookIcon class="h-10 w-10 mx-2 my-2 rounded-full"/></FacebookShareButton>
<TwitterShareButton url= {`https://www.bodhi5entertainment.com/single/${item._id}`}><TwitterIcon class="h-10 w-10 mx-2 my-2 rounded-full"/></TwitterShareButton>
<LinkedinShareButton url= {`https://www.bodhi5entertainment.com/single/${item._id}`}><LinkedinIcon class="h-10 w-10 mx-2 my-2 rounded-full"/></LinkedinShareButton>
<FacebookMessengerShareButton url= {`https://www.bodhi5entertainment.com/single/${item._id}`}><FacebookMessengerIcon class="h-10 w-10 mx-2 my-2 rounded-full"/></FacebookMessengerShareButton>
<WhatsappShareButton url= {`https://www.bodhi5entertainment.com/single/${item._id}`}><WhatsappIcon class="h-10 w-10 mx-2 my-2 rounded-full"/></WhatsappShareButton>

<svg class={`h-8 w-8 ${copySuccess==item._id?"text-green-500":"text-gray-600"}`}  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"

onClick={()=>{copyToClipboard(item._id)}}
>  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="8" y="8" width="12" height="12" rx="2" />  <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" /></svg>
</div>
  
{/* <button type="button" class="mt-2 px-4 flex justify-center items-center  bg-purple-500 hover:bg-purple-600 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  w-12 h-12 rounded-lg ">
<svg class="h-8 w-8 text-white"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5" />  <line x1="10" y1="14" x2="20" y2="4" />  <polyline points="15 4 20 4 20 9" /></svg>
</button> */}


    </div>
                    </div>
                </a>
            </div>

</>

              




             
    


        
      )

      })}
      </div>


       {/* { data.map(item=>{
                      
                      
                      console.log(item._id);


            return(


                <Link to={`/singlerecord/${item._id}`}>
                <li class="border-gray-400 flex flex-row mb-2">
                <div class="shadow border select-none cursor-pointer bg-white dark:bg-gray-800 rounded-md flex flex-1 items-center p-4">
                   
                    <div class="flex-1 pl-1 md:mr-16">
                        <div class="font-medium dark:text-white">
                            {item.name}
                        </div>
                        <div class="text-gray-600 dark:text-gray-200 text-sm">
                            {item.address}
                        </div>
                    </div>
                    <div class="flex md:space-x-8 space-x-6 w-14 h-10 justify-center items-center mr-4">
                            {item.phone}                     
                    </div>
                                   
                </div>
            </li>
                </Link>
                
         
            )

       })
        


       } */}

       
       
   




   
  </div>
</main>
</div>

    </div>



  </div>  
);


  
}


export default Event