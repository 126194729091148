
import React,{useContext, useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {UserContex} from '../App'
import Header from '../components/Header';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from '../components/Footer';
const AboutUs = ()=>{
  const {state, dispatch }= useContext(UserContex)
  const history =useNavigate();
  const [email,setEmail] =  useState("");
  const [password,setPassword] =  useState("");
  const navigate= useNavigate()
  const [data,setData]= useState([])
  const [search,setSearch] = useState("")

  useEffect(  ()=>{
    AOS.init();
    AOS.refresh()
    fetch('/getallevents',{
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{
      setData(result.events.reverse())
  })

  },[])

  const PostData =()=>{

   
    fetch("/signin",{
      method:"post",
      headers:{
          "Content-Type":"application/json"
      },
      body: JSON.stringify({
        
          password,
          email:email
      })
      
  }).then(res=>res.json())
  .then(data=> {
     
      if(data.error){
        toast.error(data.error)

      }
      else{
        console.log("Logéein Successful")
          localStorage.setItem("jwt", data.token)
          localStorage.setItem("user", JSON.stringify( data.user))
          dispatch({type:"USER", payload:data.user})
          navigate('/Dashboard')
        }
  })
  .catch(err=>{
      console.log(err)
  })

       }



  const fetchPost=(query)=>{


    setSearch(query)

    fetch('/searchall-events',{

      method:"post",
      headers:{

        "Content-Type":"application/json"

      },
      body:JSON.stringify({
        query
      })

    }).then(res=> res.json())
      .then(results=>{
        
        setData(results.events)
      })


  }





    return(

<>
<Header></Header>

<main class="relative  h-screen bg-white ">
  

<div class="relative">

<img src="https://img.freepik.com/free-photo/geometric-hex-backgrounds-networking_23-2149755775.jpg?w=900&t=st=1702309741~exp=1702310341~hmac=ea89db3a1a0f4b7a586adb9e1a1d4719dc6035c164dd50e04b56fa999ebea48b" class="absolute h-full w-full object-cover"/>
{/* <div class="absolute inset-0 bg-purple-500 opacity-50"></div> */}
<div class="absolute inset-0 overflow-hidden">
    <div class="absolute inset-0 bg-gradient-to-r from-black to-transparent"></div>
  </div>

{/* 
       <video autoPlay loop muted playbackRate={2.0}  class=" absolute h-1/2 w-full object-fill md:h-1/2  md:w-full md:object-cover">
    <source src="https://v4.cdnpk.net/videvo_files/video/free/video0467/large_watermarked/_import_614f00b3e16dc1.55454574_FPpreview.mp4" type="video/mp4" />
       </video> */}
 <div class="container mx-auto px-6 md:px-12 relative z-10 flex justify-center items-center py-2 xl:py-16">
        <div data-aos="fade-up" data-aos-duration="3000" class="lg:w-3/5 xl:w-2/5 flex flex-col items-center relative z-10">
           
            <h1 class=" font-bold text-4xl md:text-6xl text-white flex  leading-tight mt-14 ">
                    About Us
                <br/>
                <br/>
                <br/>
                
                {/* <Typed
            className="md:text-5xl sm:text-4xl text-xl font-bold md:pl-4 pl-2"
            strings={["it's pH", "water level"]}
            typeSpeed={200}
            backSpeed={220}
            loop
          /> */}
            </h1>
           
        </div>
    </div>
   
   
    </div>


        {/* <div class=" relative flex  mx-auto">
       
                <img src="https://img.freepik.com/free-photo/excited-audience-watching-confetti-fireworks-having-fun-music-festival-night-copy-space_637285-559.jpg?w=996&t=st=1698228712~exp=1698229312~hmac=e6d992326774f5a0d719062875e0439936f46b09ea558d4d295af285dc06e732" class=" w-full h-92"/>
        </div>
         */}
    




   
<div>


    
<div class="p-4">
<p class="text-3xl font-bold text-center text-purple-500">
    Professional team
</p>
<p class="mb-32 text-xl font-normal text-center text-gray-500">
    Meet the best team in wolrd
</p>
<div class="flex flex-col items-center space-y-24 md:space-y-0 md:flex-row justify-between">
    <div class="relative p-4">
        <div class="absolute mb-4 text-center transform translate-x-1/2 -top-16 right-1/2">
            <a href="#" class="relative block">
                <img alt="profil" src="sonam.jpeg" class="mx-auto object-cover rounded-lg h-40 w-40  border-2 border-white "/>
            </a>
        </div>
        <div class="px-8 py-4 pt-24 bg-purple-50 rounded-lg shadow ">
            <div class="text-center">
                <p class="text-2xl text-gray-800 ">
                Sonam Penjor

                </p>
                <p class="text-xl font-light text-gray-500 ">
                    CEO
                </p>
                <p class="py-4 mx-auto font-light text-gray-500 text-md w-60 ">
                Sonam Penjor is one of the promoters and co-founders of the B5, and is also the CEO of the Group.

</p>
            </div>
            <div class="flex items-center justify-between w-40 pt-8 mx-auto text-gray-500 border-t border-gray-200">
                <a href="#">
                    <svg width="30" height="30" fill="currentColor" class="text-xl transition-colors duration-200 hover:text-gray-800 " viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759h-306v-759h-255v-296h255v-218q0-186 104-288.5t277-102.5q147 0 228 12z">
                        </path>
                    </svg>
                </a>
                <a href="#">
                    <svg width="30" height="30" fill="currentColor" class="text-xl transition-colors duration-200 hover:text-gray-800 " viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1684 408q-67 98-162 167 1 14 1 42 0 130-38 259.5t-115.5 248.5-184.5 210.5-258 146-323 54.5q-271 0-496-145 35 4 78 4 225 0 401-138-105-2-188-64.5t-114-159.5q33 5 61 5 43 0 85-11-112-23-185.5-111.5t-73.5-205.5v-4q68 38 146 41-66-44-105-115t-39-154q0-88 44-163 121 149 294.5 238.5t371.5 99.5q-8-38-8-74 0-134 94.5-228.5t228.5-94.5q140 0 236 102 109-21 205-78-37 115-142 178 93-10 186-50z">
                        </path>
                    </svg>
                </a>
                <a href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="text-xl transition-colors duration-200 hover:text-gray-800 " viewBox="0 0 1792 1792">
                        <path d="M896 128q209 0 385.5 103t279.5 279.5 103 385.5q0 251-146.5 451.5t-378.5 277.5q-27 5-40-7t-13-30q0-3 .5-76.5t.5-134.5q0-97-52-142 57-6 102.5-18t94-39 81-66.5 53-105 20.5-150.5q0-119-79-206 37-91-8-204-28-9-81 11t-92 44l-38 24q-93-26-192-26t-192 26q-16-11-42.5-27t-83.5-38.5-85-13.5q-45 113-8 204-79 87-79 206 0 85 20.5 150t52.5 105 80.5 67 94 39 102.5 18q-39 36-49 103-21 10-45 15t-57 5-65.5-21.5-55.5-62.5q-19-32-48.5-52t-49.5-24l-20-3q-21 0-29 4.5t-5 11.5 9 14 13 12l7 5q22 10 43.5 38t31.5 51l10 23q13 38 44 61.5t67 30 69.5 7 55.5-3.5l23-4q0 38 .5 88.5t.5 54.5q0 18-13 30t-40 7q-232-77-378.5-277.5t-146.5-451.5q0-209 103-385.5t279.5-279.5 385.5-103zm-477 1103q3-7-7-12-10-3-13 2-3 7 7 12 9 6 13-2zm31 34q7-5-2-16-10-9-16-3-7 5 2 16 10 10 16 3zm30 45q9-7 0-19-8-13-17-6-9 5 0 18t17 7zm42 42q8-8-4-19-12-12-20-3-9 8 4 19 12 12 20 3zm57 25q3-11-13-16-15-4-19 7t13 15q15 6 19-6zm63 5q0-13-17-11-16 0-16 11 0 13 17 11 16 0 16-11zm58-10q-2-11-18-9-16 3-14 15t18 8 14-14z">
                        </path>
                    </svg>
                </a>
                <a href="#">
                    <svg width="30" height="30" fill="currentColor" class="text-xl transition-colors duration-200 hover:text-gray-800 " viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                        <path d="M477 625v991h-330v-991h330zm21-306q1 73-50.5 122t-135.5 49h-2q-82 0-132-49t-50-122q0-74 51.5-122.5t134.5-48.5 133 48.5 51 122.5zm1166 729v568h-329v-530q0-105-40.5-164.5t-126.5-59.5q-63 0-105.5 34.5t-63.5 85.5q-11 30-11 81v553h-329q2-399 2-647t-1-296l-1-48h329v144h-2q20-32 41-56t56.5-52 87-43.5 114.5-15.5q171 0 275 113.5t104 332.5z">
                        </path>
                    </svg>
                </a>
              
            </div>
        </div>
    </div>
    <div class="relative p-4">
        <div class="absolute mb-4 text-center transform translate-x-1/2 -top-16 right-1/2">
            <a href="#" class="relative block">
                <img alt="profil" src="st.jpeg" class="mx-auto object-cover rounded-lg h-40 w-40  border-2 border-white "/>
            </a>
        </div>
        <div class="px-8 py-4 pt-24 bg-purple-50 rounded-lg shadow ">
            <div class="text-center">
                <p class="text-2xl text-gray-800 ">
                Sonam Tshering

                </p>
                <p class="text-xl font-light text-gray-500 ">
                Co-Founder & Director


                </p>
                <p class="py-4 mx-auto font-light text-gray-500 text-md w-60 ">
                Sonam Tshering is a co-founder of B5 and Director for Finance & HR Services.        </p>
            </div>
            <div class="flex items-center justify-between w-40 pt-8 mx-auto text-gray-500 border-t border-gray-200">
                <a href="#">
                    <svg width="30" height="30" fill="currentColor" class="text-xl transition-colors duration-200 hover:text-gray-800 " viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759h-306v-759h-255v-296h255v-218q0-186 104-288.5t277-102.5q147 0 228 12z">
                        </path>
                    </svg>
                </a>
                <a href="#">
                    <svg width="30" height="30" fill="currentColor" class="text-xl transition-colors duration-200 hover:text-gray-800 " viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1684 408q-67 98-162 167 1 14 1 42 0 130-38 259.5t-115.5 248.5-184.5 210.5-258 146-323 54.5q-271 0-496-145 35 4 78 4 225 0 401-138-105-2-188-64.5t-114-159.5q33 5 61 5 43 0 85-11-112-23-185.5-111.5t-73.5-205.5v-4q68 38 146 41-66-44-105-115t-39-154q0-88 44-163 121 149 294.5 238.5t371.5 99.5q-8-38-8-74 0-134 94.5-228.5t228.5-94.5q140 0 236 102 109-21 205-78-37 115-142 178 93-10 186-50z">
                        </path>
                    </svg>
                </a>
                <a href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="text-xl transition-colors duration-200 hover:text-gray-800 " viewBox="0 0 1792 1792">
                        <path d="M896 128q209 0 385.5 103t279.5 279.5 103 385.5q0 251-146.5 451.5t-378.5 277.5q-27 5-40-7t-13-30q0-3 .5-76.5t.5-134.5q0-97-52-142 57-6 102.5-18t94-39 81-66.5 53-105 20.5-150.5q0-119-79-206 37-91-8-204-28-9-81 11t-92 44l-38 24q-93-26-192-26t-192 26q-16-11-42.5-27t-83.5-38.5-85-13.5q-45 113-8 204-79 87-79 206 0 85 20.5 150t52.5 105 80.5 67 94 39 102.5 18q-39 36-49 103-21 10-45 15t-57 5-65.5-21.5-55.5-62.5q-19-32-48.5-52t-49.5-24l-20-3q-21 0-29 4.5t-5 11.5 9 14 13 12l7 5q22 10 43.5 38t31.5 51l10 23q13 38 44 61.5t67 30 69.5 7 55.5-3.5l23-4q0 38 .5 88.5t.5 54.5q0 18-13 30t-40 7q-232-77-378.5-277.5t-146.5-451.5q0-209 103-385.5t279.5-279.5 385.5-103zm-477 1103q3-7-7-12-10-3-13 2-3 7 7 12 9 6 13-2zm31 34q7-5-2-16-10-9-16-3-7 5 2 16 10 10 16 3zm30 45q9-7 0-19-8-13-17-6-9 5 0 18t17 7zm42 42q8-8-4-19-12-12-20-3-9 8 4 19 12 12 20 3zm57 25q3-11-13-16-15-4-19 7t13 15q15 6 19-6zm63 5q0-13-17-11-16 0-16 11 0 13 17 11 16 0 16-11zm58-10q-2-11-18-9-16 3-14 15t18 8 14-14z">
                        </path>
                    </svg>
                </a>
                <a href="#">
                    <svg width="30" height="30" fill="currentColor" class="text-xl transition-colors duration-200 hover:text-gray-800 " viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                        <path d="M477 625v991h-330v-991h330zm21-306q1 73-50.5 122t-135.5 49h-2q-82 0-132-49t-50-122q0-74 51.5-122.5t134.5-48.5 133 48.5 51 122.5zm1166 729v568h-329v-530q0-105-40.5-164.5t-126.5-59.5q-63 0-105.5 34.5t-63.5 85.5q-11 30-11 81v553h-329q2-399 2-647t-1-296l-1-48h329v144h-2q20-32 41-56t56.5-52 87-43.5 114.5-15.5q171 0 275 113.5t104 332.5z">
                        </path>
                    </svg>
                </a>
             
            </div>
        </div>
    </div>
    <div class="relative p-4">
        <div class="absolute mb-4 text-center transform translate-x-1/2 -top-16 right-1/2">
            <a href="#" class="relative block">
                <img alt="profil" src="dam.jpeg" class="mx-auto object-cover rounded-lg h-40 w-40  border-2 border-white "/>
            </a>
        </div>
        <div class="px-8 py-4 pt-24 bg-purple-50 rounded-lg shadow ">
            <div class="text-center">
                <p class="text-2xl text-gray-800 ">
                Damchoe Choden Namgye
                </p>
                <p class="text-xl font-light text-gray-500 ">
                Proprietor/Country Director


                </p>
                <p class="py-4 mx-auto font-light text-gray-500 text-md w-60 ">
                Damchoe Choden Namgye is the proprietor and co-founders of B5, and is also the Country Director of the B5 Group.

</p>
            </div>
            <div class="flex items-center justify-between w-40 pt-8 mx-auto text-gray-500 border-t border-gray-200">
                <a href="#">
                    <svg width="30" height="30" fill="currentColor" class="text-xl transition-colors duration-200 hover:text-gray-800 " viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759h-306v-759h-255v-296h255v-218q0-186 104-288.5t277-102.5q147 0 228 12z">
                        </path>
                    </svg>
                </a>
                <a href="#">
                    <svg width="30" height="30" fill="currentColor" class="text-xl transition-colors duration-200 hover:text-gray-800 " viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1684 408q-67 98-162 167 1 14 1 42 0 130-38 259.5t-115.5 248.5-184.5 210.5-258 146-323 54.5q-271 0-496-145 35 4 78 4 225 0 401-138-105-2-188-64.5t-114-159.5q33 5 61 5 43 0 85-11-112-23-185.5-111.5t-73.5-205.5v-4q68 38 146 41-66-44-105-115t-39-154q0-88 44-163 121 149 294.5 238.5t371.5 99.5q-8-38-8-74 0-134 94.5-228.5t228.5-94.5q140 0 236 102 109-21 205-78-37 115-142 178 93-10 186-50z">
                        </path>
                    </svg>
                </a>
                <a href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="text-xl transition-colors duration-200 hover:text-gray-800 " viewBox="0 0 1792 1792">
                        <path d="M896 128q209 0 385.5 103t279.5 279.5 103 385.5q0 251-146.5 451.5t-378.5 277.5q-27 5-40-7t-13-30q0-3 .5-76.5t.5-134.5q0-97-52-142 57-6 102.5-18t94-39 81-66.5 53-105 20.5-150.5q0-119-79-206 37-91-8-204-28-9-81 11t-92 44l-38 24q-93-26-192-26t-192 26q-16-11-42.5-27t-83.5-38.5-85-13.5q-45 113-8 204-79 87-79 206 0 85 20.5 150t52.5 105 80.5 67 94 39 102.5 18q-39 36-49 103-21 10-45 15t-57 5-65.5-21.5-55.5-62.5q-19-32-48.5-52t-49.5-24l-20-3q-21 0-29 4.5t-5 11.5 9 14 13 12l7 5q22 10 43.5 38t31.5 51l10 23q13 38 44 61.5t67 30 69.5 7 55.5-3.5l23-4q0 38 .5 88.5t.5 54.5q0 18-13 30t-40 7q-232-77-378.5-277.5t-146.5-451.5q0-209 103-385.5t279.5-279.5 385.5-103zm-477 1103q3-7-7-12-10-3-13 2-3 7 7 12 9 6 13-2zm31 34q7-5-2-16-10-9-16-3-7 5 2 16 10 10 16 3zm30 45q9-7 0-19-8-13-17-6-9 5 0 18t17 7zm42 42q8-8-4-19-12-12-20-3-9 8 4 19 12 12 20 3zm57 25q3-11-13-16-15-4-19 7t13 15q15 6 19-6zm63 5q0-13-17-11-16 0-16 11 0 13 17 11 16 0 16-11zm58-10q-2-11-18-9-16 3-14 15t18 8 14-14z">
                        </path>
                    </svg>
                </a>
                <a href="#">
                    <svg width="30" height="30" fill="currentColor" class="text-xl transition-colors duration-200 hover:text-gray-800 " viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                        <path d="M477 625v991h-330v-991h330zm21-306q1 73-50.5 122t-135.5 49h-2q-82 0-132-49t-50-122q0-74 51.5-122.5t134.5-48.5 133 48.5 51 122.5zm1166 729v568h-329v-530q0-105-40.5-164.5t-126.5-59.5q-63 0-105.5 34.5t-63.5 85.5q-11 30-11 81v553h-329q2-399 2-647t-1-296l-1-48h329v144h-2q20-32 41-56t56.5-52 87-43.5 114.5-15.5q171 0 275 113.5t104 332.5z">
                        </path>
                    </svg>
                </a>
           
            </div>
        </div>
    </div>
   
</div>
</div>

<div class="flex flex-wrap w-full">


<div class="max-w-screen-xl p-8 mx-auto">
<h2 class="mb-12 text-3xl font-extrabold leading-9 text-gray-900 border-b-2 border-gray-100">
    FAQs
</h2>
<ul class="flex flex-wrap items-start gap-8">
    <li class="w-2/5">
        <p class="text-lg font-medium leading-6 text-gray-900">
        What is Bodhi5 Entertainment?
        </p>
        <p class="mt-2">
            <p class="text-base leading-6 text-gray-500">
            Bodhi5 Entertainment is an innovative event management platform that helps organizers plan, promote, and execute successful events of all types and sizes. Whether you're hosting a concert, conference, fundraiser, or any other event, Bodhi5 provides the tools you need to make it a memorable experience.

</p>
        </p>
    </li>
    <li class="w-2/5">
        <p class="text-lg font-medium leading-6 text-gray-900">
        How does Bodhi5 differ from other event management platforms?        </p>
        <p class="mt-2">
            <p class="text-base leading-6 text-gray-500">
            Bodhi5 stands out with its user-friendly interface, robust features, and a focus on creating a seamless experience for both event organizers and attendees. Our platform is designed to simplify the event planning process while maximizing engagement and satisfaction                 </p>
        </p>
    </li>
    <li class="w-2/5">
        <p class="text-lg font-medium leading-6 text-gray-900">
        What types of events can be managed using Bodhi5 Entertainment?        </p>
        <p class="mt-2">
            <p class="text-base leading-6 text-gray-500">
            Bodhi5 caters to a wide range of events, including but not limited to movies, concerts, conferences, workshops, fundraisers, sports events, and community gatherings. Our platform is flexible and customizable to meet the unique needs of various event organizers.                 </p>
        </p>
    </li>
    <li class="w-2/5">
        <p class="text-lg font-medium leading-6 text-gray-900">
        How can I get started with Bodhi5 Entertainment?
        </p>
        <p class="mt-2">
            <p class="text-base leading-6 text-gray-500">
            Getting started with Bodhi5 is easy! Simply visit our website, create an account, and explore the intuitive dashboard to set up your event. We provide step-by-step guidance and support throughout the process to ensure a smooth experience.

            
            </p>
        </p>
    </li>

</ul>
</div>

    </div>


</div>
    <Footer></Footer>
</main>

</>

  


    );
}

export default AboutUs;





