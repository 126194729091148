import React, {useContext, useRef, useEffect, useState} from 'react'
import {Link,useNavigate,useParams} from 'react-router-dom'
import DatePicker from 'react-datepicker'
import {UserContex} from '../App'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import NavBar from './nav'
import Header from '../components/Header'
import Footer from '../components/Footer';
// import Footer from './Footer'

import { loadStripe } from '@stripe/stripe-js';

const Membership = ()=>{


  const history = useNavigate()
  const {state, dispatch}= useContext(UserContex)

  const  [data, setData]= useState([])

  const [search,setSearch] = useState("")
  const  [cart, setCart]= useState([])
  const  [user, setuser]= useState()
  const  [date, setDate]= useState(new Date())

  const [addStock,setAddStock]= useState(false)
  const [editStock,setEditStock]= useState(false)

 

  const [checkOut,setCheckOut]= useState(false)
  const[address,setAddress]= useState("")
  const[allAddress,setAllAddress]= useState([])

  const [location,setLocation] = useState([])  

  const[progress,setProgress]= useState(false)
  const [stripePromise, setStripePromise] = useState(null);



  useEffect(  ()=>{

    

    fetch(`/getallusermemberships`,{
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{
      setData(result.events)
      setuser(result.user)
      setLocation(getAllVenues(result.events))
      
  })

  setStripePromise(loadStripe('pk_live_51ONSZbJpwmglr0iuYbQWX5Lw2zavtSQiKCSsF3WXZcozd87tyuW7CORF5GY3p0EhUFR24ywHYVI41qQJjRpaCzbP00g86p4xiO'));


  fetch(`/getconsumerprofile`,{
    method:"get",
    headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt"),
        "Content-Type":"application/json"

        
    },
 
}).then(res=>res.json())
.then(result=>{

  // console.log("DSF",result.consumer[0].address)
    // setOrderHistoryData(result.orders)
    // setData(result.consumer)
    setAllAddress(result.consumer[0].address)
})


  },[])

  




  const addMembers = (membershipId)=>{


    
    fetch('/addMember',{

        method:"post",
        headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt"),
  
          "Content-Type":"application/json"
  
        },
        body:JSON.stringify({
            date,membership:membershipId,user:user 
        })
  
      }).then(res=> res.json())
        .then(results=>{
          
        //   setData(results.events)

        history('/profile')
        
        })

  }


  const fetchPost=(query)=>{


    setSearch(query)

    fetch('/searchall-shows',{

      method:"post",
      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt"),

        "Content-Type":"application/json"

      },
      body:JSON.stringify({
        query,
      })

    }).then(res=> res.json())
      .then(results=>{
        
        setData(results.events)
      })


  }





  const getAllVenues = (events) => {
    // Use a Set to store unique venues
    const uniqueVenues = new Set();
  
    // Iterate through the events and add each venue to the Set
    events.forEach((event) => {
      uniqueVenues.add(event.category);
    });
  
    // Convert the Set back to an array
    return Array.from(uniqueVenues);
  };
  
  const handleSubscribe = async (membershipId,stripeid) => {

    console.log("SDFSDF",stripeid)
    try {
      const stripe = await stripePromise;

      // Call your backend to create a Stripe checkout session
      // const response = await 
      fetch('/create-checkout-session', {

        method:"post",
        headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt"),
  
          "Content-Type":"application/json"
  
        },
        body:JSON.stringify({
          membershipId,
          stripeid,
        })
  
      }).then(res=> res.json())
      .then(results=>{
        // console.log("ERWE",results.url)
      window.location.href = results.url

        // setData(results.events)
      })

      // const sessionUrl = response.url;

      // const session = await response.json();
    
      // window.location.href = sessionUrl
      // await stripe.redirectToCheckout({ sessionId: session.id });
    } catch (error) {
      console.error('Error during subscription:', error);
    }
  };

return(
<main class="bg-white h-screen  relative overflow-x-hidden">
    <div class="flex items-start justify-between">
       
        <div class="flex flex-col w-full ">

            

            <Header></Header>


            
{/* 
            <div>
      <p>Microphone: {listening ? 'on' : 'off'}</p>
      <button onClick={SpeechRecognition.startListening}>Start</button>
      <button onClick={SpeechRecognition.stopListening}>Stop</button>
      <button onClick={resetTranscript}>Reset</button>
      <p>{transcript}</p>
    </div> 
    
    
    */}


         <div class=" md:h-96 h-46  w-full   bg-cover bg-center bg-no-repeat" style={{ 
      backgroundImage: `url("https://i.pinimg.com/originals/72/59/af/7259afb11f50dc015e29b399be096836.jpg")` 
    }}>

                <div class=" mx-8 sm:mx-20 my-24 sm:my-32 ">

            <div className="w-full bg-white border-none rounded-lg h-16 bg-opacity-80 hover:bg-opacity-100 p-4  focus:outline-none focus:border-white active:outline-none flex focus:outline-none justify-between items-center relative">
 <input type="text" name="search" id="search" placeholder={  'Search'} 
        className=" w-full bg-white border-none rounded-lg h-16 bg-opacity-10 hover:bg-opacity-100 p-4  focus:outline-none focus:border-none active:outline-none"
        
        value= {search} onChange={(e)=>fetchPost(e.target.value)}
        />
 <button type="submit" className="ml-1 outline-none border-none focus:border-none  active:outline-none">
   <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
        viewBox="0 0 24 24" className="w-6 h-6 text-purple-500">
     <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
   </svg>
 </button>


</div>


      
            </div>
            </div>







        </div>
     

    </div>


    <div class="flex flex-col bg-white  ">

<div
  class="flex overflow-x-scroll   hide-scroll-bar"
>
  <div
    class="flex flex-nowrap md:h-20 h-12    ml-4 "
  >
     <div class="px-1 md:py-6 py-2">
                
    
<select id="animals" class="px-4 py-2 flex items-center text-base rounded-full text-purple-500 border border-purple-500 undefined" name="animals"
    
    value= {search} onChange={(e)=>fetchPost(e.target.value)}

    
    >
        <option class="shadow border select-none cursor-pointer bg-white  rounded-md flex flex-1 items-center p-4" value="">
            Search by Venue
        </option>
        {location.length>0? location.map(item=>{
return(  <option value={item}>
  {item}
</option>)
        }):<></>}
     
     
        
       
    </select>

                    </div>
                   
  </div>
</div>
</div> 









<div class="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3 place-items-center m-4">

        
        {/* <div className="grid  grid-cols-1  md:grid-cols-4  gap-4   bg-white place-items-center "> */}
          



        {data?data.map(item=>{
return(
   


<div class="flex bg-white rounded-lg shadow ">
    <div class="relative flex-none w-24 md:w-48">
    

        <img     src={item.pic?item.pic:"https://images.unsplash.com/photo-1470229722913-7c0e2dbbafd3?auto=format&fit=crop&q=80&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&w=1470"}
 alt="shopping image" class="absolute inset-0 object-cover w-full h-full rounded-lg"/>
    </div>
    <div class="flex-auto p-4">
    <Link to={`singlemembership/${item._id}`}>

        <div class="flex flex-wrap">
            <h1 class="flex-auto text-xl font-semibold ">
                {item.name}
            </h1>
            {/* <div class="text-xl font-semibold text-gray-500 dark:text-gray-300">
                $110.00
            </div> */}
            {/* <div class="flex-none w-full mt-2 text-sm font-medium text-gray-500 ">
                Active
            </div> */}
        </div>

        <p class="text-sm text-gray-500 ">
                                {item.description}
                            </p>
        
                                  </Link>
                            {item.paid?<>
                            
 <p class="text-sm text-gray-500 mb-4">
 ${item.price}
</p>
<div class="flex mb-4 text-sm font-medium">
<button type="button" class="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
onClick={()=>{handleSubscribe(item._id,item.vendor.stripe.id)}}
>
    Apply for Membership
</button>
</div>
</>
        
                            :
                            <div class="flex mb-4 text-sm font-medium">
                            <button type="button" class="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                            onClick={()=>{addMembers(item._id)}}
                            >
                                Apply for Membership
                            </button>
                        </div>
                            
                          }
                           
                           


                        </div>
                    </div>

       
//   <div class=" overflow-hidden rounded-lg shadow-lg cursor-pointer h-90 w-11/12 ">
//   <a href={`/singlemembership/${item._id}`} class="block w-full h-full">
//   <img alt="blog photo" src={item.pic?item.pic:"https://images.unsplash.com/photo-1470229722913-7c0e2dbbafd3?auto=format&fit=crop&q=80&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&w=1470"} class="object-cover object-top w-full max-h-40"/>
//       <div class="w-full p-4 bg-white ">
//           <p class="font-medium text-purple-500 text-md">
//               {item.name}
//           </p>






//           <p class="font-light text-gray-400 text-md">
//               {item.description.substring(0,28)}... Read More
//           </p>
         
//       </div>
//   </a>
// </div>
        

)

}):<></>}









</div>








    {/* <Footer/> */}



{/* 




<div class=" lg:invisible visible fixed bottom-0 flex flex-row w-full ">

<div class="flex items-center w-full">
    <button type="button" class="w-full px-4 py-2 text-base font-medium text-black bg-white  rounded-l-md hover:bg-gray-100"
    onClick={()=>history.push("/home")}
    >
    <svg class="h-6 w-6 text-blue-400 content-center justify-center	ml-4"  width="26" height="26" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <polyline points="5 12 3 12 12 3 21 12 19 12" />  <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />  <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" /></svg>
    </button>
    <button type="button" class="w-full px-4 py-2 text-base font-medium text-black bg-white  hover:bg-gray-100"
        onClick={()=>history.push("/products")}

    >
    <svg class="h-6 w-6 text-blue-400 content-center justify-center	ml-4"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M4.5 12.5l8 -8a4.94 4.94 0 0 1 7 7l-8 8a4.94 4.94 0 0 1 -7 -7" />  <path d="M8.5 8.5l7 7" /></svg>
    </button>
    <button type="button" class="w-full px-4 py-2 text-base font-medium text-black bg-white  hover:bg-gray-100"
            onClick={()=>history.push("/profile")}

    >
    <svg class="h-6 w-6 text-blue-400 content-center justify-center	ml-4"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
</svg>
    </button>
    <button type="button" class="w-full px-4 py-2 text-base font-medium text-black bg-white rounded-r-md hover:bg-gray-100"
                onClick={()=>history.push("/cart")}

    >
    <svg class="h-6 w-6 text-blue-400 content-center justify-center	ml-4 "  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="9" cy="19" r="2" />  <circle cx="17" cy="19" r="2" />  <path d="M3 3h2l2 12a3 3 0 0 0 3 2h7a3 3 0 0 0 3 -2l1 -7h-15.2" /></svg>

    </button>
</div>


</div> */}


<Footer page="findshows"></Footer>
</main>
);


  
}


export default Membership