import { useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";

function Payment(props) {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  useEffect(() => {
      // const { publishableKey } = await r.json();
      // setStripePromise(loadStripe('pk_test_51ONSZbJpwmglr0iuySAABsiEMnMPzdh5yz65LLOR6Tcsx6zUiSoZJdxYDSGhjS9ErCofO8g5fjAYlbMUiyHhLP6M00kPOrcx4T'));
      setStripePromise(loadStripe('pk_live_51ONSZbJpwmglr0iuYbQWX5Lw2zavtSQiKCSsF3WXZcozd87tyuW7CORF5GY3p0EhUFR24ywHYVI41qQJjRpaCzbP00g86p4xiO'));


    
  }, []);

  useEffect(() => {
    
      fetch("/create-payment-intent", {
        method: "POST",
        headers:{
          "Content-Type":"application/json"
        },
        body: JSON.stringify({amount:props.amount, vendor:props.vendor}),
      }).then(async (result) => {
        var { clientSecret } = await result.json();
        setClientSecret(clientSecret);
      });
    }, []);




  return (
    <>
      {/* <h1>Enter your card details (Step 3/3)</h1> */}


<p class="mb-0 text-center font-extrabold text-purple-500 ">
                      Step 3/3: Enter your card details
                  </p>
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm data={props.data} seats={props.seats} />
        </Elements>
      )}
    </>
  );
}

export default Payment;
