import React, {useContext, useRef, useEffect, useState} from 'react'
import {Link,useNavigate,useParams} from 'react-router-dom'
import DatePicker from 'react-datepicker'
import {UserContex} from '../App'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import NavBar from './nav'
import Header from '../components/Header'
import Footer from '../components/Footer';
// import Footer from './Footer'


const EventCategory = ()=>{


  const history = useNavigate()
  const {state, dispatch}= useContext(UserContex)

  const  [data, setData]= useState([])

  const [search,setSearch] = useState("")
  const  [cart, setCart]= useState([])

  const [addStock,setAddStock]= useState(false)
  const [editStock,setEditStock]= useState(false)

  const[name,setName]= useState("")
  const[nameEdit,setNameEdit]= useState("")
  const[idEdit,setidEdit]= useState("")
  const[quantity,setQuantity]= useState()
  const[quantityEdit,setQuantityEdit]= useState()
  const[rate,setRate]= useState()
  const[rateEdit,setRateEdit]= useState()

  const [checkOut,setCheckOut]= useState(false)
  const[address,setAddress]= useState("")
  const[allAddress,setAllAddress]= useState([])

  const [url,setUrl] = useState([])  

  const[progress,setProgress]= useState(false)
  const {category} = useParams()

//   const {
//     transcript,
//     listening,
//     resetTranscript,
//     browserSupportsSpeechRecognition
//   } = useSpeechRecognition();



//     const [startDate, setStartDate] = useState(new Date());


//   const monthNames = ["January", "February", "March", "April", "May", "June",
//   "July", "August", "September", "October", "November", "December"];

// var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

// const d = new Date();




  useEffect(  ()=>{

    

    fetch(`/getcategory/${category}`,{
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{
      setData(result.events)
      
  })



  fetch(`/getconsumerprofile`,{
    method:"get",
    headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt"),
        "Content-Type":"application/json"

        
    },
 
}).then(res=>res.json())
.then(result=>{

  // console.log("DSF",result.consumer[0].address)
    // setOrderHistoryData(result.orders)
    // setData(result.consumer)
    setAllAddress(result.consumer[0].address)
})


  },[])

  
  useEffect(() => {
    // This effect uses the `value` variable,
    // so it "depends on" `value`.

    const l = JSON.parse(localStorage.getItem('cart'))

    if(l){
      setCart(l)
    }


    // setSearch(transcript)
    // fetchPost(transcript)
  }, [])



  const fetchPost=(query)=>{


    setSearch(query)

    fetch('/searchcategory',{

      method:"post",
      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt"),

        "Content-Type":"application/json"

      },
      body:JSON.stringify({
        query,
        category:category
      })

    }).then(res=> res.json())
      .then(results=>{
        
        setData(results.events)
      })


  }

  const singleStock = (Itemid)=>{

      console.log(Itemid)
    fetch('/getSingleStock',{
      method:"post",
      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt"),
        "Content-Type":"application/json"

      },
      body:JSON.stringify({
        id:Itemid
      })
  }).then(res=>res.json())
  .then(result=>{


    // setDataEdit(result.stock)
    console.log(result)
    setidEdit(result.stock._id)
    setNameEdit(result.stock.name)
    setQuantityEdit(result.stock.quantity)
    setRateEdit(result.stock.rate)
            
  })

  }


  const uploadppt=({target:{files}})=>{


    console.log("UPLOAD")

    setProgress(true)

    let data = new FormData()

    for(const file of files){

   data.append('uploadimage', file)
   data.append('name',file.name)
}
       fetch("/uploadimage", {

         method:"post",
         body: data
     })
     .then(res=>res.json())
     .then(data=>{

      console.log("PROGRESS",data.url)

          setUrl(old=>[...old,data.url])
          setProgress(false)

      

       
     })
     .catch(err=>{
         console.log(err)
     })

}
  

const orderPrescription = ()=>{




  fetch('/addOrder',{

    method:"post",
    headers:{
      "Content-Type":"application/json",
      Authorization: "Bearer " +localStorage.getItem("jwt")

    },
    body: JSON.stringify({
     
      consumer:state._id,
      prescription:url,
      address:address

    })
    
  }).then(res=>res.json())
  .then(data=>{
    if(data.error){
      console.log("Error")
    }

    else{
      // toast.success("ORDER PLACED SUCESSFULLY");
         window.location.reload();

    }
  })
  .catch(err=>{
    console.log(err)
  })

  // window.location.reload();
  




}




const addtoCart = (item)=>{

  setCart(olditem=>[...olditem, item._id])

  fetch('/addToCart',{

    method:"post",
    headers:{
      Authorization: "Bearer " +localStorage.getItem("jwt"),

      "Content-Type":"application/json"

    },
    body:JSON.stringify({
      product:item._id,
      consumer:state._id
      
    })

  }).then(res=> res.json())
    .then(results=>{
      
      // setData(results.order)
    })



const json = localStorage.getItem("cart");
  const previousPosts = json ? JSON.parse(json) : [];
  const updatedPosts = [...previousPosts, item._id];
  // const uniquePosts = Array.from(new Set(updatedPosts))
  const stringifyed = JSON.stringify(updatedPosts);
  localStorage.setItem("cart", stringifyed);
}



return(
<main class="bg-white  h-screen  relative overflow-x-hidden">
    <div class="flex items-start justify-between">
       
        <div class="flex flex-col w-full ">

            

            <Header></Header>


            
{/* 
            <div>
      <p>Microphone: {listening ? 'on' : 'off'}</p>
      <button onClick={SpeechRecognition.startListening}>Start</button>
      <button onClick={SpeechRecognition.stopListening}>Stop</button>
      <button onClick={resetTranscript}>Reset</button>
      <p>{transcript}</p>
    </div> 
    
    
    */}


         <div class=" md:h-96 h-46  w-full   bg-cover bg-center bg-no-repeat" style={{ 
      backgroundImage: `url("https://img.freepik.com/free-photo/backstage-trendy-viva-magenta-generative-ai_169016-28977.jpg?w=900&t=st=1701324418~exp=1701325018~hmac=0a32f23e4f1535f3d5cc2cea587c6fe3d254cca4eda1ba9038a78c28b2fa805d")` 
    }}>

                <div class=" mx-8 sm:mx-20 my-24 sm:my-32 ">

            <div className="w-full  object-center  h-10 p-3 bg-white border-gray-50 rounded-full  outline-none  border-none flex focus:outline-none justify-between items-center relative">
 <input type="text" name="search" id="search" placeholder={  'Search'} 
        className=" w-full bg-white border-none rounded-full    focus:outline-none focus:border-white active:outline-none"
        
        value= {search} onChange={(e)=>fetchPost(e.target.value)}
        />
 <button type="submit" className="ml-1 outline-none border-none focus:border-none  active:outline-none">
   <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
        viewBox="0 0 24 24" className="w-6 h-6">
     <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
   </svg>
 </button>
 {/* <button type="submit" className="ml-1 outline-none border-none focus:border-none  active:outline-none"
 onClick={SpeechRecognition.startListening}
// onClick={()=>{setSearch(transcript); startListen()}}
>
{listening? <svg class="h-6 w-6 text-black"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M12 1a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V4a3 3 0 0 0-3-3z" />  <path d="M19 10v2a7 7 0 0 1-14 0v-2" />  <line x1="12" y1="19" x2="12" y2="23" />  <line x1="8" y1="23" x2="16" y2="23" /></svg>
:<svg class="h-6 w-6 text-red-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <line x1="1" y1="1" x2="23" y2="23" />  <path d="M9 9v3a3 3 0 0 0 5.12 2.12M15 9.34V4a3 3 0 0 0-5.94-.6" />  <path d="M17 16.95A7 7 0 0 1 5 12v-2m14 0v2a7 7 0 0 1-.11 1.23" />  <line x1="12" y1="19" x2="12" y2="23" />  <line x1="8" y1="23" x2="16" y2="23" /></svg>} 

</button> */}

</div>
            </div>
            </div>







        </div>
     

    </div>

{/* 
    <div class="flex flex-col bg-blue-50  ">

<div
  class="flex overflow-x-scroll   hide-scroll-bar"
>
  <div
    class="flex flex-nowrap md:h-20 h-32    ml-4 "
  >
    <div class="h-16 w-45 px-3 lg:mt-2 cursor-pointer"
    onClick={()=>history.push("category/Baby Care")}
    >
      <div
        class="md:w-44  h-16 flex flex-row max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
      >

        <img src="/babycare.png"/>
        <h3 class="pt-3 px-2 invisible lg:visible">Baby Care </h3>
      


      </div>
      <h3 class="pt-3 px-2 lg:invisible visible">Baby Care </h3>
    </div>


    <div class="h-16 w-45 px-3 lg:mt-2 cursor-pointer"
        onClick={()=>history.push("category/Test Strips")}

    >
      <div
        class="md:w-44  h-16 flex flex-row max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
      >

      <img src="/test_strips.png"/>
        <h3 class="pt-3 px-2 invisible lg:visible">Test Strips</h3>
    </div>
    <h3 class="pt-3 px-2 lg:invisible visible">Test Strips </h3>

    </div>
    <div class="h-16 w-45 px-3 lg:mt-2 cursor-pointer"
            onClick={()=>history.push("category/Health & Nutritions")}

    >
      <div
        class="md:w-44  h-16 flex flex-row max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
      >

      <img src="/health.png"/>
        <h3 class="pt-3 px-2 invisible lg:visible">Health & Nutritions</h3>
    </div>
    <h3 class="pt-3 px-2 lg:invisible visible">Health & Nutritions </h3>

    </div>

    <div class="h-16 w-45 px-3 lg:mt-2 cursor-pointer"
       onClick={()=>history.push("category/Women Care")}
    >
      <div
        class="md:w-44 h-16 flex flex-row max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
      >
      
      <img src="/women.png"/>
        <h3 class="pt-3 px-2 invisible lg:visible">Women Care</h3>
    
    </div>
    <h3 class="pt-3 px-2 lg:invisible visible">Women Care </h3>

    </div>
    
    <div class="h-16 w-45 px-3 lg:mt-2 cursor-pointer"
           onClick={()=>history.push("category/Personal Care")}

    >
      <div
        class="md:w-44 h-16 flex flex-row max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
      >

      <img src="/personal.png"/>
        <h3 class="pt-3 px-2 invisible lg:visible">Personal Care</h3>
    </div>
    <h3 class="pt-3 px-2 lg:invisible visible">Personal Care </h3>

    </div>
    
    <div class="h-16 w-45 px-3 lg:mt-2 cursor-pointer"
               onClick={()=>history.push("category/Health Devices")}

    >
      <div
        class="md:w-44 h-16 flex flex-row max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
      >

      <img src="/devices.png"/>
        <h3 class="pt-3 px-2 invisible lg:visible">Health Devices</h3>
          
      </div>
      <h3 class="pt-3 px-2 lg:invisible visible">Health Devices </h3>

    </div>
    <div class="h-16 w-45 px-3 lg:mt-2 cursor-pointer"
                   onClick={()=>history.push("category/Covid Essentials")}

    >
      <div
        class="md:w-44 h-16 flex flex-row max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
      >

      <img src="/covid.png"/>
        <h3 class="pt-3 px-2 invisible lg:visible">Covid Essentials</h3>
    
    </div>
    <h3 class="pt-3 px-2 lg:invisible visible">Covid Essentials </h3>

    </div>
  </div>
</div>
</div> */}








        
        <div className="grid  grid-cols-1  md:grid-cols-4  gap-4   bg-white place-items-center ">
          



        {data.map(item=>{
return(
   


       
  <div class=" overflow-hidden rounded-lg shadow-lg cursor-pointer h-90 w-80 md:w-80">
  <a href={`/single/${item._id}`} class="block w-full h-full">
  <img alt="blog photo" src={item.pic?item.pic:"https://images.unsplash.com/photo-1470229722913-7c0e2dbbafd3?auto=format&fit=crop&q=80&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&w=1470"} class="object-cover object-top w-full max-h-40"/>
      <div class="w-full p-4 bg-white ">
          <p class="font-medium text-purple-500 text-md">
              {item.name}
          </p>
          <div class="flex items-center">
<svg class="h-6 w-6 text-purple-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
</svg>

<p class="text-md text-black ml-2">

{item.venue}
</p>
</div>

    <div class="grid max-w-xl grid-cols-2 gap-4 ">


    <div class="flex items-center">
    <svg class="h-6 w-6 text-purple-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="5" cy="5" r="1" />  <circle cx="12" cy="5" r="1" />  <circle cx="19" cy="5" r="1" />  <circle cx="5" cy="12" r="1" />  <circle cx="12" cy="12" r="1" />  <circle cx="19" cy="12" r="1" />  <circle cx="5" cy="19" r="1" />  <circle cx="12" cy="19" r="1" />  <circle cx="19" cy="19" r="1" /></svg>

    <p class="text-md text-black  ml-2">

    {item.seats} Seats
    </p>
    </div>
    <div class="flex items-center">
    <svg class="h-6 w-6 text-purple-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <polyline points="12 6 12 12 16 14" /></svg>            
    <p class="text-md text-black  ml-2">

    {item.time}
    </p>
    </div>

    </div>



          <div class="flex items-center">
          <svg class="h-6 w-6 text-purple-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>

<p class="text-md text-black ml-2">

{item.price} Price
</p>
</div>


          <p class="font-light text-gray-400  text-md">
              {item.description.substring(0,28)}... Read More
          </p>
         
      </div>
  </a>
</div>
        

)

})}









</div>








    {/* <Footer/> */}



{/* 




<div class=" lg:invisible visible fixed bottom-0 flex flex-row w-full ">

<div class="flex items-center w-full">
    <button type="button" class="w-full px-4 py-2 text-base font-medium text-black bg-white  rounded-l-md hover:bg-gray-100"
    onClick={()=>history.push("/home")}
    >
    <svg class="h-6 w-6 text-blue-400 content-center justify-center	ml-4"  width="26" height="26" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <polyline points="5 12 3 12 12 3 21 12 19 12" />  <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />  <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" /></svg>
    </button>
    <button type="button" class="w-full px-4 py-2 text-base font-medium text-black bg-white  hover:bg-gray-100"
        onClick={()=>history.push("/products")}

    >
    <svg class="h-6 w-6 text-blue-400 content-center justify-center	ml-4"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M4.5 12.5l8 -8a4.94 4.94 0 0 1 7 7l-8 8a4.94 4.94 0 0 1 -7 -7" />  <path d="M8.5 8.5l7 7" /></svg>
    </button>
    <button type="button" class="w-full px-4 py-2 text-base font-medium text-black bg-white  hover:bg-gray-100"
            onClick={()=>history.push("/profile")}

    >
    <svg class="h-6 w-6 text-blue-400 content-center justify-center	ml-4"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
</svg>
    </button>
    <button type="button" class="w-full px-4 py-2 text-base font-medium text-black bg-white rounded-r-md hover:bg-gray-100"
                onClick={()=>history.push("/cart")}

    >
    <svg class="h-6 w-6 text-blue-400 content-center justify-center	ml-4 "  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="9" cy="19" r="2" />  <circle cx="17" cy="19" r="2" />  <path d="M3 3h2l2 12a3 3 0 0 0 3 2h7a3 3 0 0 0 3 -2l1 -7h-15.2" /></svg>

    </button>
</div>


</div> */}


<Footer page="events"></Footer>
</main>
);


  
}


export default EventCategory