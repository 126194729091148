import React,{ useState, useContext ,createContext, useReducer,useEffect} from 'react';
import Home from './pages/Home';
import Login from './pages/Login';
// import SignupPage from './pages/Signup';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedRoutes from './utils/ProtectedRoutes';
import OneSignal from 'react-onesignal';

import DashHome from './pages/DashHome';

import {reducer,initialState} from "./reducers/userReducer"
import Employee from './pages/Employee';
import Zone from './pages/Events';
import ContactUs from './pages/ContactUs';
import Unpaid from './pages/Unpaid';
import Paid from './pages/Paid';
import QRGenerate from './pages/QRGenerate';
import Report from './pages/Report';
import Profile from './pages/Profile';
import SinglePage from './pages/SinglePage';
import EventCategory from './pages/EventCategory';
import Payment from "./pages/Payment";
import Completion from './pages/Completion';
import io from "socket.io-client";
import FreeTicket from './pages/FreeTicket';
import TrackTicket from './pages/TrackTicket';
import MyTicket from './pages/MyTicket';
import SignupVendor from './pages/SignupVendor';
import Reset from './pages/Reset';
import NewPassword from './pages/NewPassword';
import AllEvents from './pages/AllEvents';
import RegisterUser from './pages/RegisterUser';
import ProtectedRoutesUser from './utils/ProtectedRoutesUser';
import Error from './pages/Error';
import AboutUs from './pages/AboutUs';
import PaymentConfig from './pages/PaymentConfig';
import ProtectedSuperRoutes from './utils/ProtectedSuperRoutes';
import SuperDash from './pages/SuperDash';
import SuperLogin from './pages/SuperLogin';
import SuperEvents from './pages/SuperEvents';
import SuperVendor from './pages/SuperVendor';
import SuperUsers from './pages/SuperUsers';
import VendorProfile from './pages/VendorProfile';
import ResetUser from './pages/ResetUser';
import NewPasswordUser from './pages/NewPasswordUser';
import SuperProfile from './pages/SuperProfile';
import Seller from './pages/Seller';
import SellerReport from './pages/SellerReport';
import RefPage from './pages/RefPage';
import ShowDash from './pages/ShowDash';
import AllShows from './pages/AllShows';
import SingleShow from './pages/SingleShow';
import ProtectedRoutesParticipant from './utils/ProtectedRoutesParticipant';
import ParticipantProfile from './pages/ParticipantProfile';
import ParticipantVote from './pages/ParticipantVote';
import SuperShowDash from './pages/SuperShow';
import AllShowsLive from './pages/AllShowsLive';
import SingleShowLive from './pages/SingleShowLive';
import MembershipDash from './pages/MembershipDash';
import Membership from './pages/Membership';
import SingleMembership from './pages/SingleMembership';
import RealityShowDashHome from './pages/RealityShowDashHome';
import EventsDashHome from './pages/EventsDashhome';
import MembershipDashHome from './pages/MembershipDashHome';
import MembershipEventsDash from './pages/MembershipEventsDash';
import MembershipEventsReport from './pages/MembershipEventsReport';
import SucessMembership from './pages/SuccessMembership';
import CancledRequest from './pages/Cancledrequest';

export const socket = io("https://www.bodhi5entertainment.com");
export const UserContex = createContext()


function App() {
  const [state, dispatch] = useReducer(reducer,initialState)

  window.OneSignal = window.OneSignal || [];
  const OneSignal = window.OneSignal;

  const [isLoggedIn, setisLoggedIn] = useState(null);
  const logIn = () => {
    setisLoggedIn(true);
  };
  const logOut = () => {
    setisLoggedIn(false);
  };
  
   const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
//   useEffect(() => {
//     // Example: Emit a message to the server
//     socket.emit('chat message', 'Hello, server!');

//     // Example: Listen for messages from the server
//     socket.on('chat message', (msg) => {
//         console.log('Message from server: ' + msg);
//     });

//     // Clean up on component unmount
//     return () => {
//         socket.disconnect();
//     };
// }, []);






  return (
    <>
        <UserContex.Provider value={{state,dispatch}}>

      <Router>
        {/* {isLoggedIn ? (
        <button onClick={logOut}>Logout</button>
        ) : (
        <button onClick={logIn}>Login</button>
        )} */}
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/login" element={<Login/>}/>
          <Route path="/register" element={<RegisterUser/>}/>
          <Route path="/reset" element={<Reset/>}/>
          <Route path="/resetuserpassword" element={<ResetUser/>}/>
          <Route exact path="/reset/:token" element={<NewPassword/>}/>
          <Route exact path="/resetuserpassword/:token" element={<NewPasswordUser/>}/>
          <Route path="/vendorSignup" element={<SignupVendor/>}/>
          <Route path="/masterlogin" element={<SuperLogin/>}/>
          <Route path="/contact" element={<ContactUs/>}/>
          <Route path="/about" element={<AboutUs/>}/>
          {/* <Route path="/profile" element={<Profile/>}/> */}
          {/* <Route path="/category" element={<EventCategory/>}/> */}
          <Route path="/pay" element={<Payment/>}/>
          <Route path="/completion" element={<Completion/>}/>
          <Route path="/freeticket" element={<FreeTicket/>}/>
          <Route path="/single/:postid" element={<SinglePage/>}/>
          <Route path="/participantvote/:postid" element={<ParticipantVote/>}/>
          <Route path="/singleshow/:postid" element={<SingleShow/>}/>
          <Route path="/singleshowlive/:postid" element={<SingleShowLive/>}/>
          <Route path="/reffered/:sellerid/:postid" element={<RefPage/>}/>
          <Route path="/categoryevents/:category" element={<EventCategory/>}/>
          <Route path="/findevents" element={<AllEvents/>}/>
          <Route path="/findshows" element={<AllShows/>}/>
          <Route path="/findshowslive" element={<AllShowsLive/>}/>
          <Route path="/myticket/:ticketid" element={<MyTicket/>}/>
          <Route path="/trackticket" element={<TrackTicket/>}/>
          <Route path="*" element={<Error/>}/>

          <Route  element={<ProtectedRoutes/>}>
          <Route path="/Dashboard" element={<DashHome/>}/>
          <Route path="/organizerprofile" element={<VendorProfile/>}/>
          <Route path="/employee" element={<Employee/>}/>
          <Route path="/zone" element={<Zone/>}/>
          <Route path="/unpaid" element={<Unpaid/>}/>
          <Route path="/paid" element={<Paid/>}/>
          <Route path="/qrcode" element={<QRGenerate/>}/>
          <Route path="/report" element={<Report/>}/>
          <Route path="/sellers" element={<Seller/>}/>
          <Route path="/sellerinfo/:sellerid" element={<SellerReport/>}/>
          <Route path="/realityshowdash" element={<ShowDash/>}/>
          <Route path="/membershipdash" element={<MembershipDash/>}/>
          <Route path="/stripeconfig" element={<PaymentConfig/>}/>
          <Route path="/realityshowdashhome" element={<RealityShowDashHome/>}/>
          <Route path="/membershipeventsdash/:membershipid" element={<MembershipEventsDash/>}/>
          <Route path="/eventsdashhome" element={<EventsDashHome/>}/>
          <Route path="/membershipdashhome" element={<MembershipDashHome/>}/>
          <Route path="/membershipeventsreport/:membershipid" element={<MembershipEventsReport/>}/>
          </Route>
          <Route  element={<ProtectedRoutesUser/>}>
          <Route path="/profile" element={<Profile/>}/>
          <Route path="/findmemberships" element={<Membership/>}/>
          <Route path="/singlemembership/:postid" element={<SingleMembership/>}/>
          <Route path="/successmembership/:membershipid" element={<SucessMembership/>}/>
          <Route path="/canceledmembership" element={<CancledRequest/>}/>

          </Route>
          <Route  element={<ProtectedRoutesParticipant/>}>
          <Route path="/participantprofile/:id" element={<ParticipantProfile/>}/>
         
          </Route>
          <Route  element={<ProtectedSuperRoutes/>}>
          <Route path="/superdashboard" element={<SuperDash/>}/>
          <Route path="/eventlist" element={<SuperEvents/>}/>
          <Route path="/vendorlist" element={<SuperVendor/>}/>
          <Route path="/userlist" element={<SuperUsers/>}/>
          <Route path="/superadminprofile" element={<SuperProfile/>}/>
          <Route path="/realitysuperdash" element={<SuperShowDash/>}/>

          </Route>
        </Routes>
      </Router>
      </UserContex.Provider>

    </>
  );
}

export default App;
