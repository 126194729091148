import React, {useContext, useRef, useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import DatePicker from 'react-datepicker'
import {UserContex} from '../App'
import {fill} from "@cloudinary/url-gen/actions/resize";
import {CloudinaryImage} from '@cloudinary/url-gen';
import AdminHeader from '../components/AdminHeader';
import SuperAdminHeader from '../components/SuperAdminHeader';
// import NavBar from './nav'
require('react-datepicker/dist/react-datepicker.css')

const SuperEvents = ()=>{


  const history = useNavigate()
  const {state, dispatch}= useContext(UserContex)



  const  [data, setData]= useState([])

  const [search,setSearch] = useState("")

  const [addStock,setAddStock]= useState(false)
  const [editEvent,setEdit]= useState(false)
  const[id,setId] = useState("")  
 
  const[zone,setZone]= useState("")
  const[price,setPrice]= useState("")
  const[venue,setVenue]=useState("")
  const[description,setDescription]=useState("")
  const[seats,setSeats]= useState("")
  const [name,setName]= useState("")
  const [col,setCol]= useState("")
  const [row,setRow]= useState("")
  const [url,setUrl] = useState("")  
  const [category,setCategory] = useState("")  
  const [date,setDate] = useState(new Date())
  const [isChecked, setIsChecked] = useState(false);

  const[progress,setProgress]= useState(false)


  useEffect(  ()=>{

    fetch('/getallevents',{
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{
      setData(result.events)
      
  })

  },[])



  const singlEvent = (Itemid)=>{

  fetch(`/getsingleevent/${Itemid}`,{
    method:"get",
    headers:{
      Authorization: "Bearer " +localStorage.getItem("jwt"),
      "Content-Type":"application/json"

    }
}).then(res=>res.json())
.then(result=>{


  // setDataEdit(result.stock)
  console.log("RESULT",result.event)
  setId(result.event._id)
  setName(result.event.name)
  setVenue(result.event.venue)
  setDescription(result.event.description)
  setSeats(result.event.seats)
  setPrice(result.event.price)

  //setDob(result.employee.dob)
  
  

          
})

}
  



  

  const postEvent = ()=>{

    fetch('/addevent',{

      method:"post",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({
       
        name,venue,seats,description,price,pic:url,date,row,col,category
      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
      }

      else{
        
        window.location.reload();
            // history.go(`/employee`)

      }
    })
    .catch(err=>{
      console.log(err)
    })

  }


  
  const uploadppt=({target:{files}})=>{

    setProgress(true)

    let data = new FormData()

    for(const file of files){

   data.append('uploadimage', file)
   data.append('name',file.name)
}



       fetch("/uploadimage", {

         method:"post",
         body: data
     })
     .then(res=>res.json())
     .then(data=>{

          setUrl(data.url)
          setProgress(false)

      

       
     })
     .catch(err=>{
         console.log(err)
     })

}
  






  const fetchPost=(query)=>{


    setSearch(query)

    fetch('/search-employee',{

      method:"post",
      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt"),

        "Content-Type":"application/json"

      },
      body:JSON.stringify({
        query
      })

    }).then(res=> res.json())
      .then(results=>{
        
        setData(results.employee)
      })


  }

  const deleteEmployee = (postId)=>{

    fetch(`/deleteevent/${postId}`,{
        method: "delete",
        headers:{
          "Content-Type":"application/json",
          Authorization: "Bearer " +localStorage.getItem("jwt")
        }
    }).then(res=>res.json())
    .then(result=>{
        window.location.reload(false);

    }).catch(err=>{
        console.log(err)
    })
}
  
    //deleteEmployee
    

    const Logout = ()=>{
        localStorage.clear()
        dispatch({type:"CLEAR"})
            history(`/login`)
    }

return(

    <div>


 <script src="https://cdn.jsdelivr.net/gh/alpinejs/alpine@v2.x.x/dist/alpine.min.js" defer></script>

 <div  className="flex h-screen bg-white">

 <div className=" "></div>

        <link rel="stylesheet" href="https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css" />
  






        <div className="flex-1 flex flex-col overflow-hidden ">

            
        {/* <NavBar/> */}
     <SuperAdminHeader page="eventslist"></SuperAdminHeader>

            <main className="flex-1 overflow-x-hidden overflow-y-auto bg-white">
  <div className="container mx-auto px-6 py-8">
    <h3 className="text-gray-400 text-3xl font-medium">All Events</h3>


<div className="flex flex-row ...">


<div class="shadow-lg rounded-xl bg-gray-200 w-full md:w-64 p-6 bg-white  relative overflow-hidden">
    <p class="text-black text-xl">
        Events
    </p>
    <div class="flex items-center my-4 text-blue-500 rounded justify-between">
    <svg class="h-8 w-8 text-gray-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z" />  <polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02" /></svg>
        <div class="flex flex-col w-full ml-2 items-start justify-evenly">
            <p class="text-black text-lg">
                {data.length}
            </p>
            <p class="text-gray-700 text-sm">
                Total Events
            </p>
        </div>
    </div>
   
    {/* <div class="mt-4">
        <button type="button" class="py-2 px-4  bg-black hover:bg-gray-400 focus:ring-gray-400 focus:ring-offset-gray-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md "
        
        onClick={()=>setAddStock(true)}
        >
            Add Event +
        </button>
    </div> */}
</div>



  
</div>
    
   
    
    <br></br>









  








    <div className="w-full h-10 pl-3 pr-2 bg-gray-100 border-gray-50 rounded-full  outline-none  border-none flex focus:outline-none justify-between items-center relative">
  <input type="text" name="search" id="search" placeholder="Search" 
         className=" w-full bg-gray-100 border-none rounded-full    focus:outline-none focus:border-white active:outline-none"
         value= {search} onChange={(e)=>fetchPost(e.target.value)}
         />
  <button type="submit" className="ml-1 outline-none border-none focus:border-none  active:outline-none">
    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
         viewBox="0 0 24 24" className="w-6 h-6">
      <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
    </svg>
  </button>
</div>

 
<br/>

 
       
<div class="grid grid-cols-1 gap-12 md:grid-cols-2 xl:grid-cols-3">

                    

      {data.map(item=>{

        return(



          <>
          
         
{/* <div class= "flex-col shadow-lg rounded-2xl w-80 p-4  dark:bg-gray-800 "  >
    <div class="flex flex-row items-start gap-2">
        <img src={item.pic}  class="w-28 h-28 pr-3 rounded-lg"/>
        <div class="h-28 w-full flex flex-col justify-between">
            <div>
                <p class="text-gray-800 dark:text-white text-l font-medium">
                    {item.name}
                </p>
            </div>
            <div class="flex items-center">
            <svg class="h-6 w-6 text-black"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
</svg>
            
             <p class="text-md text-black dark:text-white ml-2">
            
            {item.venue}
        </p>
    </div>
          
    
      
        </div>
   
   
   
   
    </div>

    <div class=" items-center gap-4 mt-8">
        <button type="button" class="w-1/2 px-4 py-2 text-base border rounded-lg text-white bg-red-500 hover:bg-red-700 "
        
        onClick={()=>{if(window.confirm('Are you sure you want to delete?')) deleteEmployee(item._id)}}

        >
            Remove
        </button>
        <button type="button" class="w-1/2 px-4 py-2 text-base border rounded-lg text-white bg-blue-600 hover:bg-blue-700 "
                          onClick={()=>{setEdit(true);singleEmployee(item._id)}}
                          >
            Update
        </button>
    </div>
   
</div>  */}
<div class="m-auto overflow-hidden rounded-lg shadow-lg cursor-pointer h-90 w-60 md:w-80">
                <a href="#" class="block w-full h-full">
                    <img alt="blog photo" src={item.pic?item.pic:"https://images.unsplash.com/photo-1470229722913-7c0e2dbbafd3?auto=format&fit=crop&q=80&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&w=1470"} class="object-cover w-full max-h-40"/>
                    <div class="w-full p-4 bg-white ">
                        <p class="font-medium text-indigo-500 text-md">
                            {item.name}
                        </p>
                        <div class="flex items-center">
            <svg class="h-6 w-6 text-indigo-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
</svg>
            
             <p class="text-md text-black  ml-2">
            
            {item.venue}
        </p>
    </div>
          
                        <div class="flex items-center">
                        <svg class="h-6 w-6 text-indigo-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="5" cy="5" r="1" />  <circle cx="12" cy="5" r="1" />  <circle cx="19" cy="5" r="1" />  <circle cx="5" cy="12" r="1" />  <circle cx="12" cy="12" r="1" />  <circle cx="19" cy="12" r="1" />  <circle cx="5" cy="19" r="1" />  <circle cx="12" cy="19" r="1" />  <circle cx="19" cy="19" r="1" /></svg>
            
             <p class="text-md text-black  ml-2">
            
            {item.seats} Seats
        </p>
    </div>
          
    
                        <p class="font-light text-gray-400  text-md">
                            {item.description.substring(0,30)} ......
                        </p>
                        <div class=" items-center gap-4 mt-8">
        <button type="button" class="w-1/2 px-4 py-2 text-base border rounded-lg text-white bg-red-500 hover:bg-red-700 "
        
        onClick={()=>{if(window.confirm('Are you sure you want to delete?')) deleteEmployee(item._id)}}

        >
            Remove
        </button>
        {/* <button type="button" class="w-1/2 px-4 py-2 text-base border rounded-lg text-white bg-blue-600 hover:bg-blue-700 "
                          onClick={()=>{setEdit(true);singlEvent(item._id)}}
                          >
            Edit
        </button> */}
    </div>
                    </div>
                </a>
            </div>

</>

              




             
    


        
      )

      })}
      </div>


       {/* { data.map(item=>{
                      
                      
                      console.log(item._id);


            return(


                <Link to={`/singlerecord/${item._id}`}>
                <li class="border-gray-400 flex flex-row mb-2">
                <div class="shadow border select-none cursor-pointer bg-white dark:bg-gray-800 rounded-md flex flex-1 items-center p-4">
                   
                    <div class="flex-1 pl-1 md:mr-16">
                        <div class="font-medium dark:text-white">
                            {item.name}
                        </div>
                        <div class="text-gray-600 dark:text-gray-200 text-sm">
                            {item.address}
                        </div>
                    </div>
                    <div class="flex md:space-x-8 space-x-6 w-14 h-10 justify-center items-center mr-4">
                            {item.phone}                     
                    </div>
                                   
                </div>
            </li>
                </Link>
                
         
            )

       })
        


       } */}

       
       
   




   
  </div>
</main>
</div>

    </div>



  </div>  
);


  
}


export default SuperEvents