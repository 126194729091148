
import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import { Link, useNavigate } from "react-router-dom";

function Footer(props) {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate()
  return (
  

    <>
    
    
    
    <footer class="bg-gray-100 w-full py-8 lg:visible invisible">
    <div class="max-w-screen-xl px-4 mx-auto">
        <ul class="flex flex-wrap justify-between max-w-screen-md mx-auto text-lg font-light">
            <li class="my-2">
                <a class="text-gray-400 hover:text-gray-800  transition-colors duration-200" href="/about">
                    About Us
                </a>
            </li>
            <li class="my-2">
                <a class="text-gray-400 hover:text-gray-800  transition-colors duration-200" href="/contact">
                    Contact Us
                </a>
            </li>
         
            <li class="my-2">
                <a class="text-gray-400 hover:text-gray-800  transition-colors duration-200" href="https://www.linkedin.com/company/b5-group-of-companies-pty-ltd/" target="_blank">
                    LinkedIn
                </a>
            </li>
        </ul>
        <div class="pt-8 flex max-w-xs mx-auto items-center justify-between">
            <a href="https://www.facebook.com/Bodhi5entertainment?mibextid=LQQJ4d" target="_blank" class="text-gray-400 transition-colors duration-200 hover:text-gray-800 ">
                <svg width="20" height="20" fill="currentColor" class="text-xl transition-colors duration-200 hover:text-gray-800 " viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759h-306v-759h-255v-296h255v-218q0-186 104-288.5t277-102.5q147 0 228 12z">
                    </path>
                </svg>
            </a>
            <a href="https://x.com/b5gcpl?s=11&t=fQw85gS59LmKbE2wBp594g"  target="_blank" class="text-gray-400 transition-colors duration-200 hover:text-gray-800 ">
                <svg width="20" height="20" fill="currentColor" class="text-xl transition-colors duration-200 hover:text-gray-800 " viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1684 408q-67 98-162 167 1 14 1 42 0 130-38 259.5t-115.5 248.5-184.5 210.5-258 146-323 54.5q-271 0-496-145 35 4 78 4 225 0 401-138-105-2-188-64.5t-114-159.5q33 5 61 5 43 0 85-11-112-23-185.5-111.5t-73.5-205.5v-4q68 38 146 41-66-44-105-115t-39-154q0-88 44-163 121 149 294.5 238.5t371.5 99.5q-8-38-8-74 0-134 94.5-228.5t228.5-94.5q140 0 236 102 109-21 205-78-37 115-142 178 93-10 186-50z">
                    </path>
                </svg>
            </a>
            <a href="https://www.tiktok.com/@spb5group?_t=8i0rLMnEXGE&_r=1" target="_blank" class="text-gray-400 h-6 w-6 transition-colors duration-200 hover:text-gray-800 ">
            <svg xmlns="http://www.w3.org/2000/svg" width="80px" height="80px" fill="currentColor" viewBox="0 0 1792 1792">
            <path d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z"/></svg>

            </a>
            <a href="https://www.linkedin.com/company/b5-group-of-companies-pty-ltd/"  target="_blank" class="text-gray-400 transition-colors duration-200 hover:text-gray-800 ">
                <svg width="20" height="20" fill="currentColor" class="text-xl transition-colors duration-200 hover:text-gray-800 " viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                    <path d="M477 625v991h-330v-991h330zm21-306q1 73-50.5 122t-135.5 49h-2q-82 0-132-49t-50-122q0-74 51.5-122.5t134.5-48.5 133 48.5 51 122.5zm1166 729v568h-329v-530q0-105-40.5-164.5t-126.5-59.5q-63 0-105.5 34.5t-63.5 85.5q-11 30-11 81v553h-329q2-399 2-647t-1-296l-1-48h329v144h-2q20-32 41-56t56.5-52 87-43.5 114.5-15.5q171 0 275 113.5t104 332.5z">
                    </path>
                </svg>
            </a>
            <a href="https://youtube.com/@Bodhi5Entertainment?si=Fnl2KZf3e8c13ozQ" target="_blank" class="text-gray-400 transition-colors duration-200 hover:text-gray-800 ">
            <svg class="h-6 w-6 text-gray-500 hover:text-black"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z" />  <polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02" /></svg> </a>
        </div>
    </div>
</footer>
    
    
<div class=" lg:invisible visible fixed bottom-0 flex flex-row w-full ">



<div class="relative w-full p-2  bg-white shadow-lg rounded-t-xl  ">
   
    <div class="grid grid-cols-4 gap-4">
        <div class="flex flex-col items-center"
                onClick={()=>{navigate("/")}}

        >
            <div class="relative">
                <a href="#" class="relative block">
                <svg class={`h-8 w-8 ${props.page === "home" ? "text-purple-500 " : "text-gray-500 "} `}  viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
    <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
    <polyline points="9 22 9 12 15 12 15 22" />
</svg>                
                
                
                                      </a>
               
            </div>
            <span class=" text-xs text-gray-600 ">
                Home
            </span>
        </div>
        
      
     
        <div class="flex flex-col items-center"
        
        onClick={()=>{navigate("/findevents")}}
        >
            <div class="relative">
            <svg class={`h-8 w-8 ${props.page === "events" ? "text-purple-500 " : "text-gray-500 "} `}  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M14.5 10c-.83 0-1.5-.67-1.5-1.5v-5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5v5c0 .83-.67 1.5-1.5 1.5z" />  <path d="M20.5 10H19V8.5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5-.67 1.5-1.5 1.5z" />  <path d="M9.5 14c.83 0 1.5.67 1.5 1.5v5c0 .83-.67 1.5-1.5 1.5S8 21.33 8 20.5v-5c0-.83.67-1.5 1.5-1.5z" />  <path d="M3.5 14H5v1.5c0 .83-.67 1.5-1.5 1.5S2 16.33 2 15.5 2.67 14 3.5 14z" />  <path d="M14 14.5c0-.83.67-1.5 1.5-1.5h5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-5c-.83 0-1.5-.67-1.5-1.5z" />  <path d="M15.5 19H14v1.5c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5-.67-1.5-1.5-1.5z" />  <path d="M10 9.5C10 8.67 9.33 8 8.5 8h-5C2.67 8 2 8.67 2 9.5S2.67 11 3.5 11h5c.83 0 1.5-.67 1.5-1.5z" />  <path d="M8.5 5H10V3.5C10 2.67 9.33 2 8.5 2S7 2.67 7 3.5 7.67 5 8.5 5z" /></svg>

            </div>
            <span class=" text-xs text-gray-600 ">
                Events
            </span>
        </div>
        <div class="flex flex-col items-center"
                onClick={()=>{navigate("/findshows")}}

        >
            <div class="relative">
                {/* <a href="#" class="relative block">
                <svg class={`h-6 w-6 ${props.page === "findshows" ? "text-purple-500 " : "text-gray-500"}`}  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />  <circle cx="12" cy="7" r="4" /></svg>            
                    </a> */}
                <svg class={`h-8 w-8 ${props.page === "findshows" ? "text-purple-500 " : "text-gray-500"}`}   viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <line x1="18" y1="20" x2="18" y2="10" />  <line x1="12" y1="20" x2="12" y2="4" />  <line x1="6" y1="20" x2="6" y2="14" /></svg>
            </div>
            <span class=" text-xs text-gray-600 ">
                Vote
            </span>
        </div>
        <div class="flex flex-col items-center"
                onClick={()=>{navigate("/profile")}}

        >
            <div class="relative">
                {/* <a href="#" class="relative block">
                <svg class={`h-6 w-6 ${props.page === "findshows" ? "text-purple-500 " : "text-gray-500"}`}  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />  <circle cx="12" cy="7" r="4" /></svg>            
                    </a> */}
                {/* <svg class={`h-8 w-8 ${props.page === "profile" ? "text-purple-500 " : "text-gray-500"}`}   viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <line x1="18" y1="20" x2="18" y2="10" />  <line x1="12" y1="20" x2="12" y2="4" />  <line x1="6" y1="20" x2="6" y2="14" /></svg> */}


                <svg class={`h-8 w-8 ${props.page === "profile" ? "text-purple-500 " : "text-gray-500"}`}  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />  <circle cx="12" cy="7" r="4" /></svg>
            </div>
            <span class=" text-xs text-gray-600 ">
                Profile
            </span>
        </div>
    </div>
</div>

</div>
    </>



  );
}

export default Footer;

