
// import React,{useContext, useEffect, useState} from 'react'
// import {Link, useNavigate} from 'react-router-dom'

// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// import {UserContex} from '../App'
// import Header from '../components/Header';
// import AOS from 'aos';
// import 'aos/dist/aos.css';
// import Footer from '../components/Footer';
// const Home = ()=>{
//   const {state, dispatch }= useContext(UserContex)
//   const history =useNavigate();
//   const [email,setEmail] =  useState("");
//   const [password,setPassword] =  useState("");
//   const navigate= useNavigate()
//   const [data,setData]= useState([])
//   const [search,setSearch] = useState("")

//   useEffect(  ()=>{
//     // AOS.init();
//     // AOS.refresh()
//     fetch('/getallevents',{
//       headers:{
//           Authorization: "Bearer " +localStorage.getItem("jwt")
          
//       }
//   }).then(res=>res.json())
//   .then(result=>{
//       setData(result.events.reverse())
//   })

//   },[])

//   const PostData =()=>{

   
//     fetch("/signin",{
//       method:"post",
//       headers:{
//           "Content-Type":"application/json"
//       },
//       body: JSON.stringify({
        
//           password,
//           email:email
//       })
      
//   }).then(res=>res.json())
//   .then(data=> {
     
//       if(data.error){
//         toast.error(data.error)

//       }
//       else{
//         console.log("Logéein Successful")
//           localStorage.setItem("jwt", data.token)
//           localStorage.setItem("user", JSON.stringify( data.user))
//           dispatch({type:"USER", payload:data.user})
//           navigate('/Dashboard')
//         }
//   })
//   .catch(err=>{
//       console.log(err)
//   })

//        }



//   const fetchPost=(query)=>{


//     setSearch(query)

//     fetch('/searchall-events',{

//       method:"post",
//       headers:{

//         "Content-Type":"application/json"

//       },
//       body:JSON.stringify({
//         query
//       })

//     }).then(res=> res.json())
//       .then(results=>{
        
//         setData(results.events)
//       })


//   }





//     return(

// <>
// <Header></Header>

// <main class="relative  h-screen bg-white ">
  




// <div class="relative md:block lg:block hidden">

// <img src="bann.jpeg" class="absolute h-full w-full object-cover"/>
// {/* <div class="absolute inset-0 bg-purple-500 opacity-50"></div> */}
// <div class="absolute inset-0 overflow-hidden">
//     <div class="absolute inset-0 bg-gradient-to-r from-black to-transparent"></div>
//   </div>


//  <div class="container mx-auto px-6 md:px-12 relative z-10 flex items-center py-8 xl:py-12">
//         <div class="lg:w-3/5 xl:w-2/5 flex flex-col items-start relative z-10">
//             <span class="font-bold uppercase text-white md:animate-bounce repeat-1 duration-1000">
//                 ____________________
//             </span>
//             <h1 class=" font-bold text-4xl md:text-6xl text-white leading-tight mt-2 ">
//             Elevate Your Events, Simplify Your Tickets
//                 <br/>
//                 <br/>
//                 <br/>
                
//                 {/* <Typed
//             className="md:text-5xl sm:text-4xl text-xl font-bold md:pl-4 pl-2"
//             strings={["it's pH", "water level"]}
//             typeSpeed={200}
//             backSpeed={220}
//             loop
//           /> */}
//             </h1>
           
//         </div>
//     </div>
   
   
//     </div>


//     <div class="md:hidden lg:hidden block flex justify-center "
    
//     onClick={()=>{navigate('/findevents')}}
//     >

// <div class="relative overflow-hidden bg-purple-100 rounded-lg shadow-lg w-11/12">
//     <img src="/logo.png" alt="btc logo" class="absolute w-24 h-24 rounded-full opacity-50 -top-6 -right-6 md:-right-4"/>
//     <div class="px-4 py-5 sm:p-6">
//         <dl>
//             <dt class="text-sm font-medium leading-5 text-gray-500 truncate">
//                 Join us
//             </dt>
//             <dd class="mt-1 text-3xl font-semibold leading-9 text-gray-900">
//             Elevate Your Events, Simplify Your Tickets            </dd>
//             <dd class="font-semibold text-gray-500">
//                 <span>
//                     Explore 
                  
//                 </span>
//             </dd>
//         </dl>
//     </div>
// </div>

//     </div>


//         {/* <div class=" relative flex  mx-auto">
       
//                 <img src="https://img.freepik.com/free-photo/excited-audience-watching-confetti-fireworks-having-fun-music-festival-night-copy-space_637285-559.jpg?w=996&t=st=1698228712~exp=1698229312~hmac=e6d992326774f5a0d719062875e0439936f46b09ea558d4d295af285dc06e732" class=" w-full h-92"/>
//         </div>
//          */}
    



// <section class="  bg-white" >
//     <div class="container grid grid-cols-3 place-items-center  mt-3 mx-auto text-center md:grid-cols-3">
       
//    <Link to={'/categoryevents/music'}>
//    <div class="relative md:w-24 md:h-24  w-14 h-14  hover:bg-purple-200 rounded-full text-gray-700 hover:text-purple-600">
           

//             <svg class="absolute  md:w-10 md:h-10 w-8 h-8  transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2"   width="24"  height="24"  viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"> 
            
//              <path d="M9 18V5l12-2v13" />  <circle cx="6" cy="18" r="3" />  <circle cx="18" cy="16" r="3" />
            
            
//             </svg>
//         </div>
//         <h1>
//          Music

// </h1>
//         </Link>

//    <Link to={'/categoryevents/movies'}>
//     <div class="relative md:w-24 md:h-24  w-14 h-14  hover:bg-purple-200 rounded-full text-gray-700 hover:text-purple-600">
           

//     <svg class="absolute  md:w-10 md:h-10 w-8 h-8  transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2"   width="24"  height="24"  viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"> 
        
//           <path d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z" />  <polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02" /></svg>
//         </div>
//         <h1>
// Movies

// </h1>
//         </Link>

//    <Link to={'/categoryevents/networking event'}>
//    <div class="relative md:w-24 md:h-24  w-14 h-14  hover:bg-purple-200 rounded-full text-gray-700 hover:text-purple-600">
           
//     <svg class="absolute md:w-10 md:h-10 w-8 h-8  transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2"   width="24"  height="24"  viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"> 
//   <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"/>
// </svg>


//         </div>
//         <h1>
// Social 

// </h1>
//         </Link>

       
  
       

        
//     </div>
   
// </section>

//     <div>


    

// <div  class="w-full md:p-8 p-7 bg-white">
//     <div class="flex flex-col md:flex-row md:items-end justify-between mb-12 header">
//         <div class="title">
//             <p class="mb-4 text-4xl font-bold text-gray-800">
//                 Lastest events
//             </p>
          
//         </div>
//         <div class="text-end">
//             <form class="flex flex-col justify-center   w-full max-w-sm space-y-3 md:flex-row md:w-full md:space-x-3 md:space-y-0">
//                 <div class=" relative ">
                 
//                       <input type="text" id="on-error-email" class="ring-purple-500 ring-2 rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" name="search" placeholder=" Search"
//                      value= {search} onChange={(e)=>fetchPost(e.target.value)}

                      
//                       />
        


// <svg class="absolute text-purple-500 right-2 bottom-3 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//   <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
// </svg>





                   
//                     </div>
//                     {/* <button class="flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-purple-600 rounded-lg shadow-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200" type="submit">
//                         Search
//                     </button> */}
//                 </form>
//             </div>
//         </div>

//         <div class="md:px-6 md:py-6   md:rounded-lg md:bg-purple-50 md:shadow-lg">

//         <div class="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-4 place-items-center">

// {data.slice(0, 8).map(item=>{
// return(
   


//         <div class=" overflow-hidden rounded-xl  shadow-lg cursor-pointer h-90  w-80  ">
//                 <a href={`/single/${item._id}`} class="block w-full h-full">
//                 <img alt="blog photo" src={item.pic?item.pic:"https://images.unsplash.com/photo-1470229722913-7c0e2dbbafd3?auto=format&fit=crop&q=80&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&w=1470"} class="object-cover object-top w-full max-h-40"/>
//                     <div class="w-full p-4 bg-white ">
//                         <p class="font-medium text-purple-500 text-md">
//                             {item.name}
//                         </p>
//                         <div class="flex items-center">
//             <svg class="h-6 w-6 text-purple-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
//   <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
//   <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
// </svg>
            
//              <p class="text-md text-black ">
            
//             {item.venue}
//         </p>
//     </div>


    
//     <div class="grid max-w-xl grid-cols-2 gap-4 ">


//                         <div class="flex items-center">
//                         <svg class="h-6 w-6 text-purple-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="5" cy="5" r="1" />  <circle cx="12" cy="5" r="1" />  <circle cx="19" cy="5" r="1" />  <circle cx="5" cy="12" r="1" />  <circle cx="12" cy="12" r="1" />  <circle cx="19" cy="12" r="1" />  <circle cx="5" cy="19" r="1" />  <circle cx="12" cy="19" r="1" />  <circle cx="19" cy="19" r="1" /></svg>
            
//              <p class="text-md text-black  ml-2">
            
//             {item.seats} Seats
//         </p>
//     </div>
//                         <div class="flex items-center">
//                         <svg class="h-6 w-6 text-purple-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <polyline points="12 6 12 12 16 14" /></svg>            
//              <p class="text-md text-black  ml-2">
            
//             {item.time}
//         </p>
//     </div>
          
//           </div>
          
//                         <div class="flex items-center">
//                         <svg class="h-6 w-6 text-purple-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
//   <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
// </svg>
            
//              <p class="text-md text-black  ml-2">
            
//             {item.price} Price
//         </p>
//     </div>
          
    
//                         <p class="font-light text-gray-400  text-md">
//                             {item.description.substring(0,28)}... Read More
//                         </p>
                       
//                     </div>
//                 </a>
//             </div>
          
        

// )

// })}





//         </div>
// //         <div class="mt-6  flex justify-center items-center">
// // <button onClick={()=>history('/findevents')} type="button" class="py-2 md:w-1/3 w-full h-14 px-4 flex justify-center items-center border-purple-300  bg-none hover:bg-purple-500 focus:ring-red-500 focus:ring-offset-red-200 text-purple-500 hover:text-white  transition ease-in duration-200 text-center text-base  border-2 border-purple-500 font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
  
// //     Find more Events
// //     <svg class="h-8 w-8 "  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <polyline points="13 17 18 12 13 7" />  <polyline points="6 17 11 12 6 7" /></svg>
// // </button>
// // </div>

// //         </div>



// // <div class="relative p-4 mt-10 bg-white ">
// //     <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-12 lg:items-center">
// //         <div class="lg:col-start-2 md:pl-20">
// //             <h4 class="text-2xl font-extrabold leading-8 tracking-tight text-gray-900  sm:leading-9">
// //                 Tickets at your fingertip
// //             </h4>
// //             <ul class="mt-10">
// //                 <li >
// //                     <div class="flex">
// //                         <div class="flex-shrink-0">
// //                             <div class="flex items-center justify-center w-12 h-12 text-white bg-purple-500 rounded-md">
// //                             <svg class="h-8 w-8 text-white"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2" /></svg>
// //                             </div>
// //                         </div>
// //                         <div class="ml-4">
// //                             <h5 class="text-lg font-bold leading-6 text-gray-900 ">
// //                             Instant Access to Events
// //                             </h5>
// //                             <p class="mt-2 text-base leading-6 text-gray-500 ">
// //                             Browse and purchase tickets for concerts, sports events, theater shows, and more with just a few taps.                            </p>
// //                         </div>
// //                     </div>
// //                 </li>
// //                 {/* <li class="mt-10">
// //                     <div class="flex">
// //                         <div class="flex-shrink-0">
// //                             <div class="flex items-center justify-center w-12 h-12 text-white bg-purple-500 rounded-md">
// //                             <svg class="h-8 w-8 text-white"  width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="5" y="11" width="14" height="10" rx="2" />  <circle cx="12" cy="16" r="1" />  <path d="M8 11v-4a4 4 0 0 1 8 0v4" /></svg>

// //                             </div>
// //                         </div>
// //                         <div class="ml-4">
// //                             <h5 class="text-lg font-bold leading-6 text-gray-900 ">
// //                             Secure Transactions
// //                             </h5>
// //                             <p class="mt-2 text-base leading-6 text-gray-500 ">
// //                             Enjoy peace of mind with secure and encrypted payment options for hassle-free transactions.                            </p>
// //                         </div>
// //                     </div>
// //                 </li>
// //                 <li class="mt-10">
// //                     <div class="flex">
// //                         <div class="flex-shrink-0">
// //                             <div class="flex items-center justify-center w-12 h-12 text-white bg-purple-500 rounded-md">
// //                             <svg class="h-8 w-8 text-white"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
// //   <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z"/>
// // </svg>
// //                             </div>
// //                         </div>
// //                         <div class="ml-4">
// //                             <h5 class="text-lg font-bold leading-6 text-gray-900 ">
// //                                 QR Codes
// //                             </h5>
// //                             <p class="mt-2 text-base leading-6 text-gray-500 ">
// //                               QR codes for fast teacking and tickets validation.
// //                             </p>
// //                         </div>
// //                     </div>
// //                 </li> */}
// //             </ul>
// //         </div>
// //         <div class="relative mt-10 -mx-4 mx-2 lg:mt-0 lg:col-start-1">
// //             <img src="https://img.freepik.com/free-vector/movie-ticket-concept-illustration_114360-19282.jpg?w=740&t=st=1702105660~exp=1702106260~hmac=f18e6ad6e077d2eeeb40d679565cce6cab892ae171105ff43e3d890adc5b09ea" alt="illustration" class="relative w-auto mx-auto rounded "/>
// //         </div>
// //     </div>
// // </div>


// // </div>



// <div class="bg-white ">
//     <div class="text-center w-full mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 z-20">
//         <h2 class="text-3xl font-extrabold text-black  sm:text-4xl">
//             <span class="block">
//                 Have Some Queries ?
//             </span>
//             <span class="block text-purple-500">
//                 Get in touch with us.
//             </span>
//         </h2>
//         <p class="text-xl mt-4 max-w-md mx-auto text-gray-400">
//         Our team of experts are always there to serve you and help you to have better experience.
//         </p>
//         <div class="lg:mt-0 lg:flex-shrink-0">
//             <div class="mt-12 inline-flex rounded-md shadow">
//                 <button type="button" class="py-4 px-6  bg-purple-500 hover:bg-purple-600 focus:ring-purple-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
//                 onClick={()=>{history('/contact')}}
//                 >
//                     contact
//                 </button>
//             </div>
//         </div>
//     </div>
// </div>

// <Footer page="home"></Footer>

//     </div>

// </main>

// </>

  


//     );
// }

// export default Home;









import React,{useContext, useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {UserContex} from '../App'
import Header from '../components/Header';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from '../components/Footer';
const Home = ()=>{
  const {state, dispatch }= useContext(UserContex)
  const history =useNavigate();
  const [email,setEmail] =  useState("");
  const [password,setPassword] =  useState("");
  const navigate= useNavigate()
  const [data,setData]= useState([])
  const [date,setDate]= useState(new Date())
  const [showData,setShowData]= useState([])
  const [search,setSearch] = useState("")

  useEffect(  ()=>{
    // AOS.init();
    // AOS.refresh()
   fetch('/getallevents', {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt")
      }
    })
      .then(res => res.json())
      .then(result => {
        const filteredEvents = result.events.reverse().filter(event => !event.membership);
        setData(filteredEvents);
      })
      .catch(err => console.error(err));



    fetch('/getallshows',{
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{
    
      setShowData(result.events.reverse())
  })

  },[])

  const PostData =()=>{

   
    fetch("/signin",{
      method:"post",
      headers:{
          "Content-Type":"application/json"
      },
      body: JSON.stringify({
        
          password,
          email:email
      })
      
  }).then(res=>res.json())
  .then(data=> {
     
      if(data.error){
        toast.error(data.error)

      }
      else{
        console.log("Logéein Successful")
          localStorage.setItem("jwt", data.token)
          localStorage.setItem("user", JSON.stringify( data.user))
          dispatch({type:"USER", payload:data.user})
          navigate('/Dashboard')
        }
  })
  .catch(err=>{
      console.log(err)
  })

       }



  const fetchPost=(query)=>{


    setSearch(query)

    fetch('/searchall-events',{

      method:"post",
      headers:{

        "Content-Type":"application/json"

      },
      body:JSON.stringify({
        query
      })

    }).then(res=> res.json())
      .then(results=>{
        
        setData(results.events)
      })


  }
  const fetchPostShow=(query)=>{


    setSearch(query)

    fetch('/searchall-shows',{

      method:"post",
      headers:{

        "Content-Type":"application/json"

      },
      body:JSON.stringify({
        query
      })

    }).then(res=> res.json())
      .then(results=>{
        
        setShowData(results.events)
      })


  }





    return(

<>
<main class="bg-white h-screen  relative overflow-x-hidden">
    <div class="flex items-start justify-between">
       
        <div class="flex flex-col w-full ">

            

            <Header></Header>


            


<div class="relative md:block lg:block hidden">

<img src="bann.png" class="absolute h-full w-full object-cover"/>
{/* <div class="absolute inset-0 bg-purple-500 opacity-50"></div> */}
<div class="absolute inset-0 overflow-hidden">
    <div class="absolute inset-0 bg-gradient-to-r from-black to-transparent"></div>
  </div>


 <div class="container mx-auto px-6 md:px-12 relative z-10 flex items-center py-8 xl:py-12">
        <div class="lg:w-3/5 xl:w-2/5 flex flex-col items-start relative z-10">
            <span class="font-bold uppercase text-white md:animate-bounce repeat-1 duration-1000">
                ____________________
            </span>
            <h1 class=" font-bold text-4xl md:text-6xl text-white leading-tight mt-2 ">
            Elevate Your Events, Simplify Your Tickets
                <br/>
                <br/>
                <br/>
                
                {/* <Typed
            className="md:text-5xl sm:text-4xl text-xl font-bold md:pl-4 pl-2"
            strings={["it's pH", "water level"]}
            typeSpeed={200}
            backSpeed={220}
            loop
          /> */}
            </h1>
           
        </div>
    </div>
   
   
    </div>


    <div class="md:hidden lg:hidden block flex justify-center "
    
    onClick={()=>{navigate('/findevents')}}
    >

<div class="relative overflow-hidden bg-purple-100 rounded-lg shadow-lg w-11/12">
    <img src="/logo.png" alt="btc logo" class="absolute w-24 h-24 rounded-full opacity-50 -top-6 -right-6 md:-right-4"/>
    <div class="px-4 py-5 sm:p-6">
        <dl>
            <dt class="text-sm font-medium leading-5 text-gray-500 truncate">
                Join us
            </dt>
            <dd class="mt-1 text-3xl font-semibold leading-9 text-gray-900">
            Elevate Your Events, Simplify Your Tickets            </dd>
            <dd class="font-semibold text-gray-500">
                <span>
                    Explore 
                  
                </span>
            </dd>
        </dl>
    </div>
</div>

    </div>

             <section class="  bg-white" >
     <div class="container grid grid-cols-3 place-items-center  mt-3 mx-auto text-center md:grid-cols-3">
       

     <Link to={'/findshows'}>
    <div class="relative md:w-24 md:h-24 text-center  w-14 h-14  hover:bg-purple-200 rounded-full text-gray-700 hover:text-purple-600">
           
     <svg class="absolute md:w-10 md:h-10 w-8 h-8  transform -translate-x-1/2 -translate-y-1/2 md:left-1/2 left-11 top-1/2"   width="24"  height="24"  viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"> 
 <line x1="18" y1="20" x2="18" y2="10" />  <line x1="12" y1="20" x2="12" y2="4" />  <line x1="6" y1="20" x2="6" y2="14" />
 </svg>


         </div>
      <h1>
Reality Show
 </h1>
       </Link>



   
    {/* <Link to={'/categoryevents/music'}>
    <div class="relative md:w-24 md:h-24  w-14 h-14  hover:bg-purple-200 rounded-full text-gray-700 hover:text-purple-600">
           

             <svg class="absolute  md:w-10 md:h-10 w-8 h-8  transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2"   width="24"  height="24"  viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"> 
            
              <path d="M9 18V5l12-2v13" />  <circle cx="6" cy="18" r="3" />  <circle cx="18" cy="16" r="3" />
            
            
             </svg>
         </div>
         <h1>
          Music

 </h1>
         </Link> */}

    <Link to={'/findmemberships'}>
     <div class="relative md:w-24 md:h-24  w-14 h-14  hover:bg-purple-200 rounded-full text-gray-700 hover:text-purple-600">
           
     <svg class="absolute md:w-10 md:h-10 w-8 h-8 text-center  transform -translate-x-1/2 -translate-y-1/2 md:left-1/2 left-11 top-1/2"   width="24"  height="24"  viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"> 
   <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"/> </svg>


         </div>
        <h1 class="text-center ">
 Memberships

 </h1>
         </Link>

  
         <Link to={'/findevents'}>
    <div class="relative md:w-24 md:h-24  w-12 h-12  hover:bg-purple-200 rounded-full text-gray-700 hover:text-purple-600">
           

             {/* <svg class="absolute  md:w-10 md:h-10 w-8 h-8  transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2"   width="24"  height="24"  viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"> 
            
              <path d="M9 18V5l12-2v13" />  <circle cx="6" cy="18" r="3" />  <circle cx="18" cy="16" r="3" />
            
            
             </svg> */}

             <svg class="absolute  md:w-10 md:h-10 w-8 h-8  transform -translate-x-1/2 -translate-y-1/2 md:left-1/2 left-6 top-1/2"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M14.5 10c-.83 0-1.5-.67-1.5-1.5v-5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5v5c0 .83-.67 1.5-1.5 1.5z" />  <path d="M20.5 10H19V8.5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5-.67 1.5-1.5 1.5z" />  <path d="M9.5 14c.83 0 1.5.67 1.5 1.5v5c0 .83-.67 1.5-1.5 1.5S8 21.33 8 20.5v-5c0-.83.67-1.5 1.5-1.5z" />  <path d="M3.5 14H5v1.5c0 .83-.67 1.5-1.5 1.5S2 16.33 2 15.5 2.67 14 3.5 14z" />  <path d="M14 14.5c0-.83.67-1.5 1.5-1.5h5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-5c-.83 0-1.5-.67-1.5-1.5z" />  <path d="M15.5 19H14v1.5c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5-.67-1.5-1.5-1.5z" />  <path d="M10 9.5C10 8.67 9.33 8 8.5 8h-5C2.67 8 2 8.67 2 9.5S2.67 11 3.5 11h5c.83 0 1.5-.67 1.5-1.5z" />  <path d="M8.5 5H10V3.5C10 2.67 9.33 2 8.5 2S7 2.67 7 3.5 7.67 5 8.5 5z" /></svg>
        
        
         </div>
         <h1>
         Events

 </h1>
         </Link>
  
       

        
     </div>
   
 </section>



        </div>
     

    </div>



  

    <div  class="w-full md:p-8 p-7 bg-white">
    <div class="flex flex-col md:flex-row md:items-end justify-between mb-12 header">
        <div class="title">
            <p class="mb-4 text-4xl font-bold text-gray-800">
                Lastest Events
            </p>
          
        </div>
        <div class="text-end">
            <form class="flex flex-col justify-center   w-full max-w-sm space-y-3 md:flex-row md:w-full md:space-x-3 md:space-y-0">
                <div class=" relative ">
                 
                      <input type="text" id="on-error-email" class="ring-purple-500 ring-2 rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" name="search" placeholder=" Search"
                     value= {search} onChange={(e)=>fetchPost(e.target.value)}

                      
                      />
        


<svg class="absolute text-purple-500 right-2 bottom-3 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
</svg>





                   
                    </div>
                    {/* <button class="flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-purple-600 rounded-lg shadow-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200" type="submit">
                        Search
                    </button> */}
                </form>
            </div>
        </div>

        <div class="md:px-6 md:py-6   md:rounded-lg md:bg-purple-50 md:shadow-lg">

        <div class="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-4 place-items-center">

{data.slice(0, 4).map(item=>{

const itemDateObj = new Date(item.date);

itemDateObj.setHours(0, 0, 0, 0);
date.setHours(0, 0, 0, 0);



return(
   


        <div class="relative overflow-hidden rounded-xl  shadow-lg cursor-pointer h-90  w-80  ">
            {!(itemDateObj>=date)?
                         <img src="/ended.png" alt="btc logo" class="absolute w-24 h-24 rounded-full opacity-50 -bottom-6 -right-6 md:-right-4"/>

            :<></>}
                <a href={`/single/${item._id}`} class="block w-full h-full">
                <img alt="blog photo" src={item.pic?item.pic:"https://images.unsplash.com/photo-1470229722913-7c0e2dbbafd3?auto=format&fit=crop&q=80&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&w=1470"} class="object-cover object-top w-full max-h-40"/>
                    <div class="w-full p-4 bg-white ">
                        <p class="font-medium text-purple-500 text-md">
                            {item.name}
                        </p>
                        <div class="flex items-center">
            <svg class="h-6 w-6 text-purple-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
</svg>
            
             <p class="text-md text-black ">
            
            {item.venue}
        </p>
    </div>


    
    <div class="grid max-w-xl grid-cols-2 gap-4 ">


                        <div class="flex items-center">
                        <svg class="h-6 w-6 text-purple-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="5" cy="5" r="1" />  <circle cx="12" cy="5" r="1" />  <circle cx="19" cy="5" r="1" />  <circle cx="5" cy="12" r="1" />  <circle cx="12" cy="12" r="1" />  <circle cx="19" cy="12" r="1" />  <circle cx="5" cy="19" r="1" />  <circle cx="12" cy="19" r="1" />  <circle cx="19" cy="19" r="1" /></svg>
            
             <p class="text-md text-black  ml-2">
            
            {item.seats} Seats
        </p>
    </div>
                        <div class="flex items-center">
                        <svg class="h-6 w-6 text-purple-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <polyline points="12 6 12 12 16 14" /></svg>            
             <p class="text-md text-black  ml-2">
            
            {item.time}
        </p>
    </div>
          
          </div>


          <div class="grid max-w-xl grid-cols-2 gap-4 ">

          
                        <div class="flex items-center">
                        <svg class="h-6 w-6 text-purple-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>
            
             <p class="text-md text-black  ml-2">
            
            {item.price} Price
        </p>
    </div>
          
          
                        <div class="flex items-center">
                        <svg class="h-6 w-6 text-purple-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="4" y="5" width="16" height="16" rx="2" />  <line x1="16" y1="3" x2="16" y2="7" />  <line x1="8" y1="3" x2="8" y2="7" />  <line x1="4" y1="11" x2="20" y2="11" />  <rect x="8" y="15" width="2" height="2" /></svg>
            
             <p class="text-md text-black  ml-2">
            
            {item.date.substring(0,10)} 
        </p>
    </div>
          
    
</div>

                        <p class="font-light text-gray-400  text-md">
                            {item.description.substring(0,28)}... Read More
                        </p>
                       
                    </div>
                </a>
            </div>
          
        

)

})}





        </div>
         <div class="mt-6  flex justify-center items-center">
 <button onClick={()=>history('/findevents')} type="button" class="py-2 md:w-1/3 w-full h-14 px-4 flex justify-center items-center border-purple-300  bg-none hover:bg-purple-500 focus:ring-red-500 focus:ring-offset-red-200 text-purple-500 hover:text-white  transition ease-in duration-200 text-center text-base  border-2 border-purple-500 font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
  
     Find more Events
     <svg class="h-8 w-8 "  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <polyline points="13 17 18 12 13 7" />  <polyline points="6 17 11 12 6 7" /></svg>
 </button>
 </div>

         </div>

       
    <div class="flex flex-col md:flex-row md:items-end justify-between mb-6 mt-6 header">
        <div class="title">
            <p class="mb-4 text-4xl font-bold text-gray-800">
                Lastest Reality Shows
            </p>
          
        </div>
        <div class="text-end">
            <form class="flex flex-col justify-center   w-full max-w-sm space-y-3 md:flex-row md:w-full md:space-x-3 md:space-y-0">
                <div class=" relative ">
                 
                      <input type="text" id="on-error-email" class="ring-purple-500 ring-2 rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" name="search" placeholder=" Search"
                     value= {search} onChange={(e)=>fetchPostShow(e.target.value)}

                      
                      />
        


<svg class="absolute text-purple-500 right-2 bottom-3 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
</svg>





                   
                    </div>
                    {/* <button class="flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-purple-600 rounded-lg shadow-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200" type="submit">
                        Search
                    </button> */}
                </form>
            </div>
        </div>

        <div class="md:px-6 md:py-6   md:rounded-lg md:bg-purple-50 md:shadow-lg">

        <div class="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-4 place-items-center">

{showData.slice(0, 4).map(item=>{
return(
   

    <div class="relative overflow-hidden rounded-lg shadow-lg cursor-pointer h-90  w-80 ">
          {(item.status=="Ended")?
                         <img src="/ended.png" alt="btc logo" class="absolute w-24 h-24 rounded-full opacity-50 -bottom-6 -right-6 md:-right-4"/>

            :<></>}
    <a href={`/singleshow/${item._id}`} class="block w-full h-full">
    <img alt="blog photo" src={item.pic?item.pic:"https://images.unsplash.com/photo-1470229722913-7c0e2dbbafd3?auto=format&fit=crop&q=80&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&w=1470"} class="object-cover object-top w-full max-h-40"/>
        <div class="w-full p-4 bg-white ">
            <p class="font-medium text-purple-500 text-md">
                {item.name}
            </p>
            <div class="flex items-center">
  <svg class="h-6 w-6 text-purple-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
  </svg>
  
  <p class="text-md text-black  ml-2">
  
  {item.venue}
  </p>
  </div>
  
        <div class="grid max-w-xl grid-cols-2 gap-4 ">
  
  
        <div class="flex items-center">
        <svg class="h-6 w-6 text-purple-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="5" cy="5" r="1" />  <circle cx="12" cy="5" r="1" />  <circle cx="19" cy="5" r="1" />  <circle cx="5" cy="12" r="1" />  <circle cx="12" cy="12" r="1" />  <circle cx="19" cy="12" r="1" />  <circle cx="5" cy="19" r="1" />  <circle cx="12" cy="19" r="1" />  <circle cx="19" cy="19" r="1" /></svg>
  
        <p class="text-md text-black ml-2">
  
        {item.seats} Seats
        </p>
        </div>
        <div class="flex items-center">
        <svg class="h-6 w-6 text-purple-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <polyline points="12 6 12 12 16 14" /></svg>            
        <p class="text-md text-black ml-2">
  
        {item.time}
        </p>
        </div>
  
        </div>
  
  
  
            <div class="flex items-center">
         
  <p class="text-md text-black ml-2">
  
  Nu. {item.price} / Vote
  </p>
  </div>
  
  
            <p class="font-light text-gray-400 text-md">
                {item.description.substring(0,28)}... Read More
            </p>
           
        </div>
    </a>
  </div>
          
  

)

})}





        </div>
         <div class="mt-6  flex justify-center items-center">
 <button onClick={()=>history('/findshows')} type="button" class="py-2 md:w-1/3 w-full h-14 px-4 flex justify-center items-center border-purple-300  bg-none hover:bg-purple-500 focus:ring-red-500 focus:ring-offset-red-200 text-purple-500 hover:text-white  transition ease-in duration-200 text-center text-base  border-2 border-purple-500 font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
  
     Find More Reality Show
     <svg class="h-8 w-8 "  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <polyline points="13 17 18 12 13 7" />  <polyline points="6 17 11 12 6 7" /></svg>
 </button>
 </div>

         </div>

       



 <div class="relative p-4 mt-10 bg-white ">
     <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-12 lg:items-center">
         <div class="lg:col-start-2 md:pl-20">
             <h4 class="text-2xl font-extrabold leading-8 tracking-tight text-gray-900  sm:leading-9">
                 Tickets at your fingertip
             </h4>
             <ul class="mt-10">
                 <li >
                     <div class="flex">
                         <div class="flex-shrink-0">
                             <div class="flex items-center justify-center w-12 h-12 text-white bg-purple-500 rounded-md">
                             <svg class="h-8 w-8 text-white"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2" /></svg>
                             </div>
                         </div>
                         <div class="ml-4">
                             <h5 class="text-lg font-bold leading-6 text-gray-900 ">
                             Instant Access to Events
                             </h5>
                             <p class="mt-2 text-base leading-6 text-gray-500 ">
                             Browse and purchase tickets for concerts, sports events, theater shows, and more with just a few taps.                            </p>
                         </div>
                     </div>
              </li>
                 <li class="mt-10">
                     <div class="flex">
                         <div class="flex-shrink-0">
                             <div class="flex items-center justify-center w-12 h-12 text-white bg-purple-500 rounded-md">
                             <svg class="h-8 w-8 text-white"  width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="5" y="11" width="14" height="10" rx="2" />  <circle cx="12" cy="16" r="1" />  <path d="M8 11v-4a4 4 0 0 1 8 0v4" /></svg>

                             </div>
                         </div>
                        <div class="ml-4">
                             <h5 class="text-lg font-bold leading-6 text-gray-900 ">
                             Secure Transactions
                             </h5>
                             <p class="mt-2 text-base leading-6 text-gray-500 ">
                             Enjoy peace of mind with secure and encrypted payment options for hassle-free transactions.                            </p>
                       </div>
                     </div>
                 </li>
                 <li class="mt-10">
                     <div class="flex">
                         <div class="flex-shrink-0">
                             <div class="flex items-center justify-center w-12 h-12 text-white bg-purple-500 rounded-md">
                             <svg class="h-8 w-8 text-white"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
   <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z"/>
 </svg>
                             </div>
                         </div>
                      <div class="ml-4">
                             <h5 class="text-lg font-bold leading-6 text-gray-900 ">
                                 QR Codes
                             </h5>
                             <p class="mt-2 text-base leading-6 text-gray-500 ">
                               QR codes for fast teacking and tickets validation.
                             </p>
                         </div>
                     </div>
                 </li> 
             </ul>
         </div>
         <div class="relative mt-10 -mx-4 mx-2 lg:mt-0 lg:col-start-1">
             <img src="https://img.freepik.com/free-vector/movie-ticket-concept-illustration_114360-19282.jpg?w=740&t=st=1702105660~exp=1702106260~hmac=f18e6ad6e077d2eeeb40d679565cce6cab892ae171105ff43e3d890adc5b09ea" alt="illustration" class="relative w-auto mx-auto rounded "/>
         </div>
     </div>
 </div>


 </div>

        






                <div class="bg-white ">
                    <div class="text-center w-full mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 z-20">
                        <h2 class="text-3xl font-extrabold text-black  sm:text-4xl">
                            <span class="block">
                                Have Some Queries ?
                            </span>
                            <span class="block text-purple-500">
                                Get in touch with us.
                            </span>
                        </h2>
                        <p class="text-xl mt-4 max-w-md mx-auto text-gray-400">
                        Our team of experts are always there to serve you and help you to have better experience.
                        </p>
                        <div class="lg:mt-0 lg:flex-shrink-0">
                            <div class="mt-12 inline-flex rounded-md shadow">
                                <button type="button" class="py-4 px-6  bg-purple-500 hover:bg-purple-600 focus:ring-purple-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                                onClick={()=>{history('/register')}}
                                >
                                    Register
                                </button>
                            </div>
                        </div>
                    </div>
                </div>






<Footer page="home"></Footer>
</main>


</>

  


    );
}

export default Home;