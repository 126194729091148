
import React,{useContext, useEffect, useState} from 'react'
import {Link, useN, useNavigate, useParams} from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import Header from '../components/Header';
import 'react-toastify/dist/ReactToastify.css';

import {UserContex} from '../App'
import Footer from '../components/Footer';

import { loadStripe } from '@stripe/stripe-js';



const CancledRequest = ()=>{
  const {state, dispatch }= useContext(UserContex)
  const history =useNavigate();
  const [addStock,setAddStock]= useState(false)
  const [editEmoloyee,setEdit]= useState(false)
  const [checkOut,setCheckOut]= useState(false)
  const[id,setId] = useState("")  
//   const[name,setName]= useState("")
  const[phone,setPhone]= useState("")
//   const[address,setAddress]= useState("")
  const[allAddress,setAllAddress]= useState([])
  const {membershipid} = useParams()

//   const[email,setEmail]= useState("")

//   useEffect(  async()=>{

//       name =await JSON.parse( localStorage.getItem("name"))

//      email = await JSON.parse( localStorage.getItem("email"))
//    address = await JSON.parse( localStorage.getItem("address"))
    
//     const cartp =   await JSON.parse( localStorage.getItem("cart"))

//     const response = await fetch("/addOrder",{
//         method:"post",
//         headers:{
//             Authorization:"Bearer "+localStorage.getItem("jwt"),
//             "Content-Type":"application/json"
//         },
//         body: JSON.stringify({
//             orderItem:cartp,address,name,email,date
            
//         })
        
//     })
    
//     const data = response.json()

    
//     // .then(res=>res.json())
//     // .then(data=> {
       
//         if(data.error){
//           toast.error(data.error)
  
//         }
//         else{
//             localStorage.removeItem("cart")
//             localStorage.removeItem("cartid")
//        toast.success("Order Placed")
//     //    history('/cart')
//         }
//     // })
//     // .catch(err=>{
//     //     console.log(err)
//     // })
  

//   },[])



useEffect(() => {
    toast.error("Sorry Error Occured");
    // history('/findmemberships')

  
  }, []);










      
    return(


<>
<Header></Header>











<div className="flex items-center justify-center min-h-screen bg-gray-100">
  


<div class="relative overflow-hidden bg-white rounded-lg shadow w-60 md:w-72 mt-24 object-center">
    <img src="/logo.png" alt="btc logo" class="absolute w-32 h-32 rounded-full opacity-50 -top-6 -right-6 md:-right-4"/>
    <div class="px-4 py-5 sm:p-6">
        <dl>
            <dt class="text-sm font-medium leading-5 text-gray-500 truncate">
                Sorry
            </dt>
            <dd class="mt-1 text-3xl font-semibold leading-9 text-gray-900">
            Your membership request encountered error
            </dd>
            <dd class="font-semibold text-gray-500">
                <span>
                  <Link to='/findmemberships'>
                <svg 
                
                class="h-8 w-8 text-indigo-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <polyline points="12 16 16 12 12 8" />  <line x1="8" y1="12" x2="16" y2="12" />
                
                </svg>
                </Link>

                </span>
            </dd>
        </dl>
    </div>
</div>





        </div>



<Footer></Footer>





    









</>
  


    );
}

export default CancledRequest;






