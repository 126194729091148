import React, { useEffect, useState, useRef } from 'react';
import Header from '../components/Header';
import QRCode from 'qrcode.react';
import ReactPDF, { Document, Page, Text, View, StyleSheet, Image,PDFDownloadLink } from '@react-pdf/renderer';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Footer from '../components/Footer';

function FreeTicket(props) {
  const [paymentIntentId, setPaymentIntent] = useState("");
  const [data, setData] = useState([]);
  const [seats, setSeats] = useState();
  const [event, setEvent] = useState(null);
  const [pic, setPic] = useState("");
  const [imageUrls, setImageUrls] = useState([]);
  const divRef = useRef(null);
const [load,setLoad] = useState(false)
console.log( JSON.parse(localStorage.getItem('cart')), JSON.parse(localStorage.getItem('info')))


  const handleConvertToPdf = () => {
    if (!divRef.current) return;

    // Use html2canvas to capture the content of the div
    html2canvas(divRef.current,{
        allowTaint: true,
        useCORS: true
      }).then((canvas) => {
      // Convert the canvas to a data URL
      const imgData = canvas.toDataURL('image/png');

      // Calculate the page size based on the div size
      const pdf = new jsPDF({
        unit: 'px',
        format: 'a4',
      });

      // Add the image to the PDF
      pdf.addImage(imgData, 'PNG', 0, 0);
      
//       const pdfData = pdf.output('dataurl');
// console.log(pdfData)
      // Save or open the PDF
      pdf.save('Tickets.pdf');

      // const pdfData = pdf.output("datauristring");
      // var finalpdf = btoa(pdf.output());
      //  console.log("PDFFF",finalpdf)
      // Send the PDF data to the backend
      // sendPdfToBackend(pdfData);
      // sendPdfToBackend(finalpdf)
    });
  };

  useEffect(() => {

    fetch('/addticket', {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        data: JSON.parse(localStorage.getItem('data')),
        seats: JSON.parse(localStorage.getItem('seats'))
        
      })
    })
      .then(res => res.json())
      .then(results => {
        setData(results.tickets);
        setEvent(results.event);
        setPic(results.event.pic);
        setSeats(results.seats)
        // setLoad(true)
        localStorage.removeItem('data');
        localStorage.removeItem('seats');
        // handleConvertToPdf()
      });
  }, []);

  // if(load){
  //   handleConvertToPdf()
  //   setLoad(false)
  // }


  const sendPdfToBackend = (pdfData) => {
    // Assuming you have a server endpoint that accepts file uploads using Multer
    // const backendEndpoint = '/upload-pdf';
  console.log("POSTT")
    // Create a FormData object and append the PDF data
    // const formData = new FormData();
    // formData.append('pdf', pdfData);
  
    // Make a POST request to the server
    fetch("/upload-pdf", {
      method: 'POST',
      body:  JSON.stringify({ pdfData }),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response from the server (e.g., display a success message)
        console.log('Server response:', data);
      })
      .catch((error) => {
        // Handle errors
        console.error('Error sending PDF to the server:', error);
      });
  };
  return (
    <>
      <Header></Header>
      {/* <button
      onClick={handleConvertToPdf}

      class="fixed  z-50 bottom-10 md:top-96 right-8 bg-blue-500 w-12 md:w-32 h-12 rounded-full drop-shadow-lg flex justify-center items-center text-white text-4xl hover:bg-blue-600 hover:drop-shadow-2xl hover:animate-bounce duration-300"> 
<svg class="h-8 w-8 text-white"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />  <polyline points="7 10 12 15 17 10" />  <line x1="12" y1="15" x2="12" y2="3" /></svg>
    
    <h6 class="text-xs md:text-sm md:p-2 hidden sm:block">Download</h6>

</button> */}
      <div   className="bg-gray-50 flex items-center justify-center px-5 py-5 mb-2">
        {/* <div ref={divRef} className="grid relative grid-cols-1 gap-6 md:grid-cols-1 xl:grid-cols-1">
          {data ? data.map((item, index) => (
            <div class="flex ">
              <div className="flex max-w-md h-80  overflow-hidden bg-white  rounded-lg shadow-lg"
               
              >
                <div className="w-1/2 bg-fit ">

                    <img class="h-full " src={pic}/>

                </div>
                <div className="w-2/3 p-4">
                  <h1 className="text-2xl font-bold text-black">{event.name}</h1>
                
                  <div class="flex items-center">
                  <svg class="h-8 w-8 text-indigo-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="4" y="5" width="16" height="16" rx="2" />  <line x1="16" y1="3" x2="16" y2="7" />  <line x1="8" y1="3" x2="8" y2="7" />  <line x1="4" y1="11" x2="20" y2="11" />  <rect x="8" y="15" width="2" height="2" /></svg> 
             <p class="text-md text-black  ml-2">
            
             {event.date.substring(0,10)}
        </p>
    </div>
                  <div class="flex items-center">
            <svg class="h-8 w-8 text-indigo-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
</svg>
            
             <p class="text-md text-black  ml-2">
            
            {event.venue}
        </p>
    </div>

    <div class="flex items-center">
                        <svg class="h-8 w-8 text-purple-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <polyline points="12 6 12 12 16 14" /></svg>            
             <p class="text-md text-black dark:text-white ml-2">
            
            {event.time}
        </p>
    </div>
    
               {  seats? <div class="flex items-center">
                  <svg class="h-8 w-8 text-indigo-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="5" cy="5" r="1" />  <circle cx="12" cy="5" r="1" />  <circle cx="19" cy="5" r="1" />  <circle cx="5" cy="12" r="1" />  <circle cx="12" cy="12" r="1" />  <circle cx="19" cy="12" r="1" />  <circle cx="5" cy="19" r="1" />  <circle cx="12" cy="19" r="1" />  <circle cx="19" cy="19" r="1" /></svg>
            
             <p class="text-md text-black  ml-2">
            
           SEAT: FREE {item.seat} 
        </p>
    </div>:<>
    </>}
          
                  <div className="flex justify-between mt-3 item-center">
                    
                    <h1 className="text-xl font-bold text-black">${event.price}</h1>
                    <QRCode value={JSON.stringify(item)} size={90}></QRCode>

                  </div>
                </div>
              </div>




            </div>
          )) : <>
          
          
          
          </>}



          
        </div> */}


<div class="flex flex-col justify-start max-w-xl gap-4 p-4 bg-white shadow-xl rounded-xl md:flex-row">
    <div class="relative">
        <img src="/logo.png" class="w-full rounded-xl md:w-auto md:max-h-40"/>
        
    </div>
    <div class="flex flex-col justify-between">
        <div class="flex items-start justify-between my-2 text-gray-700 md:m-0">
            <p class="text-xl leading-5">
                We have Emailed you your tickets. Please check your mail
            </p>
          
        </div>
      
    </div>
</div>

      </div>
      {/* <PDFDownloadLink document={<MyDocument event={event} />} fileName="bill.pdf">
        {({ blob, url, loading, error }) =>
          loading ? 'Loading document...' : (
            <span className="px-4 py-2 m-2 flex w-24 items-center text-base rounded-full text-blue-500 hover:bg-blue-200 border border-indigo-300 undefined cursor-pointer">
              <svg className="h-8 w-8 text-blue-500" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z"></path>
                <path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2"></path>
                <path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4"></path>
                <rect x="7" y="13" width="10" height="8" rx="2"></rect>
              </svg>
              Print
            </span>
          )
        }
      </PDFDownloadLink> */}


{/* <span className="px-4 py-2 m-2 flex w-24 items-center text-base rounded-full text-blue-500 hover:bg-blue-200 border border-indigo-300 undefined cursor-pointer"

onClick={handleConvertToPdf}
>
              <svg className="h-8 w-8 text-blue-500" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z"></path>
                <path d="M17 17h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2"></path>
                <path d="M17 9v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4"></path>
                <rect x="7" y="13" width="10" height="8" rx="2"></rect>
              </svg>
              Print
            </span> */}

            {/* {handleConvertToPdf()} */}
            <Footer></Footer>
    </>
  );
}

export default FreeTicket;
