
import React,{useContext, useState,useEffect} from 'react'
import {Link, useNavigate,useParams} from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {UserContex} from '../App'
import Header from '../components/Header';
import Payment from './Payment';
import { name } from '@cloudinary/url-gen/actions/namedTransformation';
import { loadStripe } from'@stripe/stripe-js';
import {socket} from "../App"
import Footer from '../components/Footer';
// import { CardElement, Elements, useElements } from
 
// '@stripe/react-stripe-js';

// const stripePromise = loadStripe('pk_test_51O7H4NFtdms7xyPvenr9xBB9Hor425E614fYOEv0vCgo9ygKGSVezMIfQWcg8Rhef7I2APPt1PMC4lfdz3TnHtLT00idMMp6pq');


const RefPage = ()=>{
  const {state, dispatch }= useContext(UserContex)
  const history =useNavigate();
  const [email,setEmail] =  useState("");
  const [name,setName] =  useState("");
  const [phone,setPhone] =  useState("");
  const [password,setPassword] =  useState("");
  const [billing,setBilling] =  useState(false);
  const [payment,setPayment] =  useState(false);
  const [freeEvent,setFreeEvent] =  useState(false);
  const [policy,setPolicy] =  useState(false);
  const [seats,setSeats] =  useState(0);
  const [size,setSize] =  useState(0);
  const navigate= useNavigate()

  const [data,setData]= useState([])
  const [cart,setCart]= useState([])
  const [details,setDetails]= useState([])
  const [column, setColumn] = useState("")
  const [booked,setBooked] = useState([])
  const [taken,setTaken] = useState([])
const {sellerid,postid} = useParams()
const [date, setDate] = useState(new Date().setHours(0, 0, 0, 0))
  const [eventDate, seteventDate] = useState(new Date().setHours(0, 0, 0, 0))
// const elements = useElements()

// setColumn("grid-cols-"+data.col) 

// console.log("Booked",cart)

// if(booked.length>0){
//     localStorage.setItem("data", JSON.stringify(booked));

// }


socket.off(postid).on(postid, (data) => {

    data.map(item=>{
        setTaken(old=>[...old, item])

    })

  });
  
socket.off(`refresh-${postid}`).on(`refresh-${postid}`, (data) => {

    fetch(`/getsinglereserved/${postid}`, {
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(res => res.json())
        .then(result => {
            setTaken((result.reserved.map(item => parseInt(item.seat))));
            // setColumn(String("grid-cols-" + result.event.col));
        });
  });
  
 
  console.log("SELLLL",sellerid)
 useEffect(() => {
    // console.log("Effect is running"); // Add this line for debugging

    fetch(`/getsingleevent/${postid}`, {
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(res => res.json())
        .then(result => {
            // console.log("EVENT",result.event)
            setData(result.event);
            // console.log("OCCP",result.event.occupied)
            // setSize(new Set(result.event.occupied).size);
            const nd = new Date(result.event.date)
            seteventDate(nd)


            setColumn(String("grid-cols-" + result.event.col));
        });


    fetch(`/getsinglereserved/${postid}`, {
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(res => res.json())
        .then(result => {
            setTaken((result.reserved.map(item => parseInt(item.seat))));
            // setColumn(String("grid-cols-" + result.event.col));
        });




}, []);

 

       

       const addToCart = (item)=>{




        if(cart.includes(item)){

           let index = cart.indexOf(item)
            setCart([
                ...cart.slice(0,  index),
                ...cart.slice(index + 1, cart.length)
              ]);
              setSeats(seats-1)



        }
        else{
            // console.log("ADD")

            setCart(olditem=>[...olditem, item])
            
            setSeats(seats+1)

        }

       }

    //    const addToCart = (item)=>{

    //     console.log("ITEM",item)
    //     console.log("CART",cart)


    //     if(cart.includes(item)){

    //         // console.log("REMO",cart)

    //         let index = cart.indexOf(item)
    //          setCart([
    //              ...cart.slice(0,  index),
    //              ...cart.slice(index + 1, cart.length)
    //            ]);
    //            setSeats(seats-1)
 
 
 
    //      }
    //      else{
 
    //          setCart(olditem=>[...olditem, item])
             
    //          setSeats(seats+1)
    //         //  console.log("ADD",cart)

    //      }


    //    }

       const addDetails = (name,email,item)=>{


       
            console.log("ADD", name,email,item)

            setDetails(olditem=>[...olditem, {name:name,email:email,seat:item,event:postid}])
            

       }




       const addBooked= ()=>{


        cart.map(seat=>{

            setBooked(olditem=>[...olditem, {name:name,email:email,seat:seat,event:postid,seller:sellerid}])

        })

        setPayment(false)
        setBilling(true)
            

       }

    //    const addBookedFree = () => {
    //     cart.map(seat=>{

    //         setBooked(olditem=>[...olditem, {name:name,email:email,seat:seat,event:postid,seller:sellerid}])

    //     })
    //     setPayment(false)

    //     setFreeEvent(true)


    //   };


      const addBookedFree = () => {
        let updatedBooked = [];
      
        // setBooked(olditem=>[...olditem, {name:name,email:email,seat:seat,event:postid,seller:sellerid}])

        cart.forEach(seat => {
          updatedBooked.push({ name: name, email: email, phone: phone, seat: seat, event: postid,seller:sellerid });
        });
      
        // Update the state with the new booked data
        setBooked(prevBooked => [...prevBooked, ...updatedBooked]);
      
        console.log("ADDED", updatedBooked);
      
        setPayment(false);
        setFreeEvent(true);
      
        fetch('/addticket', {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            data: updatedBooked,  // Use the updatedBooked array
            seats: data.row ? true : false
          })
        })
        .then(res => res.json())
        .then(results => {
          // Handle the response as needed
        });
      };
      



      const setDatato = ()=>{
        localStorage.setItem("data", JSON.stringify(booked));
        localStorage.setItem("seats", JSON.stringify(seats));
        history('/freeticket')
      }

       const [time, setTime] = useState(300);
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    let interval;

    if (isRunning) {
      interval = setInterval(() => {
        setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
      }, 1000);
    
    }

  
   
    // Cleanup the interval when the component unmounts or when isRunning is set to false
    return () => clearInterval(interval);
  }, [isRunning]);

  const formatTime = (seconds) => {
 
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
   

    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const handleButtonClick = () => {
    if(seats){
        socket.emit('cart', {cart,postid});

        setIsRunning(true);

    }
  };

  
  if(time<1){
    setIsRunning(false);
    setTime(300)
    setPayment(false)

    fetch(`/deletesinglereserved/${postid}`, {
        method:"delete",
        headers:{
            "Content-Type":"application/json"
        },
        body: JSON.stringify({
          
            cart
        })
    })
        .then(res => res.json())
        .then(result => {
            // setColumn(String("grid-cols-" + result.event.col));
            setCart([])
            setSeats(0)

            socket.emit('refresh', postid);


        });


  }

    //   const handleSubmit = async (event) => {
    //     console.log("PAY")
    //     event.preventDefault();
    
    //     const stripe = await stripePromise;
    //     const { error, paymentMethod } = await stripe.createPaymentMethod({
    //       type: 'card',
    //       card: cardElement,
    //     });
    
    //     if (error) {
    //       console.error('Payment error:', error);
    //       return;
    //     }
    
    //     // Send payment information to Node.js backend
    //     const paymentData = {
    //       paymentMethodId: paymentMethod.id,
    //       amount: 1000, // Replace with actual payment amount
    //     };
    
    //     try {
    //       const response = await fetch('/payment', {
    //         method: 'POST',
    //         headers: {
    //           'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify(paymentData),
    //       });
    
    //       const responseData = await response.json();
    
    //       if (responseData.success) {
    //         // Update payment status in React frontend
    //         console.log('Payment successful!');
    //       } else {
    //         console.error('Payment failed:', responseData.error);
    //       }
    //     } catch (error) {
    //       console.error('Error sending payment information:', error);
    //     }
    //   };

    return(

<>
<main class="relative h-screen  bg-white dark:bg-gray-800 ">
  
<Header></Header>


       <img src="https://img.freepik.com/free-vector/abstract-purple-wavy-modern-light-background_361591-1420.jpg?size=1450&ext=jpg&ga=GA1.1.1697546048.1698228343&semt=ais" class="absolute h-1/2 w-full object-cover overflow-hidden"/>
{/* 
       <video autoPlay loop muted playbackRate={2.0}  class=" absolute h-1/2 w-full object-fill md:h-1/2  md:w-full md:object-cover">
    <source src="https://v4.cdnpk.net/videvo_files/video/free/video0467/large_watermarked/_import_614f00b3e16dc1.55454574_FPpreview.mp4" type="video/mp4" />
       </video> */}
      <div>
      <img src={data.pic?data.pic:"https://img.freepik.com/free-psd/sound-party-cover-template_23-2148131763.jpg?size=626&ext=jpg&ga=GA1.1.1697546048.1698228343&semt=sph" } class=" relative h-60 w-11/12 ml-4 md:h-96 md:w-1/2 object-center md:ml-80  md:mt-24 mt-32 rounded-lg "/> 

      </div>

   
   
   

        {/* <div class=" relative flex  mx-auto">
       
                <img src="https://img.freepik.com/free-photo/excited-audience-watching-confetti-fireworks-having-fun-music-festival-night-copy-space_637285-559.jpg?w=996&t=st=1698228712~exp=1698229312~hmac=e6d992326774f5a0d719062875e0439936f46b09ea558d4d295af285dc06e732" class=" w-full h-92"/>
        </div>
         */}
    





   
{/* <div class="w-full md:p-12 bg-white overflow-hidden ">

         
         <div class="p-4 mx-auto text-center  bg-white border-indigo-500 rounded shadow h-36 w-96 dark:bg-gray-800">
             <div class="flex items-center justify-between h-full">
                 <div class="flex flex-col justify-between h-full">
                     <div>
                    
         
         <div class="flex items-center">
                                                           <button type="button" class="w-full p-2 text-base text-gray-600 bg-white border rounded-l-xl hover:bg-gray-100"
                                                           onClick={()=>{addToCart(seats-1);}}
                                                           disabled={(data.row&&data.col)|| (seats==0)}

                                                           >
                                                          
                                                           -
                                                           </button>
                                   
                                                           <button type="button" class="w-full px-4 py-2 text-base text-gray-600 bg-white border hover:bg-gray-100">
                                                           {seats}
                                                           </button>
                                   
                                   
                                                           <button type="button" class="w-full p-2 text-base text-gray-600 bg-white border-t border-b border-r rounded-r-xl hover:bg-gray-100"
                                                           
                                                           onClick={()=>{addToCart(seats)}}
                                                           disabled={(data.row&&data.col) }
                                                           >
                                                          
                                                           +
                                                           </button>
                                                           </div>
                     </div>
                     <div>
                         <p class="mb-0 text-left text-gray-500 dark:text-gray-300">
                             {data.occupied} booked of {data.seats} seats
                         </p>
                         <div class="w-full h-4 mt-3 bg-gray-300 rounded-full">
                             <div class=" h-full text-center text-xs text-white bg-indigo-500 rounded-full"
                             style={{ width: `${(data.occupied*100)/data.seats}%` }}
                             >
                             </div>
                         </div>
                     </div>
                 </div>
                 <div class="flex flex-col justify-between h-full">
                     <p class="text-4xl font-bold text-gray-900 dark:text-white">
                         <span class="text-sm">
                             $
                         </span>
                         {data.price*seats}
                         
                                                  <span class="text-sm text-gray-300">
                             / seat
                         </span>
                     </p>
                     <button
                     onClick={()=>{setPayment(true);handleButtonClick()}}
                     disabled={data.occupied>=data.seats}
                     type="button" class="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                        {data.occupied>=data.seats?"Housefull":"Book Ticket" } 
                     </button>
                 </div>
             </div>
         </div>

{data.row&&data.col&&data.seats?<>



        <div class={`grid  gap-3   ${data.col?String(`md:grid-cols-${data.col}`):""} grid-cols-6 overflow-hidden md:p-14 p-4 `}>


   {[...Array(parseInt(data.seats))].map((_, index) => (
          <button type="button" class={`w-8 h-8 md:w-14 md:h-14 text-base  rounded-full text-white ${cart.includes(index+1)?" bg-yellow-500":data.booked?data.booked.includes(String(index+1))?"bg-red-500":taken.includes(index+1)?"bg-red-500":"bg-green-500":""}` }
          onClick={()=>{addToCart(index+1)}}
          disabled={(data.booked && data.booked.includes(String(index + 1)))|| taken.includes(index+1)}
         >
          <span class="p-1">
              {index+1}
          </span>
      </button>
      
        ))}
</div>

</>:<></>}

    <div class="flex items-end justify-between mb-12 ml-2 header">
        <div class="title">
            <p class="mb-4 text-4xl font-bold text-gray-800">
                {data.name}
            </p>
            <p class="text-2xl font-light text-gray-400">
            {data.description}

            </p>
        </div>
      
      
        </div>
        
    </div> */}




<div class="w-full md:p-12  bg-white overflow-auto mt-4 mb-4 relative">


{!(date>=eventDate)? 

<>
<p class="mb-0 text-center font-extrabold text-purple-500 ">
                      Step 1/3: Book Ticket(s)
                  </p>
<div class="p-4 mx-auto text-center  bg-white border-indigo-500 rounded shadow h-36 md:w-96 w-11/12 ">
      <div class="flex items-center justify-between h-full">
         
          <div class="flex flex-col justify-between h-full">
         
              <div>
                  {/* <span class="p-2 mr-2 text-white bg-gray-700 rounded dark:bg-gray-400">
                      Business
                  </span>
                  <span class="dark:text-white">
                      Plan
                  </span> */}
  
  <div class="flex items-center">
                                                    <button type="button" class="w-full p-2 text-base text-gray-600 bg-white border rounded-l-xl hover:bg-gray-100"
                                                    onClick={()=>{addToCart(seats-1);}}
                                                    disabled={(data.row&&data.col)|| (seats==0)}

                                                    >
                                                   
                                                    -
                                                    </button>
                            
                                                    <button type="button" class="w-full px-4 py-2 text-base text-gray-600 bg-white border hover:bg-gray-100">
                                                    {/* {item.qty} */}{seats}
                                                    </button>
                            
                            
                                                    <button type="button" class="w-full p-2 text-base text-gray-600 bg-white border-t border-b border-r rounded-r-xl hover:bg-gray-100"
                                                    
                                                    onClick={()=>{addToCart(seats)}}
                                                    disabled={(data.row&&data.col) }
                                                    >
                                                    {/* <svg width="9" fill="currentColor" height="8" class="" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z">
                                                    </path>
                                                    </svg> */}
                                                    +
                                                    </button>
                                                    </div>
              </div>
              <div>
             
                  <div class="w-full h-4 mt-3 bg-gray-300 rounded-full">
                      <div class=" h-full text-center text-xs text-white bg-indigo-500 rounded-full"
                      style={{ width: `${(size*100)/data.seats}%` }}
                      >
                          {/* 60% {data.seats} */}
                      </div>
                  </div>
              </div>
          </div>
          <div class="flex flex-col justify-between h-full">
              <p class="text-4xl font-bold text-gray-900 ">
                  <span class="text-sm">
                      $
                  </span>
                  {data.price*seats}
                  
                                           <span class="text-sm text-gray-300">
                      
                  </span>
              </p>
              <button
              onClick={()=>{setPayment(true);handleButtonClick()}}
              disabled={data.occupied>=data.seats || seats<=0}
              type="button" class="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                 {data.occupied>=data.seats?"Housefull":"Book Ticket" } 
              </button>
              {seats<=0?"Please Select Seats to Proceed":""}
          </div>
      </div>
  </div>
</>
:
  
  <div class="p-4 mx-auto text-center  bg-white border-indigo-500 rounded shadow h-36 md:w-96 w-11/12 ">
<div class="flex justify-center items-center ">
     
      <img src='/ended.png' class="h-24 w-24 "/>
    
  </div>
</div>
  }


  {/* {column} */}

{data.row&&data.col&&data.seats?<>



 <div class={`grid  gap-3   ${data.col?String(`md:grid-cols-${data.col}`):""} grid-cols-6 overflow-hidden md:p-14 p-4 `}>


{[...Array(parseInt(data.seats))].map((_, index) => (
   <button type="button" class={`w-8 h-8 md:w-14 md:h-14 text-base  rounded-full text-white ${cart.includes(index+1)?" bg-yellow-500":data.booked?data.booked.includes(String(index+1))?"bg-red-500":taken.includes(index+1)?"bg-red-500":"bg-green-500":""}` }
   onClick={()=>{addToCart(index+1)}}
   disabled={(data.booked && data.booked.includes(String(index + 1)))|| taken.includes(index+1)}
  >
   <span class="p-1">
       {index+1}
   </span>
</button>

 ))}
</div>

</>:<></>}

<div class="flex items-end justify-between m-4 header">
 <div class="title">
     <p class="mb-4 text-4xl font-bold text-gray-800">
         {data.name}
     </p>
     <p class="text-2xl font-light text-gray-400">
     {data.description}

     </p>
 </div>


 </div>
 
</div>


    {
payment? 
(
  <>
    <div
      className="justify-center md:p-44 items-center flex   fixed inset-0 z-50 outline-none focus:outline-none overflow-auto"
    >

        {/*content*/}
        <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex rounded-full items-start  ">
            
            <button
              className="p-1 ml-auto rounded-full bg-white text-white text-3 "
              onClick={() => setPayment(false)}
            >
<svg class="h-8 w-8 text-red-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <line x1="15" y1="9" x2="9" y2="15" />  <line x1="9" y1="9" x2="15" y2="15" /></svg>

   </button>
          </div>
          {/*body*/}
         
          


          <div className="text-gray-500 rounded-3xl shadow-xl w-full mb-5" >



    
    <div className="text-center">
      <div className="text-sl font-bold mb-4">
        Time Remaining: {formatTime(time)}
      </div>
     
    </div>

      <div className="md:flex w-full">
        
        <div className="w-full md:h-96    px-5 md:px-10 relative overflow-auto">


        {/* {cart.map((item, index) => (
         
         <div class="flex flex-col w-full max-w-md px-4 py-1 bg-white rounded-lg shadow dark:bg-gray-800 sm:px-6 md:px-8 lg:px-10">
         <div class="self-start mb-1 text-xl font-light text-gray-600 sm:text-2xl dark:text-white">
             Add User {index+1} Details Seat {item}
         </div>
         <div class="mt-1">
             <form action="#" autoComplete="off">
                 <div class="flex flex-col mb-1">



                 <div class="grid max-w-xl grid-cols-2 gap-4 ">
     

     <div className="py-1">


     <span className="px-1 text-sm text-gray-600"> Name</span>
     <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
     bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
     
     placeholder="Name"
     value={name}
     onChange={(e)=>setName(e.target.value)}
     >
     
     </input>
     </div>
     
     <div className="py-1">
     <span className="px-1 text-sm text-gray-600">Phone</span>
     <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
     bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
     
     placeholder="Phone"
     value={email}
     onChange={(e)=>setEmail(e.target.value)}
     >
     
     </input>
     </div>
     
     
     </div>



                     <div class="flex relative ">
                         <span class="rounded-l-md inline-flex  items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-500 shadow-sm text-sm">
                             <svg width="15" height="15" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                 <path d="M1792 710v794q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-794q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48 92.5-65.5q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38-42.5-30.5q-91-64-262-182.5t-205-142.5q-62-42-117-115.5t-55-136.5q0-78 41.5-130t118.5-52h1472q65 0 112.5 47t47.5 113z">
                                 </path>
                             </svg>
                         </span>
                         <input type="text" id="sign-in-email" class=" rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Your email"/>
                         </div>
                     </div>
                
                        
                         <div class="flex w-full justify-end">
                             <button type="submit" class="py-2 px-4   bg-purple-600 hover:bg-purple-700 focus:ring-purple-500 focus:ring-offset-purple-200 text-white md:w-1/4 w-1/4 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                             onClick={()=>{addDetails(name,email,item)}}
                             >
                                 Add +
                             </button>
                         </div>
                     </form>
                 </div>
                
             </div>
            
        ))} */}

<div class="flex flex-col w-full max-w-md px-4 py-1 bg-white rounded-lg shadow dark:bg-gray-800 sm:px-6 md:px-8 lg:px-10">
         <div class="self-start mb-1 text-xl font-light text-gray-600 sm:text-2xl dark:text-white">
             Add User Details 
         </div>
         <div class="mt-1">
             <form action="#" autoComplete="off">
                 <div class="flex flex-col mb-1">



                 <div class="grid max-w-xl grid-cols-2 gap-4 ">
     

     <div className="py-1">


     <span className="px-1 text-sm text-gray-600"> Name</span>
     <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
     bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
     
     placeholder="Name"
     value={name}
     onChange={(e)=>setName(e.target.value)}
     >
     
     </input>
     </div>
     
     <div className="py-1">
     <span className="px-1 text-sm text-gray-600">Phone</span>
     <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
     bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
     
     placeholder="Phone"
     value={phone}
     onChange={(e)=>setPhone(e.target.value)}
     >
     
     </input>
     </div>

     
     </div>



                     <div class="flex relative ">
                         <span class="rounded-l-md inline-flex  items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-500 shadow-sm text-sm">
                             <svg width="15" height="15" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                 <path d="M1792 710v794q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-794q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48 92.5-65.5q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38-42.5-30.5q-91-64-262-182.5t-205-142.5q-62-42-117-115.5t-55-136.5q0-78 41.5-130t118.5-52h1472q65 0 112.5 47t47.5 113z">
                                 </path>
                             </svg>
                         </span>
                         <input type="text" id="sign-in-email" class=" rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Your email"
                         
                         value={email}
     onChange={(e)=>setEmail(e.target.value)}
                         />
                         </div>
                         <a   class={"text-blue-400 text-sm"} 
              
              onClick={()=>{setPolicy(true)}}
              >Refund and Cancellation Policy </a>
                         <h2 class={email==""||name==""||phone==""?"text-red-500":"text-white"}>All fields are mandatory </h2>

                     </div>

                     {
policy? 
(
<>
<div
className="justify-center md:p-44 items-center flex   fixed inset-0 z-50 outline-none focus:outline-none overflow-auto"
>
 {/*content*/}
 <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
   {/*header*/}
   <div className="flex rounded-full items-start  ">
     
     <button
       className="p-1 ml-auto rounded-full bg-white text-white text-3 "
       onClick={() => setPolicy(false)}
     >
<svg class="h-8 w-8 text-red-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <line x1="15" y1="9" x2="9" y2="15" />  <line x1="9" y1="9" x2="15" y2="15" /></svg>

</button>
   </div>
   {/*body*/}
  
  <h1 className='text-lg text-center'>
  Refund and Cancellation Policy

  </h1>


<p className='text-gray-400 text-sm m-2'>
Thank you for purchasing tickets through B5 Events. Please review our refund and cancellation policy carefully:

No Refunds for Missed Events: We do not offer refunds if you do not attend the event. All ticket sales are final.

Ticket Delivery Issues: If you do not receive your tickets after purchase, please contact us immediately. Send an email to info@b5events.com with your order details, and we will assist in resolving the issue.

No Refund for Non-Receipt of Tickets Without Prior Contact: Failure to receive tickets without informing us will not be eligible for a refund. It is your responsibility to notify us if there is an issue.

Event Cancellations: In the rare event of a cancellation by B5 Events, a full refund will be provided.

By making a purchase, you agree to these terms.

</p>







 

</div>


</div>
{/* <div className="opacity-25 fixed inset-0 z-40 bg-black"></div> */}
</>
)


:null

}

SEATS
                     <div class={`grid  md:gap-10 gap-1  md:grid-cols-5 grid-cols-8 overflow-hidden md:p-4 `}>


                     {cart.map(item=>{

return(
    <>
     <button type="button" class={`w-8 h-8 md:w-14 md:h-14 text-base  rounded-full text-white ${cart.includes(item)?" bg-yellow-500":" bg-green-500"} hover:bg-red-500`}
       onClick={()=>{addToCart(item)}}
       >
       <span class="md:p-4">
           {item}
       </span>
   </button>
    </>
)
})}

{/* {[...Array(parseInt(data.seats))].map((_, index) => (
      
   
     ))} */}
</div>

                     {/* <div class="flex flex-col mb-1">
                         <div class="flex relative ">
                             <span class="rounded-l-md inline-flex  items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-500 shadow-sm text-sm">
                                 <svg width="15" height="15" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                     <path d="M1376 768q40 0 68 28t28 68v576q0 40-28 68t-68 28h-960q-40 0-68-28t-28-68v-576q0-40 28-68t68-28h32v-320q0-185 131.5-316.5t316.5-131.5 316.5 131.5 131.5 316.5q0 26-19 45t-45 19h-64q-26 0-45-19t-19-45q0-106-75-181t-181-75-181 75-75 181v320h736z">
                                     </path>
                                 </svg>
                             </span>
                             <input type="password" id="sign-in-email" class=" rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Your password"/>
                             </div>
                         </div> */}
                        
                         {/* <div class="flex w-full justify-end">
                             <button type="submit" class="py-2 px-4   bg-purple-600 hover:bg-purple-700 focus:ring-purple-500 focus:ring-offset-purple-200 text-white md:w-1/4 w-1/4 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                            //  onClick={()=>{addDetails()}}
                             >
                                 Add +
                             </button>
                         </div> */}
                     </form>
                 </div>
                
             </div>
            


       
</div>

<div className=" md:block md:w-1/2 w-full  bg-white  px-5">


<div class="m-auto overflow-hidden rounded-lg shadow-lg cursor-pointer h-90 w-60 md:w-80">
                
                <img alt="blog photo" src={data.pic?data.pic:"https://images.unsplash.com/photo-1470229722913-7c0e2dbbafd3?auto=format&fit=crop&q=80&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&w=1470"} class="object-cover w-full max-h-40"/>
                    <div class="w-full p-4 bg-white dark:bg-gray-800">
                        <p class="font-medium text-indigo-500 text-md">
                            {data.name}
                        </p>
                        <div class="flex items-center">
            <svg class="h-6 w-6 text-indigo-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
</svg>
            
             <p class="text-md text-black dark:text-white ml-2">
            
            {data.venue}
        </p>
    </div>
          
                        <div class="flex items-center">
                        <svg class="h-6 w-6 text-indigo-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="5" cy="5" r="1" />  <circle cx="12" cy="5" r="1" />  <circle cx="19" cy="5" r="1" />  <circle cx="5" cy="12" r="1" />  <circle cx="12" cy="12" r="1" />  <circle cx="19" cy="12" r="1" />  <circle cx="5" cy="19" r="1" />  <circle cx="12" cy="19" r="1" />  <circle cx="19" cy="19" r="1" /></svg>
            
             <p class="text-md text-black dark:text-white ml-2">
            
            {seats} Seats
        </p>
    </div>
          
          
                        <div class="flex items-center">
                        <svg class="h-6 w-6 text-indigo-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>
            
             <p class="text-md text-black dark:text-white ml-2">
            
             {data.price*seats}
        </p>
    </div>
          
    
                        
                       
                    </div>
            </div>



   

</div>
          </div>
       
          <div className="flex rounded-full items-start  ">

            {data.price==0?
            <button type="submit" class="py-2 px-4  p-1 ml-auto rounded-full  text-white text-3  bg-purple-600 hover:bg-purple-700 focus:ring-purple-500 focus:ring-offset-purple-200 text-white md:w-1/4 w-1/4 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
            onClick={()=>{addBookedFree()}}
            disabled={(email.trim() === ""&&name.trim()===""&&phone.trim()==="")}

            >
                Register 
            </button>:
            
            <button type="submit" class="py-2 px-4  p-1 ml-auto rounded-full  text-white text-3  bg-purple-600 hover:bg-purple-700 focus:ring-purple-500 focus:ring-offset-purple-200 text-white md:w-1/4 w-1/4 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                             onClick={()=>{addBooked()}}
                             disabled={(email.trim() === ""&&name.trim()===""&&phone.trim()==="")}

                             >
                                 Proceed to Payment 
                             </button>
        
        }
            
          
          </div>
        </div>



        

      </div>

      
    </div>
    {/* <div className="opacity-25 fixed inset-0 z-40 bg-black"></div> */}
  </>
)


:null

}
    {
billing? 
(
  <>
    <div
      className="justify-center md:p-44 items-center flex   fixed inset-0 z-50 outline-none focus:outline-none overflow-auto"
    >
        {/*content*/}
        <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex rounded-full items-start  ">
            
            <button
              className="p-1 ml-auto rounded-full bg-white text-white text-3 "
              onClick={() => setBilling(false)}
            >
<svg class="h-8 w-8 text-red-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <line x1="15" y1="9" x2="9" y2="15" />  <line x1="9" y1="9" x2="15" y2="15" /></svg>

   </button>
          </div>
          {/*body*/}
         
          


          <div className="text-gray-500 rounded-3xl shadow-xl w-full mb-5" >
      <div className="md:flex w-full">
        
        <div className="w-full md:h-96    px-5 md:px-10 relative overflow-auto">

     <Payment data={booked} seats={data.row?true:false}   amount={data.price*seats} vendor={data.vendor.stripe.id}/>

       
</div>

<div className=" md:block md:w-1/2 w-full  bg-white  px-5">


<div class="m-auto overflow-hidden rounded-lg shadow-lg cursor-pointer h-90 w-60 md:w-80">
                
                <img alt="blog photo" src={data.pic?data.pic:"https://images.unsplash.com/photo-1470229722913-7c0e2dbbafd3?auto=format&fit=crop&q=80&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&w=1470"} class="object-cover w-full max-h-40"/>
                    <div class="w-full p-4 bg-white dark:bg-gray-800">
                        <p class="font-medium text-indigo-500 text-md">
                            {data.name}
                        </p>
                        <div class="flex items-center">
            <svg class="h-6 w-6 text-indigo-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
</svg>
            
             <p class="text-md text-black dark:text-white ml-2">
            
            {data.venue}
        </p>
    </div>
          
                        <div class="flex items-center">
                        <svg class="h-6 w-6 text-indigo-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="5" cy="5" r="1" />  <circle cx="12" cy="5" r="1" />  <circle cx="19" cy="5" r="1" />  <circle cx="5" cy="12" r="1" />  <circle cx="12" cy="12" r="1" />  <circle cx="19" cy="12" r="1" />  <circle cx="5" cy="19" r="1" />  <circle cx="12" cy="19" r="1" />  <circle cx="19" cy="19" r="1" /></svg>
            
             <p class="text-md text-black dark:text-white ml-2">
            
            {seats} Seats
        </p>
    </div>
          
          
                        <div class="flex items-center">
                        <svg class="h-6 w-6 text-indigo-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>
            
             <p class="text-md text-black dark:text-white ml-2">
            
             {data.price*seats}
        </p>
    </div>
          
    
                        
                       
                    </div>
            </div>

{/* <div class="relative overflow-hidden bg-white rounded-lg shadow w-60 md:w-72 ml-8 md:ml-0 mb-2">
    <img src="/logo.png" alt="btc logo" class="absolute w-24 h-24 rounded-full opacity-50 -top-6 -right-6 md:-right-4"/>
    <div class="px-4 py-5 sm:p-6">
        <dl>
            <dt class="text-sm font-medium leading-5 text-gray-500 truncate">
                Total Amount 
            </dt>
            <dd class="mt-1 text-3xl font-semibold leading-9 text-gray-900">
                $ {data.price*seats}
            </dd>
            <dd class="font-semibold text-gray-500">
                <span>
                    500
                    <span class="text-xs">
                        .000
                    </span>
                    BTC
                </span>
            </dd>
        </dl>
    </div>
</div> */}

   

</div>
          </div>
       
          {/* <div className="flex rounded-full items-start  ">
            
          <button type="submit" class="py-2 px-4  p-1 ml-auto rounded-full  text-white text-3  bg-purple-600 hover:bg-purple-700 focus:ring-purple-500 focus:ring-offset-purple-200 text-white md:w-1/4 w-1/4 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                             onClick={()=>{addBooked()}}
                             >
                                 Proceed to Payment -
                             </button>
          </div> */}
        </div>



        

      </div>

      
    </div>
    {/* <div className="opacity-25 fixed inset-0 z-40 bg-black"></div> */}
  </>
)


:null

}
    {
freeEvent? 
(
  <>
    <div
      className="justify-center  items-center flex   fixed inset-0 z-50 outline-none focus:outline-none overflow-auto"
    >
        {/*content*/}
        <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-48 bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex rounded-full items-start  ">
            
            <button
              className="p-1 ml-auto rounded-full bg-white text-white text-3 "
              onClick={() => setFreeEvent(false)}
            >
<svg class="h-8 w-8 text-red-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <line x1="15" y1="9" x2="9" y2="15" />  <line x1="9" y1="9" x2="15" y2="15" /></svg>

   </button>
          </div>
          {/*body*/}
         
          

     

<div className=" md:block md:w-12 w-full  bg-white  px-5">



<div 

onClick={()=>{setDatato()}}
class="p-4 bg-white shadow-lg rounded-2xl w-36 cursor-pointer dark:bg-gray-800">
    <div class="flex items-center">
        <span class="relative w-4 h-4 p-2 bg-green-500 rounded-full">
            <svg width="20" fill="currentColor" height="20" class="absolute h-2 text-white transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M1362 1185q0 153-99.5 263.5t-258.5 136.5v175q0 14-9 23t-23 9h-135q-13 0-22.5-9.5t-9.5-22.5v-175q-66-9-127.5-31t-101.5-44.5-74-48-46.5-37.5-17.5-18q-17-21-2-41l103-135q7-10 23-12 15-2 24 9l2 2q113 99 243 125 37 8 74 8 81 0 142.5-43t61.5-122q0-28-15-53t-33.5-42-58.5-37.5-66-32-80-32.5q-39-16-61.5-25t-61.5-26.5-62.5-31-56.5-35.5-53.5-42.5-43.5-49-35.5-58-21-66.5-8.5-78q0-138 98-242t255-134v-180q0-13 9.5-22.5t22.5-9.5h135q14 0 23 9t9 23v176q57 6 110.5 23t87 33.5 63.5 37.5 39 29 15 14q17 18 5 38l-81 146q-8 15-23 16-14 3-27-7-3-3-14.5-12t-39-26.5-58.5-32-74.5-26-85.5-11.5q-95 0-155 43t-60 111q0 26 8.5 48t29.5 41.5 39.5 33 56 31 60.5 27 70 27.5q53 20 81 31.5t76 35 75.5 42.5 62 50 53 63.5 31.5 76.5 13 94z">
                </path>
            </svg>
        </span>
        <p class="ml-2 text-gray-700 text-md dark:text-gray-50">
            Get My Tickte
        </p>
    </div>
    <div class="flex flex-col justify-start">
        <p class="my-4 text-4xl font-bold text-left text-gray-800 dark:text-white">
            {seats} Nos
        </p>
        <div class="relative h-2 bg-gray-200 rounded w-28">
            <div class="absolute top-0 left-0 w-2/3 h-2 bg-green-500 rounded">
            </div>
        </div>
    </div>
</div>

   

</div>
          </div>
       
          {/* <div className="flex rounded-full items-start  ">
            
          <button type="submit" class="py-2 px-4  p-1 ml-auto rounded-full  text-white text-3  bg-purple-600 hover:bg-purple-700 focus:ring-purple-500 focus:ring-offset-purple-200 text-white md:w-1/4 w-1/4 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                             onClick={()=>{addBooked()}}
                             >
                                 Proceed to Payment -
                             </button>
          </div> */}
        </div>



        

 
  </>
)


:null

}


<Footer page="events"></Footer>
</main>

</>

  


    );
}

export default RefPage;