import React, {useContext, useRef, useEffect, useState} from 'react'
import {Link,useNavigate,useParams} from 'react-router-dom'
import DatePicker from 'react-datepicker'
import {UserContex} from '../App'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import NavBar from './nav'
import Header from '../components/Header'
// import Footer from './Footer'
import QRCode from 'qrcode.react';
import Footer from '../components/Footer';


const MyTicket = ()=>{


  const history = useNavigate()
  const {state, dispatch}= useContext(UserContex)

  const  [data, setData]= useState(null)

  const [search,setSearch] = useState("")
  const  [event, setEvent]= useState(null)
  const {ticketid} = useParams()



//   const {
//     transcript,
//     listening,
//     resetTranscript,
//     browserSupportsSpeechRecognition
//   } = useSpeechRecognition();



//     const [startDate, setStartDate] = useState(new Date());


//   const monthNames = ["January", "February", "March", "April", "May", "June",
//   "July", "August", "September", "October", "November", "December"];

// var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

// const d = new Date();



useEffect(()=>{
    fetch('/trackmyticket',{

        method:"post",
        headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt"),
  
          "Content-Type":"application/json"
  
        },
        body:JSON.stringify({
          id:ticketid
        })
  
      }).then(res=> res.json())
        .then(results=>{
          
          setData(results.ticket)
          setEvent(results.ticket.event)
        })
},[])
  


  const fetchPost=(query)=>{


    setSearch(query)

    fetch('/trackmyticket',{

      method:"post",
      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt"),

        "Content-Type":"application/json"

      },
      body:JSON.stringify({
        id:query
      })

    }).then(res=> res.json())
      .then(results=>{
        
        setData(results.ticket)
        setEvent(results.ticket.event)
      })


  }




return(
<main class="bg-white h-screen  relative overflow-x-hidden">
    <div class="flex items-start justify-between">
       
        <div class="flex flex-col w-full ">

            

            <Header></Header>

            <div   className="bg-gray-50 flex flex-col items-center justify-center px-2 py-2 ">


{data && event?<>


    {/* <div class="flex ">
              <div className="flex max-w-md h-1/4 overflow-hidden bg-white  rounded-lg shadow-lg"
               
              >
                <div className="w-1/2 bg-fit ">

                    <img class="h-full " src={event?event.pic:""}/>

                </div>
                <div className=" p-2">
                  <h1 className="text-2xl font-bold text-black">{event.name}</h1>
                
                  <div class="flex items-center">
                  <svg class="h-6 w-6 text-indigo-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="4" y="5" width="16" height="16" rx="2" />  <line x1="16" y1="3" x2="16" y2="7" />  <line x1="8" y1="3" x2="8" y2="7" />  <line x1="4" y1="11" x2="20" y2="11" />  <rect x="8" y="15" width="2" height="2" /></svg> 
             <p class="text-md text-black  ml-2">
            
             {event.date.substring(0,10)}
        </p>
    </div>
                  <div class="flex items-center">
            <svg class="h-6 w-6 text-indigo-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
</svg>
            
             <p class="text-md text-black  ml-2">
            
            {event.venue}
        </p>
    </div>


        {event.category=="Movie"||event.category=="Movies"?
         <div class="flex items-center">
                  <svg class="h-6 w-6 text-indigo-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="5" cy="5" r="1" />  <circle cx="12" cy="5" r="1" />  <circle cx="19" cy="5" r="1" />  <circle cx="5" cy="12" r="1" />  <circle cx="12" cy="12" r="1" />  <circle cx="19" cy="12" r="1" />  <circle cx="5" cy="19" r="1" />  <circle cx="12" cy="19" r="1" />  <circle cx="19" cy="19" r="1" /></svg>
            
             <p class="text-md text-black  ml-2">
            
           SEAT: {data.seat} 
        </p>
    </div>
          
        :<></>}
             
    
              <div class="flex items-center">
                  <svg class="h-6 w-6 text-indigo-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="5" cy="5" r="1" />  <circle cx="12" cy="5" r="1" />  <circle cx="19" cy="5" r="1" />  <circle cx="5" cy="12" r="1" />  <circle cx="12" cy="12" r="1" />  <circle cx="19" cy="12" r="1" />  <circle cx="5" cy="19" r="1" />  <circle cx="12" cy="19" r="1" />  <circle cx="19" cy="19" r="1" /></svg>
            
             <p class="text-md text-black  ml-2">
            
           Ticket No: {data._id.slice(-4)} 
        </p>
    </div>
          
                  <div className="flex justify-between mt-3 item-center">
                    
                    <h1 className="text-xl font-bold text-black">${event.price}</h1>
                    <QRCode value={JSON.stringify(data)} size={180}></QRCode>

                  </div>
                </div>
              </div>




            </div> */}

<div className='md:block lg:block hidden'>

<div class="flex bg-white rounded-lg shadow    ">
    <div class="relative flex-none w-48">
        <img src={event?event.pic:""} alt="shopping image" class="absolute inset-0 object-cover w-full h-full rounded-lg md:scale-100 "/>
    </div>
  
    <div className=" p-2">
                  <h1 className="text-2xl font-bold text-black">{event.name}</h1>
                
                  <div class="flex items-center">
                  <svg class="h-6 w-6 text-indigo-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="4" y="5" width="16" height="16" rx="2" />  <line x1="16" y1="3" x2="16" y2="7" />  <line x1="8" y1="3" x2="8" y2="7" />  <line x1="4" y1="11" x2="20" y2="11" />  <rect x="8" y="15" width="2" height="2" /></svg> 
             <p class="text-md text-black  ml-2">
            
             {event.date.substring(0,10)}
        </p>
    </div>
                  <div class="flex items-center">
            <svg class="h-6 w-6 text-indigo-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
</svg>
            
             <p class="text-md text-black  ml-2">
            
            {event.venue}
        </p>
    </div>


        {event.category=="Movie"||event.category=="Movies"?
         <div class="flex items-center">
                  <svg class="h-6 w-6 text-indigo-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="5" cy="5" r="1" />  <circle cx="12" cy="5" r="1" />  <circle cx="19" cy="5" r="1" />  <circle cx="5" cy="12" r="1" />  <circle cx="12" cy="12" r="1" />  <circle cx="19" cy="12" r="1" />  <circle cx="5" cy="19" r="1" />  <circle cx="12" cy="19" r="1" />  <circle cx="19" cy="19" r="1" /></svg>
            
             <p class="text-md text-black  ml-2">
            
           SEAT: {data.seat} 
        </p>
    </div>
          
        :<></>}
             
    
              <div class="flex items-center">
                  <svg class="h-6 w-6 text-indigo-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="5" cy="5" r="1" />  <circle cx="12" cy="5" r="1" />  <circle cx="19" cy="5" r="1" />  <circle cx="5" cy="12" r="1" />  <circle cx="12" cy="12" r="1" />  <circle cx="19" cy="12" r="1" />  <circle cx="5" cy="19" r="1" />  <circle cx="12" cy="19" r="1" />  <circle cx="19" cy="19" r="1" /></svg>
            
             <p class="text-md text-black  ml-2">
            
           Ticket No: {data._id.slice(-4)} 
        </p>
    </div>
    <h1 className="text-xl font-bold text-black mb-2 ">${event.price}</h1>

                  <div className="flex justify-between  mt-3 item-center mr-4">
                    
                    <h1 className="text-sm  text-black mb-2 text-white ">${event.price}</h1>
                    <QRCode value={JSON.stringify(data)} size={150}></QRCode>

                  </div>
                </div>


                    </div>

</div>


                    <div className="flex bg-white rounded-lg shadow md:hidden lg:hidden block">
      <div className="relative overflow-hidden rounded-xl shadow-lg cursor-pointer h-90 w-80">
        <img
          alt="blog photo"
          src={event.pic || "https://images.unsplash.com/photo-1470229722913-7c0e2dbbafd3?auto=format&fit=crop&q=80&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&w=1470"}
          className="object-cover object-top w-full max-h-40"
        />
        <div className="w-full p-4 bg-white">
          <p className="font-medium text-purple-500 text-md">{event.name}</p>
          <div className="flex items-center">
            <svg className="h-6 w-6 text-purple-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
            <p className="text-md text-black">{event.venue}</p>
          </div>
          <div className="grid max-w-xl grid-cols-2 gap-4">
            <div className="flex items-center">
              <svg className="h-6 w-6 text-purple-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <circle cx="12" cy="12" r="10" />
                <polyline points="12 6 12 12 16 14" />
              </svg>
              <p className="text-md text-black ml-2">{event.time}</p>
            </div>
            <div className="flex items-center">
              <svg className="h-6 w-6 text-purple-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <p className="text-md text-black ml-2">{event.price} Price</p>
            </div>
            <div className="flex items-center">
              <svg className="h-6 w-6 text-purple-500" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" />
                <rect x="4" y="5" width="16" height="16" rx="2" />
                <line x1="16" y1="3" x2="16" y2="7" />
                <line x1="8" y1="3" x2="8" y2="7" />
                <line x1="4" y1="11" x2="20" y2="11" />
                <rect x="8" y="15" width="2" height="2" />
              </svg>
              <p className="text-md text-black ml-2">{event.date.substring(0, 10)}</p>
            </div>
          </div>
        </div>
        <div className="  transform  w-full p-4 bg-white rounded-lg shadow-lg flex flex-col items-center">
          <p className="text-md text-black mb-2">Ticket No: {data._id.slice(-4)}</p>
          <QRCode value={JSON.stringify(data)} size={150} />
        </div>
      </div>
    </div>


</>:<></>}

         
</div>



        </div>
     

    </div>










    {/* <Footer/> */}



{/* 




<div class=" lg:invisible visible fixed bottom-0 flex flex-row w-full ">

<div class="flex items-center w-full">
    <button type="button" class="w-full px-4 py-2 text-base font-medium text-black bg-white  rounded-l-md hover:bg-gray-100"
    onClick={()=>history.push("/home")}
    >
    <svg class="h-6 w-6 text-blue-400 content-center justify-center	ml-4"  width="26" height="26" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <polyline points="5 12 3 12 12 3 21 12 19 12" />  <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />  <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" /></svg>
    </button>
    <button type="button" class="w-full px-4 py-2 text-base font-medium text-black bg-white  hover:bg-gray-100"
        onClick={()=>history.push("/products")}

    >
    <svg class="h-6 w-6 text-blue-400 content-center justify-center	ml-4"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M4.5 12.5l8 -8a4.94 4.94 0 0 1 7 7l-8 8a4.94 4.94 0 0 1 -7 -7" />  <path d="M8.5 8.5l7 7" /></svg>
    </button>
    <button type="button" class="w-full px-4 py-2 text-base font-medium text-black bg-white  hover:bg-gray-100"
            onClick={()=>history.push("/profile")}

    >
    <svg class="h-6 w-6 text-blue-400 content-center justify-center	ml-4"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
</svg>
    </button>
    <button type="button" class="w-full px-4 py-2 text-base font-medium text-black bg-white rounded-r-md hover:bg-gray-100"
                onClick={()=>history.push("/cart")}

    >
    <svg class="h-6 w-6 text-blue-400 content-center justify-center	ml-4 "  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="9" cy="19" r="2" />  <circle cx="17" cy="19" r="2" />  <path d="M3 3h2l2 12a3 3 0 0 0 3 2h7a3 3 0 0 0 3 -2l1 -7h-15.2" /></svg>

    </button>
</div>


</div> */}


<Footer></Footer>
</main>
);


  
}


export default MyTicket