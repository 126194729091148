import React, {useContext, useRef, useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import DatePicker from 'react-datepicker'
import {UserContex} from '../App'
import AdminHeader from '../components/AdminHeader'

// import NavBar from './nav'

const EventsDashHome = ()=>{


  const history = useNavigate()
  const {state, dispatch}= useContext(UserContex)



  const  [data, setData]= useState([])

  const [search,setSearch] = useState("")

  const [addStock,setAddStock]= useState(false)
  const [editEmoloyee,setEdit]= useState(false)
  const[id,setId] = useState("")  
  const[name,setName]= useState("")
  const[phone,setPhone]= useState("")
  const[address,setAddress]= useState("")
  const[email,setEmail]= useState("")
  const[password,setPassword]= useState("")
  const[cid,setCID]= useState("")
  const[zone,setZone]= useState("")
  const[status,setStatus]= useState("")
  const[skills,setSkills]=useState([])
  const[detail,setDetail]=useState(null)
  const[salary,setSalary]= useState("")
  const [dob,setDob]= useState(new Date())
  const [url,setUrl] = useState("")  
  const [accountLink,setAccountLink] = useState("")  
  const [stripeVerification,setVerification]  = useState(false)
  const[progress,setProgress]= useState(false)


  useEffect(  ()=>{

    fetch('/getvendordetailevent',{
      method:"get",
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt"),
          "Content-Type":"application/json"
  
          
      },
     
  }).then(res=>res.json())
  .then(result=>{
    console.log("detai",result.details)
    setDetail(result.details)

    if(result.verified){setVerification(false)}
    else{ setVerification(true)

      setAccountLink(result.accountLink)
      console.log(result.accountLink)

    }
    // setEvent(result.ticket.event)

      // setOrderHistoryData(result.orders)
      
  })

    fetch('/getEmployee',{
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{
      setData(result.employees)
      
  })

  },[])



  const singleEmployee = (Itemid)=>{

    console.log(Itemid)
  fetch('/getSingleEmployee',{
    method:"post",
    headers:{
      Authorization: "Bearer " +localStorage.getItem("jwt"),
      "Content-Type":"application/json"

    },
    body:JSON.stringify({
      id:Itemid
    })
}).then(res=>res.json())
.then(result=>{


  // setDataEdit(result.stock)
  console.log(result.employee._id)
  setId(result.employee._id)
  setName(result.employee.name)
  setEmail(result.employee.email)
  setPhone(result.employee.phone)
  setZone(result.employee.zone)
  setCID(result.employee.cid)
  setSalary(result.employee.salary)
  setStatus(result.employee.status)

  //setDob(result.employee.dob)
  
  

          
})

}
  



  


  const postStock = ()=>{

    fetch('/signupCollector',{

      method:"post",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({
        name:name,
        email,
        password,
        phone,
        zone,
        cid,
        dob,
        salary,
      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
      }

      else{
        
        window.location.reload();
            // history.go(`/employee`)

      }
    })
    .catch(err=>{
      console.log(err)
    })

  }


  
  const uploadppt=({target:{files}})=>{

    setProgress(true)

    let data = new FormData()

    for(const file of files){

   data.append('uploadimage', file)
   data.append('name',file.name)
}
       fetch("/uploadimage", {

         method:"post",
         body: data
     })
     .then(res=>res.json())
     .then(data=>{

          setUrl(data.url)
          setProgress(false)

      

       
     })
     .catch(err=>{
         console.log(err)
     })

}
  



  const postEmployeeUpdate = ()=>{

    fetch('/updateEmployee',{

      method:"post",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({
        id:id,
        name:name,
        email,
        password,
        phone,
        status,
        zone,
        cid,
        dob,
        salary,
        
      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
      }

      else{
        window.location.reload();
        // history.go('/dashboard')
      }
    })
    .catch(err=>{
      console.log(err)
    })

  }





  const fetchPost=(query)=>{


    setSearch(query)

    fetch('/search-employee',{

      method:"post",
      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt"),

        "Content-Type":"application/json"

      },
      body:JSON.stringify({
        query
      })

    }).then(res=> res.json())
      .then(results=>{
        
        setData(results.employee)
      })


  }

  const deleteEmployee = (postId)=>{

    fetch(`/deleteEmployee/${postId}`,{
        method: "delete",
        headers:{
          "Content-Type":"application/json",
          Authorization: "Bearer " +localStorage.getItem("jwt")
        }
    }).then(res=>res.json())
    .then(result=>{
      history('/dashboard')

    }).catch(err=>{
        console.log(err)
    })
}
  
    //deleteEmployee
    

    const Logout = ()=>{
        localStorage.clear()
        dispatch({type:"CLEAR"})
            history(`/login`)
    }

return(

    
<main class="relative h-screen overflow-hidden bg-white  rounded-2xl">
    <div class="flex items-start justify-between">
       
        <div class="flex flex-col w-full pl-0 md:p-4 md:space-y-4">
      

          <AdminHeader page="events"></AdminHeader>




                <div class="overflow-auto h-screen pb-24 px-4 md:px-6">
                <h1 class="text-4xl font-semibold text-black">
                    {detail?detail.name:"Loading..."}
                </h1>
                <h2 class="text-md text-gray-400">
                    Manage Events!
                </h2>
               {stripeVerification? 
  <a href={accountLink}>
    <button type="button" class="py-2 px-4 flex justify-center items-center  bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
    <svg width="20" height="20" fill="currentColor" class="mr-2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
        <path d="M1344 1472q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm256 0q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm128-224v320q0 40-28 68t-68 28h-1472q-40 0-68-28t-28-68v-320q0-40 28-68t68-28h427q21 56 70.5 92t110.5 36h256q61 0 110.5-36t70.5-92h427q40 0 68 28t28 68zm-325-648q-17 40-59 40h-256v448q0 26-19 45t-45 19h-256q-26 0-45-19t-19-45v-448h-256q-42 0-59-40-17-39 14-69l448-448q18-19 45-19t45 19l448 448q31 30 14 69z">
        </path>
    </svg>
    Stripe Verification Pending
</button>

  </a>             
              

                
                :<></>}
                {/* <div class="flex my-6  w-full space-y-4 md:space-x-4 md:space-y-0 flex-col md:flex-row"> */}
                    


                    {/* <div class="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 my-4  justify-around ">
                        <div class="w-full shadow-lg">
                            <div class="shadow-lg px-4 py-6 w-full bg-white dark:bg-gray-700 relative">
                                <p class="text-2xl text-black dark:text-white font-bold">
                               Nu. {salesDataToday?  salesDataToday : <></> }   
                                </p>
                                <p class="text-gray-400 text-sm">
                                   Todays Sale
                                </p>
                            </div>
                            <div class="w-full h-2 bg-gray-100  drop-shadow-2xl">
                                    <div class="w-full h-full text-center text-xs text-white bg-green-400">
                                    </div>
                                </div>
                        </div>
                       
                       
                        <div class="w-full rounded-lg shadow-lg ">
                            <div class=" shadow-lg px-4 py-6 w-full bg-white dark:bg-gray-700 relative">
                                <p class="text-2xl text-black dark:text-white font-bold">
                               Nu. {saleDataMonth?  saleDataMonth : <></> }   
                                </p>
                                <p class="text-gray-400 text-sm">
                                    Sales This Month
                                </p>
                            </div>
                            <div class="w-full h-2 bg-gray-100">
                                    <div class="w-full h-full text-center text-xs text-white bg-blue-300">
                                    </div>
                                </div>
                        </div>



                        <div class="w-full shadow-lg">
                            <div class="shadow-lg px-4 py-6 w-full bg-white dark:bg-gray-700 relative">
                                <p class="text-2xl text-black dark:text-white font-bold">
                               Nu. {creditDataToday?  creditDataToday : <></> }   
                                </p>
                                <p class="text-gray-400 text-sm">
                                    Credit Today
                                </p>
                            </div>
                            <div class="w-full h-2 bg-gray-100">
                                    <div class="w-full h-full text-center text-xs text-white bg-green-400">
                                    </div>
                                </div>
                        </div>


                        <div class="w-full shadow-lg">
                            <div class="shadow-lg px-4 py-6 w-full bg-white dark:bg-gray-700 relative">
                                <p class="text-2xl text-black dark:text-white font-bold">
                                  Nu  {ExpenseDataMonth}
                                </p>
                                <p class="text-gray-400 text-sm">
                                     Expense This Month
                                </p>
                                <span class="rounded-full absolute p-4 bg-purple-500 top-2 right-4">
                                    <svg width="40" fill="currentColor" height="40" class="text-white h-4 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1362 1185q0 153-99.5 263.5t-258.5 136.5v175q0 14-9 23t-23 9h-135q-13 0-22.5-9.5t-9.5-22.5v-175q-66-9-127.5-31t-101.5-44.5-74-48-46.5-37.5-17.5-18q-17-21-2-41l103-135q7-10 23-12 15-2 24 9l2 2q113 99 243 125 37 8 74 8 81 0 142.5-43t61.5-122q0-28-15-53t-33.5-42-58.5-37.5-66-32-80-32.5q-39-16-61.5-25t-61.5-26.5-62.5-31-56.5-35.5-53.5-42.5-43.5-49-35.5-58-21-66.5-8.5-78q0-138 98-242t255-134v-180q0-13 9.5-22.5t22.5-9.5h135q14 0 23 9t9 23v176q57 6 110.5 23t87 33.5 63.5 37.5 39 29 15 14q17 18 5 38l-81 146q-8 15-23 16-14 3-27-7-3-3-14.5-12t-39-26.5-58.5-32-74.5-26-85.5-11.5q-95 0-155 43t-60 111q0 26 8.5 48t29.5 41.5 39.5 33 56 31 60.5 27 70 27.5q53 20 81 31.5t76 35 75.5 42.5 62 50 53 63.5 31.5 76.5 13 94z">
                                        </path>
                                    </svg>
                                </span>
                            </div>
                            <div class="w-full h-2 bg-gray-100">
                                    <div class="w-full h-full text-center text-xs text-white bg-blue-300">
                                    </div>
                                </div>
                        </div>
                    </div> */}

                    <br></br>



                {/* </div> */}



                
               
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 my-4">
                   
              
                   
                   
                  
                <Link to="/zone"> 
                    
                    <div class="shadow-lg rounded-2xl p-4 bg-white ">
    <div class="flex items-center">
    <svg class="h-8 w-8 text-purple-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z" />  <polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02" /></svg>
            <p class="text-md text-black ml-2">
            Events
        </p>
    </div>
    <div class="flex flex-col justify-start">
        <p class="text-gray-700 d text-2xl text-left font-bold my-4">
            Create Events
            
        </p>
        <div class="flex items-center text-green-500 text-sm">
        <svg class="h-8 w-8 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>

            <span>
            Create, Remove or Edit Events 

            </span>
            
        </div>
    </div>
</div>

                    </Link>


               



<Link to="/report"> 
                    
                    <div class="shadow-lg rounded-2xl p-4 bg-white ">
    <div class="flex items-center">
    <svg class="h-8 w-8 text-purple-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"/>
</svg>

        <p class="text-md text-black ml-2">
            Report
        </p>
    </div>
    <div class="flex flex-col justify-start">
        <p class="text-gray-700  text-2xl text-left font-bold my-4">
            Check the report
            
        </p>
        <div class="flex items-center text-green-500 text-sm">
        <svg class="h-8 w-8 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>

            <span>
            Generate reports for sold events  

            </span>
            
        </div>
    </div>
</div>

                    </Link>



                    <Link to="/qrcode"> 
                    
                    <div class="shadow-lg rounded-2xl p-4 bg-white ">
    <div class="flex items-center">
<svg class="h-8 w-8 text-purple-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z"/>
</svg>
        <p class="text-md text-black  ml-2">
            QR Code
        </p>
    </div>
    <div class="flex flex-col justify-start">
        <p class="text-gray-700  text-2xl text-left font-bold my-4">
            Read QR Code
            
        </p>
        <div class="flex items-center text-green-500 text-sm">
        <svg class="h-8 w-8 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>

            <span>
            Generate and Download QR codes  

            </span>
            
        </div>
    </div>
</div>

                    </Link>

                    {/* <Link to="/stripeconfig"> 
                    
                    <div class="shadow-lg rounded-2xl p-4 bg-white dark:bg-gray-800">
    <div class="flex items-center">
    <svg class="h-8 w-8 text-purple-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>

        <p class="text-md text-black dark:text-white ml-2">
            Payment 
        </p>
    </div>
    <div class="flex flex-col justify-start">
        <p class="text-gray-700 dark:text-gray-100 text-2xl text-left font-bold my-4">
            Configure Payment 
            
        </p>
        <div class="flex items-center text-green-500 text-sm">
        <svg class="h-8 w-8 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>

            <span>
            Configure Payment for your events

            </span>
            
        </div>
    </div>
</div>

                    </Link> */}



                   
<Link to="/sellers"> 
                    
                    <div class="shadow-lg rounded-2xl p-4 bg-white ">
    <div class="flex items-center">
    <svg class="h-8 w-8 text-purple-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="9" cy="7" r="4" />  <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />  <path d="M16 11h6m-3 -3v6" /></svg>
        <p class="text-md text-black ml-2">
            Sellers
        </p>
    </div>
    <div class="flex flex-col justify-start">
        <p class="text-gray-700  text-2xl text-left font-bold my-4">
            Manage Sellers
            
        </p>
        <div class="flex items-center text-green-500 text-sm">
        <svg class="h-8 w-8 text-green-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>

            <span>
            Manage Sellers to sell tickets 

            </span>
            
        </div>
    </div>
</div>

                    </Link>



            
                </div>









            </div>







            </div>
        </div>
    </main>

);


  
}


export default EventsDashHome